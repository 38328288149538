<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
            <el-form-item label="标题" prop="title">
                <el-input
                        v-model="queryParams.title"
                        placeholder="查询标题"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="发送时间">
                <el-date-picker
                        v-model="queryParams.dateRange"
                        size="small"
                        style="width: 240px"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
        </el-row>

        <el-table v-loading="loading" :data="configList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="主键" align="center" prop="id"/>
            <el-table-column label="标题" align="center" prop="title"/>
            <el-table-column label="发送时间" align="center" prop="createTime"/>
            <el-table-column label="内容" align="center" prop="content"/>
            <el-table-column label="发送人" align="center" prop="sendAccountId"/>
            <el-table-column label="接收人" align="center" prop="receiveAccountId"/>
            <el-table-column label="读状态" align="center" prop="isRead"/>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.limit"
                @pagination="getList"
        />

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="参数名称" prop="configName">
                    <el-input v-model="form.configName" placeholder="请输入参数名称"/>
                </el-form-item>
                <el-form-item label="参数键名" prop="configKey">
                    <el-input v-model="form.configKey" placeholder="请输入参数键名"/>
                </el-form-item>
                <el-form-item label="参数键值" prop="configValue">
                    <el-input v-model="form.configValue" type="textarea" placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="系统内置" prop="configType">
                    <el-select v-model="form.configType" filterable placeholder="请选择系统内置">
                        <el-option label="请选择字典生成" value=""/>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {listMessage} from "@/api/msg/myMessageManage";

    export default {
        name: "MyMessageManage",
        components: {},
        data() {
            return {
                // 遮罩层
                loading: true,
                // 导出遮罩层
                exportLoading: false,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 参数配置表格数据
                configList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                dateRange : "",
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10,
                    configName: null,
                    configKey: null,
                    configValue: null,
                    configType: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {}
            };
        },
        created() {
            this.getList();
        },
        methods: {
            /** 查询参数配置列表 */
            getList() {
                this.loading = true;
                listMessage(this.queryParams).then(response => {
                    this.configList = response.data.records;
                    this.total = response.data.total;
                    this.loading = false;
                });
            },
            // 取消按钮
            cancel() {
                this.open = false;
                this.reset();
            },
            // 表单重置
            reset() {
                this.form = {
                    configId: null,
                    configName: null,
                    configKey: null,
                    configValue: null,
                    configType: null,
                    createBy: null,
                    createTime: null,
                    updateBy: null,
                    updateTime: null,
                    remark: null
                };
                this.resetForm("form");
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
            // 多选框选中数据
            handleSelectionChange(selection) {
                this.ids = selection.map(item => item.configId)
                this.single = selection.length !== 1
                this.multiple = !selection.length
            },
            /** 新增按钮操作 */
            handleAdd() {
                this.reset();
                this.open = true;
                this.title = "添加参数配置";
            },
            /** 修改按钮操作 */
            handleUpdate(row) {
                this.reset();
                const configId = row.configId || this.ids
                getConfig(configId).then(response => {
                    this.form = response.data;
                    this.open = true;
                    this.title = "修改参数配置";
                });
            },
            /** 提交按钮 */
            submitForm() {
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.form.configId != null) {
                            updateConfig(this.form).then(response => {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            });
                        } else {
                            addConfig(this.form).then(response => {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            });
                        }
                    }
                });
            },
            /** 删除按钮操作 */
            handleDelete(row) {
                const configIds = row.configId || this.ids;
                this.$confirm('是否确认删除参数配置编号为"' + configIds + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function () {
                    return delConfig(configIds);
                }).then(() => {
                    this.getList();
                    this.msgSuccess("删除成功");
                })
            }
        }
    };
</script>
