<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
            <el-form-item label="网址" prop="domain">
                <el-input v-model="queryParams.domain" placeholder="网址" clearable size="small"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="pagedomainList">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addRoles"
        >新增
        </el-button>
        <el-table
                :data="tableData"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="domain"
                    label="网址"
                    width="280">
            </el-table-column>
            <el-table-column
                    prop="possId"
                    label="数据唯一标识"
                    width="180">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.possId" disabled width="100%">
                        <el-option
                                v-for="item in possessList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-s-grid"
                            @click="JumpPc(scope.row)"
                    >配置电脑
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-s-grid"
                            @click="Jump(scope.row)"
                    >配置手机
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="edit(scope.row,scope.row.id)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="deleteCurrent(scope.row.id)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30]"
                    :page.sync="queryParams.page"
                    :limit.sync="queryParams.limit"
                    @pagination="pagedomainList"
        />

        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogFormVisible"
                width="800px"
                @close="resetFrom('roleForm')"
        >
            <el-form
                    :model="dialogForm"
                    ref="roleForm"
                    label-width="100px"
            >
                <el-form-item label="网址：">
                    <el-row>
                        <el-col :span="4">
                            <el-select v-model="dialogForm.protocol" width="100%">
                                <el-option
                                        v-for="item in protocols"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8">
                            <el-input v-model="dialogForm.subDomain"></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-select v-model="dialogForm.domainName" width="100%">
                                <el-option
                                        v-for="item in options"
                                        :key="item.domainName"
                                        :label="item.domainName"
                                        :value="item.domainName">
                                </el-option>
                                <el-option label="手动填写" value=""></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4" style="text-align: center">
                            <el-button type="primary" icon="el-icon-plus" @click="addDomain" circle></el-button>
                        </el-col>
                    </el-row>
                    <el-row style="height: 16px;"><span style="font-size: 12px;color: #ff5d5d;">如果需要修改域名解析,请联系技术人员,修改域名解析时,很多时候网管局的DNS经常会刷新不成功</span>
                    </el-row>
                </el-form-item>
                <el-form-item label="唯一标识：">
                    <el-row>
                        <el-col :span="16">
                            <el-select v-model="dialogForm.possId" width="100%">
                                <el-option
                                        v-for="item in possessList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="success" @click="save('roleForm')">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog title="添加域名"
                   :visible.sync="domainDialogVisible"
                   width="500px"
                   @close="resetFrom('domain')">
            <el-form
                    :model="domainForm"
                    ref="domain"
                    label-width="100px"
            >
                <el-form-item label="云服务商：">
                    <el-select v-model="domainForm.provider" @change="findDomainByKey" width="100%">
                        <el-option
                                v-for="item in providerList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="secretId：">
                    <el-input v-model="domainForm.secretId" @change="findDomainByKey"></el-input>
                </el-form-item>
                <el-form-item label="secretKey：">
                    <el-input v-model="domainForm.secretKey" @change="findDomainByKey"></el-input>
                </el-form-item>
                <el-form-item label="选择域名：" v-show="domainList.length > 0">
                    <el-select v-model="domainForm.domainName" width="100%">
                        <el-option
                                v-for="item in domainList"
                                :key="item.id"
                                :label="item.domainName"
                                :value="item.domainName">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="domainDialogVisible = false">取消</el-button>
                <el-button type="success" @click="saveDoamin()">保存</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
    import * as api from "@/api/mini/pagedomain";
    import * as apiDomain from "@/api/mini/domain";
    import * as apiUtils from "../../utils/api";

    export default {
        name: "buyOrder",
        data() {
            return {
                // 总条数
                total: 0,
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10
                },
                tableData: [],
                dialogFormVisible: false,
                dialogTitle: "",
                dialogForm: {
                    protocol: 'http://',
                    domain: '',
                    domainName: '',
                    possId: '',
                    subDomain: 'abc'
                },
                dialogForm_null: Object.assign({}, this.dialogForm),
                rowIndex: 9999,
                options: [],
                domainDialogVisible: false,
                domainForm: {
                    provider: 'ALIYUN',
                    secretId: '',
                    secretKey: ''
                },
                possessList: [],
                providerList: [
                    {
                        id: "ALIYUN",
                        name: "阿里云"
                    },
                    {
                        id: "TENCENT",
                        name: "腾讯云"
                    }
                ],
                domainList: [],
                protocols: [
                    {
                        name: 'http://'
                    }
                ],
            }
        },
        mounted() {
            this.pagedomainList()
            this.findPossessList()
            this.findDomainList()
        },
        methods: {
            resetQuery() {
                this.resetForm("queryForm");
                this.queryParams = {};
                this.pagedomainList();
            },
            findDomainList() {
                apiDomain.list().then(res => {
                    this.options = res.data
                    if (this.options.length > 0) {
                        this.$set(this.dialogForm, 'domainName', this.options[0].domainName)
                    }
                })
            },
            saveDoamin() {
                apiDomain.save(this.domainForm).then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: res.msg
                        });
                        console.log(res)
                        this.domainDialogVisible = false;
                        this.$set(this.dialogForm, 'domainName', res.data.domainName)
                        this.options.push({id: res.data.id, domainName: res.data.domainName})
                        return
                    }
                    this.$message({
                        type: "error",
                        message: res.msg
                    });
                });
            },
            findDomainByKey() {
                if (this.domainForm.secretId && this.domainForm.secretKey) {
                    apiDomain.findDomainByKey(this.domainForm).then(res => {
                        if (!res.success) {
                            this.$message.error(res.msg)
                            return;
                        }
                        this.domainList = res.data.data
                    })
                }
            },
            addDomain() {
                this.domainDialogVisible = true;
            },
            findPossessList() {
                apiUtils.possessList().then(res => {
                    this.possessList = res.data
                })
            },
            /*
            * 获取列表数据
            * */
            pagedomainList() {
                api.getListPage(this.queryParams).then(res => {
                    this.tableData = res.data.data
                    this.total = res.data.count
                });
            },
            /*
            * 配置--跳转--微页面
            * */
            Jump(scope) {
                console.log(scope)
                // let host = scope.domain + '/customize/edit';
                let host = scope.domain + '/customize/edit';
                console.log(host)
                window.open(host, '_target')

            },
            JumpPc(scope){
                let host = scope.domain + '/customize/pc/edit';
                window.open(host, '_target')
            },
            //新增
            addRoles() {
                this.dialogTitle = "添加微页面";
                this.dialogForm = Object.assign({
                    protocol: 'http://',
                    domain: '',
                    domainName: this.options[0].domainName,
                    possId: '',
                    subDomain: ''
                }, {});
                this.dialogFormVisible = true;
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            edit(row, index) {
                console.log(row);
                this.dialogTitle = "修改微页面";
                this.dialogForm = Object.assign({
                    protocol: 'http://',
                    domain: '',
                    domainName: '',
                    possId: '',
                    subDomain: ''
                }, row);
                this.dialogFormVisible = true;
                this.rowIndex = index;
            },

            /*
            * 新增/修改 保存列表数据
            * */
            save(roleForm) {
                this.$refs[roleForm].validate(valid => {
                    if (valid) {
                        let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                        //如果是新的域名,则进行域名解析,判断是否可以进行解析
                        let form = this.dialogForm
                        let name = this.dialogForm.domainName === '' ? this.dialogForm.domainName : '.' + this.dialogForm.domainName
                        form['domain'] = this.dialogForm.protocol + this.dialogForm.subDomain + name
                        if (id || this.dialogForm.domainName === '') {
                            api.save(form).then(res => {
                                this.pagedomainList()
                                this.$message({
                                    type: "success",
                                    message: "修改成功！"
                                });
                                this.dialogFormVisible = false;
                            });
                        } else {
                            apiDomain.checkSubDomain(form).then(res => {
                                if (res.success) {
                                    api.save(form).then(rr => {
                                        this.pagedomainList()
                                        this.$message({
                                            message: rr.msg
                                        });
                                        this.dialogFormVisible = false;
                                    });
                                } else {
                                    this.$message({
                                        type: "error",
                                        message: res.msg
                                    });
                                }
                            })
                        }


                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                this.$confirm('是否删除域名?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.deleteCurrent(id).then(res => {
                        this.pagedomainList()
                        this.$message({
                            type: "success",
                            message: "删除成功"
                        });
                    })
                });
            }
        }
    }
</script>

<style scoped>

</style>
