import request from '@/utils/request.js';

const url = "/manage/goods";

//获取微页面商品列表
export function findPutGoods(goodsId, params) {
    params["goodsId"] = goodsId
    return request({
        url: `${url}/findPutGoods`,
        method: 'get',
        params: params
    })
}

//批量上下架商品
export function savePutGoods(exits = true, goodsId = 0, pageIds = []) {
    let pageStr = pageIds.join(",")
    return request.post(`${url}/savePutGoods`, {"exits": exits, "goodsId": goodsId, "pageIds": pageStr})
}

//添加列表数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: params
    })
}

//删除当前列表
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}

//查询数据
export function get(id) {
    return request({
        url: `${url}/get/${id}`,
        method: 'get'
    })
}

//获取绑定数据
export function getChild(id) {
    return request({
        url: `${url}/getChild/${id}`,
        method: 'get'
    })
}

//创建新商品
export function createNew(oid) {
    return request({
        url: `${url}/createNew/${oid}`,
        method: 'get'
    })
}

//绑定商品和额外商品
export function bind(id, oid) {
    return request({
        url: `${url}/bind/${id}/${oid}`,
        method: 'get'
    })
}

//解绑商品和额外商品
export function ubind(id, oid) {
    return request({
        url: `${url}/ubind/${id}/${oid}`,
        method: 'get'
    })
}

//查询额外商品列表
export function findOther(params) {
    return request({
        url: `${url}/findOther`,
        method: 'get',
        params: params
    })
}

/**
 * 批量导入外部订单
 * @param params 传递参数为查询条件参数
 */
export function batchOtherImportXiaoe(params) {
    params['ggdChannel'] = 'XIAOE'
    params['ggdType'] = 'CHECK'
    return request.post(`${url}/batchOtherImport`, JSON.stringify(params));
}

/**
 * 单个导入外部订单
 * @param params 传递参数为单个信息的全部数据
 */
export function otherImportXiaoe(params) {
    params['ggdChannel'] = 'XIAOE'
    params['ggdType'] = 'RADIO'
    return request.post(`${url}/batchOtherImport`, JSON.stringify(params));
}

/**
 * 单个导入外部订单
 * @param params 传递参数为单个信息的全部数据
 */
export function otherImportSg(params) {
    params['ggdChannel'] = 'XIAOE'
    params['ggdType'] = 'RADIO'
    return request.post(`${url}/batchOtherImport`, JSON.stringify(params));
}

export function querySgGoodsList(query) {
    return request({
        url: `${url}/sg/listPage`,
        method: 'GET',
        params: query
    })
}

/**
 * 创建商品
 * @param id 外部商品id
 * @param channel 创建来源
 * @returns {*}
 */
export function createGoods(id, channel) {
    return request({
        url: `${url}/create-goods?id=${id}&channel=${channel}`,
        method: 'POST'
    })
}

/**
 * 开课
 * @param goodsId
 * @param telephone
 * @returns {*}
 */
export function openCourse(goodsId, telephone) {
    return request({
        url: `${url}/open-course?goodsId=${goodsId}&telephone=${telephone}`,
        method: 'POST'
    })
}