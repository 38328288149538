import request from '@/utils/request.js';

const url = "/manage/crm/all-goods";

export function list() {
    return request({
        url: `${url}/list`,
        method: 'GET'
    })
}

export function listPage(params) {
    return request({
        url: `${url}/listPage`,
        method: 'GET',
        params: params
    })
}

export function getOne(id) {
    return request({
        url: `${url}/one?id=${id}`,
        method: 'GET'
    })
}

export function addGoods(params) {
    return request({
        url: `${url}/add`,
        method: 'POST',
        data: JSON.stringify(params)
    })
}

export function deleteGoods(id){
    return request({
        url: `${url}/delete?id=${id}`,
        method: 'DELETE',
    })
}

export function updateGoods(params) {
    return request({
        url: `${url}/update`,
        method: 'PUT',
        data: JSON.stringify(params)
    })
}

export function changeGoodsStatus(params) {
    return request({
        url: `${url}/disabled-enabled?id=${params}`,
        method: 'PUT'
    })
}

export function getSubjectList() {
    return request({
        url: `${url}/subjectList`,
        method: 'GET'
    })
}

export function getByIds(ids) {
    return request({
        url: `${url}/getByIds?ids=${ids}`,
        method: 'GET'
    })
}

export function getListPageExcludeIds(params) {
    return request({
        url: `${url}/getListPageExcludeIds`,
        method: 'GET',
        params: params
    })
}

/**
 * 查询三个商品列表
 * @param params
 * @returns {*}
 */
export function finSgGoodsPage(params) {
    return request({
        url: `${url}/goods-sg/page`,
        method: 'GET',
        params: params
    })
}