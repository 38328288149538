<template>
  <div class="about">
    <el-button @click="drawer = true" type="primary" style="margin-left: 16px;">
      点我打开
    </el-button>
    <el-drawer
        title="我是标题"
        size="60%"
        ref="rightDrawer"
        :visible.sync="drawer"
        :with-header="false">
      <span style="margin: 10px"> &nbsp;</span>
      <Detail :id="851463" @closeTheDrawer="closeTheDrawer"></Detail>
    </el-drawer>
  </div>
</template>
<script>
import Detail from "@/components/details/Detail";
import * as api from "@/utils/api"

export default {
  name: 'About',
  components: {
    Detail
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods:{
    closeTheDrawer:function (){
      this.drawer = false
    }
  },
  mounted() {
    // api.customerMessage({id:851463}).then(res => {
    //   console.log(res,'res')
    // })
  }
}
</script>
<style lang="less" scoped>
/deep/.el-drawer__body {
  overflow: auto;
  /* overflow-x: auto; */
}
/deep/ .el-drawer /deep/ .rtl {
  overflow: scroll
}

</style>
