import request from '@/utils/request.js';

const url = "/manage/crm/tags";

//获取分页数据
export function paging(query) {
    return request({
        url: `${url}/paging`, method: 'get', params: query
    })
}

/**
 * 获取条件选项(默认启用数据)
 * @param query
 */
export function options(query) {
    return request({
        url: `${url}/options`, method: 'get', params: query
    })
}

/**
 * 获取所有数据,不分页
 * @param query
 */
export function all() {
    return request({
        url: `${url}/all`, method: 'get'
    })
}


/**
 * 保存
 * @param params
 */
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params))
}

/**
 * 删除
 * @param params
 */
export function removeById(id) {
    return request.delete(`${url}/delete?id=${id}`)
}

/**
 * 修改提醒状态
 * @param id
 * @param remind
 */
export function updateRemind(id, remind) {
    return request.post(`${url}/updateRemind/${id}/${remind}`)
}

/**
 * 发送标签消息通知
 * @param opporId
 */
export function sendMessage(opporId) {
    return request.post(`${url}/sendMessage/${opporId}`)
}