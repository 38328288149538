<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" @submit.native.prevent>
            <el-form-item label="字典名称" prop="dictName">
                <el-input
                        v-model="queryParams.dictName"
                        clearable
                        size="small"
                        style="width: 240px"
                        @keyup.enter.native="handleQuery"
                        @submit.native.prevent="false"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">

        </el-row>

        <el-table v-loading="loading" :data="dictList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="字典名称" prop="dictName" :show-overflow-tooltip="true" width="150">
                <template slot-scope="scope">
                    <router-link :to="'/system/dictionary/child/' + scope.row.id" class="link-type">
                        <span>{{ scope.row.dictName }}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center" width="100">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.dictStatus"
                               :active-value="0"
                               :inactive-value="1"
                               @change="changeStatus(scope.row)"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="修改时间" align="center" prop="updateTime" width="180"></el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(scope.row)"
                            v-permission="'sys:dictionary:update'"
                    >修改
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.limit"
                @pagination="getList"
        />

        <!-- 添加或修改角色配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="字典名称" prop="dictName">
                    <el-input v-model="form.dictName" placeholder="请输入字典名称"/>
                </el-form-item>
                <el-form-item label="排序值" prop="dictSort">
                    <el-input v-model="form.dictSort"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as dictApi from "@/api/system/dictionary";

export default {
    name: "dictionary",
    data() {
        return {
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 总条数
            total: 0,
            // 角色表格数据
            dictList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10
            },
            // 表单参数
            form: {
                dictSort: 0
            },
            // 表单校验
            rules: {},
        };
    },
    created() {
        this.getList();
    },
    methods: {
        changeStatus(row) {
            let status = 0
            if (row.dictStatus) {
                status = 1
            }
            let dict = {
                id: row.id,
                dictStatus: status
            }
            dictApi.saveDictionary(dict).then(res => {
                if (res.success) {
                    this.msgSuccess(res.msg);
                    this.getList();
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        /** 查询角色列表 */
        getList() {
            this.loading = true;
            this.queryParams.dictParent = 0
            dictApi.listDictionary(this.queryParams).then(
                res => {
                    this.dictList = res.data.records;
                    this.total = res.data.total;
                    this.loading = false;
                }
            );
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length != 1
        },
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
            this.form.dictStatus = 0
            this.form.dictParent = 0
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    dictApi.saveDictionary(this.form).then(res => {
                        if (res.success) {
                            this.msgSuccess(res.msg);
                            this.open = false;
                            this.getList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            });
        },
        reset() {
            this.form = {};
            this.resetForm("form");
        },
        handleUpdate(row) {
            this.reset();
            const id = row.id
            dictApi.get(id).then(response => {
                this.form = response.data;
                this.open = true;
                this.title = "修改用户";
            });
        },
        handleDelete(row) {
            dictApi.deleteDictionary(row.id).then(res => {
                if (res.success) {
                    this.msgSuccess(res.msg);
                    this.open = false;
                    this.getList();
                } else {
                    this.$message.warning(res.msg)
                }
            });
        }
    }
};
</script>
