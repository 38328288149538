import request from '@/utils/request.js';


const url = "/manage/crm/backdeplog";

//获取列表数据
export function listPage(query) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: query
    })
}

