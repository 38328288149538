import request from '@/utils/request.js';

const url = "/manage/crm/back/weight";

//获取列表数据
export function listBackWeight(query) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: query
    })
}

//保存/修改
export function saveBackWeight(query) {
    return request.post(`${url}/save`, query);
}

//开始任务
export function startTask(params) {
    return request.post(`${url}/startTask`, params);
}

//配置
export function saveOption(id, options) {
    return request({
        url: `${url}/saveOption?id=${id}&options=${options}`,
        method: 'get'
    })
}

//根据ID获取信息
export function getBackWeightById(id) {
    return request.get(`${url}/getBackWeightById/${id}`);
}

//删除
export function deleteBackWeight(id) {
    return request.delete(`${url}/${id}`);
}

/**
 * 获取当前统计
 */
export function info() {
    return request.get(`${url}/info`);
}
