<template>
  <div id="app">
<!--    <div id="nav">-->
    <!--      <router-link to="/login">login</router-link>-->
<!--    </div>-->
    <router-view/>
  </div>
</template>

<script>
import watermark from "watermark-dom";
import {getUserName} from "@/utils/auth";

export default {
    name: 'app',
    mounted() {
        let options = {
            // 水印内容
            watermark_txt: getUserName(),
            // 水印起始位置x轴坐标
            watermark_x: 20,
            // 水印起始位置Y轴坐标
            watermark_y: 20,
            // 水印行数
            watermark_rows: 5,
            // 水印列数
            watermark_cols: 10,
            // 水印x轴间隔
            watermark_x_space: 100,
            // 水印y轴间隔
            watermark_y_space: 100,
            // 水印字体颜色
            watermark_color: '#aaa',
            // 水印透明度
            watermark_alpha: 0.2,
            // 水印字体大小
            watermark_fontsize: '15px',
            // 水印字体
            watermark_font: '微软雅黑',
            // 水印宽度
            watermark_width: 210,
            // 水印长度
            watermark_height: 80,
            // 水印倾斜度数
            watermark_angle: 15
        }
        watermark.load(options);
    },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.max-z-index {
  z-index: 9999 !important;
}
</style>
