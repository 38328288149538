<template>
    <div>
        <el-row>
            <el-col :span="22">
                <div class="mini-title">
                    请勾选需要在名片首咨展示的列表内容，没勾选内容不会展现
                </div>
            </el-col>
            <el-col :span="2">
                <div style="text-align: end;">
                    <el-button type="success" icon="el-icon-check" size="mini"
                               @click="handleUpdate('FIRST_TABLES')">保存
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <table class="itxst" align="center">
                <draggable v-model="firsts" animation="200" force-fallback="true"
                           filter=".disable" :move="onMove">
                    <tr v-for="s in firsts" :key="s.value">
                        <td width="80px">
                            <template>
                                <el-checkbox size="medium" v-model="s.status"></el-checkbox>
                            </template>
                        </td>
                        <td width="90%" @click="ischeck(s)">{{ s.label }}</td>
                        <td width="50px">
                            <el-tooltip class="item" effect="dark"
                                        content="按住拖动可改变展示顺序"
                                        placement="top">
                                <i class="el-icon-s-fold"></i>
                            </el-tooltip>
                        </td>
                    </tr>
                </draggable>
            </table>
        </el-row>
    </div>
</template>

<script>
import * as searchApi from "@/api/system/searchDeploy";
import draggable from 'vuedraggable'

export default {
    name: "FirstTables",
    components: {draggable},
    data() {
        return {
            firsts: [],
        }
    },
    mounted() {
        this.list()
    },
    methods: {
        list() {
            searchApi.list({classify: "FIRST_TABLES"}).then(res => {
                this.firsts = res.data;
            });
        },
        handleUpdate(name) {
            const loading = this.$loading({
                lock: true,
                text: '保存中....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = []
            let shows = this.firsts
            for (let i in shows) {
                shows[i].sort = i
                data.push(shows[i])
            }
            searchApi.batchUpdate(data).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.list();
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close();
            }).catch(r => {
                loading.close();
            })

        },
        ischeck(o) {
            o.status = o.status ? false : true;
        },
        onMove(e) {
            if (e.relatedContext.element.name == "telephone") {
                return false;
            }
            return true
        },
    },
}
</script>

<style scoped>
/*定义要拖拽元素的样式*/
table.itxst {
    width: 100%;
    color: #333333;
    text-align: center;
    border-collapse: collapse;
}

table.itxst th {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #dedede;
}

table.itxst td {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #ffffff;
}

table.itxst tr {
    cursor: pointer;
}

table.itxst td.move:hover {
    cursor: move;
}

.mini-title {
    font-size: 12px;
    color: #909399;
}
</style>