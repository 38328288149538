<template>
  <div class="app-container">
    <el-row type="flex" justify="space-around" align="center" :gutter="20">
      <GgdCard background="#409EFF" title="待回库机会总数" :content="sum" unit="个"></GgdCard>
      <GgdCard background="#FF88A7" title="1小时内回库数" :content="hour" unit="个"></GgdCard>
      <GgdCard background="#7B5EEA" title="今日待回库数" :content="today" unit="个"></GgdCard>
    </el-row>
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px"
             @submit.native.prevent>
      <el-form-item label="名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
        >删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="historyDataSync = true"
        >批量修改历史数据
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="waitBackSync = true"
        >查询待回库数据
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="historyBackSync = true"
        >回库历史记录
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="warning"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="backTaskSync = true;findBackTaskList()"
        >延迟回库任务
        </el-button>
      </el-col>
    </el-row>
    <span style="color: red;font-size: 14px;">*操作行为指:学习顾问对机会进行:备注,修改信息,添加机会标签</span>
    <el-table max-height="600" v-loading="loading" :data="backWeightList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="主键" align="center" width="55" prop="id"/>
      <el-table-column label="名称" align="center" prop="name"/>
      <el-table-column label="行为" align="center" prop="type">
        <template v-slot="solt">
          <span v-if="solt.row.type === 1">
             <el-tooltip class="item" effect="dark" content="学习顾问主动领取" placement="top">
               <span>领取</span></el-tooltip>
          </span>
          <span v-else-if="solt.row.type === 6">
            <el-tooltip class="item" effect="dark" content="当机会成单时" placement="top">
              <span>成单</span></el-tooltip>
          </span>
          <span v-else-if="solt.row.type === 7">
            <el-tooltip class="item" effect="dark" content="机会归属为分配" placement="top">
              <span>分配</span></el-tooltip>
          </span>
          <span v-else-if="solt.row.type === 99">
            <el-tooltip class="item" effect="dark" content="学习顾问对机会进行:备注,修改信息,添加机会标签"
                        placement="top">
              <span>操作</span></el-tooltip>
          </span>
          <span v-else-if="solt.row.type === 18">
            <el-tooltip class="item" effect="dark" content="添加微信" placement="top">
              <span>添加微信</span></el-tooltip>
          </span>
          <span v-else-if="solt.row.type === 16">
            <el-tooltip class="item" effect="dark" content="冻结机会" placement="top">
              <span>冻结机会</span></el-tooltip>
          </span>
          <span v-else-if="solt.row.type === 3">
            <el-tooltip class="item" effect="dark" content="拨打" placement="top">
              <span>拨打</span></el-tooltip>
          </span>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column label="权重" align="center" prop="weight">
        <template v-slot="solt">
          <span v-if="solt.row.weight === 0">零级</span>
          <span v-else-if="solt.row.weight === 1">一级</span>
          <span v-else-if="solt.row.weight === 2">二级</span>
          <span v-else-if="solt.row.weight === 3">三级</span>
          <span v-else-if="solt.row.weight === 4">四级</span>
          <span v-else-if="solt.row.weight === 98">五级</span>
          <span v-else-if="solt.row.weight === 99">顶级</span>
          <span v-else>其他级别</span>
        </template>
      </el-table-column>
      <el-table-column label="延迟时长(小时)" align="center" prop="delay"/>
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-select v-model="scope.row.status" filterable disabled>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="0"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"/>
      <el-table-column label="修改时间" align="center" prop="updateTime"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="60vw" top="15vh" append-to-body>
      <el-form ref="formRef" :model="form" label-width="150px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称"/>
        </el-form-item>
        <el-form-item label="行为" prop="type">
          <el-select v-model="form.type">
            <el-option :value="1" label="领取"></el-option>
            <el-option :value="7" label="分配"></el-option>
            <el-option :value="6" label="成单"></el-option>
            <el-option :value="18" label="添加微信"></el-option>
            <el-option :value="16" label="冻结机会"></el-option>
            <el-option :value="3" label="拨打"></el-option>
            <el-option :value="99" label="操作"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="weight">
          <el-tooltip effect="dark" placement="bottom" slot="label">
            <div slot="content">
              <span>权重越大越优先执行,默认级别最低</span>
            </div>
            <span>权重</span>
          </el-tooltip>
          <el-select v-model="form.weight">
            <el-option :value="0" label="零级"></el-option>
            <el-option :value="1" label="一级"></el-option>
            <el-option :value="2" label="二级"></el-option>
            <el-option :value="3" label="三级"></el-option>
            <el-option :value="4" label="四级"></el-option>
            <el-option :value="98" label="五级"></el-option>
            <el-option :value="99" label="顶级"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="delay">
          <el-tooltip effect="dark" placement="bottom" slot="label">
            <div slot="content">
              <span>满足规则的机会延迟回库时间（小时）</span>
            </div>
            <span>延迟时长</span>
          </el-tooltip>
          <el-input v-model="form.delay" type="number" min="0" step="1" max="10000" placeholder="延迟时长(小时)"/>
        </el-form-item>
        <el-form-item prop="cover">
          <el-tooltip effect="dark" placement="bottom" slot="label">
            <div slot="content">
              <span>当新回库计算时间早于原回库时间时</span>
            </div>
            <span style="font-size: 10px">当新回库时间早于旧回库时间时，是否覆盖</span>
          </el-tooltip>
          <el-switch
              v-model="form.coverSwitch"
              active-text="开启"
              inactive-text="关闭">
          </el-switch>
        </el-form-item>
        <div v-show="form.coverSwitch">
          <el-form-item prop="cover">
            <el-tooltip effect="dark" placement="bottom" slot="label">
              <div slot="content">
                <span>当新回库计算时间早于原回库时间时</span>
              </div>
              <span>是否覆盖</span>
            </el-tooltip>
            <el-select v-model="form.cover">
              <el-option :value="true" label="覆盖"></el-option>
              <el-option :value="false" label="不覆盖"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-divider></el-divider>
        <el-form-item label="唯一配置" prop="optionList">
          <el-tooltip effect="dark" placement="bottom" slot="label">
            <div slot="content">
              <span>归属于勾选唯一配置的机会执行此规则</span>
            </div>
            <span>唯一配置11</span>
          </el-tooltip>
          <el-switch
              active-text="全选"
              inactive-text="取消全选"
              v-model="addSelectFlag1" @change="checkAll1">
          </el-switch>
          <el-checkbox-group size="mini" v-model="form.possessList">
            <el-checkbox border v-for="poss in possList" :label="poss.id" :key="poss.id" @change="checkedPossChange()">{{ poss.name }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item>
          <el-tooltip effect="dark" placement="bottom" slot="label">
            <div slot="content">
              <span>当选中属性发生改变则生效</span>
            </div>
            <span>生效规则</span>
          </el-tooltip>
          <div v-if="form.type === 6">
            <el-form-item>
              <div style="display: flex;flex-direction: row;flex-wrap: nowrap;">
                <el-input v-model="tson.min" placeholder="最小金额"></el-input>
                -
                <el-input v-model="tson.max" placeholder="最大金额"></el-input>
              </div>
            </el-form-item>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="规则改变题型" :visible.sync="historyUpdateSync" width="60vw" top="15vh" append-to-body>
      此规则，只针对当前时刻（时间）后发生操作行为生效。<br/>
      历史行为不发生改变，依据过去的规则。
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="historyUpdateSync = false;historyDataSync = true">修改历史机会</el-button>
        <el-button @click="historyUpdateSync = false;getList">不修改历史机会</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改历史数据" :visible.sync="historyDataSync" width="800px" top="15vh" append-to-body>
       <span>当有权重级别更高级的规则生效，那权重级别低的规则将不生效。权重级别相同时，以最新的行为覆盖回库时间。
例如：权重5级生效的回库时间是1月10日，那么，所有权重级别低于5级的行为发生后，回库时间不会被更改。</span>
      <el-form :model="historyForm" label-width="150px">
        <el-form-item label="行为" prop="type">
          <el-select v-model="historyForm.type">
            <el-option :value="1" label="领取"></el-option>
            <el-option :value="7" label="分配"></el-option>
            <el-option :value="6" label="成单"></el-option>
            <el-option :value="18" label="添加微信"></el-option>
            <el-option :value="16" label="冻结机会"></el-option>
            <el-option :value="3" label="拨打"></el-option>
            <el-option :value="99" label="操作"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成单金额" v-if="historyForm.type === 6">
          <div style="display: flex;flex-direction: row;flex-wrap: nowrap;align-items: center;">
            <el-input v-model="historyData.min" placeholder="最小金额"></el-input>
            -
            <el-input v-model="historyData.max" placeholder="最大金额"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="在时间后操作的数据" v-else>
          <el-select style="width: 80px" v-model="historyData.compareType">
            <el-option value="le" label="&lt;="></el-option>
            <el-option value="lt" label="&lt;"></el-option>
            <el-option value="gt" label="&gt;"></el-option>
            <el-option value="ge" label="&gt;="></el-option>
          </el-select>
          <el-date-picker
              v-model="historyData.sdate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
          <el-time-select
              v-model="historyData.stime"
              :picker-options="{start: '00:00',step: '00:15',end: '23:30'}"
              placeholder="选择时间">
          </el-time-select>
        </el-form-item>

        <el-form-item label="权重 &lt;= ">
          <el-select v-model="historyForm.weight">
            <el-option :value="0" label="零级"></el-option>
            <el-option :value="1" label="一级"></el-option>
            <el-option :value="2" label="二级"></el-option>
            <el-option :value="3" label="三级"></el-option>
            <el-option :value="4" label="四级"></el-option>
            <el-option :value="98" label="五级"></el-option>
            <el-option :value="99" label="顶级"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="唯一配置" prop="optionList">
          <el-tooltip effect="dark" placement="bottom" slot="label">
            <div slot="content">
              <span>归属于勾选唯一配置的机会执行此规则</span>
            </div>
            <span>唯一配置</span>
          </el-tooltip>
          <el-switch
              active-text="全选"
              inactive-text="取消全选"
              :value="historySelectFlag" @change="checkAll2">
          </el-switch>
          <el-checkbox-group size="mini" v-model="historyForm.possessList">
            <el-checkbox-button v-for="poss in possList" :label="poss.id" :key="poss.id">{{ poss.name }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="距离当前时刻(时间)几小时后,回库" prop="delay">
          <el-input v-model="historyForm.delay"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="historySubmitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>

    </el-dialog>

    <!--  查看回库任务  -->
    <el-dialog title="延迟回库任务" :visible.sync="backTaskSync" width="80%" append-to-body>
      <el-form :model="backTaskParam" ref="backParam" :inline="true" label-width="80px">
        <el-form-item label="创建人" prop="createBy">
            <el-input v-model="backTaskParam.createBy" size="small"></el-input>
        </el-form-item>
          <el-form-item label="任务状态" prop="status">
              <el-select v-model="backTaskParam.status" clearable size="small">
                  <el-option value="" label="全部"></el-option>
                  <el-option :value="0" label="待执行"></el-option>
                  <el-option :value="1" label="已执行"></el-option>
                  <el-option :value="2" label="执行失败"></el-option>
                  <el-option :value="9" label="停止"></el-option>
              </el-select>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="findBackTaskList">搜索</el-button>
          <el-button type="success" icon="el-icon-search" size="mini" @click="restartTaskForm();addBackTaskSync=true">添加任务</el-button>
        </el-form-item>
      </el-form>
      <el-table max-height="500" v-loading="loading" width="100%" :data="backTaskList">
        <el-table-column type="selection" width="55" align="center"/>
          <el-table-column label="选择用户" align="center" prop="accountList">
              <template v-slot="scope">
                  <content v-for="acc in scope.row.accountList">
                      <content v-for="item in adviserList">
                              <span v-if="acc === item.id">{{item.nickName}} </span>
                      </content>
                  </content>
              </template>
          </el-table-column>
          <el-table-column label="选择部门" align="center" prop="deptList">
              <template v-slot="scope">
                  <content v-for="dept in scope.row.deptList">
                      <content v-for="item in deptList">
                          <span v-if="dept === item.id">{{item.title.replaceAll("—","")}} </span>
                      </content>
                  </content>
              </template>
          </el-table-column>
          <el-table-column label="执行时间" align="center" prop="executeTime"/>
          <el-table-column label="延迟时间" align="center" prop="time">
              <template v-slot="scope">
                  <span>{{scope.row.time}}</span>
              </template>
          </el-table-column>
          <el-table-column label="任务状态" align="center" prop="status">
              <template v-slot="scope">
                  <span v-if="scope.row.status === 0">待执行</span>
                  <span v-else-if="scope.row.status === 1">已执行</span>
                  <span v-else-if="scope.row.status === 2">执行失败</span>
                  <span v-else>停止</span>
              </template>
          </el-table-column>
          <el-table-column label="创建人" align="center" prop="createBy"/>
          <el-table-column label="创建时间" align="center" prop="createTime"/>
          <el-table-column label="操作" fixed="right">
              <template v-slot="scope">
                  <el-button v-if="scope.row.status === 0" type="warning"  size="mini" @click="stopTask(scope.row)">停止任务</el-button>
              </template>
          </el-table-column>
      </el-table>
      <pagination
          :total="backTaskParam.total"
          :page.sync="backTaskParam.current"
          :limit.sync="backTaskParam.size"
          @pagination="findBackTaskList"
      />
    </el-dialog>

    <!--  添加延迟回库任务  -->
    <el-dialog title="添加延迟回库任务" :visible.sync="addBackTaskSync" append-to-body>
      <el-form :model="addBackTaskForm" ref="addBackTaskForm" label-width="120px">
        <el-form-item label="执行时间" prop="executeTime">
          <el-date-picker
              v-model="addBackTaskForm.executeDay"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
          <el-time-select
              v-model="addBackTaskForm.executeTime"
              :picker-options="{
                start:'00:00',
                step: '00:30',
                end:'23:30'
              }"
              placeholder="选择时间">
          </el-time-select>
        </el-form-item>
        <el-form-item label="延迟时间(小时)" prop="time">
          <el-input v-model="addBackTaskForm.time"></el-input>
        </el-form-item>
        <el-form-item label="人员" prop="accountIdList">
          <el-select v-model="addBackTaskForm.accountList" multiple>
            <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" prop="deptList">
          <el-select v-model="addBackTaskForm.deptList" multiple>
            <el-option
                v-for="dept in deptList"
                :value="dept.id"
                :key="dept.id"
                :label="dept.title"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitTask">确 定</el-button>
        <el-button @click="addBackTaskSync=false">取 消</el-button>
      </div>
    </el-dialog>

    <!--  回库历史记录 start  -->
    <el-dialog title="回库历史记录" :visible.sync="historyBackSync" width="800px" top="12vh" append-to-body>
      <el-form :model="historyParam" ref="backParam" :inline="true" label-width="120px">
        <el-form-item label="学习顾问名称" prop="accountId">
          <el-select v-model="historyParam.accountId" filterable class="condition-select" placeholder="请选择"
                     size="small">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机会主键" prop="opporId">
          <el-input
              v-model="historyParam.opporId"
              placeholder="请输入机会主键"
              clearable
              size="small"
          />
        </el-form-item>
        <el-form-item label="机会类型" prop="type">
          <el-select v-model="historyParam.type" size="small">
            <el-option :value="null" label="全部"></el-option>
            <el-option :value="1" label="领取"></el-option>
            <el-option :value="7" label="分配"></el-option>
            <el-option :value="6" label="成单"></el-option>
            <el-option :value="18" label="添加微信"></el-option>
            <el-option :value="16" label="冻结机会"></el-option>
            <el-option :value="3" label="拨打"></el-option>
            <el-option :value="99" label="操作"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回库时间" prop="historyTimes">
          <el-date-picker
              v-model="historyTimes"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="listHistory">搜索</el-button>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="backHistory">回退数据</el-button>
        </el-form-item>
      </el-form>
      <el-table max-height="500" v-loading="loading" width="100%" :data="historyList">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="机会主键" align="center" prop="opporId"/>
        <el-table-column label="归属学习顾问" align="center" prop="accountId">
          <template v-slot="scope">
            <el-select v-model="scope.row.accountId" filterable class="condition-select" disabled placeholder="请选择"
                       size="small">
              <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="回库时间" align="center" prop="createTime"/>
        <el-table-column label="额外信息" align="center" prop="remarks"/>
      </el-table>
      <pagination
          :total="historyParam.total"
          :page.sync="historyParam.page"
          :limit.sync="historyParam.limit"
          @pagination="listHistory"
      />
    </el-dialog>
    <!--  回库历史记录  -->

    <!--  待回库列表 start  -->
    <el-dialog title="待回库数据" :visible.sync="waitBackSync" width="800px" top="12vh" append-to-body>
      <el-form :model="backParam" ref="backParam" :inline="true" label-width="120px">
        <el-form-item label="学习顾问名称" prop="accountName">
          <el-input
              v-model="backParam.accountName"
              placeholder="请输入名称"
              clearable
              size="small"
          />
        </el-form-item>
        <el-form-item label="机会主键" prop="opporId">
          <el-input
              v-model="backParam.opporId"
              placeholder="请输入机会主键"
              clearable
              size="small"
          />
        </el-form-item>
        <el-form-item label="行为" prop="type">
          <el-select v-model="backParam.type" size="small">
            <el-option :value="1" label="领取"></el-option>
            <el-option :value="7" label="分配"></el-option>
            <el-option :value="6" label="成单"></el-option>
            <el-option :value="18" label="添加微信"></el-option>
            <el-option :value="16" label="冻结机会"></el-option>
            <el-option :value="3" label="拨打"></el-option>
            <el-option :value="99" label="操作"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作方式" prop="style">
          <el-select v-model="backParam.style" size="small">
            <el-option :value="0" label="自动回库"></el-option>
            <el-option :value="1" label="手动回库"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权重">
          <el-select v-model="backParam.weight" size="small">
            <el-option :value="0" label="零级"></el-option>
            <el-option :value="1" label="一级"></el-option>
            <el-option :value="2" label="二级"></el-option>
            <el-option :value="3" label="三级"></el-option>
            <el-option :value="4" label="四级"></el-option>
            <el-option :value="98" label="五级"></el-option>
            <el-option :value="99" label="顶级"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="listBack">搜索</el-button>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="stopBack">重置查询数据</el-button>
          <el-button type="primary" size="mini" @click="exportBack">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table max-height="500" v-loading="loading" :data="backList">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="生成方式" align="center" prop="style">
          <template v-slot="solt">
            <span v-if="solt.row.style === 0">自动回库</span>
            <span v-else-if="solt.row.style === 1">手动回库</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column label="行为" align="center" prop="type">
          <template v-slot="solt">
            <span v-if="solt.row.type === 1">领取</span>
            <span v-else-if="solt.row.type === 6">成单</span>
            <span v-else-if="solt.row.type === 7">分配</span>
            <span v-else-if="solt.row.type === 99">操作</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column label="机会主键" align="center" prop="opporId"/>
        <el-table-column label="当前学习顾问" align="center" width="120" prop="accountName"/>
        <el-table-column label="唯一标识" align="center" prop="possessName"/>
        <el-table-column label="权重" align="center" prop="weight">
          <template v-slot="solt">
            <span v-if="solt.row.weight === 0">零级</span>
            <span v-else-if="solt.row.weight === 1">一级</span>
            <span v-else-if="solt.row.weight === 2">二级</span>
            <span v-else-if="solt.row.weight === 3">三级</span>
            <span v-else-if="solt.row.weight === 4">四级</span>
            <span v-else-if="solt.row.weight === 98">五级</span>
            <span v-else-if="solt.row.weight === 99">顶级</span>
            <span v-else>其他级别</span>
          </template>
        </el-table-column>
        <el-table-column label="回库时间" align="center" prop="executeTime">
          <template v-slot="scope">
            {{ scope.row.executeTime|formatDate }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime"/>
        <el-table-column fixed="right" label="操作" align="center" class-name="small-padding fixed-width">
        </el-table-column>
      </el-table>

      <pagination
          v-show="backParam.total>0"
          :total="backParam.total"
          :page.sync="backParam.page"
          :limit.sync="backParam.limit"
          @pagination="listBack"
      />
    </el-dialog>
    <!--  待回库列表 end  -->
  </div>
</template>

<script>
import * as backWeightApi from "@/api/crm/backWeight";
import * as deptApi from "@/api/system/dept";
import * as backApi from "@/api/crm/backDepot";
import * as backlogApi from "@/api/crm/backDepotLog";
import * as possessApi from "@/api/crm/possess";
import GgdCard from "@/components/GgdCard.vue";
import axios from "axios";
import * as api from "@/utils/api";
import * as accountApi from "@/api/system/account";
import {findTask} from "../../../api/crm/backDepot";

export default {
    name: "BackWeight",
    components: {
        GgdCard
    },
    data() {
        return {
            adviserList: [],
            historyParam: {
                total: 0,
                page: 1,
                limit: 10
            },
            backTaskParam: {
                total: 0,
                current: 1,
                size: 10,
                status:""
            },
            historyTimes: [],
            historyList: [],
            backTaskList: [],
            backTaskSync: false,
            addBackTaskForm: {
                executeDay: '',
                executeTime: '12:00',
                time: 24,
                deptList:[],
                accountList:[]
            },
            accountList: [],
            deptList: [],
            deptTree: [],
            addBackTaskSync: false,
            historyBackSync: false,
            backParam: {page: 0, limit: 10, total: 0},
            waitBackSync: false,
            historyUpdateSync: false,
            historyData: {},
            historyForm: {
                possessList: []
            },
            historyDataSync: false,
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 参数配置表格数据
            backWeightList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10,
                name: null
            },
            // 表单参数
            form: {
                possessList: [],
                cover: true,
            },
            // 表单校验
            optionList: [],
            possList: [],
            isIndeterminate: true,
            checkeds: [],
            tson: {},
            possIds: [],
            sum: 0,
            hour: 0,
            today: 0,
            backList: [],
            addSelectFlag1: false
        };
    },
    filters: {
        formatDate: function (value) {
            let date = new Date(value * 1000);//这个是纳秒的，想要毫秒的可以不用除以1000000
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            // let s = date.getSeconds();
            // s = s < 10 ? ('0' + s) : s;
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
        }
    },
    computed: {
        // 添加 - 全选判定
        addSelectFlag() {
            let possIds = this.possList.map(e => e.id) || []
            let arr = this.form.possessList || []
            if (arr.length === possIds.length) {
                return true
            } else {
                return false
            }
        },
        historySelectFlag() {
            let possIds = this.possList.map(e => e.id) || []
            let arr = this.historyForm.possessList || []

            if (arr.length === possIds.length) {
                return true
            } else {
                return false
            }
        }
    },
    created() {
        this.getList();
        this.getPossess();
        this.info()
        this.getAdviserList()
        this.getDept()
        this.findBackTaskList()
    },
    methods: {
        restartTaskForm(){
            this.addBackTaskForm =  {
                executeDay: '',
                    executeTime: '12:00',
                    time: 24,
                    deptList:[],
                    accountList:[]
            }
        },
        /**
         * 提交任务
         */
        submitTask(){
            if(!this.addBackTaskForm.executeTime){
                this.$message.error("必须填写执行时间")
                return
            }
            let tm = this.addBackTaskForm.executeTime.split(":")
            if(!this.addBackTaskForm.executeDay){
                this.$message.error("必须填写执行日期")
                return
            }
            // if(!this.addBackTaskForm.time){
            //     this.$message.error("必须填写延迟时间")
            //     return
            // }
            this.addBackTaskForm.executeDay.setHours(tm[0])
            this.addBackTaskForm.executeDay.setMinutes(tm[1])
            let executeTime = this.addBackTaskForm.executeDay
            let time = this.addBackTaskForm.time
            let data = {
                accountList:this.addBackTaskForm.accountList,
                deptList:this.addBackTaskForm.deptList,
                time:time
            }
            let task = {
                task:"BACKDEPT",
                status:0,
                executeTime:executeTime,
                data:JSON.stringify(data)
            }

            backApi.saveTask(task).then(res=>{
                if(res.success){
                    this.$message.success("保存成功")
                    this.addBackTaskSync = false
                    this.findBackTaskList()
                }else{
                    this.$message.error(res.msg)
                }
            });
        },
        /**
         * 停止任务
         */
        stopTask(row){
            backApi.stopTask(row.id).then(res=>{
                if(res.success){
                    this.$message.success("任务已暂停")
                }else{
                    this.$message.error(res.msg)
                }
            })
            this.findBackTaskList()
        },
        /**
         * 查询任务数据
         */
        findBackTaskList(){
          backApi.findTask(this.backTaskParam).then(res=>{
              this.backTaskParam.total = res.data.total
              this.backTaskList = res.data.data
              for (let b of this.backTaskList){
                  let d = JSON.parse(b.data)
                  b['accountList'] = d['accountList']
                  b['deptList'] = d['deptList']
                  b['time'] = d['time']
              }
          })
        },
        getChild(r, i) {
            let str = ""
            for (let j = 0; j < i; j++) {
                str += '—'
            }
            for (let c in r) {
                this.deptList.push({"title": str + r[c].title, "id": r[c].id, "name": r[c].title})
                if (r[c].children) {
                    this.getChild(r[c].children, i + 1)
                }
            }
        },
        getDept() {
            deptApi.treeDept().then(response => {
                let res = response.data
                this.getChild(res, 0)
            });
        },
        /**
         * 获取学习顾问列表
         */
        getAdviserList: function () {
            accountApi.adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        // 回退已回库数据,返回最后领取学习顾问名下
        backHistory() {

        },
        // 查询已回库历史记录
        listHistory() {
            console.log(this.historyTimes)
            if (this.historyTimes && this.historyTimes.length > 1) {
                let start = this.historyTimes[0]
                let end = this.historyTimes[1]
                this.historyParam['startTime'] = start
                this.historyParam['endTime'] = end
            }
            backlogApi.listPage(this.historyParam).then(res => {
                this.historyList = res.data
                this.historyParam.total = res.count
                this.historyParam.page = res.current
            })
        },
        //导出待回库记录
        exportBack() {
            const loading = this.$loading({
                lock: true,//同v-loading的修饰符
                text: '加载数据中,请稍等...',//加载文案
                backgroundColor: 'rgba(55,55,55,0.4)',//背景色
                spinner: 'el-icon-loading',//加载图标
                target: document.querySelector(".el-tabs__content")//loading需要覆盖的DOM节点，默认为body
            })
            axios({
                method: "post",
                url: "/api/manage/crm/backdep/exportBack",
                data: JSON.stringify(this.backParam),
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                },
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '回库日志.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
                loading.close()
            }).catch(error => {
                this.$message.error("导出回库记录失败");
                loading.close()
            })
        },
        stopBack() {
            backApi.listBack(this.backParam).then(res => {
                this.backList = res.data
                this.backParam.total = res.total
                this.backParam.page = res.current
                let total = res.total;
                this.$confirm('是否取消回库:' + total + '条数据!', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    backApi.stopBack(this.backParam).then(res => {
                        if (res.success) {
                            this.$message({
                                type: 'success',
                                message: '取消成功!'
                            });
                            this.listBack()
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg
                            });
                        }
                    })
                });
            })

        },
        handleDeleteBack(row) {
            console.log(row)
        },
        listBack() {
            backApi.listBack(this.backParam).then(res => {
                this.backList = res.data
                this.backParam.total = res.total
                this.backParam.page = res.current
            })
        },
        info() {
            backWeightApi.info().then(res => {
                this.sum = res.data.sum;
                this.hour = res.data.hour;
                this.today = res.data.today;
            });
        },
        checkedPossChange() {
            // console.log(this.form.possessList ,666)
            // this.addSelectFlag1 = this.form.possessList.length === this.possList.length
            this.addSelectFlag1 = false
            this.$forceUpdate()
        },
        // 这里有问题
        checkAll1(val) {
            this.form.possessList = val ? this.possList.map(e => e.id) || [] : []
            this.$forceUpdate()
        },
        checkAll2() {
            let possIds = this.historySelectFlag ? [] : this.possList.map(e => e.id) || []
            this.$set(this.historyForm, 'possessList', possIds)
        },
        //获取唯一标识
        getPossess() {
            possessApi.list().then(res => {
                this.possList = res.data;
            });
        },
        /** 查询参数配置列表 */
        getList() {
            this.loading = true;
            backWeightApi.listBackWeight(this.queryParams).then(response => {
                this.backWeightList = response.data.records;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.historyDataSync = false
        },
        // 表单重置
        reset() {
            this.form = {};
            this.resetForm("formRef");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            console.log(this.possList)
            this.open = true;
            this.title = "添加";
            this.form = {
                possessList: [],
                cover: true
            }
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            backWeightApi.getBackWeightById(row.id).then(response => {
                if (response.data.data) {
                    this.tson = JSON.parse(response.data.data)
                }
                this.form = response.data;
                if (this.form.possessIds === '*') {
                    this.addSelectFlag1 = true
                    this.form.possessList = this.possList.map(e => e.id)
                }else {
                    this.form.possessList = this.form.possessIds.split(',').map(Number).filter(e => {if (e) return e}) || []
                }
                this.open = true;
                this.title = "修改";
            });
        },

        /*
        *
        * 清洗唯一配置数据
        *
        * 全选为 * , 非全选为 逗号分割字符串
        * */
        filterPossessListData(isSelectAll, list) {
            let possIds = this.possList.map(e => e.id)
            if (isSelectAll) {
                return '*'
            } else {
                return list.join(',')
            }
        },
        /** 提交按钮 */
        submitForm() {
            const params = Object.assign({}, this.form)
            let tson = {}
            if (!params.name) {
                this.$message({showClose: true, message: '请设定规则名称!', type: "error"});
                return;
            }
            if (!params.type) {
                this.$message({showClose: true, message: '请选择行为类型!', type: "error"});
                return;
            }
            if (params.type === 6) {
                if (this.tson.min && this.tson.max) {
                    tson['min'] = this.tson.min
                    tson['max'] = this.tson.max
                } else {
                    this.$message({showClose: true, message: '最大金额,最小金额不可为空!', type: "error"});
                    return;
                }
            }
            params['data'] = JSON.stringify(tson)
            params.possessIds = this.filterPossessListData(this.addSelectFlag1, this.form.possessList)
            console.log(params.possessIds)
            // params.possessIds = Array.from(new Set(params.possessIds));
            backWeightApi.saveBackWeight(params).then(response => {
                if (response.success) {
                    this.msgSuccess("修改成功");
                    this.historyUpdateSync = true
                    this.open = false;
                    this.getList();
                } else {
                    this.$message.error(response.msg);
                }
            });
        },
        /** 修改历史
         * 提交按钮 */
        historySubmitForm() {
            const params = Object.assign({}, this.historyForm)
            let tson = {}
            if (!params.type) {
                this.$message({showClose: true, message: '请选择行为类型!', type: "error"});
                return;
            }
            if (params.type === 6) {
                if (this.historyData.min && this.historyData.max) {
                    tson['min'] = this.historyData.min
                    tson['max'] = this.historyData.max
                } else {
                    this.$message({showClose: true, message: '最大金额,最小金额不可为空!', type: "error"});
                    return;
                }
            } else {
                if (this.historyData.sdate && this.historyData.stime) {
                    let time = this.historyData.sdate + ' ' + this.historyData.stime + ":00"
                    tson['time'] = time
                    let compareType = this.historyData.compareType
                    if (!compareType) {
                        compareType = 'ge'
                    }
                    tson['compareType'] = compareType
                } else {
                    this.$message({showClose: true, message: '请填写任务时间规则!', type: "error"});
                    return;
                }
            }

            params['data'] = JSON.stringify(tson)
            params.possessIds = this.filterPossessListData(this.historySelectFlag, this.historyForm.possessList)

            const loading = this.$loading({
                lock: true,//同v-loading的修饰符
                text: '计算中,请稍等...',//加载文案
                backgroundColor: 'rgba(55,55,55,0.4)',//背景色
                spinner: 'el-icon-loading',//加载图标
                target: document.querySelector(".el-tabs__content")//loading需要覆盖的DOM节点，默认为body
            })
            backWeightApi.startTask(params).then(res => {
                if (res.success) {
                    this.msgSuccess(res.msg);
                    this.historyDataSync = false
                    this.historyForm = {
                        possessList: []
                    }
                    this.getList();
                } else {
                    this.$message({showClose: true, message: res.msg, type: "error"});
                }
                loading.close()
            }).catch(e => {
                loading.close()
            })

        },
        /** 删除按钮操作 */
        handleDelete(row) {
            this.$confirm('是否确认删除?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return backWeightApi.deleteBackWeight(row.id);
            }).then((res) => {
                if (res.success) {
                    this.getList();
                    this.msgSuccess("删除成功");
                } else {
                    this.$message({showClose: true, message: res.msg, type: "error"});
                }

            })
        }
    }
};
</script>
