import Vue from 'vue'
import Vuex from 'vuex'
import {getToken, setToken, removeToken, getInformation, setInformation, removeInformation} from "@/utils/auth"
import Message from './modules/message'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: getToken(),
        information: getInformation(),
        headerTitle: localStorage.getItem("headerTitle"),


        /*
        * 用于message组件 打开消息盒子
        * 全局进行监听
        * */
        shopMessageObj: {}
    },
    mutations: {
        SET_HEADER_TITLE(state, title) {
            state.headerTitle = title
        },

        //存储token
        SET_TOKEN: (state, token) => {
            state.token = token;
            setToken(token);
        },
        //存储用户信息
        INFO_RMATION: (state, information) => {
            state.information = information;
            setInformation(information);
        },
        SET_SHOP_MESSAGE_OBJ: (state, obj) => {
            // Vue.set(state, 'shopMessageObj', obj )
            state.shopMessageObj = Object.assign({}, obj)
        }
    },
    actions: {
        setHeaderTitle({ commit }, title) {
            localStorage.setItem('headerTitle', title)
            commit('SET_HEADER_TITLE', title)
        },
        //退出登录
        FedLogOut({commit}) {
            return new Promise((resolve) => {
                commit("SET_TOKEN", "");
                removeToken();
                commit("INFO_RMATION", "");
                removeInformation();
                resolve();
            });
        },
        changeShopMessageObj({commit}, obj) {
            commit('SET_SHOP_MESSAGE_OBJ', obj)
        }
        //使用退出时候
        // this.$store.dispatch("FedLogOut").then(() => {
        //调用退出接口
        // });
    },
    getters: {
        token: state => state.token,
        headerTitle: state => state.headerTitle,
        shopMessageObj: state => state.shopMessageObj
    },
    modules: {
        message: Message
    },
})
