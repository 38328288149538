import request from "@/utils/request";

const url = '/manage/domains';

/**
 * 查询我的域名
 * @param subDoamin
 */
export function myDomains(query) {
    return request({
        method: 'GET',
        url: `${url}/myDomains`,
        params: query
    })
}

/**
 * 更新域名状态
 * @param id
 */
export function sslCheck(id) {
    return request({
        method: 'GET',
        url: `${url}/sslCheck?id=${id}`
    })
}

/**
 * 添加域名
 * @param subDoamin
 */
export function addDomains(subDoamin) {
    return request.post(`${url}/addDomain`, JSON.stringify(subDoamin))
}