import request from '@/utils/request.js';

const url = "/manage/doudian";

//获取列表数据
export function orderList(params) {
    return request({
        url: `${url}/order/list`,
        method: 'get',
        params: params
    })
}

//获取订单详情
export function orderDetail(shopOrderId) {
    return request({
        url: `${url}/order/orderDetail?shopOrderId=${shopOrderId}`,
        method: 'get'
    })
}

//批量解密接口数据
export function batchDecrypt(params) {
    return request.post(`${url}/order/batchDecrypt`, JSON.stringify(params));
}

//修改订单收货信息
export function addressModify(params) {
    return request.post(`${url}/order/addressModify`, JSON.stringify(params));
}

//数据脱敏
export function batchSensitive(params) {
    return request.post(`${url}/order/batchSensitive`, JSON.stringify(params));
}

//获取商品列表
export function productList(params) {
    return request({
        url: `${url}/product/list`,
        method: 'get',
        params: params
    })
}

//获取抖店商品详情
export function productDetail(params) {
    return request.post(`${url}/product/detail`, JSON.stringify(params));
}

//查询签名列表
export function listSign(params) {
    return request({
        url: `${url}/sms/listSign`,
        method: 'get',
        params: params
    })
}

//查询短信模板
export function listTemplate(params) {
    return request({
        url: `${url}/sms/listTemplate`,
        method: 'get',
        params: params
    })
}

//查询短信发送结果
export function smsList(params) {
    return request({
        url: `${url}/sms/list`,
        method: 'get',
        params: params
    })
}

//发送短信
export function sendSms(params) {
    return request.post(`${url}/sms/send`, JSON.stringify(params));
}

//发送短信
export function batchSend(params) {
    return request.post(`${url}/sms/batchSend`, JSON.stringify(params));
}

/**
 * 通过订单绑定信息发送短信
 * @param params
 */
export function batchSendByOrder(params) {
    return request.post(`${url}/sms/batchSendByOrder`, JSON.stringify(params));
}

//查询模板列表
export function templateList(params) {
    return request({
        url: `${url}/sms/templateList`,
        method: 'get',
        params: params
    })
}

//申请短信模板
export function applyTemplate(params) {
    return request.post(`${url}/sms/applyTemplate`, JSON.stringify(params));
}

//申请短信签名
export function applySign(params) {
    return request.post(`${url}/sms/applySign`, JSON.stringify(params));
}

//删除签名
export function deleteSign(params) {
    return request.post(`${url}/sms/deleteSign`, JSON.stringify(params));
}

//删除短信模板
export function deleteTemplate(params) {
    return request.post(`${url}/sms/deleteTemplate`, JSON.stringify(params));
}


/**
 * 抖店任务
 */

//分页查询
export function listPageTask(params) {
    return request({
        url: `${url}/task/listPage?page=${params.page}&size=${params.size}`,
        method: 'get',
        params: params
    })
}

//查询
export function listTask(params) {
    return request({
        url: `${url}/task/list`,
        method: 'get',
        params: params
    })
}

//查询
export function getTask(id) {
    return request({
        url: `${url}/task/get/${id}`,
        method: 'get'
    })
}

//删除
export function removeTask(id) {
    return request({
        url: `${url}/task/remove/${id}`,
        method: 'get'
    })
}

//删除短信模板
export function saveTask(params) {
    return request.post(`${url}/task/save`, JSON.stringify(params));
}

/**
 * 通过订单号,获取订单信息
 * @param orderId
 */
export function getOrderBindByOrderId(orderId) {
    return request({
        url: `${url}/order/bind/getOrderBindByOrderId?orderId=${orderId}`,
        method: 'get'
    })
}

/**
 * 新增订单绑定数据
 * @param params
 */
export function saveOrderBind(params) {
    return request.post(`${url}/order/bind/saveOrderBind`, JSON.stringify(params));
}

/**
 * 批量新增订单数据
 * @param params
 */
export function saveOrderBindList(params) {
    return request.post(`${url}/order/bind/saveOrderBind`, JSON.stringify(params));
}

/**
 * 查询订单已经绑定的数据表头
 * @param ids
 */
export function findHeadByIds(ids) {
    return request.post(`${url}/order/bind/findHeadByIds`, JSON.stringify(ids));
}