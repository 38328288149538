import request from '@/utils/request.js';

const url = "/manage/domain";

export function checkSubDomain(params) {
    return request({
        url: `${url}/checkSubDomain`,
        method: 'post',
        params: params
    })
}
/**
 * 通过secretId和secretKey 获取域名信息
 * @param params
 */
export function findDomainByKey(params) {
    return request({
        url: `${url}/findDomainByKey`,
        method: 'post',
        params: params
    })
}

/**
 * 获取拥有域名
 * @param params
 */
export function list(params) {
    return request({
        url: `${url}/list`,
        method: 'post',
        params: params
    })
}

/**
 * 分页获取拥有域名
 * @param params
 */
export function listPage(params) {
    return request({
        url: `${url}/list`,
        method: 'post',
        params: params
    })
}

/**
 * 保存域名信息
 * @param params
 * @returns {*}
 */
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

/**
 * 删除当前列表
 * @param id
 * @returns {*}
 */
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}