import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '@/views/About'
import role from "../views/system/role/index";
import account from "../views/system/account/index";
import dictionary from "../views/system/dictionary/index";
import DictionaryChild from "../views/system/dictionary/child";
import dept from "../views/system/dept/index";
import backWeight from "../views/crm/backWeight/index";
import operationLog from "../views/crm/operationLog/index";
import downLoadLog from "../views/crm/downLoadLog/index";
import mediatorCut from "../views/crm/mediatorCut/index";
import orders from "../views/crm/orders/index";
import possess from "../views/crm/possess/index";
import traffic from "../views/crm/traffic/index";
import myMessageManage from "../views/msg/myMessageManage/index";
import configuration from "../views/system/configuration/index";
import PageDomain from "../views/mini/PageDomain";
import sales from "../views/mini/sales";
import leavePhone from "../views/mini/leavePhone";
import applets from "../views/applets/applets";
import statistics from "../views/mini/Statistics";
import BuyOrder from "../views/mini/BuyOrder";
import domain from "../views/mini/domain";
import promote from "../views/promotepage/promote";
import Activated from "../views/mini/Activated";
import doudianTask from "@/views/crm/other/DoudianTask";
import Goods from "@/views/mini/goods/Goods";
import OtherGoods from "@/views/mini/goods/OtherGoods";
import subject from "@/views/system/Subject";
import OpporAll from "@/views/opportunity/OpporAll";
import ConfigureCount from "@/views/configure/ConfigureCount.vue";
import CrmAllGoods from "@/views/mini/goods/CrmAllGoods.vue";
import Tags from "@/views/crm/Tags.vue";
import Configuration from "@/views/configure/Configuration.vue";
import ConfigurationSon from "@/views/configure/ConfigurationSon.vue";
import Adviser from "@/views/crm/adviser/Adviser.vue";
import ReceiveButton from "@/views/system/receive/ReceiveButton.vue";
import ReceiveButtonDetail from "@/views/system/receive/ReceiveButtonDetail.vue";
import ConfigurationMarket from "@/views/configure/ConfigurationMarket.vue";
import MessageConfig from "@/views/msg/config/index.vue"
import ConsultantNoAddWx from "@/views/consultant/ConsultantNoAddWx.vue"
import Sms from "@/views/system/sms/index.vue"
import WeworkQrcode from "../views/wework/WeworkQrcode.vue"
import Poster from "@/views/sphere/poster.vue";
import EnterpriseTag from "@/views/sphere/EnterpriseTag.vue";
import ReceiveButtonLog from "@/views/system/receive/ReceiveButtonLog.vue"
import CodeLinkStatistics from "@/views/sphere/CodeLinkStatistics.vue";



const Home = () => import('../views/home/Index')

const Login = () => import('../views/login/Index')
const Consultant = () => import('../views/consultant/Index')
const Teacher = () => import('../views/consultant/Teacher')
const DataAnalysis = () => import('../views/dataAnalysis/Index')
const DataAnalysisNew = () => import('../views/dataAnalysis/DataAnalysis')
const DataAnalysisTeam = () => import('../views/dataAnalysis/Team')
const DataAnalysisDetails = () => import('../views/dataAnalysis/Details')

const Stock = () => import("../views/consultant/Stock")

const PersonInfo = () => import("../views/dataAnalysis/PersonInfo")
const OpportunityMain = () => import("../views/opportunity/OpportunityMain")
const FirstConsult = () => import("../views/opportunity/FirstConsult")
const CardSea = () => import("../views/opportunity/CardSea")
const Dashboard = () => import("../views/dataAnalysis/Dashboard")
const Doudian = () => import("../views/crm/other/Doudian")
const DoudianTask = () => import("../views/crm/other/DoudianTask")
const OrderRepair = () => import("../views/crm/orders/OrderRepair.vue")
const Menu = () => import("../views/system/menu/index.vue")
const StockReceive = () => import('../views/consultant/StockReceive.vue')
const dynamicFormBuild = () => import('../views/system/receive/dynamicFormBuild.vue')
const RinseScript = () => import('../views/crm/rinse/index.vue')
const TagsScript = () => import('../views/crm/rinse/TagsScript.vue')
const PromoteLog = () => import('@/views/system/PromoteLog.vue')
const OperateCount = () => import('../views/crm/operateCount/index.vue')
const RefundRepair = () => import('../views/crm/orders/RefundRepair.vue')
const SphereList = () => import('../views/sphere/SphereList.vue')
const SphereSending = () => import('../views/sphere/SphereSending.vue')

const Channel = () => import('../views/sphere/Channel.vue')
const ChannelAdd = () => import('../views/sphere/ChannelAdd.vue')


const consultantes = () => import('../views/consultant/IndexGuwen.vue')
const orderses = () => import('../views/crm/orders/indexGuwen.vue')
const opportunityes = () => import('../views/opportunity/OpporAllGuwen.vue')
const accountes = () => import('../views/system/account/indexGuwen.vue')
const welcome = () => import('../views/welcome.vue')

// 新版机会我的机会页面
const MyClue = () => import('@/views/clue/MyClue.vue')
// 我的机会配置
const MyClueConfig = () => import('@/views/clue/MyClueConfig.vue')
// 新版机会管理页面
const AllClue = () => import('@/views/clue/AllClue.vue')
// 新版机会管理配置
const AllClueConfig = () => import('@/views/clue/AllClueConfig.vue')
// 机会池管理
const CluePond = () => import('@/views/clue/CluePond.vue')
//关键字段管理
const ClueCrux = () => import('@/views/clue/ClueCrux.vue')
//领取按钮
const MyStockReceive = () => import('@/views/clue/component/MyStockReceive.vue')
//领取库存
const MyStock = () => import('@/views/clue/component/MyStock.vue')
//微信视频号订单
const WechatChannelsOrder = () => import('@/views/wework/wechat/WechatChannelsOrder.vue')
//访问记录
const WaiterAccessRecord = () => import('@/views/waiter/WaiterAccessRecord.vue')
//班主任配置
const WaiterConfigure = () => import('@/views/waiter/WaiterConfigure.vue')
//学员管理
const WaiterStudent = () => import('@/views/waiter/WaiterStudent.vue')
//我的学员
const WaiterMyStudent = () => import('@/views/waiter/WaiterMyStudent.vue')
//服务号管理
const WaiterServices = () => import('@/views/waiter/WaiterServices.vue')
//服务记录
const WaiterServicesRecord = () => import('@/views/waiter/WaiterServicesRecord.vue')
//班主任领取
const WaiterReceive = () => import('@/views/waiter/WaiterReceive.vue')
//班主任管理
const WaiterClassTeacher = () => import('@/views/waiter/WaiterClassTeacher.vue')
//数据统计
const WaiterDataAnalysis = () => import('@/views/waiter/WaiterDataAnalysis.vue')
//领取按钮
const WaiterReceiveEdit = () => import('@/views/waiter/detail/WaiterReceiveEdit.vue')
//学员领取页面
const StudentReceive = () => import('@/views/waiter/detail/StudentReceive.vue')


Vue.use(VueRouter)

const redirectAlreadyLogin = true; //判断登陆后是否需要进入此页面
const requiredLogin = true; //判断是否需要登录
//判断登陆后是否需要进入此页面/判断是否需要登录
//登录就是这个redirectAlreadyLogin 判断他有cookie时 就不让他进到登录页面了

const routes = [
    {
        //主页面
        path: '/',
        name: 'Home',
        component: Home,
        redirect: "/welcome",//定义默认路由
        meta: {
            requiredLogin
        },
        children: [
            {
                //临时页面开始
                path: '/consultantes',
                name: 'consultantes',
                component: consultantes,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/welcome',
                name: 'welcome',
                component: welcome,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/orderses',
                name: 'orderses',
                component: orderses,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/opportunityes',
                name: 'opportunityes',
                component: opportunityes,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/accountes',
                name: 'accountes',
                component: accountes,
                meta: {
                    requiredLogin
                }
                //临时页面结束
            },
            {
                //学习顾问页面
                path: '/Consultant',
                name: 'Consultant',
                component: Consultant,
                meta: {
                    requiredLogin
                }
            },
            {
                //未添加微信的页面
                path: '/consultant-no-add-wx',
                name: 'ConsultantNoAddWx',
                component: ConsultantNoAddWx,
                meta: {
                    requiredLogin
                }
            },
            {
                //班主任页面
                path: '/Teacher',
                name: 'Teacher',
                component: Teacher,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/StockReceive',
                name: 'StockReceive',
                component: StockReceive,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/MyStockReceive',
                name: 'MyStockReceive',
                component: MyStockReceive,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/MyStock',
                name: 'MyStock',
                component: MyStock,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/dynamicFormBuild',
                name: 'dynamicFormBuild',
                component: dynamicFormBuild,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/rinseScript',
                name: 'rinseScript',
                component: RinseScript,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/tagsScript',
                name: 'tagsScript',
                component: TagsScript,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/Stock',
                name: 'Stock',
                component: Stock,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/About',
                name: 'About',
                component: About,
                meta: {
                    requiredLogin
                }
            },
            {
                //角色
                path: '/system/role',
                component: role,
                meta: {
                    requiredLogin
                }
            },
            {
                //用户
                path: '/system/account',
                component: account,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/system/dictionary',
                component: dictionary,
                meta: {
                    requiredLogin
                },

            },
            {
                path: '/system/dictionary/child/:dictId(\\d+)',
                component: DictionaryChild,
                meta: {
                    requiredLogin
                }
            },
            {
                //用户
                path: '/system/configuration',
                component: configuration,
                meta: {
                    requiredLogin
                }
            },
            {
                //部门
                path: '/system/dept',
                component: dept,
                meta: {
                    requiredLogin
                }
            },
            {
                //规则管理
                path: '/crm/backWeight',
                component: backWeight,
                meta: {
                    requiredLogin
                }
            },
            {
                //操作日志
                path: '/system/operationLog',
                component: operationLog,
                meta: {
                    requiredLogin
                }
            },
            {
                //下载日志
                path: '/system/downloadLog',
                component: downLoadLog,
                meta: {
                    requiredLogin
                }
            },
            {
                //领取日志
                path: '/system/receive-log',
                component: ReceiveButtonLog,
                meta: {
                    requiredLogin
                }
            },
            {
                //短信管理
                path: '/system/sms',
                name: 'Sms',
                component: Sms,
                meta: {
                    requiredLogin
                }
            },
            {
                //渠道管理
                path: '/crm/mediatorCut',
                component: mediatorCut,
                meta: {
                    requiredLogin
                }
            },
            {
                //订单管理
                path: '/crm/orders',
                component: orders,
                meta: {
                    requiredLogin
                }
            },
            {
                //补录订单
                path: '/crm/order-repair',
                component: OrderRepair,
                meta: {
                    requiredLogin
                }
            },
            {
                //退款申请
                path: '/crm/refund-repair',
                component: RefundRepair,
                meta: {
                    requiredLogin
                }
            },
            {
                //唯一值管理
                path: '/crm/possess',
                component: possess,
                meta: {
                    requiredLogin
                }
            },
            {
                //话务管理
                path: '/crm/traffic',
                component: traffic,
                meta: {
                    requiredLogin
                }
            },

            {
                //我的消息
                path: '/msg/myMessageManage',
                component: myMessageManage,
                meta: {
                    requiredLogin
                }
            },
            {
                //我的消息
                path: '/DataAnalysis/DataAnalysis',
                component: DataAnalysisNew,
                meta: {
                    requiredLogin
                }
            },
            {
                //私域客户列表
                path: '/sphere/SphereList',
                component: SphereList,
                meta: {
                    requiredLogin
                }
            },
            {
                //客户群发
                path: '/sphere/SphereSending',
                component: SphereSending,
                meta: {
                    requiredLogin
                }
            },
            {
                //引流
                path: '/sphere/Channel',
                component: Channel,
                meta: {
                    requiredLogin
                }
            },
            {
                //引流添加
                path: '/sphere/ChannelAdd',
                component: ChannelAdd,
                meta: {
                    requiredLogin
                }
            },

            {
                path: '/DataAnalysis',
                component: {render: h => h("router-view")},
                meta: {
                    requiredLogin
                }, children: [
                    {
                        path: '/DataAnalysis/Index',
                        name: 'DataAnalysis',
                        component: DataAnalysis,
                        meta: {
                            requiredLogin
                        }
                    },
                    {
                        path: '/DataAnalysis/Team',
                        name: 'DataAnalysisTeam',
                        component: DataAnalysisTeam,
                        meta: {
                            requiredLogin
                        }
                    },
                    {
                        path: '/DataAnalysis/Details',
                        name: 'Details',
                        component: DataAnalysisDetails,
                        meta: {
                            requiredLogin
                        }
                    },
                    {
                        path: '/DataAnalysis/PersonInfo',
                        name: 'PersonInfo',
                        component: PersonInfo,
                        meta: {
                            requiredLogin
                        }
                    }
                ]
            },
            {
                path: '/OpportunityManage',
                name: 'OpportunityManage',
                component: OpportunityMain,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/oppor',
                name: 'oppor',
                component: OpporAll,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/FirstConsult',
                name: 'FirstConsult',
                component: FirstConsult,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/CardSea',
                name: 'CardSea',
                component: CardSea,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/PageDomain',
                name: 'PageDomain',
                component: PageDomain,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/buyOrder',
                name: 'buyOrder',
                component: BuyOrder,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/sales',
                name: 'sales',
                component: sales,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/leavePhone',
                name: 'leavePhone',
                component: leavePhone,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/applets',
                name: 'applets',
                component: {render: h => h("router-view")},
                meta: {
                    requiredLogin
                },
                children: [//子集路由
                    {
                        path: '/applets',
                        name: 'catalogue',
                        component: applets,
                        meta: {
                            requiredLogin
                        }
                    },
                    {
                        path: '/applets/catalogue',
                        name: 'catalogue',
                        component: () => import('@/views/applets/appletsCatalog'),
                        meta: {
                            requiredLogin
                        }
                    }
                ]
            },
            {
                path: '/statistics',
                name: 'statistics',
                component: statistics,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/domain',
                name: 'domain',
                component: domain,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/promote',
                name: 'promote',
                component: promote,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/activated',
                name: 'activated',
                component: Activated,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/doudian',
                name: 'doudian',
                component: Doudian,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/doudianTask',
                name: 'doudianTask',
                component: doudianTask,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/goods',
                name: 'goods',
                component: Goods,
                meta: {
                    requiredLogin
                }
            }, {
                path: '/crm-all-goods',
                name: 'crm-all-goods',
                component: CrmAllGoods,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/other-goods',
                name: 'other-goods',
                component: OtherGoods,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/system/subject',
                name: 'subject',
                component: subject,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/system/menu',
                name: 'Menu',
                component: Menu,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/system/message-config',
                name: 'MessageConfig',
                component: MessageConfig,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/operateCount',
                name: 'OperateCount',
                component: OperateCount,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/configure-count',
                name: 'configureCount',
                component: ConfigureCount,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/promoteLog',
                name: 'PromoteLog',
                component: PromoteLog,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/tags',
                name: 'tags',
                component: Tags,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/configuration',
                name: 'Configuration',
                component: Configuration,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/configuration-son',
                name: 'ConfigurationSon',
                component: ConfigurationSon,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/configuration-market',
                name: 'ConfigurationMarket',
                component: ConfigurationMarket,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/receive-button',
                name: 'ReceiveButton',
                component: ReceiveButton,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/crm/adviser',
                name: 'Adviser',
                component: Adviser,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/receive-button-detail',
                name: 'ReceiveButtonDetail',
                component: ReceiveButtonDetail,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/wework-qrcode',
                name: 'WeworkQrcode',
                component: WeworkQrcode,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/my-clue',
                name: 'MyClue',
                component: MyClue,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/my-clue-config',
                name: 'MyClueConfig',
                component: MyClueConfig,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/all-clue',
                name: 'AllClue',
                component: AllClue,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/all-clue-config',
                name: 'AllClueConfig',
                component: AllClueConfig,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/clue-pond',
                name: 'CluePond',
                component: CluePond,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/clue-crux',
                name: 'ClueCrux',
                component: ClueCrux,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/sphere/poster',
                name: 'poster',
                component: Poster,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/sphere/EnterpriseTag',
                name: 'EnterPriseTag',
                component: EnterpriseTag,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/sphere/CodeLinkStatistics',
                name: 'CodeLinkStatistics',
                component: CodeLinkStatistics,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WechatChannelsOrder',
                name: 'WechatChannelsOrder',
                component: WechatChannelsOrder,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterAccessRecord',
                name: 'WaiterAccessRecord',
                component: WaiterAccessRecord,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterConfigure',
                name: 'WaiterConfigure',
                component: WaiterConfigure,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterMyStudent',
                name: 'WaiterMyStudent',
                component: WaiterMyStudent,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterServices',
                name: 'WaiterServices',
                component: WaiterServices,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterServicesRecord',
                name: 'WaiterServicesRecord',
                component: WaiterServicesRecord,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterStudent',
                name: 'WaiterStudent',
                component: WaiterStudent,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterClassTeacher',
                name: 'WaiterClassTeacher',
                component: WaiterClassTeacher,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterReceive',
                name: 'WaiterReceive',
                component: WaiterReceive,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterDataAnalysis',
                name: 'WaiterDataAnalysis',
                component: WaiterDataAnalysis,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/WaiterReceiveEdit',
                name: 'WaiterReceiveEdit',
                component: WaiterReceiveEdit,
                meta: {
                    requiredLogin
                }
            },
            {
                path: '/StudentReceive',
                name: 'StudentReceive',
                component: StudentReceive,
                meta: {
                    requiredLogin
                }
            },
        ]
    },
    {
        //登录页
        path: '*',
        name: 'Login',
        component: Login,
        meta: {
            redirectAlreadyLogin
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // return { top: 0 }
        //解决返回列表页面滚动条在顶部得问题
        if (savedPosition) {
            return savedPosition
        } else {
            // 始终滚动到顶部
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({left: 0, top: 0})
                }, 500)
            })
        }

    },
});

// 全局导航守卫
// router.beforeEach((to, from, next) => {
//     const isLoggedIn = false; // 这里你可以用实际的登录状态来替换
//
//     if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
//         // 如果没有登录，并且目标路由需要授权，则重定向到登录页面
//         next({ name: 'Login' });
//     } else {
//         next();
//     }
// });
/**
 * 防止路由重复点击报错
 **/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};


export default router
