<template>
    <div>
        <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addRoles"
        >新增
        </el-button>
        <el-table
                :data="tableData"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="domain"
                    label="网址"
                    width="280">
            </el-table-column>
            <el-table-column
                    prop="possId"
                    label="数据唯一标识"
                    width="180">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-s-grid"
                            @click="Jump(scope.row)"
                    >配置
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="edit(scope.row,scope.row.id)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="deleteCurrent(scope.row.id)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.limit"
        />

        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogFormVisible"
                width="600px"
                @close="resetFrom('roleForm')"
        >
            <el-form
                    :model="dialogForm"
                    ref="roleForm"
                    label-width="120px"
            >
                <el-form-item label="网址">
                    <el-row>
                        <el-col :span="6">
                            <el-input v-model="dialogForm.domain"></el-input>
                        </el-col>
                        <el-col :span="12">
                            <el-select v-model="domainUrl" width="100%">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <!--                        <el-col :span="6">-->
                        <!--                            <el-button type="primary" icon="el-icon-plus" circle></el-button>-->
                        <!--                        </el-col>-->
                    </el-row>
                </el-form-item>
                <el-form-item label="数据唯一标识">
                    <el-input v-model="dialogForm.possId"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="success" @click="save('roleForm')">保存</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import * as api from "@/api/mini/pagedomain";

    export default {
        name: "PageDomain",
        data() {
            return {
                // 总条数
                total: 0,
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10,
                    configName: null,
                    configKey: null,
                    configValue: null,
                    configType: null,
                },
                tableData: [],
                dialogFormVisible: false,
                dialogTitle: "",
                dialogForm: {
                    name: "",
                    detail: ""
                },
                dialogForm_null: Object.assign({}, this.dialogForm),
                rowIndex: 9999,
                domainUrl: '',
                options: [{
                    value: '选项1',
                    label: '.zhuoxuye.com'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
            }
        },
        mounted() {
            this.pagedomainList()
        },
        methods: {
            /*
            * 获取列表数据
            * */
            pagedomainList() {
                api.getList().then(res => {
                    console.log(res, 'ressss')
                    this.tableData = res.data
                });
            },
            /*
            * 配置--跳转--微页面
            * */
            Jump(scope) {
                console.log(scope)
                // let host = scope.domain + '/customize/edit';
                let host = 'http://' + scope.domain + '/customize/edit';
                console.log(host)
                window.open(host, '_target')

            },
            //新增
            addRoles() {
                this.dialogTitle = "添加微页面";
                this.dialogForm = Object.assign({}, this.dialogForm_null);
                this.dialogFormVisible = true;
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            edit(row, index) {
                console.log(row);
                this.dialogTitle = "修改微页面";
                this.dialogForm = Object.assign({}, row);
                this.dialogFormVisible = true;
                this.rowIndex = index;
            },

            /*
            * 新增/修改 保存列表数据
            * */
            save(roleForm) {
                console.log(this.dialogForm); //打印值到底存上了没有
                this.$refs[roleForm].validate(valid => {
                    console.log(this.dialogForm);
                    console.log(valid);
                    if (valid) {
                        let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                        console.log(id);
                        /* if (id) {
                           //id非空 - 编辑
                           console.log('是编辑')
                           api.save(this.dialogForm).then(res => {
                             console.log(res,'ressss')
                           });
                         } else {
                           //id为空 - 新增
                           console.log('是新增')
                           api.save(this.dialogForm).then(res => {
                             console.log(res,'ressss')
                           });
                         }*/
                        api.save(this.dialogForm).then(res => {
                            console.log(res, 'ressss')
                            this.pagedomainList()
                            this.$message({
                                type: "success",
                                message: id ? "修改成功！" : "新增成功！"
                            });
                            this.dialogFormVisible = false;
                        });

                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                api.deleteCurrent(id).then(res => {
                    console.log(res)
                    this.pagedomainList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>
