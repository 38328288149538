<template>
    <div class="app-container">

        <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="名称" prop="dictLabel">
                <el-input v-model="queryParams.dictLabel" placeholder="字典名称" clearable size="mini"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row style="margin-bottom: 10px">
            <el-button icon="el-icon-refresh-left" size="medium" type="info" plain @click="goBack">返回</el-button>
            <el-button icon="el-icon-plus" size="medium" type="primary" @click="handleAdd">新增</el-button>
        </el-row>
        <el-table :data="dictList">
            <el-table-column label="字典值" prop="dictValue"></el-table-column>
            <el-table-column label="字典标签" prop="dictLabel"></el-table-column>
            <el-table-column label="状态" align="center" width="100">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.dictStatus"
                               :active-value="0"
                               :inactive-value="1"
                               @change="changeStatus(scope.row)"
                    ></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="排序值" align="center" prop="dictSort"></el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime" width="180"></el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(scope.row)"
                            v-permission="'sys:dictionary:update'"
                    >修改
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--    <pagination-->
        <!--        v-show="total>0"-->
        <!--        :total="total"-->
        <!--        :page.sync="queryParams.page"-->
        <!--        :limit.sync="queryParams.limit"-->
        <!--        @pagination="getList"-->
        <!--    />-->
        <!-- 添加或修改角色配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="字典标签" prop="dictLabel">
                    <el-input v-model="form.dictLabel" placeholder="请输入字典标签"/>
                </el-form-item>
                <el-form-item label="字典值" prop="dictValue">
                    <el-input v-model="form.dictValue" placeholder="请输入字典值"/>
                </el-form-item>
                <el-form-item label="排序值" prop="dictSort">
                    <el-input v-model="form.dictSort"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as dictApi from "@/api/system/dictionary";

export default {
    name: "dictionary-child",
    data() {
        return {
            searchRules: {},
            queryParams: {
                page: 1,
                limit: 10
            },
            dictList: [],
            total: 0,
            dictId: 0,
            form: {},
            // 表单校验
            rules: {},
            title: "",
            open: false,
        }
    },
    created() {
        const dictId = this.$route.params && this.$route.params.dictId;
        this.dictId = dictId
        this.getList()
    },
    methods: {
        getList() {
            this.queryParams.dictParent = this.dictId
            dictApi.list(this.queryParams).then(
                res => {
                    this.dictList = res.data;
                    // this.total = res.data.total;
                    this.loading = false;
                }
            );
        },
        goBack() {
            this.$router.replace("/system/dictionary")
        },
        handleUpdate(row) {
            this.reset();
            const id = row.id
            dictApi.get(id).then(response => {
                this.form = response.data;
                this.open = true;
                this.title = "修改用户";
            });
        },
        changeStatus(row) {
            let status = 0
            if (row.dictStatus) {
                status = 1
            }
            let dict = {
                id: row.id,
                dictStatus: status
            }
            dictApi.saveDictionary(dict).then(res => {
                if (res.success) {
                    this.msgSuccess(res.msg);
                    this.getList();
                } else {
                    this.$message.warning(res.msg)
                }
            })
        }, submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    dictApi.saveDictionary(this.form).then(res => {
                        if (res.success) {
                            this.msgSuccess(res.msg);
                            this.open = false;
                            this.getList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            });
        }, cancel() {
            this.open = false;
            this.reset();
        }, reset() {
            this.form = {};
            this.resetForm("form");
        },
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
            this.form.dictStatus = 0
            this.form.dictParent = this.dictId
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.$refs['queryForm'].validate((valid) => {
                if (valid) {
                    this.getList();
                } else {
                    return false;
                }
            });

        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
    }
}
</script>

<style scoped>

</style>
