import request from "@/utils/request";
const uri = '/manage/crm/batch';



/**
 * 获取有效批次列表
 * @returns {*}
 */
export function getValidBatchList() {
    return request({
        url: `${uri}/valid-batch-list`,
        method: 'GET'
    })
}

export function getUserBatch() {
    return request({
        url: `${uri}/user-batch-map-list`,
        method: 'GET'
    })
}

export function queryUserBatchHistoryList(query) {
    return request({
        url: `${uri}/user-batch-history-list`,
        method: 'GET',
        params: query
    })
}

export function grantCoupon(params) {
    return request({
        url: `${uri}/grant-coupon`,
        method: 'PUT',
        params: params
    })
}

export function generateCouponUrl(params) {
    return request({
        url: `${uri}/generate-coupon-url`,
        method: 'POST',
        params: params
    })
}