import request from '@/utils/request.js';

const url = "/manage/clue/pond";

/**
 * 全部查询
 */
export function listCluePond(params) {
  return request({
    url: `${url}/list`,
    method: 'get',
    params: params
  })
}

/**
 * 查询可查看线索池
 */
export function findCluePond(params) {
  return request({
    url: `${url}/find-clue-pond`,
    method: 'get',
    params: params
  })
}

/**
 * 分页查询
 */
export function pageCluePond(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

/**
 * 获取详情
 */
export function infoCluePond(id) {
  return request({
    url: `${url}/info/${id}`,
    method: 'get',
  })
}

/**
 * 修改
 */
export function updateCluePond(params) {
  return request.put(`${url}`, JSON.stringify(params));
}

/**
 * 新增
 */
export function addCluePond(params) {
  return request.post(`${url}`, JSON.stringify(params));
}

/**
 * 删除
 */
export function deleteCluePond(params) {
  return request.delete(`${url}/info`, JSON.stringify(params));
}
