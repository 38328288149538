import request from '@/utils/request.js';

const url = "/manage/xiaoe";

export function shopCodeList(params) {
    return request({
        url: `${url}/shopCode/list`,
        method: 'get',
        params: params
    })
}

//商品列表
export function goodsList(params) {
    return request.post(`${url}/goods/list`, JSON.stringify(params));
}


//商品详情
export function goodsGet(params) {
    return request.post(`${url}/goods/get`, JSON.stringify(params));
}

//商品增加库存
export function goodsStockAdd(params) {
    return request.post(`${url}/goods/stockAdd`, JSON.stringify(params));
}

//商品减少库存
export function goodsStockReduce(params) {
    return request.post(`${url}/goods/stockReduce`, JSON.stringify(params));
}