<template>
  <div>

  </div>
</template>

<script>
import * as api from "@/api/mini/goods";

export default {
  name: "other-goods",
  data() {
    return {
      abc: true,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10
      },
      tableData: [],
      partParams: {
        page: 1,
        limit: 10,
        total: 0
      },
      goodsFormVisible: false,
      goodsForm: {
        id: null
      },
      formTitle: '修改商品'
    }
  },
  created() {
    this.list()
  },
  methods: {
    bind() {

    },
    save() {
      api.save(this.goodsForm).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.goodsFormVisible = false
          this.list()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    list() {
      api.getList(this.partParams).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    edit(row, id) {
      api.get(id).then(res => {
        this.goodsForm = res.data
        this.formTitle = '修改商品'
        this.goodsFormVisible = true
      })
    },
    //新增
    addGoods() {
      this.dialogTitle = "新增商品";
      this.goodsForm = {}
      this.goodsFormVisible = true;
    },
    //重置表单规则
    resetFrom(roleForm) {
      this.$refs[roleForm].clearValidate();
    },
  }
}
</script>

<style scoped>

</style>
