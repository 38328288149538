<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
             @submit.prevent.native>
      <el-form-item label="名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入名称"
            clearable
            size="small"
            @keyup.enter.native="findList"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="findList">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-permission="'crm:tags:add'"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
    </el-row>
    <el-table v-loading="loading" :data="tagsList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="主键" align="center" prop="id"/>
      <el-table-column label="名称" align="center" prop="name"/>
      <el-table-column label="提醒内容" align="center" prop="remarks"/>
      <el-table-column label="提醒" align="center" prop="remind">
          <template v-slot="scope">
              <el-switch
                      v-model="scope.row.remind"
                      @change="handleRemind(scope.row)"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
              </el-switch>
          </template>
      </el-table-column>
      <el-table-column label="状态" align="center" prop="status">
        <template v-slot="scope">
          <span v-if="scope.row.status === item.value" v-for="item in statusList">{{ item.label }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template v-slot="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              v-permission="'crm:tags:update'"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              v-permission="'crm:tags:remove'"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.current"
        :limit.sync="queryParams.size"
        @pagination="findList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="45%" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item label="提醒内容" prop="remarks">
          <el-input v-model="form.remarks" type="textarea"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="form.status">
            <el-option v-for="item in statusList" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as tagsApi from "@/api/crm/tags"

export default {
  name: "Tags",
  data() {
    {
      return {
        total: 0,
        queryParams: {
            current:1,
            size:10
        },
        loading: false,
        tagsList: [],
        form: {
          status: 0
        },
        rules: {},
        title: "",
        open: false,
        statusList: [
          {label: "启用", value: 0},
          {label: "禁用", value: 1},
          // {label: "删除", value: 9}
        ]
      }
    }
  },
  created() {
    this.findList()
  },
  methods: {
    handleRemind(row){
        tagsApi.updateRemind(row.id,row.remind).then(res=>{
          if(res.success){
              this.$message.success("修改成功")
          }else{
              this.$message.error(res.msg)
          }
        })
    },
    /**
     * 查询数据
     */
    findList() {
      this.loading = true
      tagsApi.paging(this.queryParams).then(res => {
        this.tagsList = res.data
        this.total = res.count
        this.loading = false
      })
    },
    /**
     * 更新
     */
    handleUpdate(row) {
      this.form = Object.assign({}, row)
      this.title = "修改标签"
      this.open = true
    },
    /**
     * 新增
     */
    handleAdd() {
      this.title = "添加新标签"
      this.open = true
      this.form = {
        status: 0
      }
    },
    handleDelete(row) {
      tagsApi.removeById(row.id).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          return
        }
        this.$message.error(res.msg)
      })
    },
    submitForm() {
      tagsApi.save(this.form).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.open = false
          this.findList()
          return
        }
        this.$message.error(res.msg)
      })
    },
    cancel() {
      this.open = false
    },
  }
}
</script>

<style scoped>

</style>
