<template>
    <div style="width: 100%;" class="app-container common__main_page">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="80px">
            <el-form-item label="名称" prop="name">
                <el-input
                        v-model="queryParams.name"
                        placeholder="请输入名称"
                        clearable
                        size="small"
                        @keyup.enter.native="findList"
                />
            </el-form-item>

            <el-form-item label="唯一标识" prop="possessId">
                <el-select v-model="queryParams.possessId" placeholder="请选择唯一标识" size="small" clearable
                           filterable>
                    <el-option
                            v-for="item in possessArr"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="首咨/库存" prop="opportunityType">
                <el-select v-model="queryParams.opportunityType" placeholder="请选择首咨/库存" size="small"
                           clearable>
                    <el-option
                            v-for="item in opportunityTypeArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="按钮状态" prop="status">
                <el-select v-model="queryParams.status" placeholder="请选择按钮状态" size="small"
                           clearable>
                    <el-option
                            v-for="item in statusArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="领取时间" prop="btnDateArr">
                <el-date-picker
                        v-model="btnDateArr"
                        type="daterange"
                        align="right"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        size="small"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>


        </el-form>
        <el-row style="margin-bottom: 10px">
            <el-col :span="6">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="findList">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
            </el-col>
            <el-col :span="16" style="text-align: right">
                <el-button
                        type="primary"
                        v-permission="'sys:receive:add'"
                        plain
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAdd"
                >新增
                </el-button>
                <router-link to="/crm/possess">
                    <el-button type="success" size="mini">唯一配置</el-button>
                </router-link>
                <router-link to="/Configure">
                    <el-button type="danger" size="mini">顾问配置</el-button>
                </router-link>
                <el-button
                        type="primary"
                        size="mini"
                        v-permission="'sys:receive:export'"
                        icon="el-icon-download"
                        @click="exportData">导出
                </el-button>
            </el-col>

        </el-row>

    <el-table v-loading="loading" :data="receiveList" show-summary :summary-method="getSummaries" style="overflow: scroll">
      <el-table-column label="名称" align="center" prop="name"/>
      <el-table-column label="按钮" align="center" prop="label"/>
      <el-table-column label="创建时间" align="center" prop="createTime"/>
      <el-table-column width="150" label="状态" align="center" prop="status">
        <template v-slot="scope">
          <el-switch
              v-model="scope.row.status"
              active-text="启用"
              :active-value="1"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="updateStatus(scope.row)"
              :inactive-value="0"
              inactive-text="停用">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column width="110" label="可领取人数" align="center" prop="receiveAccountNum"/>
      <el-table-column width="110" label="实际领取人数" align="center" prop="realReceiveAccountNum"/>
      <el-table-column width="110" label="已领取机会数" align="center" prop="receiveOpportunityNum"/>
      <el-table-column width="110" label="人均领取个数" align="center" prop="avgReceiveAccountNum"/>
      <el-table-column width="150" label="验证码领取" align="center" prop="enableVerify">
          <template v-slot="scope">
              <el-switch
                      v-model="scope.row.enableVerify"
                      active-text="启用"
                      :active-value="true"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      @change="updateEnableVerify($event ,scope.row)"
                      :inactive-value="false"
                      inactive-text="停用">
              </el-switch>
          </template>
      </el-table-column>
      <el-table-column label="领取详情" align="center" prop="receiveDetail">
        <template slot-scope="scope">
          <el-button type="text" @click="checkReceiveDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template v-slot="scope">
          <el-button
              v-permission="'sys:receive:update'"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              v-permission="'sys:receive:delete'"
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
            <el-button
                    v-permission="'sys:receive:log'"
                    size="mini"
                    type="text"
                    icon="el-icon-more"
                    @click="handleQueryLog(scope.row.id)"
            >日志
            </el-button>
        </template>
      </el-table-column>
    </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="queryParams.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="queryParams.total">
        </el-pagination>

        <el-dialog :title="title + '领取详情'" :visible.sync="receiveDetailDialog" width="80%">

            <el-form :model="queryReceiveParams" ref="queryReceiveForm" :inline="true" label-width="68px">
                <el-form-item label="领取时间" prop="receiveDateArr">
                    <el-date-picker
                            v-model="receiveDateArr"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            size="small"
                            align="center"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="getReceiveData(form.id)">
                    </el-date-picker>
                </el-form-item>
            </el-form>

            <el-table
                    :data="receiveDataArr"
                    v-loading="receiveDataLoading"
                    max-height="550">
                <el-table-column prop="receiveDate" label="日期" width="100px" align="center"/>
                <el-table-column prop="receiveNum" label="领取人数" width="100px" align="center"/>
                <!--        <el-table-column v-for="(dept,index) in deptArr"-->
                <!--                         :prop="dept.prop"-->
                <!--                         :label="dept.deptName"-->
                <!--                         :key="index"-->
                <!--                         width="100px"-->
                <!--                         align="center">-->
                <!--        </el-table-column>-->
            </el-table>
        </el-dialog>

    </div>
</template>

<script>
import * as receiveApi from '@/api/system/receive'
import * as deptApi from "@/api/system/dept";
import * as roleApi from "@/api/system/role";
import * as apiUtils from "@/utils/api";

// prop开头标识
const prop_flag = 'DEP_'

export default {
    name: "ReceiveBtn",
    data() {
        return {
            total: 0,
            queryParams: {
                name: null,
                possessId: null,
                opportunityType: 2,
                status: null,
                size: 10,
                current: 1,
                total: 0
            },
            queryReceiveParams: {},
            loading: false,
            receiveList: [],
            receiveDetailDialog: false,
            title: '',
            receiveDataArr: [],
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0);
                        start.setMinutes(0);
                        start.setSeconds(0);
                        start.setMilliseconds(0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        start.setHours(0);
                        start.setMinutes(0);
                        start.setSeconds(0);
                        start.setMilliseconds(0);
                        end.setTime(end.getTime() - 3600 * 1000 * 24);
                        end.setHours(23);
                        end.setMinutes(59);
                        end.setSeconds(59);
                        end.setMilliseconds(999);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            receiveDateArr: [],
            form: {},
            receiveDataLoading: false,
            possessArr: [],
            opportunityTypeArr: [{value: 0, label: '库存'}, {value: 1, label: '首咨'}, {value: 2, label: '全部'}],
            // 角色选项
            roleOptions: [],
            btnDateArr: [],
            statData: {},
            statusArr: [
                {value: null, label: '全部'},
                {value: 1, label: '启用'},
                {value: 0, label: '停用'}
            ],
        };
    },
    created() {
        this.btnDateArr = [this.getDate(-7, '-'), this.getDate(-1, '-')]
        this.findPossessList();
        this.findList();
    },
    methods: {
        handleSizeChange(val) {
            this.queryParams.size = val
            this.findList()
        },
        handleCurrentChange(val) {
            this.queryParams.current = val
            this.findList()
        },
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                switch (column.property) {
                    case "name":
                        sums[index] = this.statData.name;
                        break;
                    case "status":
                        sums[index] = this.statData.status;
                        break;
                    case "receiveAccountNum":
                        sums[index] = this.statData.receiveAccountNum;
                        break;
                    case "realReceiveAccountNum":
                        sums[index] = this.statData.realReceiveAccountNum;
                        break;
                    case "receiveOpportunityNum":
                        sums[index] = this.statData.receiveOpportunityNum;
                        break;
                    case "avgReceiveAccountNum":
                        sums[index] = parseInt(this.statData.avgReceiveAccountNum).toFixed(0);
                        break;
                    default:
                        return '';
                }
            });
            return sums;
        },
        getDate(num, str) {
            let today = new Date();
            let nowTime = today.getTime();
            let ms = 24 * 3600 * 1000 * num;
            today.setTime(parseInt(nowTime + ms));
            let oYear = today.getFullYear();
            let oMoth = (today.getMonth() + 1).toString();
            if (oMoth.length <= 1) oMoth = '0' + oMoth;
            var oDay = today.getDate().toString();
            if (oDay.length <= 1) oDay = '0' + oDay;
            return oYear + str + oMoth + str + oDay;
        },
        /**
         * 日期格式化工具
         * @param date
         * @returns {string}
         */
        dateFormat(date) {
            const y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? '0' + m : m;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        },
        exportData() {
            receiveApi.exportData().then(resp => {
                let now = new Date();
                const blob = new Blob([resp]);
                const fileName = '领取按钮' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(ex => {
                this.$message.error("导出失败,请联系管理员");
            })
        },
        findPossessList() {
            apiUtils.possessList().then(res => {
                if (res.success) {
                    this.possessArr = res.data
                }
            })
        },
        updateStatus(row) {
            if (row.status) {
                //启用
                receiveApi.enableReceive(row.id).then(res => {
                    this.$message.success("启用成功")
                })
            } else {
                //停用
                receiveApi.disableReceive(row.id).then(res => {
                    this.$message.success("停用成功")
                })

            }
            this.findList();
        },
        updateEnableVerify(val, row) {
            const params = {
                id: row.id,
                enableVerify: val
            }
            receiveApi.updateEnableVerify(params).then(res => {
                if (res.success) {
                    this.$message.success("修改成功")
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        /**
         * 重置查询
         */
        resetQuery(value) {
            this.$refs[value].resetFields();
            this.receiveDateArr = []
            this.btnDateArr = []
            this.findList();
        },
        /**
         * 新增
         */
        handleAdd() {
            this.$router.push({
                path: '/receive-button-detail'
            })
        },
        /**
         * 修改
         * @param id
         */
        handleUpdate(row) {
            this.$router.push({
                path: '/receive-button-detail',
                query: {
                    id: row.id
                }
            })
        },
        /**
         * 删除
         * @param id
         */
        handleDelete(row) {
            this.$confirm('是否确认删除?', '删除领取按钮', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
            }).then(() => {
                receiveApi.removeReceive(row.id).then(res => {
                    if (res.success) {
                        this.$message.success("删除成功!")
                        this.findList()
                    }
                })
            })
        },
        /**
         * 查询
         */
        findList() {
            if (this.btnDateArr) {
                this.queryParams['startDate'] = this.btnDateArr[0]
                this.queryParams['endDate'] = this.btnDateArr[1]
            }
            receiveApi.listPage(this.queryParams).then(res => {
                if (res.success) {
                    this.receiveList = res.data.data
                    this.statData = JSON.parse(res.msg)
                    this.queryParams.size = res.data.size
                    this.queryParams.total = res.data.count
                    this.queryParams.current = res.data.current
                }
            })
        },
        /**
         * 获取按钮领取数据
         *
         * @param receiveBtnId 按钮id
         */
        getReceiveData(receiveBtnId) {
            this.receiveDataArr = []
            this.receiveDataLoading = true;
            if (!this.receiveDateArr) {
                receiveApi.getReceiveData(receiveBtnId).then(resp => {
                    if (resp.success) {
                        this.receiveDataArr = resp.data
                        // resp.data.map(item => {
                        //     let params = {}
                        //     for (let i in item) {
                        //         params[`${prop_flag}${i}`] = item[i]
                        //     }
                        //     this.receiveDataArr.push(params)
                        // })
                        this.receiveDataLoading = false;
                    }
                })
            } else receiveApi.getReceiveData(receiveBtnId, this.receiveDateArr[0], this.receiveDateArr[1]).then(resp => {
                if (resp.success) {
                    this.receiveDataArr = resp.data
                    // resp.data.map(item => {
                    //     let params = {}
                    //     for (let i in item) {
                    //         params[`${prop_flag}${i}`] = item[i]
                    //     }
                    //     this.receiveDataArr.push(params)
                    // })
                    this.receiveDataLoading = false;
                }
            })
        },
        /**
         * 查看
         *
         * @param data 按钮数据
         */
        checkReceiveDetail(data) {
            this.form = {};
            this.form = data;
            this.receiveDateArr = []
            this.receiveDetailDialog = true;
            this.getReceiveData(data.id);
        },
        handleQueryLog(btnId) {
            this.$router.push({
                path: "/system/receive-log",
                query: {btnId: btnId}
            })
        },
    }
};
</script>
<style scoped>
body {
    margin: 0;
}

/deep/ .el-tree-node.is-current > .el-tree-node__content {
    background-color: #164bff !important;
    color: #eeeeee !important;
}
</style>
