import request from '@/utils/request.js';


const url = "/manage/crm/order";

//获取列表数据
export function listOrders(query) {
    return request({
        url: `${url}/orderManageListJson`,
        method: 'get',
        params: query
    })
}

//获取列表数据
export function listOpporOrders(query) {
    return request({
        url: `${url}/listOpporOrders`,
        method: 'get',
        params: query
    })
}

/**
 * 发送成交信息
 * @param param
 * @returns {*}
 */
export function sendMessage(orderNumber) {
    return request({url: `${url}/sendMessage`, method: 'get', params: {orderNumber: orderNumber}});
}

/**
 * 查询机会历史订单
 * @param studentPhone
 * @returns {*}
 */
export function historyOrdersByTelephone(studentPhone) {
    return request({url: `${url}/historyOrdersByTelephone/${studentPhone}`, method: 'get'});
}

/**
 * 撤回手工订单
 * @param id
 * @returns {AxiosPromise}
 */
export function callbackManuallyOrder(id) {
    return request({url: `${url}/callbackManuallyOrder/${id}`, method: 'get'});
}

/**
 * 根据订单编号查询订单信息
 * @param orderNumber
 * @returns {*}
 */
export function getByOrderNumber(orderNumber) {
    return request({
        url: `${url}/get-orderNumber/?orderNumber=${orderNumber}`,
        method: 'GET'
    })
}