import request from '@/utils/request.js';

const url = "/manage/system/role";

//获取列表数据
export function listRole(query) {
    return request({
        url: `${url}/oneListJson`,
        method: 'get',
        params: query
    })
}

//获取所有数据
export function list(query) {
    return request.get(`${url}/list`, query);
}

// 角色状态修改
export function changeRoleStatus(id, status) {
    const data = {
        id,
        status
    }
    return request({
        url: '/system/role/changeStatus',
        method: 'put',
        data: data
    })
}

//保存
export function saveRole(param) {
    return request.post(`${url}/save`, param);
}

//修改
export function updateRole(param) {
    return request.post(`${url}/update`, param);
}

//删除角色
export function deleteRole(ids) {
    return request.get(`${url}/delete/${ids}`);
}

//设置权限
export function dataScope(param) {
    return request.post(`${url}/dataScope`, param);
}

//获取
export function getRole(id) {
    return request.get(`${url}/getRole/${id}`);
}

