import request from '@/utils/request.js';

const url = "/manage/pagedomain";

//添加列表数据
export function save(params) {
  return request.post(`${url}/save`, JSON.stringify(params));
}

/**
 * 获取随机字符串
 */
export function getCode() {
  return request({
    url: `${url}/getCode`,
    method: 'get'
  })
}

//获取列表数据
export function getListPage(params) {
  return request({
    url: `${url}/listPage`,
    method: 'post',
    params: params
  })
}

/**
 * 获取统计数据
 * @param params
 */
export function statistics(params,domainList) {
  const data = Object.assign({}, params);
  data['domainList'] = domainList
  return request({
    url: `${url}/statistics`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

/**
 * 获取总合计数
 * @param params
 * @param domainList
 */
export function statisticsCount(params,domainList) {
  const data = Object.assign({}, params);
  data['domainList'] = domainList
  return request({
    url: `${url}/statisticsCount`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

//获取列表数据
export function findPage(params) {
  return request({
    url: `${url}/findPage`,
    method: 'post',
    params: params
  })
}

//删除当前列表
export function deleteCurrent(id) {
  return request.post(`${url}/remove/${id}`);
}

export function getList(params) {
  return request({
    url: `${url}/list`,
    method: 'post',
    params: params
  });
}

/**
 * 查询成功数据
 * @param page
 * @param params
 */
export function findSuccess(page, params) {
  return request.post(`${url}/findSuccess/${page}`, params);
}

/**
 * 查询轮播图列表
 * @returns
 */
export function findRevolveImg() {
  return request.get(`${url}/findRevolveImg`)
}

/**
 * 保存轮播图列表
 * @returns
 */
export function saveRevolveImg(data) {
  return request.post(`${url}/saveRevolveImg`, data)
}