<template>
    <div>
        <el-dialog v-if="showObj.isShow" title="顾问子配置" :visible.sync="showObj.isShow" width="60%">
            <el-card class="box-card">
                <div class="configure-item">
                    <el-form :model="configure" :rules="formRules" ref="formRules" label-width="200px">
                        <el-input v-if="type === 2" hidden v-model="configure.id"></el-input>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item label="父级配置" prop="parentId" size="mini">
                                    <el-select v-model="configure.parentId"
                                               @change="handleChangeSuper"
                                               :disabled="type === 2"
                                               placeholder="请选择">
                                        <el-option
                                                v-for="item in superConfigureList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">公共配置名称</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="name" label="子配置名称" size="mini">
                                    <el-input v-model="configure.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">配置名称</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="bringline" label="机会保护期（天)" size="mini">
                                    <el-input v-model="configure.bringline" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">学习顾问在机会保护期内不能重新领取自己放弃的资源</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="opporMax" label="最大资源数（个)" size="mini">
                                    <el-input v-model="configure.opporMax" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">每位学习顾问可拥有的最大资源数量</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="drawDayMax" label="顾问机会的日领取限制（个)" size="mini">
                                    <el-input v-model="configure.drawDayMax" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">学习顾问每日可领取顾问机会上限</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="firstAdvisoryDayMax" label="顾问首咨的日领取限制（个)" size="mini">
                                    <el-input v-model="configure.firstAdvisoryDayMax"
                                              type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">学习顾问每日可领取首咨机会上限</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="phoneCount" label="每日查看手机/微信数量（个)" size="mini">
                                    <el-input v-model="configure.phoneCount" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">顾问每日查看/手机/微信的数量</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="wakeOpportunityDayMax" label="每日领取唤醒机会数量（个)" size="mini">
                                    <el-input v-model="configure.wakeOpportunityDayMax"
                                              type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">顾问每日查看/手机/微信的数量</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="mainInlet" label="领取入口" size="mini">
                                    <el-checkbox-group v-model="configure.mainInlet" size="mini">
                                        <el-checkbox :disabled="mainInlet.indexOf(1) === -1" :label="1" border>自创建
                                        </el-checkbox>
                                        <el-checkbox :disabled="mainInlet.indexOf(2) === -1" :label="2" border>
                                            机会校验领取
                                        </el-checkbox>
                                        <el-checkbox :disabled="mainInlet.indexOf(3) === -1" :label="3" border>
                                            领取库存
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">注意:子配置只能操作父配置已有选项</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-form-item style="width: 100%" size="mini" label="其他领取入口"
                                          v-if="checkedBtnList.length > 0">
                                <div style="margin-bottom: 10px;display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;"
                                     v-for="item in checkedBtnList" :key="item.id">
                                    <el-tag style="margin-right: 10px">{{ item.name }} | 领取上限 {{
                                        item.size
                                        }}
                                    </el-tag>
                                    <el-divider direction="vertical"></el-divider>
                                    <div style="display: flex;flex-direction: row;align-items: center;">
                                        <el-switch
                                                v-model="item.enabled"
                                                active-color="#13ce66"
                                                inactive-color="#ff4949"
                                                :active-value="1"
                                                :inactive-value="0"
                                                @change="change()">
                                        </el-switch>

                                        <div style="margin-left: 10px">是否启用</div>
                                    </div>
                                    <el-divider direction="vertical"></el-divider>
                                    <el-input-number style="margin-left: 10px" v-model="item.receiveMax" :min="0"
                                                     :max="99999" label="描述文字"></el-input-number>
                                </div>

                            </el-form-item>
                        </el-row>

                        <el-row>
                            <el-form-item>
                                <el-button size="mini" type="primary" @click="submitForm">确定</el-button>
                                <el-button size="mini" @click="showObj.isShow = false">取消</el-button>
                            </el-form-item>
                        </el-row>

                    </el-form>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import * as api from '@/utils/api'
import * as receiveApi from '@/api/system/receive'

export default {
    props: {
        showObj: {
            type: Object,
            require: true
        },
        type: { //添加，编辑
            require: false,
            default: 1,
            type: Number
        },
        superConfigureList: {
            require: false,
            default: [],
            type: Array
        },
        configureId: {
            require: false,
            default: 0,
            type: Number
        }
    },
    name: "ConfigureSonDetail",
    data() {
        const validateNumber = (rule, value, callback) => {
            if (value == null || value.length > 4) {
                callback(new Error('最大允许输入9999'))
            }
            if (!(/^(\d+)$/).test(value)) {
                callback(new Error('请输入数字值'))
            } else {
                callback()
            }
        };
        return {
            configure: {
                id: '',
                name: '',
                bringline: '',
                opporMax: '',
                drawDayMax: '',
                firstAdvisoryDayMax: '',
                phoneCount: '',
                wakeOpportunityDayMax: '',
                mainInlet: [],
            },
            superConfigure: {
                id: '',
                name: '',
                bringline: '',
                opporMax: '',
                drawDayMax: '',
                firstAdvisoryDayMax: '',
                phoneCount: '',
                wakeOpportunityDayMax: '',
                mainInlet: [],
            },
            checkBtn: null,
            checkedBtnList: [],
            btnList: [],
            formRules: {
                name: [{required: true, message: '请输入配置名称', trigger: 'blur'}],
                bringline: [{validator: validateNumber, trigger: 'blur'}],
                opporMax: [{validator: validateNumber, trigger: 'blur'}],
                drawDayMax: [{validator: validateNumber, trigger: 'blur'}],
                firstAdvisoryDayMax: [{validator: validateNumber, trigger: 'blur'}],
                phoneCount: [{validator: validateNumber, trigger: 'blur'}],
                wakeOpportunityDayMax: [{validator: validateNumber, trigger: 'blur'}]
            },
            mainInlet: [],
            typeList: ['', 'success', 'info', 'warning', 'danger'],
        }
    },
    mounted() {
        this.findReceiveList()
    },
    methods: {
        change() {
            this.$forceUpdate()
        },
        getConfig() {
            if (this.configureId) {
                api.getConfigure(this.configureId).then(resp => {
                    // 从父配置上读取主要领取入口与其他领取入口数据
                    api.getConfigure(resp.data.parentId).then(res => {
                        this.mainInlet = res.data.mainInlet.split(',').map(e => Number(e));
                        res.data.otherInlet.split(',').forEach(id => {
                            if (this.btnList && this.btnList.length > 0) {
                              this.checkedBtnList.push(this.btnList.filter(e => Number(e.id) === Number(id))[0])
                              if (resp.data.otherInletJson) {
                                for (let btn of this.checkedBtnList) {
                                  for (let data of resp.data.otherInletJson) {
                                    if (btn?.id === data.btnId) {
                                      btn.enabled = data.enabled
                                      btn.receiveMax = data.receiveMax === null ? 0 : data.receiveMax
                                    }
                                  }
                                }
                              }
                            }

                        })

                    })
                    this.configure.id = resp.data.id;
                    this.configure.parentId = resp.data.parentId;
                    this.configure.name = resp.data.name;
                    this.configure.bringline = resp.data.bringline;
                    this.configure.opporMax = resp.data.opporMax;
                    this.configure.drawDayMax = resp.data.drawDayMax;
                    this.configure.firstAdvisoryDayMax = resp.data.firstAdvisoryDayMax;
                    this.configure.phoneCount = resp.data.phoneCount;
                    this.configure.wakeOpportunityDayMax = resp.data.wakeOpportunityDayMax;
                    if (resp.data.mainInlet) {
                        this.configure.mainInlet = resp.data.mainInlet.split(',').map(e => Number(e));
                    }

                })
            }
        },
        handleChangeSuper() {
            this.superConfigure = {
                id: '',
                name: '',
                bringline: '',
                opporMax: '',
                drawDayMax: '',
                firstAdvisoryDayMax: '',
                phoneCount: '',
                wakeOpportunityDayMax: '',
                mainInlet: [],
            };
            this.configure.mainInlet = []
            this.mainInlet = []
            this.checkedBtnList = []
            this.superConfigure = this.superConfigureList.filter(item => item.id === this.configure.parentId)[0];
            this.configure.parentId = this.superConfigure.id;
            this.configure.bringline = this.superConfigure.bringline;
            this.configure.opporMax = this.superConfigure.opporMax;
            this.configure.drawDayMax = this.superConfigure.drawDayMax;
            this.configure.firstAdvisoryDayMax = this.superConfigure.firstAdvisoryDayMax;
            this.configure.phoneCount = this.superConfigure.phoneCount;
            this.configure.wakeOpportunityDayMax = this.superConfigure.wakeOpportunityDayMax;
            if (this.superConfigure.mainInlet) {
                this.configure.mainInlet = this.superConfigure.mainInlet.split(',').map(e => Number(e));
                this.mainInlet = this.superConfigure.mainInlet.split(',').map(e => Number(e));
            }
            if (this.superConfigure.otherInlet && this.btnList && this.btnList.length > 0) {
                this.superConfigure.otherInlet.split(',').forEach(id => {
                    const item = this.btnList.filter(e => Number(e.id) === Number(id))[0];
                    item.receiveMax = 0;
                    this.checkedBtnList.push(item)
                })
            }
        },
        /**
         * 删除一个按钮
         * @param id
         */
        delBtn(id) {
            const data = this.checkedBtnList.filter(e => e.id === id);
            const index = this.checkedBtnList.indexOf(data[0]);
            if (index > -1) {
                this.checkedBtnList.splice(index, 1)
            }
        },
        /**
         * 添加一个按钮
         */
        addBtn() {
            this.checkedBtnList.push(this.checkBtn);
            this.checkBtn = null
        },
        /**
         * 获取所有按钮
         */
        findReceiveList() {
            receiveApi.list().then(res => {
                if (res.success) {
                    this.btnList = res.data
                    if (this.type === 2) {
                        this.getConfig();
                    } else {
                    }
                }
            })
        },
        /**
         * 提交表单
         */
        submitForm() {
            this.$refs['formRules'].validate((valid) => {
                if (valid) {
                    const params = JSON.parse(JSON.stringify(this.configure))
                    params['mainInlet'] = this.configure.mainInlet.join(',')
                    const otherInletArr = []
                  console.log(this.checkedBtnList)

                    this.checkedBtnList.forEach(e => {
                        otherInletArr.push({
                                btnId: e.id,
                                enabled: e.enabled,
                                receiveMax: e.receiveMax,
                            }
                        )
                    });
                    params['otherInletJson'] = otherInletArr
                    api.saveOrUpdateConfigure(params, this.type).then(resp => {
                        if (resp.success) {
                            this.$message.success(resp.msg)
                            this.$emit("getConfigure")
                            this.showObj.isShow = false
                        } else {
                            this.$message.warning(resp.msg)
                        }
                    })
                } else {
                    this.$message.warning("请输入正确的内容")
                }

            })
        }
    }

}
</script>

<style scoped>

.configure-des {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #909399;
}

</style>
