import request from '@/utils/request.js';

const url = "/manage/crm/refund-repair";


/**
 * 获取待审核列表数据
 * @returns {*}
 * @param params
 */
export function listPage(params) {
    return request({
        url: `${url}/page`,
        method: 'GET',
        params: params
    })
}

export function getById(id) {
    return request({
        url: `${url}/get?id=${id}`,
        method: 'GET'
    })
}

export function getOrderNumberList(params) {
    return request({
        url: `${url}/order-number`,
        method: 'GET',
        params: params
    })
}

export function submitRefundRepair(params) {
    return request({
        url: `${url}/submit-refund`,
        method: 'POST',
        data: params
    })
}

export function examineBack(params) {
    return request({
        url: `${url}/examine-back`,
        method: 'PUT',
        data: params
    })
}

export function examinePass(params) {
    return request({
        url: `${url}/examine-pass`,
        method: 'PUT',
        data: params
    })
}

export function backRepair(id) {
    return request({
        url: `${url}/back-repair?id=${id}`,
        method: 'PUT'
    })
}

export function deleteById(id) {
    return request({
        url: `${url}/delete?id=${id}`,
        method: 'DELETE'
    })
}

export function getRefundStatus() {
    return request({
        url: `${url}/refund-status`,
        method: 'GET'
    })
}

export function handleSync(refundId) {
    return request({
        url: `${url}/handle-sync?id=${refundId}`,
        method: 'PUT'
    })
}

export function queryRefundLog(orderNumber) {
    return request({
        url: `${url}/query-sync-refund?orderNumber=${orderNumber}`,
        method: 'GET'
    })
}

export function exportRefund(params) {
    return request({
        url: `${url}/export`,
        method: 'POST',
        data: params,
        responseType: "blob"
    })
}

export function queryLog(id) {
    return request({
        url: `${url}/log?id=${id}`,
        method: 'GET'
    })
}