<template>
  <div>
    <el-form v-model="queryParams" :inline="true" label-position="right" label-width="80px" @submit.prevent.native>
      <el-form-item label="父级配置" prop="parentId" size="mini">
        <el-select v-model="queryParams.parentId" clearable placeholder="请选择">
          <el-option
              v-for="item in superConfigureList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="配置名称" prop="configureName" size="mini">
        <el-input v-model="queryParams.name" class="condition-input" placeholder="配置名称"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="margin-right: 10%;margin-bottom: 10px">
      <el-button type="success" plain @click="handleAdd">添加新的子配置</el-button>
    </div>

    <el-table :data="childConfigureList" style="width: 100%" stripe fit>
      <el-table-column align="center" width="200" prop="name" fixed="left" label="配置名称"/>
      <el-table-column align="center" width="120" prop="bringline" label="机会保护期"/>
      <el-table-column align="center" width="120" prop="opporMax" label="最大资源数"/>
      <el-table-column align="center" width="120" prop="drawDayMax" label="日领取上限"/>
      <el-table-column align="center" width="120" prop="firstAdvisoryDayMax" label="首咨日领取上限"/>
      <el-table-column align="center" width="120" prop="phoneCount" label="查看手机号"/>
      <el-table-column align="center" width="120" prop="wakeOpportunityDayMax" label="日领取唤醒数量"/>

      <el-table-column align="center" min-width="100" prop="otherInlet" label="领取入口">
        <template slot-scope="scope">
          <div v-for="item in getOtherIntelNameList(scope.row.otherInlet)">
            <el-tag :type="typeOptions[item.id % 5]">{{ item.name }}</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scpoe">
          <el-button type="primary" size="mini" @click="handleEdit(scpoe.row.id)">配置</el-button>
          <el-button type="danger" size="mini" @click="handleDel(scpoe.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
    </el-pagination>

    <ConfigureSonDetail v-if="showDetail.showObj.isShow"
                        :show-obj="showDetail.showObj"
                        :configure-id="showDetail.configureId"
                        :type="showDetail.type"
                        :superConfigureList="superConfigureList"
                        @getConfigure="getList">
    </ConfigureSonDetail>
  </div>
</template>

<script>
import * as api from "@/utils/api";
import ConfigureSonDetail from "@/views/configure/components/ConfigureSonDetail.vue";
import * as receiveApi from "@/api/system/receive";


export default {
    name: "ConfigurationSon",
    components: {ConfigureSonDetail},
    data() {
        return {
            typeOptions: ['', 'success', 'warning', 'danger', 'info'],
            queryParams: {}, // 查询条件
            childConfigureList: [], // 子配置列表
            superConfigureList: [], // 父配置列表
            title: '',
            form: false,
            btnList: [],
            page: {
                current: 1,
                size: 10,
                total: 0
            },
            showDetail: {
                showObj: {
                    isShow: false
                },
                type: '',
                configureId: ''
            },
        }
    },
    mounted() {
        this.init();
        this.getList();
    },
    methods: {
        init() {
            api.configureList(1, 99999, {parentId: 0}).then(res => {
                this.superConfigureList = res.data.data;
            })
            receiveApi.list().then(res => {
                if (res.success) {
                    this.btnList = res.data
                }
            })
        },
        handleAdd() {
            this.showDetail.configureId = null;
            this.showDetail.type = 1;
            this.showDetail.showObj.isShow = true;
        },
        /**
         * 编辑配置
         * @param id
         */
        handleEdit(id) {
            this.showDetail.configureId = id;
            this.showDetail.type = 2;
            this.showDetail.showObj.isShow = true;
        },
        /**
         * 删除配置
         * @param id
         */
        handleDel(id) {
            api.deleteById(id).then(resp => {
                if (resp.success) {
                    this.$message.success(resp.msg)
                    this.getList()
                } else this.$message.error(resp.msg)
            })
        },
        /**
         * 查询数据
         */
        getList() {
            const params = JSON.parse(JSON.stringify(this.queryParams));
            if (!params.parentId) {
                params['parentId'] = -1;
            }
            // 这里可以对查询参数进行操作而不影响页面显示
            api.configureList(this.page.current, this.page.size, params).then(res => {
                this.page.current = res.data.current
                this.page.size = res.data.size
                this.page.total = res.data.total
                this.childConfigureList = res.data.data;
            })
        },
        getOtherIntelNameList(otherInletStr) {
            let arr = [];
            if (otherInletStr !== null) {
                let otherInletArr = otherInletStr.split(',').map(Number);
                const otherBtnArr = this.btnList.filter(btn => otherInletArr.includes(btn.id));
                arr = otherBtnArr.map(e => e);
            }
            return arr;
        },
        /**
         * 重置查询
         */
        resetQuery() {
            this.queryParams = {}
        },
        handleSizeChange(val) {
            this.page.size = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.page.current = val
            this.getList()
        },
    }
}
</script>

<style scoped>

</style>