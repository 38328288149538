<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="8" style="text-align: center" v-show="Show">
        <div class="qrcode-style" id="qrcode"></div>
        <span class="span">{{ payParamsObject.payType == 'WEIXIN' ? '微信扫码支付' : '支付宝扫码支付' }}</span>
      </el-col>
      <el-col :span="8" style="text-align: center">
        <div class="qrcode-style" id="qrcode1"></div>
        <span class="span">登录网校付款</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" :offset="4">
        <div class="linHeight">网页打开支付</div>
      </el-col>
      <el-col :span="12">
        <el-col :span="20">
          <el-input readonly="readonly" v-model="PaymentAddress.pc"></el-input>
        </el-col>
        <el-col :span="3" :offset="1">
          <el-tooltip class="item" effect="dark" content="点击复制" placement="bottom" v-if="PaymentAddress.pc">
            <i class="el-icon-document-copy" v-clipboard:copy="PaymentAddress.pc" v-clipboard:success="onCopy"
               v-clipboard:error="onError"></i>
          </el-tooltip>
        </el-col>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" :offset="4">
        <div class="linHeight">手机无息分期</div>
      </el-col>
      <el-col :span="12">
        <el-col :span="20">
          <el-input readonly="readonly" v-model="PaymentAddress.mobile"></el-input>
        </el-col>
        <el-col :span="3" :offset="1">
          <el-tooltip class="item" effect="dark" content="点击复制" placement="bottom" v-if="PaymentAddress.mobile">
            <i class="el-icon-document-copy" v-clipboard:copy="PaymentAddress.mobile" v-clipboard:success="onCopy"
               v-clipboard:error="onError"></i>
          </el-tooltip>
        </el-col>
      </el-col>
    </el-row>
    <el-row type="flex" align="center">
      <div class="tips">*因国家风控中心管控收紧，异地线上付款频繁失败，请优先使用网校链接付款。现扫码付款次数有限，请谨慎使用。</div>
    </el-row>
    <el-row type="flex" justify="center">
      <el-button type="primary" @click="Show = !Show">使用二维码</el-button>
    </el-row>
  </div>
</template>

<script>

import * as api from "../../utils/api"
import QRCode from "qrcodejs2";

export default {
  name: "Payment",
  props: {
    payParamsObject: {
      type: Object
    }
  },
  data() {
    return {
      Show: false,
      address: '',
      PaymentAddress: {
        pc: '',
        mobile: '',
      },
    }
  },
  watch:{
    Show:function (val, oldVal) {
      if(val == true){
        this.qrcode()
      }
    }
  },
  mounted() {
    this.address = this.payParamsObject.qrcode
    this.paySuccess();

  },
  methods: {
    qrcode() {
      document.getElementById("qrcode").innerHTML = "";
      let qrcode = new QRCode("qrcode", {
        width: 120, // 设置宽度，单位像素
        height: 120, // 设置高度，单位像素
        text: this.address // 设置二维码内容或跳转地址,
      });

    },
    paySuccess() {
      api.paySuccess(this.payParamsObject).then(res => {
        let data = res.data;
        this.PaymentAddress.pc = `http://${data.pcDomain}/web/uc/order/${data.orderId == '' ? 'list' : 'newlyOrder?orderId=' + data.orderId}`;
        this.PaymentAddress.mobile = `http://${data.mobileDomain}/mobile/uc/order/${data.orderId == '' ? 'orderList' : 'newlyOrder?id=' + data.orderId}`;
        console.log(this.PaymentAddress)
        let qrcode1 = new QRCode("qrcode1", {
          width: 120, // 设置宽度，单位像素
          height: 120, // 设置高度，单位像素
          text: this.PaymentAddress.mobile  // 设置二维码内容或跳转地址
        });
      })
    },
    onCopy(e) { 　　 // 复制成功
      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    onError(e) {　　 // 复制失败
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .qrcode-style img {
  margin: auto !important;
}

.span {
  margin: 10px 0;
  display: block;
}

.linHeight {
  line-height: 32px;
}
/deep/.el-row{
  margin: 30px auto;
}
/deep/.el-icon-document-copy{
  font-size: 30px;
}
.tips{
  margin: auto;
  text-align: center;
  color: red;
  font-size: 16px;
  font-style: oblique;
}
</style>
