<template>
    <div style="width: 100%;" class="app-container">

        <el-row :gutter="24">

            <el-col :span="4">
                <div style="text-align: center;font-size: 13px;color: #ff2b2b;">点击下方部门筛选数据</div>
                <el-tree
                    highlight-current-row
                    :data="deptTree"
                    :expand-on-click-node="false"
                    :filter-node-method="filterNode"
                    :props="{children: 'children', hasChildren: 'hasChildren', label: 'title'}"
                    node-key="id"
                    ref="tree"
                    default-expand-all
                    @node-click="handleNodeClick"
                />
            </el-col>

            <el-col :span="20">
                <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="100px">
                    <el-form-item label="学习顾问姓名" prop="realName">
                        <el-input
                            v-model="queryParams.nickName"
                            placeholder="输入姓名"
                            clearable
                            size="small"
                            @keyup.enter.native="handleQuery"
                        />
                    </el-form-item>
                    <!--      <el-form-item label="部门" prop="deptId">-->
                    <!--        <el-select v-model="queryParams.deptId" placeholder="选择部门" filterable clearable size="small">-->
                    <!--          <el-option label="选择部门" value=""/>-->
                    <!--          <el-option-->
                    <!--              v-for="dept in deptList"-->
                    <!--              :value="dept.id"-->
                    <!--              :key="dept.id"-->
                    <!--              :label="dept.deptName"-->
                    <!--          ></el-option>-->
                    <!--        </el-select>-->
                    <!--      </el-form-item>-->
                    <el-form-item label="顾问配置" prop="configureId">
                        <el-select v-model="queryParams.configureId" filterable placeholder="选择配置" clearable size="small">
                            <el-option label="选择配置" :value="null"/>
                            <el-option
                                v-for="con in configureSonList"
                                :value="con.id"
                                :key="con.id"
                                :label="con.name"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="用户状态" prop="status">
                        <el-select v-model="queryParams.status" filterable placeholder="选择用户状态" clearable size="small">
                            <el-option label="全部" value=""/>
                            <el-option label="在职" value="1"/>
                            <el-option label="离职" value="2"/>
                            <el-option label="停用" value="0"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>

                <el-row :gutter="10" class="mb8">
                    <el-col :span="1.5">
                        <el-button
                            type="success"
                            plain
                            icon="el-icon-edit"
                            size="mini"
                            :disabled="multiple"
                            @click="updateConfigure()"
                        >修改配置
                        </el-button>
                    </el-col>

                    <el-col :span="1.5">
                        <el-button
                            v-permission="'crm:adviser:delete'"
                            type="danger"
                            plain
                            icon="el-icon-delete"
                            size="mini"
                            :disabled="multiple"
                            @click="handleDelete"
                        >删除
                        </el-button>
                    </el-col>
                </el-row>

                <el-table max-height="600" v-loading="loading" :data="configList" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center"/>
                    <el-table-column label="学习顾问姓名" align="center" prop="nickName" fixed="left"/>
                    <el-table-column label="部门" align="center" prop="deptId">
                        <template v-slot="scope">
                            <span v-for="item in deptList" :key="item.id" v-if="scope.row.deptId === item.id">{{ item.deptName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="销售公共配置" min-width="120px" align="center" prop="configureId">
                        <template v-slot="scope">
                            <el-tag>{{ getConfigureSuperName(scope.row.configureId) }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="销售子配置" min-width="110px" align="center" prop="configureId">
                        <template v-slot="scope">
                            <el-tag type="info" v-for="item in configureSonList" :key="item.id"
                                    v-if="item.id === scope.row.configureId">{{ item.name }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="营销配置" min-width="110px" align="center" prop="configMarketId">
                        <template v-slot="scope">
                            <el-tag type="success" v-for="item in configMarketList" :key="item.id"
                                    v-if="item.id === scope.row.configMarketId">{{ item.name }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="话务工号" align="center" prop="user">
                        <template v-slot="scope">
                            <span v-for="item in trafficUserList" v-if="item.accountId === scope.row.id">{{ item.user }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="待处理机会数" align="center" prop="opporSum"/>
                    <el-table-column label="今日首咨数" align="center" prop="firstAdvisoryCount"/>
                    <el-table-column label="今日库存数" align="center" prop="stockAdvisoryCount"/>
                    <el-table-column label="微页面留言数" align="center" prop="messageAdvisoryCount"/>
                    <el-table-column label="查看手机号" align="center" prop="phoneCount"/>
                    <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-edit"
                                @click="updateConfigure(scope.row)"
                            >修改配置
                            </el-button>
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-phone-outline"
                                @click="handleUpdate(scope.row)"
                            >话务
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="openDialog(scope.row)"
                            >查询话务
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <pagination
                    v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.current"
                    :limit.sync="queryParams.size"
                    @pagination="getList"
                />
            </el-col>
        </el-row>

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog title="修改话务" :visible.sync="trafficOpen" width="500px" append-to-body>
            <el-form ref="trafficForm" :model="trafficForm" label-width="80px">
                <el-input v-model="trafficForm.accountId" hidden/>
                <el-form-item label="话务">
                    <el-select v-model="trafficForm.tarId" filterable size="small">
                        <el-option value="0" label="取消话务"></el-option>
                        <el-option v-for="traff in trafficOptions" :key="traff.id" :value="traff.id"
                                   :label="traff.user">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFormTraffic">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="咨询师电话" :visible.sync="phoneOpen" width="50%">
            <el-button
                    size="mini"
                    type="primary"
                    @click="addPhone"
            >添加手机
            </el-button>
            <el-table ref="listTable" row-key="id" height="500" :data="phoneList">
                <el-table-column label="手机号" align="center" prop="phone"/>
                <el-table-column label="权重" align="center" prop="sort"/>
                <el-table-column label="操作" fixed="right">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="deletePhone(scope.row.id)">删除
                        </el-button>
                        <el-button type="text" size="small" @click="updateSort(scope.row.id)">修改权重
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="修改销售配置&营销配置" :visible.sync="configureOpen" width="25%" append-to-body>
            <el-form ref="trafficForm" :model="configureForm">
                <el-form-item label="选择销售配置">
                    <el-select v-model="configureForm.configureId" filterable size="small" @change="update">
                        <el-option :value="0" label="取消顾问配置"/>
                        <el-option v-for="con in configureSonList" :key="con.id" :value="con.id"
                                   :label="con.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择营销配置">
                    <el-select v-model="configureForm.configMarketId" filterable size="small" @change="update">
                        <el-option :value="0" label="取消营销配置"/>
                        <el-option v-for="item in configMarketList" :key="item.id" :value="item.id"
                                   :label="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFormConfigure">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!--    设置双呼话务    -->
        <el-dialog :title="accoutName+' 的话务信息'" :visible.sync="dialogTableVisible" width="80%">
            <el-button
                    size="mini"
                    type="primary"
                    @click="openAddTrafficDialog"
            >添加话务
            </el-button>
            <el-button
                    size="mini"
                    type="success"
                    icon="el-icon-phone"
                    @click="updateTelephone()"
            >绑定手机
            </el-button>
            <el-table :data="counselorList" @selection-change="handleSelectionChangeRelation">
                <!--              <el-table-column-->
                <!--                      type="selection"-->
                <!--                      width="55">-->
                <!--              </el-table-column>-->
                <el-table-column property="phone" label="手机号"></el-table-column>
                <el-table-column property="companyCode" label="话务商">
                    <template v-slot="scope">
                        <span v-for="item in trafficBusinessOptions" :key="item.id"
                              v-if="scope.row.trafficId === item.id">{{ item.configName }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="dayCount" label="每日拨打"></el-table-column>
                <el-table-column property="weekCount" label="每周拨打"></el-table-column>
                <el-table-column property="monthCount" label="每月拨打"></el-table-column>
                <el-table-column property="createTime" label="创建时间"></el-table-column>
                <el-table-column property="" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="openAddTrafficDialog(scope.row)">修改</el-button>
                        <el-button type="danger" size="mini" @click="deleteRelationById(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :title="'为 '+accoutName+' 添加话务'" :visible.sync="addTrafficVisible" width="50%"
                   :close-on-click-modal="false">
            <el-form :model="trafficObj" :rules="trafficRules" ref="trafficRuleForm" label-width="120px">
                <el-form-item label="手机号:" prop="phoneId">
                    <el-select v-model="trafficObj.phoneId" placeholder="请选择">
                        <el-option
                                v-for="item in phoneList"
                                :key="item.id"
                                :label="item.phone"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="请选择话务:" prop="trafficId">
                    <el-select v-model="trafficObj.trafficId" placeholder="请选择">
                        <el-option
                                v-for="item in trafficBusinessOptions"
                                :key="item.id"
                                :label="item.configName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancleDialogFormVisible('trafficRuleForm')">取 消</el-button>
                <el-button type="primary" @click="doDialogFormVisible('trafficRuleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as adviserApi from "@/api/crm/adviser";
import * as trafficApi from "@/api/crm/traffic";
import * as deptApi from "@/api/system/dept";
import * as api from "@/utils/api";
import * as accountApi from '@/api/system/account'
import {findConfigByConfigType} from "@/api/system/config";
import * as configApi from "@/api/crm/configuration";
import {
    deleteNewAdviser,
    deletePhone,
    findAccountPhone,
    queryAllTrafficBusiness,
    saveNewAdviser,
    selectAdviserForPage,
    savePhone
} from "@/api/crm/newAdviser";

export default {
    name: "AdviserTest",
    components: {},
    data() {
        return {
            phoneList: [],
            phoneOpen: false,
            hidden: true,
            doubleName: "",
            doubleSync: false,
            opporCheckboxGroup: [],
            doubleList: [],
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            adviserRow: [],
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 参数配置表格数据
            configList: [],
            // 是否显示弹出层
            open: false,
            //修改话务弹窗
            trafficOpen: false,
            // 查询参数
            queryParams: {
                current: 1,
                size: 10,
                status: "1"
            },
            // 表单参数
            form: {},
            trafficForm: {},
            trafficOptions: [],
            deptList: [],
            deptTree: [],
            adviserGroupList: [],
            configureSonList: [],
            configureSuperList: [],
            configMarketList: [],
            configureForm: {},
            configureOpen: false,
            trafficUserList: [],
            dialogTableVisible: false,
            counselor: {},
            counselorList: [],
            selectedRelation: [],
            selectedId: 0,
            myTotal: 0,
            queryObj: {
                current: 1,
                accountId: 0
            },
            addTrafficVisible: false,
            trafficObj: {
                accountId: 0,
                accountPhone: ''
            },
            trafficRules: {
                trafficId: [
                    {required: true, message: '请选择话务', trigger: 'change'}
                ],
                phoneId: [
                    {required: true, message: '请选择手机', trigger: 'change'}
                ],
            },
            trafficBusinessOptions: [],
            accoutName: "",
            accountId: null,
        };
    },
    created() {
        // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
        this.getConfigureList();
        this.getTrafficList();
        this.getDept();
        this.getTrafficUserList();
        this.getList();
        this.findDoubleList()
        this.getConfigMarketList();
        this.queryAllTrafficBusiness()
    },
    methods: {
        updateSort(id) {
            this.$prompt('修改权重为', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\d+$/,
                  inputErrorMessage: '权重值非负数',
                inputValue: "0"
            }).then(({value}) => {
                    savePhone({id: id, sort: value}).then(res => {
                    if (res.success) {
                        this.$message.success("保存成功")
                        this.phoneOpen = false
                        this.dialogTableVisible = false
                    } else {
                        this.$message.error(res.msg)
                    }
                })

            })
        },
        addPhone(row) {
            this.$prompt('添加手机号', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: ""
            }).then(({value}) => {
                savePhone({accountId: this.accountId, phone: value}).then(res => {
                    if (res.success) {
                        this.$message.success("保存成功")
                        this.phoneOpen = false
                        this.dialogTableVisible = false
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            })
        },
        //删除咨询师绑定手机号
        deletePhone(id) {
            deletePhone(id).then(res => {
                if (res.success) {
                    this.$message.success("删除成功");
                } else {
                    this.$message.error(res.msg)
                }
                this.phoneOpen = false
                this.openDialog()
            })
        },
        update() {
            this.$forceUpdate();
        },
        getConfigMarketList() {
            const params = {
                pageSize: 99999,
                pageNum: 1
            }
            configApi.getList(params).then(resp => {
                if (resp.success) {
                    this.configMarketList = resp.data.data;
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        findDoubleList() {
            findConfigByConfigType("DOUBLE_CALL_PARAM").then(res => {
                this.doubleList = JSON.parse(res.list)
            })
        },
        updateTelephone(row) {
            this.phoneOpen = true
            findAccountPhone({"accountId": this.accountId}).then(res => {
                this.phoneList = res.data
            });
        },
        getConfigureSuperName(sonId) {
            let name = '未找到公共配置';
            if (sonId) {
                const son = this.configureSonList.find(e => e.id === sonId);
                if (son) {
                    const parentId = son.parentId;
                    name = this.configureSuperList.find(e => e.id === parentId).name
                }
            }
            return name;
        },
        keyboard(e) {
            if (e.keyCode === 13) {
                this.handleQuery()
            }
        },
        getTrafficUserList() {
            trafficApi.list({isUse: 1}).then(res => {
                this.trafficUserList = res.data
            })
        },
        getDept() {
            deptApi.treeDept().then(res => {
                this.deptTree = res.data;
            });
            deptApi.list().then(resp => {
                this.deptList = resp.data
            })
        },
        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 节点单击事件
        handleNodeClick(data) {
            const deptIds = this.getTreeId(data, []);
            this.queryParams.deptIds = deptIds.join(',');
            this.getList();
        },
        getTreeId(dept, res) {
            res.push(dept.id)
            if (dept.children) {
                for (let child of dept.children) {
                    this.getTreeId(child, res)
                }
            }
            return res;
        },
        //获取话务下拉框
        getTrafficList() {
            trafficApi.list({accountId: 0}).then(res => {
                this.trafficOptions = res.data;
            });
        },
        //获取顾问配置下拉框
        getConfigureList() {
            api.configureList(1, 99999, {parentId: -1}).then(res => {
                this.configureSonList = res.data.data;
            });
            api.configureList(1, 99999, {parentId: 0}).then(res => {
                this.configureSuperList = res.data.data;
            });
        },
        /** 查询参数配置列表 */
        getList() {
            this.loading = true;
            accountApi.adviserPage(this.queryParams).then(response => {
                    this.configList = response.data.data;
                    this.total = response.data.total;
                    this.loading = false;
                }
            );
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.trafficOpen = false;
            this.configureOpen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {};
            this.resetForm("form");
            this.resetForm("trafficForm");
            this.resetForm("configureForm");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.current = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.queryParams = {
                current: 1,
                size: 10,
                status: "1"
            };
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.adviserRow = selection
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 修改话务 */
        handleUpdate(row) {
            this.reset();
            this.trafficForm = {};
            this.trafficForm.accountId = row.id;
            this.trafficOpen = true;
        },
        /** 提交按钮 */
        submitForm() {
            if (this.form.ids != null) {
                // const ids = this.form.ids
                // const configureId = this.form.configureId
                const params = {}
                params.configureId =  this.form.configureId;
                params.configMarketId =  this.form.configMarketId;
                accountApi.setAdviser(params, this.form.ids).then(response => {
                    this.msgSuccess("修改成功");
                    this.open = false;
                    this.getTrafficUserList()
                    this.getList();
                });
            }
        },
        updateConfigure(row) {
            let ids = []
            if (row) {
                ids.push(row.id)
            } else {
                ids = this.ids
            }
            this.configureForm.ids = ids;
            if (row) {
                this.configureForm.configureId = row.configureId
                this.configureForm.configMarketId = row.configMarketId
            }
            this.configureOpen = true;
        },
        submitFormConfigure() {
            // console.log(this.configureForm.ids, this.configureForm.configureId)
            const params = {}
            params.configureId =  this.configureForm.configureId;
            params.configMarketId =  this.configureForm.configMarketId;
            accountApi.setAdviser(params, this.configureForm.ids).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                } else {
                    this.$message.error(res.msg)
                }
                this.ids = []
                this.configureForm.configureId = null
                this.configureForm.configMarketId = null
                this.configureOpen = false;
                this.getList();
            });
        },
        /** 提交话务按钮 */
        submitFormTraffic() {
            // console.log(this.trafficForm)
            if (this.trafficForm.accountId != null) {
                accountApi.bindTraffic(this.trafficForm.accountId, this.trafficForm.tarId).then(response => {
                    this.msgSuccess("修改成功");
                    this.trafficOpen = false;
                    this.getList();
                });
            } else {
                this.$message({showClose: true, message: "修改失败", type: "error"});
                this.trafficOpen = false;
                this.getList();
            }
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return adviserApi.deleteAdviser(ids);
            }).then(() => {
                this.getList();
                this.msgSuccess("删除成功");
            })
        },

        selectNewAdviserForPage() {
            selectAdviserForPage(this.queryObj).then(res => {
                if (res.code === 200) {
                    this.counselorList = res.data
                }
            }).catch()
        },
        /**
         * 打开对话框
         * @param row
         */
        openDialog(row) {
            if (row.id) {
                this.accoutName = row.nickName
                this.accountId = row.id
                this.trafficObj.accountId = row.id
                this.selectedId = row.id
            }
            this.dialogTableVisible = true;
            this.queryObj.accountId = this.selectedId
            this.queryObj.current = 1
            this.selectNewAdviserForPage()
            this.phoneList = []
            findAccountPhone({"accountId": this.selectedId}).then(res => {
                this.phoneList = res.data
            });
        },
        /**
         * 获取要删除的id
         * @param e
         */
        handleSelectionChangeRelation(e) {
            this.selectedRelation = e.map(x => x.id)
        },
        /**
         * 批量删除话务关系表
         * @returns {ElMessageComponent}
         */
        deleteRelation() {
            if (this.selectedRelation.length === 0) {
                return this.$message.info("请至少要选择一条记录要删除...")
            }
            this.$confirm('真的要删除这条记录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteNewAdviser(this.selectedRelation).then(res => {
                    if (res.code === 200) {
                        this.$message.success("删除成功!!!")
                        this.queryObj.current = 1
                        this.selectNewAdviserForPage()
                    }
                }).catch(error => {
                }).finally(() => {
                    this.selectedRelation = []
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**
         * 根据id删除
         */
        deleteRelationById(row) {
            let ids = [];
            ids.push(row.id)
            this.$confirm('真的要删除这条记录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteNewAdviser(ids).then(res => {
                    if (res.code === 200) {
                        this.$message.success("删除成功!!!")
                        this.queryObj.current = 1
                        this.selectNewAdviserForPage()
                    }
                }).catch(error => {
                }).finally(() => {
                    this.ids = []
                    this.selectedRelation = []
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        /**
         * 页码改变时 执行这个
         * @param page
         */
        handlerCurrentPage(page) {
            this.queryObj.current = page
            this.selectNewAdviserForPage()
        },
        /**
         * 打开添加话务的窗口
         */
        openAddTrafficDialog(row) {
            if (row.accountId) {
                this.trafficObj = row
            }
            this.addTrafficVisible = true

        },
        /**
         * 添加话务关系 打开添加事务弹窗
         */
        doDialogFormVisible(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    saveNewAdviser(this.trafficObj).then(res => {
                        if (res.code === 200) {
                            this.addTrafficVisible = false
                            this.$message.success("添加成功")
                            this.queryObj.current = 1
                            this.selectNewAdviserForPage()
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch(error => {
                    })
                }
            });
        },
        /**
         * 关闭添加关系弹窗
         * @param formName
         */
        cancleDialogFormVisible(formName) {
            this.$refs[formName].resetFields();
            this.trafficObj = {}
            this.addTrafficVisible = false
        },
        /**
         * 查询所有的话务商 列表展示
         */
        queryAllTrafficBusiness() {
            queryAllTrafficBusiness().then(res => {
                if (res.code === 200) {
                    this.trafficBusinessOptions = res.data
                }
            }).catch(error => {
            })
        }
    }
};
</script>
<style scoped>
/deep/ .el-tree-node.is-current > .el-tree-node__content {
    background-color: #164bff !important;
    color: #eeeeee !important;
}
</style>
