<template>
    <div>
        <div style="display: flex;align-items: center;">
            <GgdCard background="#2456bb" title="页面PV" :content="pvCount"></GgdCard>
            <GgdCard background="#ffd200" title="页面UV" :content="uvCount"></GgdCard>
            <GgdCard background="#e73333" title="合计数据" :content="sumCount"></GgdCard>
            <GgdCard background="#49d179" title="有效数据" :content="sucCount"></GgdCard>
            <el-popover
                    placement="left"
                    width="600"
                    trigger="click">
                <div>
                    <el-table
                            size="mini"
                            :data="subjectList"
                            style="width: 100%;margin-bottom: 20px;"
                            row-key="id"
                            border
                            default-expand-all
                            :tree-props="{children: 'children'}">
                        <el-table-column
                                prop="subjectName"
                                label="项目名称"
                                sortable>
                        </el-table-column>
                        <el-table-column label="数量">
                            <template v-slot="scope">
                                <span v-if="scope.row.id">{{ sumMap[scope.row.id] }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-button type="success" slot="reference">查看科目数据</el-button>
            </el-popover>
        </div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
            <el-row>
                <el-form-item label="页面名称" prop="pageName">
                    <el-input v-model="queryParams.pageName" placeholder="页面名称" clearable size="small"/>
                </el-form-item>
                <el-form-item label="数据统计">
                    <el-select v-model="queryParams.timeType" filterable clearable size="small">
                        <el-option :value="1" label="今日"></el-option>
                        <el-option :value="2" label="昨日"></el-option>
                        <el-option :value="3" label="自定义"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="留资时间" v-if="queryParams.timeType == 3">
                    <el-date-picker
                            size="small"
                            v-model="createTimeRange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-row>
            <el-form-item label="归属线索池：" prop="pondId">
                <el-select size="mini" v-model="queryParams.pondId" width="100%" clearable filterable>
                    <el-option value="" label="全部"></el-option>
                    <el-option
                        v-for="item in pondList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="网址" prop="domain">
                <el-input v-model="queryParams.domain" placeholder="网址" clearable size="small"/>
            </el-form-item>
            <el-form-item label="标识" prop="possId">
                <el-select
                        v-model="queryParams.possId"
                        filterable
                        placeholder="标识"
                        clearable
                        size="small"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="poss in possessList" :value="poss.id"
                               :label="poss.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="归属域名" prop="domainName">
                <el-select
                        v-model="queryParams.domainName"
                        filterable
                        placeholder="归属域名"
                        clearable
                        size="small"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="mediator in options" :value="mediator.domainName"
                               :label="mediator.domainName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="pagedomainList">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-row>
            <el-button
                    type="success"
                    icon="el-icon-plus"
                    @click="addRoles"
                    size="mini"
            >新增
            </el-button>
            <el-button
                    type="primary"
                    icon="el-icon-download"
                    @click="exportData"
                    size="mini"
            >导出
            </el-button>
            <el-button
                    type="warning"
                    icon="el-icon-picture-outline"
                    @click="showReceive"
                    size="mini"
            >轮播图
            </el-button>

            <el-button type="warning"
                       icon="el-icon-s-marketing"
                       @click="findSupplier"
                       style="float: right;"
                       v-if="supplierSync"
                       size="mini">
                页面管理
            </el-button>
            <el-button type="primary"
                       icon="el-icon-s-marketing"
                       @click="createSupplier"
                       style="float: right;"
                       v-if="supplierSync"
                       size="mini">
                生成页面
            </el-button>
        </el-row>
        <el-table max-height="600"
                  ref="tableRef"
                  :data="tableData"
                  v-loading="loading"
                  stripe
                  @selection-change="selectChanage"
                  style="width: 100%; margin-top: 20px">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
                    prop="title"
                    label="页面名称">
            </el-table-column>
            <el-table-column prop="domain" label="网址">
                <template v-slot="scope">
                    {{ showUrl(scope.row) }}
                    <el-tooltip effect="dark" content="点击复制网址" placement="bottom">
                        <i class="el-icon-document-copy btn-click-open"
                           @click="copyUrl(showUrl(scope.row))"></i>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                    prop="possId" align="center"
                    label="数据唯一标识">
                <template slot-scope="scope">
                    <span v-for="item in possessList" v-if="item.id === scope.row.possId">{{ item.name }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="访问统计">
                <template v-slot="scope">
                    <!--          访问量:{{ scope.row.pv ? scope.row.pv : 0 }} <br/> 用户量:{{ scope.row.uv ? scope.row.uv : 0 }}-->
                    访问量:{{ scope.row.pv || '0' }} <br/> 用户量:{{ scope.row.uv || '0' }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="数据统计">
                <template v-slot="scope">
                    <!--          数据量:{{ scope.row.countData ? scope.row.countData : 0 }} <br/>-->
                    有效量:{{ scope.row.successData || '0' }}
                </template>
            </el-table-column>
            <el-table-column align="center" label="重复标签">
                <template slot-scope="scope">
                    <el-tag v-for="labelId in scope.row.repeatLabelIds ? scope.row.repeatLabelIds.split(',').filter(e => e) :[]">
                        {{ showLabel(Number(labelId)) }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="数据">
                <template slot-scope="scope">
                    <el-button size="mini" @click="findDataList(scope.row.id)">查看详细</el-button>
                    <el-button type="success" size="mini" @click="showSubject(scope.row)">科目数据</el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" label="线索池" prop="pondId">
                <template v-slot="scope">
                    <el-tag :type="typeOptions[item.id % 5]" v-for="item in pondList" :key="item.id" v-if="scope.row.pondId === item.id">
                        {{item.name}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            <el-table-column align="center" label="最后修改人" prop="updateBy"></el-table-column>
            <el-table-column align="center" label="修改时间" prop="updateTime"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-s-grid"
                            @click="createApplet(scope.row)"
                    >小程序码
                    </el-button>
                    <!--          <el-button-->
                    <!--              size="mini"-->
                    <!--              type="text"-->
                    <!--              icon="el-icon-monitor"-->
                    <!--              @click="JumpPc(scope.row)"-->
                    <!--          >配置电脑-->
                    <!--          </el-button>-->
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-mobile"
                            @click="Jump(scope.row)"
                    >配置手机
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-copy-document"
                            @click="copyPage(scope.row,scope.row.id)"
                    >复制页面
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="edit(scope.row,scope.row.id)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="deleteCurrent(scope.row.id)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30]"
                    :page.sync="queryParams.page"
                    :limit.sync="queryParams.limit"
                    @pagination="pagedomainList"
        />

        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogFormVisible"
                width="800px"
                @close="resetFrom('dialogForm')"
        >
            <el-form
                    :model="dialogForm"
                    ref="dialogForm"
                    :rules="dialogFormRules"
                    label-width="120px"
            >
                <el-form-item label="页面名称：" prop="title">
                    <el-input size="mini" v-model="dialogForm.title"></el-input>
                </el-form-item>
                <el-form-item label="生成方式：" prop="type">
                    <el-radio-group size="mini" v-model="dialogForm.type" :disabled="dialogForm.id > 0">
                        <el-radio :label="0">
                            <el-tooltip class="item" effect="dark"
                                        content="创建微页面无需创建新的域名，仅域名参数不同，推荐使用" placement="top">
                                <span>单域名模式<i class="el-icon-warning-outline"></i></span>
                            </el-tooltip>
                        </el-radio>
                        <el-radio :label="1">
                            <el-tooltip class="item" effect="dark"
                                        content="创建微页面需要创建新的子域名，维护和解析成本高，不推荐使用"
                                        placement="top">
                                <span>多域名模式<i class="el-icon-warning-outline"></i></span>
                            </el-tooltip>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="网址：" prop="domainName">
                    <template #default>
                        <div v-if="dialogForm.type === 1">
                            <el-row>
                                <el-col :span="4">
                                    <el-select size="mini" v-model="dialogForm.protocol" width="100%"
                                               :disabled="dialogForm.id > 0">
                                        <el-option
                                                v-for="item in protocols"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="8">
                                    <el-input size="mini" v-model="dialogForm.subDomain"
                                              :disabled="dialogForm.id > 0"></el-input>
                                </el-col>
                                <el-col :span="8">
                                    <el-select size="mini" v-model="dialogForm.domainName" width="100%"
                                               :disabled="dialogForm.id > 0">
                                        <el-option
                                                v-for="item in options"
                                                :key="item.domainName"
                                                :label="item.domainName"
                                                :value="item.domainName">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="4" style="text-align: center">
                                    <el-button size="mini" type="primary" icon="el-icon-plus" @click="addDomain"
                                               circle></el-button>
                                </el-col>
                            </el-row>
                            <el-row style="height: 16px;"><span style="font-size: 12px;color: #ff5d5d;">如果需要修改域名解析,请联系技术人员,修改域名解析时,很多时候网管局的DNS经常会刷新不成功</span>
                            </el-row>
                        </div>
                        <div v-else>
                            <el-row>
                                <el-col :span="2">
                                    <span v-if="domainsMap[dialogForm.domainName] && domainsMap[dialogForm.domainName].protocolSsl">https://</span>
                                    <span v-else>http://</span>
                                </el-col>
                                <el-col :span="8">
                                    <el-select size="mini" v-model="dialogForm.domainName" @change="chanageDomain"
                                               :disabled="dialogForm.id > 0">
                                        <el-option :key="item.id" :value="item.domainName" :disabled="item.status!==0"
                                                   :label="item.domainName" v-for="item in domainsList">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="4">
                                    <el-button size="mini" type="primary" icon="el-icon-plus" @click="addSubDomain"
                                               circle></el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                </el-form-item>
                <el-form-item label="唯一标识：" prop="possId">
                    <el-select size="mini" v-model="dialogForm.possId" width="100%" filterable>
                        <el-option
                                v-for="item in possessList"
                                v-if="item.status === 0"
                                :disabled="item.id === 1"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="归属线索池：" prop="pondId">
                    <el-select size="mini" v-model="dialogForm.pondId" width="100%" filterable>
                        <el-option
                            v-for="item in pondList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="重复数据标签：" prop="repeatLabelIdList">
                    <el-select size="mini" v-model="dialogForm.repeatLabelIdList" multiple width="100%"
                               @change="forceUpdate">
                        <el-option
                                v-for="item in tagList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                :disabled="item.status===1">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="电脑端：">
                    <el-row>
                        <el-col :span="16">
                            <el-switch size="mini"
                                       v-model="dialogForm.computer"
                                       active-color="#13ce66"
                                       inactive-color="#ff4949">
                            </el-switch>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="直接跳转：">
                    <el-row>
                        <el-col :span="16">
                            <el-switch size="mini"
                                       v-model="dialogForm.jump"
                                       active-color="#13ce66"
                                       inactive-color="#ff4949">
                            </el-switch>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="跳转地址：" v-if="dialogForm.jump">
                    <el-row>
                        <el-col :span="16">
                            <el-input size="mini" v-model="dialogForm.jumpUrl"></el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-button type="primary" size="mini" @click="addExtra" round>新增</el-button>
                <el-form-item label-width="0" required v-for="(k,i) in extraOptions">
                    <el-row>
                        <el-col :span="6">
                            <el-select v-model="k.key" placeholder="选择机会对应属性">
                                <el-option :value="o.name" :label="o.label" v-for="o in codeList"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="10">
                            <el-input placeholder="${**}为变量,可直接固定内容" v-model="k.value"
                                      class="input-with-select"></el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-select v-model="codes[i]" placeholder="插入变量" @change="addCode(k,i)">
                                <el-option :value="o.value" :label="o.label" v-for="o in attrList"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="3">
                            <el-button @click="removeExtra(k,i)" type="primary">删除</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="success" @click="save('dialogForm')">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog title="添加域名"
                   :visible.sync="domainDialogVisible"
                   width="500px"
                   @close="resetFrom('domain')">
            <el-form
                    :model="domainForm"
                    ref="domain"
                    label-width="100px"
            >
                <el-form-item label="云服务商：">
                    <el-select v-model="domainForm.provider" @change="findDomainByKey" width="100%">
                        <el-option
                                v-for="item in providerList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="secretId：">
                    <el-input v-model="domainForm.secretId" @change="findDomainByKey"></el-input>
                </el-form-item>
                <el-form-item label="secretKey：">
                    <el-input v-model="domainForm.secretKey" @change="findDomainByKey"></el-input>
                </el-form-item>
                <el-form-item label="选择域名：" v-show="domainList.length > 0">
                    <el-select v-model="domainForm.domainName" width="100%">
                        <el-option
                                v-for="item in domainList"
                                :key="item.id"
                                :label="item.domainName"
                                :value="item.domainName">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="domainDialogVisible = false">取消</el-button>
                <el-button type="success" @click="saveDoamin()">保存</el-button>
            </div>

        </el-dialog>
        <el-dialog title="生成小程序二维码"
                   :visible.sync="appletDialogVisible"
                   width="500px"
                   @close="clearApplet()">
            <el-form
                    :model="appletForm"
                    ref="appletForm"
                    label-width="100px"
            >
                <el-form-item label="页面地址：">
                    <el-input v-model="appletForm.url" disabled></el-input>
                </el-form-item>
                <el-form-item label="渠道：" prop="mediatorId">
                    <el-select
                            v-model="appletForm.mediatorId"
                            filterable
                            placeholder="渠道"
                            clearable
                            size="small"
                            style="width: 240px"
                    >
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="mediator in mediatorList" :key="mediator.id" :value="mediator.id"
                                   :label="mediator.mediatorName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="小程序参数：">
                    <el-input v-model="appletParam" disabled></el-input>
                </el-form-item>
                <el-form-item label="通用地址：">
                    <el-input v-model="appletUrl" disabled></el-input>
                </el-form-item>
                <el-form-item label="小程序码：">
                    <div id="qrcode"></div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="appletDialogVisible = false">取消</el-button>
                <el-button type="success" @click="createWechatQrcode()">生成</el-button>
            </div>
        </el-dialog>

        <!--  数据详情  -->
        <el-dialog title="页面数据"
                   :visible.sync="dataListSync"
                   top="100px"
                   width="80%">
            <el-table
                    :data="successDatas"
                    height="500"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="telephone"
                        label="机会">
                </el-table-column>
                <el-table-column
                        prop="ip"
                        label="学员ip">
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="访问方式">
                    <template v-slot="scope">
                        <span v-if="scope.row.type === 'APPLET'">小程序</span>
                        <span v-else-if="scope.row.type === 'PC'">电脑</span>
                        <span v-else>手机</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="操作日期">
                </el-table-column>
                <!--        <el-table-column align="center" v-for="code in codeList" :label="code.label">-->
                <!--          <template v-slot="scope" v-if="scope.row.tson">-->
                <!--            {{ scope.row.tson[code.name] }}-->
                <!--          </template>-->
                <!--        </el-table-column>-->
            </el-table>
            <el-pagination
                    background
                    @current-change="findSuccess"
                    :current-page.sync="successParams.current"
                    layout="total, prev, pager, next"
                    :total="successParams.total">
            </el-pagination>
        </el-dialog>

        <!-- 外部分享数据详情 -->
        <el-dialog title="页面配置" :visible.sync="supplierCreateSync" top="100px" width="80%">
            <el-form :model="supplierData" ref="supplierData" label-width="100px">
                <el-input v-model="supplierData.name" :value="supplierData.name" hidden></el-input>
                <el-form-item label="页面名称：">
                    <el-col :span="10">
                        <el-input v-model="supplierData.label"></el-input>
                    </el-col>
                    <el-col :span="12">
                        <div class="dialog-footer" style="text-align: right">
                            <el-button type="warning" @click="supplierAdd(supplierData.data)">添加</el-button>
                            <el-button type="success" @click="supplierSave">保存</el-button>
                        </div>
                    </el-col>
                </el-form-item>
                <el-form-item label="页面地址：">
                    {{ supplierUrl + '/leave/' + supplierData.name }}
                </el-form-item>
                <el-form-item label="页面列表：">
                    <el-table ref="tableRef" :data="supplierList" stripe style="width: 100%">
                        <el-table-column prop="pageName" label="页面名称"></el-table-column>
                        <el-table-column prop="domain" label="网址"></el-table-column>
                        <el-table-column prop="possId" align="center" label="数据唯一标识">
                            <template slot-scope="scope">
                                <span v-for="item in possessList" v-if="item.id === scope.row.possId">{{
                                    item.name
                                    }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
                        <el-table-column align="center" prop="updateTime" label="更新时间"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="text" icon="el-icon-delete"
                                           @click="deleteSupplier(scope.row)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>

        </el-dialog>

        <!-- 外部分享数据列表 -->
        <el-dialog title="数据查询链接"
                   :visible.sync="findSupplierSync"
                   top="100px"
                   width="80%">
            <el-table
                    :data="supplierDatas"
                    height="500"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="label"
                        label="名称">
                </el-table-column>
                <el-table-column
                        prop="name"
                        min-width="100"
                        label="地址">
                    <template slot-scope="scope">
                        {{ supplierUrl + '/leave/' + scope.row.name }}
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="createTime"
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="updateTime"
                        label="更新时间">
                </el-table-column>
                <el-table-column
                        align="center"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="supplierEdit(scope.row)">修改</el-button>
                        <el-button size="mini" type="warning" v-if="scope.row.status"
                                   @click="updateStaus(scope.row.id,0)">停用
                        </el-button>
                        <el-button size="mini" type="success" v-else @click="updateStaus(scope.row.id,1)">启用
                        </el-button>
                        <el-button size="mini" type="danger" @click="supplierDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @current-change="findSuccess"
                    :current-page.sync="supplierParams.current"
                    layout="total, prev, pager, next"
                    :total="supplierParams.total">
            </el-pagination>
        </el-dialog>

        <el-dialog title="添加数据"
                   :visible.sync="addSupplierSync"
                   top="100px"
                   width="80%">
            <el-form :model="queryTableParams" ref="queryTableForms" :inline="true" label-width="100px">
                <el-form-item label="名称" prop="pageName">
                    <el-input v-model="queryTableParams.pageName" placeholder="名称" clearable size="small"/>
                </el-form-item>
                <el-form-item label="网址" prop="domain">
                    <el-input v-model="queryTableParams.domain" placeholder="网址" clearable size="small"/>
                </el-form-item>
                <el-form-item label="标识" prop="possId">
                    <el-select
                            v-model="queryTableParams.possId"
                            filterable
                            placeholder="标识"
                            clearable
                            size="small"
                            style="width: 240px"
                    >
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="poss in possessList" :value="poss.id"
                                   :label="poss.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="supplierAdd()">搜索</el-button>
                </el-form-item>
                <el-form-item style="text-align: right;float: right">
                    <el-button type="success" icon="el-icon-finished" size="mini" @click="addSupplierDatas">添加
                    </el-button>
                    <el-button type="info" size="mini" @click="addSupplierSync = false">取消</el-button>
                </el-form-item>
            </el-form>
            <el-table
                    ref="tableRef"
                    :data="addTableDatas"
                    stripe
                    @selection-change="addChange"
                    style="width: 100%">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column
                        prop="pageName"
                        label="页面名称">
                </el-table-column>
                <el-table-column
                        prop="domain"
                        label="网址">
                </el-table-column>
                <el-table-column
                        prop="possId" align="center"
                        label="数据唯一标识">
                    <template slot-scope="scope">
                        <span v-for="item in possessList" v-if="item.id === scope.row.possId">{{ item.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
                <el-table-column align="center" label="更新时间" prop="updateTime"></el-table-column>
            </el-table>
            <pagination :total="queryTableParams.total" :page-size="10" :page-sizes="[10, 20, 30]"
                        :page.sync="queryTableParams.page"
                        :limit.sync="queryTableParams.limit"
                        @pagination="supplierAdd()"
            />
        </el-dialog>

        <el-dialog title="科目数据"
                   :visible.sync="showSubjectSync"
                   top="100px"
                   width="80%">
            <el-table
                    size="mini"
                    :data="subjectList"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    border
                    default-expand-all
                    :tree-props="{children: 'children'}">
                <el-table-column
                        prop="subjectName"
                        label="项目名称"
                        sortable>
                </el-table-column>
                <el-table-column label="数量">
                    <template v-slot="scope">
                        <span v-for="(value,key) in subRowMap" v-if="scope.row.id == key">{{ value }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="轮播图配置"
                   :visible.sync="revolveSync"
                   top="100px"
                   @close="fileList = []"
                   width="80%">
            <el-upload
                    class="avatar-uploader"
                    action="/api/manage/file/uploadImg"
                    :headers="headers"
                    list-type="picture-card"
                    :file-list="fileList"
                    :on-preview="handleRevolvePreview"
                    :on-success="handleRevolveAvatarSuccess"
                    :before-upload="beforeRevolveAvatarUpload"
                    :on-remove="handleRevolveRemove">
                <i class="el-icon-plus"></i>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button @click="revolveSync = false">取消</el-button>
                <el-button type="success" @click="saveReceive()">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogRevolveVisible">
            <img width="100%" :src="dialogRevolveImageUrl" alt="">
        </el-dialog>

        <el-dialog title="配置域名"
                   :visible.sync="subDomainSync"
                   top="100px"
                   width="80%">
            <el-button type="success" size="mini" @click="saveSubdomain">添加</el-button>
            <el-table ref="subDomaintableRef"
                      :data="domainsList"
                      stripe width="80%">
                <el-table-column align="center" label="域名" prop="domainName"></el-table-column>
                <el-table-column align="center" label="HTTPS" prop="protocolSsl">
                    <template v-slot="scope">
                        <el-tag type="success" v-if="scope.row.protocolSsl">是</el-tag>
                        <el-tag type="info" v-else>否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="证书颁发时间" width="110"
                                 prop="protocolBefore"></el-table-column>
                <el-table-column align="center" label="证书到期时间" width="110" prop="protocolAfter"></el-table-column>
                <el-table-column align="center" label="状态" prop="status">
                    <template v-slot="scope">
                        <span v-if="scope.row.status === 0">启用</span>
                        <span v-if="scope.row.status === 1">停用</span>
                        <span v-if="scope.row.status === 9">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
                <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
                <el-table-column align="center" label="修改时间" prop="updateTime"></el-table-column>
                <el-table-column align="center" label="修改人" prop="updateBy"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template v-slot="scope">
                        <el-button type="text" size="mini" @click="asyncDomain(scope.row.id)">更新状态</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>

</template>

<script>
import * as api from "@/api/mini/pagedomain";
import * as apiDomain from "@/api/mini/domain";
import * as possApi from "@/api/crm/possess";
import * as apiUtils from "../../utils/api";
import QRCode from 'qrcodejs2'
import * as configApi from "@/api/system/config"
import GgdCard from "@/components/GgdCard";
import * as searchApi from '@/api/system/searchDeploy'
import axios from "axios";
import * as subjectApi from '@/api/system/subject'
import * as tagApi from "@/api/crm/tags"
import * as domainsApi from "@/api/domain/domain"
import {copy} from "@/utils/utils";
import * as clueApi from "@/api/clue/cluePond";
import {listCluePond} from "@/api/clue/cluePond";

export default {
    name: "PageDomain",
    components: {
        GgdCard
    },
    data() {
        return {
            typeOptions: ['', 'success', 'warning', 'danger', 'info'],
            pondList:[],
            subDomainForm: {},
            headers: {"version": localStorage.getItem("_version"),},
            loading: false,
            dialogRevolveImageUrl: '',
            dialogRevolveVisible: false,
            fileList: [],
            revolveSync: false,
            // 总条数
            total: 0,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10,
                timeType: 1
            },
            addSupplierParams: {
                page: 1,
                limit: 10
            },
            tableData: [],
            dialogFormVisible: false,
            dialogTitle: "",
            dialogForm: {},
            dialogFormRules: {
                title: [{required: true, message: '请输入页面名称', trigger: 'blur'}],
                type: [{required: true, message: '请选择页面生成类型', trigger: 'blur'}],
                domainName: [{required: true, message: '请选择域名', trigger: 'blur'}],
            },
            dialogForm_null: Object.assign({}, this.dialogForm),
            rowIndex: 9999,
            options: [],
            domainDialogVisible: false,
            domainForm: {
                provider: 'ALIYUN',
                secretId: '',
                secretKey: ''
            },
            possessList: [],
            providerList: [
                {
                    id: "ALIYUN",
                    name: "阿里云"
                },
                {
                    id: "TENCENT",
                    name: "腾讯云"
                }
            ],
            domainList: [],
            protocols: [
                {
                    name: 'http://'
                }
            ],
            appletDialogVisible: false,
            appletForm: {},
            mediatorList: [],
            appletParam: "",
            appletUrl: "",
            pvCount: 0,
            uvCount: 0,
            sumCount: 0,
            sucCount: 0,
            createTimeRange: null,
            timeType: 1,
            extraOptions: [{}],
            codes: [],
            codeList: [],
            attrList: [
                {"label": "商品名称", "value": '${goods_name}'},
                {"label": "商品价格", "value": '${goods_price}'},
                {"label": "商品封面", "value": '${goods_cover}'},
                {"label": "学员姓名", "value": '${name}'},
                {"label": "操作时间", "value": '${now}'},
            ],
            dataListSync: false,
            successDatas: [],
            successParams: {
                total: 0,
            },
            findSupplierSync: false,
            supplierDatas: [],
            supplierParams: {
                total: 0
            },
            supplierCreateSync: false,
            supplierData: {},
            selections: [],
            supplierList: [],
            addSupplierSync: false,
            addSelections: [],
            addTableDatas: [],
            queryTableParams: {
                page: 1,
                limit: 10,
                total: 0,
            },
            supplierSync: false,
            supplierUrl: "",
            extraIds: "",
            sumMap: {"asd": 100, "qwe": 12},
            subjectList: [],
            showSubjectSync: false,
            subRowMap: {},
            tagList: [],
            domainsList: [],
            domainsMap: {},
            subDomainSync: false,
            domainUrl: "",
            possList: [],
        }
    },
    mounted() {
        this.findPondList()
        this.pagedomainList()
        this.findPossessList()
        this.findDomainList()
        this.findNewSea()
        this.getSupplierUrl()
        this.getLabelList()
        this.findSubject()
        this.myDomains()
    },
    watch: {
        "dialogForm.type": function (newVal, oldVal) {
            if (newVal === 0) {
                this.dialogForm.domainName = this.domainsList[0].domainName
            } else {
                this.dialogForm.domainName = this.options[0].domainName
            }
        }
    },
    methods: {
        findPondList() {
            clueApi.listCluePond({}).then(res => {
                if (res.success) {
                    this.pondList = res.data
                }
              this.pondList.unshift({id:1,name:'基础池'})
            })
        },
        //选择网址时,反选域名
        chanageDomain(val) {
            let m = this.domainsMap[val]
            if (m && m.protocolSsl) {
                this.dialogForm.protocol = "https://"
            }
        },
        showUrl(row) {
            if (row.type === 0) {
                if (this.domainsMap[row.domainName] && this.domainsMap[row.domainName].protocolSsl) {
                    return "https://" + row.domain
                }
            }
            return "http://" + row.domain
        },
        copyUrl(url) {
            copy(url);
            this.$message.success("成功复制：" + url)
        },
        getCode(data) {
            api.getCode().then(res => {
                data.code = res.data
            })
        },
        asyncDomain(id) {
            domainsApi.sslCheck(id).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    setTimeout(() => {
                        this.myDomains()
                    }, 1000)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        saveSubdomain() {
            this.$prompt('添加域名', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: ""
            }).then(({value}) => {
                domainsApi.addDomains({"domainName": value}).then(res => {
                    if (res.success) {
                        this.$message.success(res.msg)
                        this.myDomains()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            })
        },
        myDomains() {
            domainsApi.myDomains({}).then(res => {
                this.domainsList = res.data
                for (let d of this.domainsList) {
                    this.domainsMap[d.domainName] = d
                }
            })
        },
        forceUpdate() {
            this.$forceUpdate();
        },
        showLabel(labelId) {
            const filter = this.tagList.filter(e => e.id === labelId);
            return filter && filter.length > 0 ? filter[0].name : null
        },
        getLabelList() {
            tagApi.all().then(res => {
                if (res.success) {
                    this.tagList = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        removeExtra(k, i) {
            console.log(k, i)
            this.extraOptions.splice(i, 1)
        },
        showReceive() {
            this.revolveSync = true
            this.findRevolveImg()
        },
        //保存轮播图修改
        saveReceive() {
            let data = this.fileList.map(e => {
                return {
                    "component": "revolve-img",
                    "children": {name: e.name, url: e.url},
                    "name": "轮播图配置",
                    "config": {},
                }
            })
            api.saveRevolveImg(data).then(res => {
                if (res.success) {
                    this.$message.success("保存成功")
                    this.revolveSync = false
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取轮播图列表
        findRevolveImg() {
            api.findRevolveImg().then(res => {
                if (res.success) {
                    this.fileList = res.data.map(e => {
                        return e.children
                    })
                }
            })
        },
        handleRevolveAvatarSuccess(res, file) {
            // console.log(res, file)revolve
            // this.$set(this.dialogForm, 'background', res.msg)
            if (res.success) {
                this.fileList.push({
                    name: file.name,
                    url: res.msg
                })
            } else {
                this.$message.error(res.msg)
            }
        },
        beforeRevolveAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传背景图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            this.$message({
                type: "success",
                message: "上传成功！"
            });
            return isJPG && isLt2M;
        },
        handleRevolveRemove(file, fileList) {
            console.log(file, fileList);
            this.fileList = fileList
        },
        handleRevolvePreview(file) {
            this.dialogRevolveImageUrl = file.url;
            this.dialogRevolveVisible = true;
        },
        showSubject(row) {
            this.subRowMap = {}
            if (row.subCount) {
                this.subRowMap = row.subCount
            }
            this.showSubjectSync = true
        },
        getSupplierUrl() {
            configApi.findConfigByConfigType("SUPPLIER_CONFIG").then(res => {
                if (res.url) {
                    this.supplierUrl = res.url
                    this.supplierSync = true
                }
            })
        },
        supplierDelete(id) {
            searchApi.delDeploy(id).then(res => {
                if (res.success) {
                    this.$message({message: "删除成功", type: 'success'})
                } else {
                    this.$message({message: res.msg, type: 'error'})
                }
                this.findSupplier()
            })
        },
        addSupplierDatas() {
            for (let e of this.addSelections) {
                this.supplierList.push(e)
            }
            this.$message.success('添加成功')
        },
        updateStaus(id, status) {
            searchApi.updateStatus(id, status).then(res => {
                if (res.success) {
                    this.$message({message: "修改状态成功", type: 'success'})
                } else {
                    this.$message({message: res.msg, type: 'error'})
                }
                this.findSupplier()
            });
        },
        supplierEdit(row) {
            let data = JSON.parse(row.data)
            this.supplierData = row
            api.getList(data).then(res => {
                this.supplierList = res.data
            })
            this.supplierCreateSync = true
        },
        deleteSupplier(row) {
            for (let i in this.supplierList) {
                let l = this.supplierList[i]
                if (l.id === row.id) {
                    this.supplierList.splice(i, 1)
                }
            }
        },
        supplierSave() {
            if (this.supplierList.length <= 0) {
                this.$message({message: "请选择数据", type: 'error'})
                return
            }
            if (!this.supplierData.label || this.supplierData.label.length <= 0) {
                this.$message({message: "请设定页面名称", type: 'error'})
                return
            }
            let ids = this.supplierList.map(e => e.id).join(",")
            this.supplierData.ids = ids
            this.supplierData.classify = "SUPPLIER_DATA"
            let data = {"ids": ids}
            this.supplierData.data = JSON.stringify(data)
            searchApi.saveDeploy(this.supplierData).then(res => {
                if (res.success) {
                    this.$message({message: "更新成功", type: 'success'})
                    this.supplierCreateSync = false
                    this.findSupplier()
                } else {
                    this.$message({message: "更新失败", type: 'error'})
                }
            })
        },
        supplierAdd(data) {
            if (data) {
                let js = JSON.parse(data)
                this.extraIds = js.ids
            }
            this.queryTableParams.existIds = this.extraIds
            this.addSupplierSync = true
            api.findPage(this.queryTableParams).then(res => {
                this.addTableDatas = res.data
                this.queryTableParams.total = res.count
            });
        },
        addChange(selection) {
            this.addSelections = selection
        },
        selectChanage(selection) {
            this.selections = selection
        },
        findSupplier() {
            searchApi.list({"classify": "SUPPLIER_DATA"}).then(res => {
                this.supplierDatas = res.data
            })
            this.findSupplierSync = true
        },
        supplierDetails(id) {
            this.supplierCreateSync = true
        },
        createSupplier() {
            if (this.selections.length <= 0) {
                this.$message({message: "请选择数据", type: 'error'})
                return
            }
            let ids = this.selections.map(e => e.id).join(",")
            // this.$refs.tableRef.clearSelection()
            let time = Math.ceil(new Date().getTime() / 1000)
            let name = this.intToStr(time)
            this.supplierData = {}
            this.supplierData.ids = ids
            this.supplierList = this.selections
            this.supplierData.name = name
            this.supplierCreateSync = true
        },
        intToStr(n) {
            let eng_list = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
            let str = "" + n
            let res = ''
            for (let s of str) {
                res += eng_list[s]
            }
            return res;
        },
        exportData() {
            this.$message({message: "下载中,请稍后...", type: 'success'})
            axios({
                method: "post",
                url: "/api/manage/pagedomain/exportData",
                data: JSON.stringify(this.queryParams),
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                },
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '微页面日志.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(error => {
                this.$message.error("导出领取记录失败");
            })
        },
        findSuccess() {
            api.findSuccess(this.successParams.current, this.successParams).then(res => {
                this.successDatas = res.data
                this.successParams.total = res.total
                for (let js of this.successDatas) {
                    js.tson = JSON.parse(js.extra)
                }
            })
        },
        findDataList(id) {
            if (this.createTimeRange) {
                this.queryParams['startTime'] = this.createTimeRange[0]
                this.queryParams['endTime'] = this.createTimeRange[1]
            }

            this.dataListSync = true
            this.successParams = this.queryParams
            this.successParams['fromId'] = id
            this.successParams.current = 1
            this.findSuccess()
        },
        addExtra() {
            this.extraOptions.push({})
        },
        findNewSea() {
            searchApi.findByClassify("NEW_INFORMATION").then(res => {
                this.codeList = res.data
            });
        },
        addCode(k, i) {
            this.extraOptions[i].value = this.codes[i]
        },
        copyPage(row, id) {
            this.dialogTitle = "复制微页面";
            this.extraOptions = []
            if (row.extra) {
                this.extraOptions = JSON.parse(row.extra)
            }
            this.dialogForm = Object.assign({
                protocol: 'http://',
                domain: '',
                domainName: '',
                possId: '',
                subDomain: ''
            }, {
                computer: row.computer,
                type: row.type,
                dataKey: row.dataKey,
                domain: row.domain,
                domainName: row.domainName,
                endTime: row.endTime,
                exist: row.exist,
                extra: row.extra,
                goodsId: row.goodsId,
                jump: row.jump,
                jumpUrl: row.jumpUrl,
                pageName: row.pageName,
                phone: row.phone,
                possId: row.possId,
                protocol: row.protocol,
                repeatLabelIds: row.repeatLabelIds,
                status: row.status,
                subDomain: row.subDomain,
                timeType: row.timeType,
                pondId: row.pondId,
            });
            this.getCode(this.dialogForm)
            this.dialogForm['id'] = null
            this.dialogForm['copyid'] = id
            this.dialogFormVisible = true;
            this.rowIndex = id;
        },
        clearApplet() {
            document.getElementById("qrcode").innerHTML = "";
            this.appletForm = {}
            this.appletParam = ""
            this.appletUrl = ""
        },
        createWechatQrcode() {
            configApi.findConfigByConfigType("APPLET_HTTPS_URL").then(res => {
                if (res && res.url) {
                    let u = this.appletForm.url.replace("http://", "").replace("https://", "").split(":")[0]
                    let url = res.url + `/applet?url=${u}`;
                    let param = `?url=${u}`
                    let mediatorId = this.appletForm.mediatorId
                    if (mediatorId && mediatorId > 0) {
                        url += "&mediatorId=" + mediatorId;
                        param += "&mediatorId=" + mediatorId;
                    }
                    this.appletParam = param
                    this.appletUrl = url
                    document.getElementById("qrcode").innerHTML = "";
                    let qrcode = new QRCode("qrcode", {
                        width: 150,
                        height: 150,
                        text: url,
                        colorDark: "#333",
                        colorLight: "#fff"
                    });
                } else {
                    this.$message.error("获取数据列表失败")
                }
            });

        },
        createApplet(row) {
            this.getMediatorList();
            this.appletForm.url = row.domain;
            this.appletDialogVisible = true
        },
        getMediatorList() {
            apiUtils.allMediators().then(res => {
                this.mediatorList = res.data
            });
        },
        resetQuery() {
            this.resetForm("queryForm");
            this.queryParams = {
                page: 1,
                limit: 10,
                timeType: 1
            };
            this.pagedomainList();
        },
        findDomainList() {
            apiDomain.list().then(res => {
                this.options = res.data
                // if (this.options && this.options.length > 0) {
                //     this.$set(this.dialogForm, 'domainName', this.options[0].domainName)
                // }
            })
        },
        saveDoamin() {
            apiDomain.save(this.domainForm).then(res => {
                if (res.success) {
                    this.$message({
                        type: "success",
                        message: res.msg
                    });
                    this.domainDialogVisible = false;
                    this.$set(this.dialogForm, 'domainName', res.data.domainName)
                    this.options.push({id: res.data.id, domainName: res.data.domainName})
                    return
                }
                this.$message({
                    type: "error",
                    message: res.msg
                });
            });
        },
        findDomainByKey() {
            if (this.domainForm.secretId && this.domainForm.secretKey) {
                apiDomain.findDomainByKey(this.domainForm).then(res => {
                    if (!res.success) {
                        this.$message.error(res.msg)
                        return;
                    }
                    this.domainList = res.data.data
                })
            }
        },
        addSubDomain() {
            this.subDomainSync = true
        },
        addDomain() {
            this.domainDialogVisible = true;
        },
        findPossessList() {
            possApi.possessAll().then(res => {
                if (res.success) {
                    this.possessList = res.data
                }
            })
        },
        findSubject() {
            subjectApi.getList({}).then(res => {
                console.log('findSubject', res)
                this.subjectList = res
            })
        },
        statisticsFun() {
            api.statistics(this.queryParams, this.tableData.map(e => e.id)).then(ress => {
                for (let data of this.tableData) {
                    Object.assign(data, {"countData": 0, "pv": 0, "subCount": {}, "successData": 0, "uv": 0});
                    for (let item of ress.data) {
                        if (item.id === data.id) {
                            console.log("eq", item)
                            Object.assign(data, item);
                        }
                    }
                }
            })

            api.statisticsCount(this.queryParams, this.tableData.map(e => e.id)).then(rese => {
                this.sucCount = rese.data.successData
                this.pvCount = rese.data.pv || 0
                this.sumCount = rese.data.countData
                this.uvCount = rese.data.uv
                this.sumMap = rese.data.sumMap
            })
        },
        /*
        * 获取列表数据
        * */
        pagedomainList() {
            const loading = this.$loading({
                lock: true,
                text: '正在努力获取数据',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            if (this.createTimeRange) {
                this.queryParams['startTime'] = this.createTimeRange[0]
                this.queryParams['endTime'] = this.createTimeRange[1]
            }
            api.getListPage(this.queryParams).then(res => {
                this.tableData = res.entity.data
                this.total = res.entity.count
                for (let data of this.tableData) {
                    Object.assign(data, {
                        "countData": '加载中',
                        "pv": '加载中',
                        "subCount": '加载中',
                        "successData": '加载中',
                        "uv": '加载中'
                    });
                }
                this.sucCount = '加载中'
                this.pvCount = '加载中'
                this.sumCount = '加载中'
                this.uvCount = '加载中'
                this.sumMap = '加载中'
                loading.close()
                this.statisticsFun()
            }).catch(e => {
                loading.close()
            });

        },
        /*
        * 配置--跳转--微页面
        * */
        Jump(scope) {
            let host = scope.domain + '/customize/edit';
            window.open("http://" + host, '_target')
        },
        JumpPc(scope) {
            let host = scope.domain + '/customize/pc/edit';
            window.open(host, '_target')
        },
        //新增
        addRoles() {
            this.dialogTitle = "添加微页面";
            let dom = this.options && this.options.length > 0 ? this.options[0].domainName : ''
            this.dialogForm = Object.assign({
                type: 0,
                protocol: 'http://',
                domain: '',
                domainName: '',
                possId: '',
                subDomain: '',
                jump: false,
                repeatLabelIdList: []
            }, {});
            this.getCode(this.dialogForm)

            this.dialogFormVisible = true;
        },
        //重置表单规则
        resetFrom(roleForm) {
            this.$refs[roleForm].clearValidate();
        },
        handleButton(val) {
            //调用事件
            this[val.methods](val.row, val.index);
        },
        edit(row, index) {
            console.log(row);
            this.extraOptions = []
            if (row.extra) {
                this.extraOptions = JSON.parse(row.extra)
            }
            this.dialogTitle = "修改微页面";
            this.dialogForm = Object.assign({
                protocol: 'http://',
                domain: '',
                domainName: '',
                possId: '',
                subDomain: ''
            }, row);
            console.log(row.repeatLabelIds)
            this.dialogForm.repeatLabelIdList = row.repeatLabelIds ? row.repeatLabelIds.split(',').filter(e => e).map(Number) : []
            this.dialogFormVisible = true;
            this.rowIndex = index;
        },
        /*
        * 新增/修改 保存列表数据
        * */
        save(roleForm) {
            let extra = []
            for (let e of this.extraOptions) {
                if (e.key) {
                    extra.push(e)
                }
            }
            this.dialogForm.extra = JSON.stringify(extra)

            if (this.dialogForm.jump) {
                let jump_url = this.dialogForm.jumpUrl
                if (jump_url.indexOf("http") !== 0) {
                    this.$message({
                        type: "error",
                        message: "网址错误,请检查网址开头是否为http://或https://"
                    });
                    return false
                }
            }

            this.$refs[roleForm].validate(valid => {
                if (valid) {
                    let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                    //如果是新的域名,则进行域名解析,判断是否可以进行解析
                    let form = this.dialogForm
                    if (form.repeatLabelIdList) {
                        form.repeatLabelIds = form.repeatLabelIdList.join(',')
                    }
                    if (this.dialogForm.type === 1 && !id) {
                        let name = this.dialogForm.domainName === '' ? this.dialogForm.domainName : '.' + this.dialogForm.domainName
                        form['domain'] = this.dialogForm.subDomain + name
                        let check = {domainName: form.domainName, subDomain: form.subDomain}
                        apiDomain.checkSubDomain(check).then(res => {
                            if (res.success) {
                                api.save(form).then(rr => {
                                    this.pagedomainList()
                                    this.$message({
                                        message: rr.msg
                                    });
                                    this.dialogFormVisible = false;
                                });
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg
                                });
                            }
                        })
                    } else {
                        api.save(form).then(res => {
                            if (res.success) {
                                this.pagedomainList()
                                this.$message({
                                    type: "success",
                                    message: "修改成功！"
                                });
                                this.dialogFormVisible = false;
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg
                                })
                            }
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        /*
        * 删除
        * */
        deleteCurrent(id) {
            this.$confirm('是否删除域名?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.deleteCurrent(id).then(res => {
                    this.pagedomainList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            });
        }
    }
}
</script>

<style scoped>

</style>
