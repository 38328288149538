import request from '@/utils/request.js';

//获取签名列表
export function queryEnterpriseTagList(params,query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/sphere/enterpriseTag/list`,
        method: 'post',
        params: query,
        data: params
    })
}

//添加
export function insertTagGroup(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/enterpriseTag/insertTagGroup`,JSON.stringify(params));
}

//修改
export function tagUpdate(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/enterpriseTag/update`, JSON.stringify(params));
}

//删除
export function tagDelete(id) {
    return request.delete(`${process.env.VUE_APP_BASEURL_API2}/sphere/enterpriseTag/delete/${id}`);
}

//修改时删除
export function deleteByUpdate(params) {
    return request.delete(`${process.env.VUE_APP_BASEURL_API2}/sphere/enterpriseTag/deleteByUpdate?tagName=${params}`);
}

//同步
export function getTagList() {
    return request.get(`${process.env.VUE_APP_BASEURL_API2}/sphere/enterpriseTag/getTagList`);
}
