import request from '@/utils/request.js';


//回显海报素材
export function showMaterial(id) {
    return request.get(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/showEcho/${id}`);
}

//修改海报素材
export function updateMaterial(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/update`,JSON.stringify(params));
}

//删除海报素材
export function deleteMaterial(id) {
    return request.delete(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/deleteMaterial/${id}`);
}

//获取类型列表
export function typeList(params) {
    return request.get(`${process.env.VUE_APP_BASEURL_API2}/sphere/poster/list?typeName=${params.posterType}`);
}

//添加
export function addPosterType(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/poster/add?typeName=${params.typeName}`);
}

//修改
export function updatePosterType(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/poster/update`, JSON.stringify(params));
}

//回显
export function queryPosterId(id) {
    return request.get(`${process.env.VUE_APP_BASEURL_API2}/sphere/poster/echo/${id}`);
}

//删除
export function deletePosterType(id) {
    return request.delete(`${process.env.VUE_APP_BASEURL_API2}/sphere/poster/delete/${id}`);
}

//修改启用状态
export function updatePosterStatus(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/poster/updateStatus`,JSON.stringify(params));
}

//制定素材
export function queryImgList(id) {
    return request.get(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/getMaterialList/${id}`);
}

//全启用素材
export function queryMaterialAll(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/materialListAll`,params);
}

//新增素材
export function addPosterMaterial(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/saveMaterial`,JSON.stringify(params));
}

