<template>
  <el-dialog
      title="修改唯一配置"
      :visible.sync="BatchUpdateObj.isShow"
      width="40%">
      <span style="color: red;">*只会修改未归属咨询师数据</span>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" label-position="left">
      <el-form-item label="原唯一配置" prop="beforePossessId">
        <el-select v-model="ruleForm.beforePossessId" placeholder="请选择原唯一配置" size="small" @change="oldChange">
          <el-option v-for="(item,index) in ConfigurationData" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新唯一配置" prop="afterPossessId">
        <el-select v-model="ruleForm.afterPossessId" placeholder="请选择新唯一配置" size="small">
          <el-option v-for="(item,index) in ConfigurationData" :key="index" :label="item.name" :value="item.id"
                     :disabled="item.id === ruleForm.beforePossessId ? true : false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="修改机会数" prop="limit">
        <el-input type="number" v-model="ruleForm.limit" size="small" style="width: 215px"></el-input>
      </el-form-item>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span style="font-size: 18px">修改机会条件</span>
        </div>
        <el-form-item label="修改机会上传时间" label-width="140px" prop="date">
          <el-date-picker
              v-model="ruleForm.date"
              type="daterange"
              size="mini"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :default-time="['08:00:00', '21:00:00']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="修改机会成单状态" label-width="140px" prop="orderStatus">
          <el-radio-group v-model="ruleForm.orderStatus">
            <el-radio label="2">全部</el-radio>
            <el-radio label="0">未成单</el-radio>
            <el-radio label="1">已成单</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-card>
      <el-form-item label-width="0" style="text-align: center">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="BatchUpdateObj.isShow = false">取 消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import * as api from '@/utils/api'

export default {
  name: "BatchUpdateOnlyConfigure",
  props: {
    BatchUpdateObj: {
      type: Object,
      require: true,
    },
    pondId:{
        type: Number,
        default:1
    }
  },
  data() {
    return {
      ruleForm: {
        beforePossessId: '',
        afterPossessId: '',
        limit: '',
        date: '',
        orderStatus: '',
      },
      rules: {
        beforePossessId: [
          {required: true, message: '请选择原唯一配置', trigger: 'blur'},
        ],
        afterPossessId: [
          {required: true, message: '请选择新唯一配置', trigger: 'blur'},
        ],
        limit: [
          {required: true, message: '请填写修改机会数', trigger: 'blur'},
        ],
        date: [
          {required: true, message: '请选择修改机会上传时间', trigger: 'blur'},
        ],
        orderStatus: [
          {required: true, message: '请选择修改机会成单状态', trigger: 'blur'},
        ],
      },
      ConfigurationData: [],
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = {...this.ruleForm};
          formData.startTime = this.ruleForm.date[0] + ' 00:00:00';
          formData.endTime = this.ruleForm.date[1] + ' 23:59:59';
          formData.pondId = this.pondId;
          this.$delete(formData, 'date')
          api.updateBatchPosses(formData).then(res => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.BatchUpdateObj.isShow = false;
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    possessList() {
      api.possessList().then(res => {
        this.ConfigurationData = res.data;
      })
    },
    oldChange() {
      this.ruleForm.afterPossessId = ''
    }
  },
  mounted() {
    this.possessList()
  }
}
</script>

<style scoped>
/deep/ .el-form .el-form-item {
  margin-bottom: 16px;
}

/deep/ .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: auto;
  max-width: 220px;
}

/deep/ .el-card {
  margin-bottom: 20px;
}
</style>
