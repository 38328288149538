<template>
  <div>
    <el-divider content-position="left">{{ id }}/{{ telephone }}</el-divider>
    <el-row type="flex" class="row-bg" justify="center">
      <el-form :inline="true" :model="formData" class="demo-form-inline">
        <el-form-item label="关键词">
          <el-col :span="22">
            <el-input v-model="formData.courseName" placeholder="请输入关键词" @input="goodList"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="类别">
          <el-col :span="20">
            <el-select v-model="formData.subjectIds" placeholder="请选择" @change="goodList">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="(item,index) in categoryList" :key="index" :label="item.subjectName"
                         :value="item.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table
        ref="singleTable"
        highlight-current-row
        @current-change="tableCurrentChange"
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          prop="courseName"
          label="商品名"
          width="460">
      </el-table-column>
      <el-table-column
          prop="callerNumber"
          label="附属商品">
      </el-table-column>
      <el-table-column
          prop="orPrice"
          label="价格">
      </el-table-column>
      <el-table-column
          prop="calledNumber"
          label="优惠范围">
      </el-table-column>
    </el-table>
    <el-pagination
        small
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="pages.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.total">
    </el-pagination>

    <div v-if="tableDataNew.length" style="margin: 30px 0 10px"><b>已选商品</b></div>
    <el-table
        ref="rowTable"
        :data="tableDataNew"
        border
        style="width: 100%"
        v-if="tableDataNew.length">
      <el-table-column
          prop="courseName"
          label="商品名"
          width="460">
      </el-table-column>
      <el-table-column
          prop="callerNumber"
          label="附属商品">
      </el-table-column>
      <el-table-column
          prop="orPrice"
          label="价格">
      </el-table-column>
      <el-table-column
          prop="calledNumber"
          label="优惠范围">
      </el-table-column>
    </el-table>
    <el-row>
      <el-row class="setup margin-top30">
        <el-col style="width: 100px"><b>商品金额</b></el-col>
        <el-col :span="4"><b>{{ currentRow.orPrice }}</b></el-col>
      </el-row>
      <el-row class="setup">
        <el-col style="width: 100px"><b>优惠金额</b></el-col>
        <el-col :span="4"><b>{{ discount }}</b></el-col>
      </el-row>
      <el-row class="setup">
        <el-col style="width: 100px"><b>应付金额</b></el-col>
        <el-col :span="4">
          <el-input v-model="orPriceNew" @change="changeorPrice"></el-input>
        </el-col>
      </el-row>
      <el-row class="setup">
        <el-col style="width: 100px"><b>支付方式</b></el-col>
        <el-col>
          <el-radio-group v-model="wayRadio" @change="changeWayRadio">
            <el-radio label="WEIXIN">微信</el-radio>
            <el-radio label="ALIPAY">支付宝</el-radio>
            <el-radio label="GIVING">后台</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row>
        <el-button type="info">取消下单</el-button>
        <el-button type="success" @click="placeOrder">提交订单</el-button>
      </el-row>
    </el-row>
    <el-dialog
        width="70%"
        title="支付信息"
        :visible.sync="dialogInnerVisible"
        v-if="dialogInnerVisible"
        append-to-body>
      <Payment :payParamsObject="payParamsObject"></Payment>
    </el-dialog>

  </div>
</template>

<script>
import Payment from '@/components/details/Payment'
import * as api from "../../utils/api"
import {getUserId, getDataKey} from "../../utils/auth";
import {subjectList} from "../../utils/api";

export default {
  name: "PlaceOrder",
  components:{
    Payment
  },
  props: {
    id: {
      type: Number
    },
    telephone: {
      type: String
    },
  },
  data() {
    return {
      formData: {
        courseName: '',
        subjectIds: ''
      },
      wayRadio:'',
      discount:'',//优惠金额
      orPriceNew:'',//应付金额
       weixn :'WEIXIN',
      categoryList: [],
      currentPage4: 5,
      tableData: [],
      pages: {current: 1, size: 10, total: 0},
      tableDataNew: [],
      currentRow:{},
      paramsO:{
        opporId:  this.id,
        // subjectIds:'',
        // goodsId: 28724554,
        // realPrice: 10980,
        // goodsName: '定金课-2021年二级消防工程师-企业委培班',
        // orPrice: 10980,
        // discount: 0,
      },
      dialogInnerVisible:false,
      payParamsObject:{},
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    pageSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pages.size = val;
      this.goodList();
    },
    pageCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages.current = val;
      this.goodList()
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    tableCurrentChange(val) {
      console.log(this.$store.state.information,'information')
      console.log( getUserId())
      this.tableDataNew = [];
      this.orPriceNew = '';
      this.discount = '';
      this.currentRow = Object.assign({}, val);;
      this.currentRow.orPriceNew = '';
      console.log(this.currentRow)
      this.tableDataNew.push(this.currentRow)
      console.log(this.tableDataNew)
      this.paramsO.goodsName = this.currentRow.courseName;
      this.paramsO.goodsId = this.currentRow.id;
      this.paramsO.orPrice = this.currentRow.orPrice;
      this.paramsO.subjectIds = this.formData.subjectIds;
    },
    changeWayRadio(){
      console.log(this.wayRadio)
    },
    changeorPrice(){
      if(this.currentRow.orPrice == undefined){
        this.$message({
          message: '请先选择商品',
          type: 'warning'
        })
        this.discount = ''
        this.orPriceNew = ''
        return false;
      }
      console.log(this.orPriceNew > this.currentRow.orPrice)
      console.log(this.orPriceNew , this.currentRow.orPrice)
      if(this.orPriceNew > this.currentRow.orPrice){
        this.$message({
          message: '实际支付金额不应大于商品金额',
          type: 'warning'
        })
        this.orPriceNew = ''
      }else{
        this.discount = this.currentRow.orPrice - this.orPriceNew;
        this.paramsO.realPrice = this.orPriceNew;
        this.paramsO.discount = this.discount;
      }
    },
    subjectList() {//类别接口
      api.subjectList().then(res => {
        this.categoryList = res.data
      })
    },
    goodList() {//获取数据列表
      api.goodList(this.pages.current, this.pages.size, this.formData).then(res => {
        this.tableData = res.data.data;
        this.pages.current = res.data.pageNum;
        this.pages.size = res.data.pageSize;
        this.pages.total = res.data.total;
      })
    },
    placeOrder() {//提交订单
      this.paramsO['payType'] = this.wayRadio.toString();
      console.log(this.paramsO.payType)
      console.log(this.paramsO,this.paramsO.payType.toString(),this.wayRadio)
      api.createOrder(this.paramsO).then(res => {
        if(res.code == 0){
          console.log(res, '提交订单数据')
          if(res.msg.indexOf("不可重复购买") != -1) {
            this.$message({
              message: '您已购买此商品并在有效期内，无需重复购买！',
              type: 'warning'
            })
          }else{
            if(res.data){
              this.payParamsObject['qrcode'] = res.data.data;
              this.payParamsObject['orderId'] = res.data.orderId;
              this.payParamsObject['payType'] = this.paramsO.payType;
              console.log(this.payParamsObject,'payParamsObject')
            }
            if(this.paramsO.payType != 'GIVING'){
              this.dialogInnerVisible = true;
            }else{
              this.$notify({
                title: '后台下单成功',
                message: '请通知管理员去后台进行审核操作！',
                type: 'success'
              });
              this.$emit('close');
            }
          }
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  },
  mounted() {
    this.subjectList()
    this.goodList()
  },
}
</script>

<style scoped>
/deep/ .el-input__inner {
  line-height: 32px !important;
  height: 32px !important;
}

/deep/ .el-table td, /deep/ .el-table th {
  padding: 10px 0 !important;
}

.setup {
  line-height: 36px;
}

.margin-top30 {
  margin-top: 30px;
}
</style>
