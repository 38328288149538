import request from '@/utils/request.js';

const url = "/manage/crm/traffic";

//获取列表数据
export function listTraffic(query) {
    return request({
        url: `${url}/trafficList`,
        method: 'get',
        params: query
    })
}

//获取列表数据
export function list(query) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: query
    })
}

//获取列表数据
export function saveOrUpdate(query) {
    return request.post(`${url}/saveOrUpdate`, query);
}

//获取列表数据
export function deleteTraffic(ids) {
    return request.post(`${url}/deleteTraffic`, ids);
}

//通过ID获取
export function getTrafficById(id) {
    return request.get(`${url}/getTrafficById/${id}`);
}

//解绑
export function unBind(ids) {
    return request.post(`${url}/unBind`, ids);
}

export function getOneByUserId(id) {
    return request.get(`${url}/getOneByUserId/${id}`);
}

/**
 * 获取话务服务商
 */
export function trafficProviders() {
    return request.get(`${url}/providers`)
}