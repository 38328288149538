import request from '@/utils/request.js';

const url = "/manage/system/config";

export function findConfig() {
    return request.get(url + "/findConfig");
}

export function saveConfig(param) {
    return request.post(url + "/saveConfig", JSON.stringify(param));
}

/**
 * 根据配置类型查询
 * @param configType
 * @returns {any}
 */
export function findConfigByConfigType(configType) {
    return request.get(`${url}/findConfigByConfigType?configType=${configType}`);
}