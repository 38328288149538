import request from '@/utils/request.js';

const url = "/manage/crm/possess";

//获取列表数据
export function listPossess(query) {
    return request({
        url: `${url}/possessList`,
        method: 'get',
        params: query
    })
}

/**
 * 不论权限,获取全唯一配置
 * @param params
 */
export function listAll(params) {
    return request.get(`${url}/listAll`, params);
}

//获取全部数据
export function list(param) {
    return request.get(`${url}/list`, param);
}

//根据ID获取
export function getPossess(id) {
    return request({
        url: `${url}/getPossess/${id}`,
        method: 'get',
    })
}

// 新增
export function addPossess(data) {
    return request.post(`${url}/addPossess`, data);
}

// 修改
export function updatePossess(data) {
    return request.post(`${url}/updatePossess`, data);
}

// 状态修改
export function changeStatus(id, status) {
    const data = {
        id,
        status
    }
    return request({
        url: `${url}/changeStatus`,
        method: 'put',
        data: data
    })
}

// 是否可添加修改
export function changeCanAdd(id,status,canAdd) {
    const data = {
        id,
        status,
        canAdd
    }
    return request({
        url: `${url}/changeCanAdd`,
        method: 'put',
        data: data
    })
}

// 获取唯一值对应的部门
export function findDeptTreeByPoss(id) {
    return request.get(`${url}/findDeptTreeByPoss`, id);
}

// 删除拥有标识
export function deletePoss(id) {
    return request.get(`${url}/deletePoss/${id}`);
}

/**
 * 查询所有的配置启用禁用都可以查询
 * @param param
 * @returns {Promise<AxiosResponse<any>>}
 */
export function possessAll() {
    return request.get(`${url}/possessAll`);
}