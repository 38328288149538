<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
            <el-form-item label="归属账号" prop="configId">
                <el-select v-model="queryParams.configId" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="c in promoteConfigList" :value="c.id" :label="c.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="页面名称" prop="pageName">
                <el-input v-model="queryParams.pageName" placeholder="页面名称" clearable size="small"/>
            </el-form-item>
            <el-form-item label="来源页面" prop="configId">
                <el-select v-model="queryParams.promoteId" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="c in promoteList" :value="c.promoteId" :label="c.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数据转化" prop="turnStatus">
                <el-select v-model="queryParams.turnStatus" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option :value="0" label="未转化"></el-option>
                    <el-option :value="1" label="已转化"></el-option>
                    <el-option :value="2" label="转化失败"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数据时间" prop="queryTimes">
                <el-date-picker size="small"
                                v-model="queryTimes"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                value-format="yyyy-MM-dd"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="dataList">搜索</el-button>
                <el-button type="warning" icon="el-icon-search" size="mini" @click="dialogFormVisible = true">获取数据
                </el-button>
                <el-tooltip class="item" effect="dark" content="转化全部未转化数据" placement="top">
                    <el-button type="success" icon="el-icon-search" size="mini" @click="turnPromoteVisible = true">转化数据
                    </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="转化选中数据" placement="top">
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="turnSelected">转化选中</el-button>
                </el-tooltip>
                <el-button icon="el-icon-refresh" size="mini" @click="exportPromoteDataExcel">导出数据</el-button>
                <el-tooltip class="item" effect="dark" content="回退未被领取数据" placement="top">
                    <el-button type="warning" icon="el-icon-refresh" size="mini" @click="turnBackData">回退数据</el-button>
                </el-tooltip>
            </el-form-item>
        </el-form>

        <el-table
                :data="configTable"
                ref="dataTable"
                stripe
                @selection-change="handleSelectionChange"
                style="width: 100%">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column align="center"
                             prop="bizNo"
                             label="留资流水号">
            </el-table-column>
            <el-table-column align="center"
                             prop="configId"
                             label="来源账号">
                <template slot-scope="scope">
                    <span v-for="m of promoteConfigList" v-if="m.id == scope.row.configId">{{m.name}}</span>
                </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="promoteId"
                             label="留资页面">
                <template slot-scope="scope">
                    <span v-for="m of promoteList" v-if="m.promoteId == scope.row.promoteId">{{m.name}}</span>
                </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="time"
                             :formatter="dateFormat"
                             label="留资时间">
            </el-table-column>
            <el-table-column align="center"
                             prop="turnStatus"
                             label="转化">
                <template slot-scope="scope">
                    <span v-if="1 === scope.row.turnStatus">已转化</span>
                    <span v-if="0 === scope.row.turnStatus">未转化</span>
                    <span v-if="2 === scope.row.turnStatus">转化失败</span>
                </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="orderStatus"
                             label="成单">
                <template slot-scope="scope">
                    <span v-if="1 === scope.row.orderStatus">已成单</span>
                    <span v-if="0 === scope.row.orderStatus">未成单</span>
                </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="firstSale"
                             label="首次领取学习顾问">
            </el-table-column>
            <el-table-column align="center"
                             prop="nowSale"
                             label="当前学习顾问">
            </el-table-column>
            <el-table-column align="center"
                             prop="nowDept"
                             label="当前部门">
            </el-table-column>
            <el-table-column align="center"
                             prop="stockTime"
                             :formatter="dateFormat"
                             label="入库时间">
            </el-table-column>
            <template v-for="(column,index) in rowKeyArray">
                <el-table-column :label="column.name" :key="column.key" prop="propertyList" align="center">
                    <template slot-scope="scope">
                        <div v-for="(col,ind) in scope.row.propertyList" :key="ind">
                            <span v-if="col.name === column.name">{{col.value}}</span>
                        </div>
                    </template>
                </el-table-column>
            </template>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-check"
                            @click="turnSelected(scope.row.id)"
                    >转化
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="deleteCurrent(scope.row.id)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30, 40]" :page.sync="queryParams.page"
                    @pagination="dataList"
                    :limit.sync="queryParams.limit"/>

        <el-dialog title="更新数据" :visible.sync="dialogFormVisible">
            <span class="demonstration">时间段:</span>
            <el-date-picker
                    v-model="times"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                    end-placeholder="结束日期">
            </el-date-picker>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" round @click="dialogFormVisible = false">取消</el-button>
                <el-button size="small" round type="success" @click="updateData"
                           v-loading.fullscreen.lock="fullscreenLoading">更新数据
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="转化数据" :visible.sync="turnPromoteVisible">
            <el-form
                    :model="turnForm"
                    ref="turnForm"
                    label-width="120px"
            >
                <el-form-item label="归属账号" prop="configId">
                    <el-select v-model="turnForm.configId" filterable class="condition-select" size="small"
                               placeholder="请选择">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="c in promoteConfigList" :value="c.id" :label="c.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源页面" prop="configId">
                    <el-select v-model="turnForm.promoteId" filterable class="condition-select" size="small"
                               placeholder="请选择">
                        <el-option value="" label="全部"></el-option>
                        <el-option v-for="c in promoteList" :value="c.promoteId" :label="c.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数据时间" prop="times">
                    <el-date-picker
                            v-model="times"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            value-format="yyyy-MM-dd"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" round @click="turnPromoteVisible = false">取消</el-button>
                <el-button size="small" round type="success" @click="turnPromoteData()"
                           v-loading.fullscreen.lock="fullscreenLoading">更新数据
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import * as api from "@/api/mini/promoteData";
    import * as apiPage from "@/api/mini/promotePage";
    import * as apiConfig from "@/api/mini/promoteConfig";
    import axios from "axios";

    export default {
        name: "promoteData",
        data() {
            return {
                // 总条数
                total: 0,
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10
                },
                configTable: [],
                dialogFormVisible: false,
                turnPromoteVisible: false,
                dialogTitle: "",
                times: "",
                queryTimes: [],
                dialogForm_null: Object.assign({}, this.dialogForm),
                rowKey: {},
                rowKeyArray: [],
                fullscreenLoading: false,
                promoteList: [],
                promoteConfigList: [],
                turnForm: {},
                multipleSelection: []
            }
        },
        mounted() {
            this.findPromotePage();
            this.dataList();
            this.findPromoteConfigList()
        },
        methods: {
            turnBackData() {
                api.turnBackData().then(res => {
                    this.$message({
                        type: "success",
                        message: res.msg
                    });
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            turnSelected(id) {
                this.turnForm = {}
                let ids;
                console.log(id)
                console.log(this.multipleSelection)
                if (id > 0) {
                    ids = id
                } else {
                    ids = this.multipleSelection.map((e) => {
                        return e.id
                    }).join(",");
                }

                if (ids) {
                    this.turnForm.ids = ids
                    api.turnPromoteDataId(this.turnForm).then(res => {
                        this.fullscreenLoading = false;
                        this.dataList()
                        this.$message({
                            type: "success",
                            message: res.msg
                        });
                    })
                    this.turnForm = {}
                } else {
                    this.$message({
                        type: "error",
                        message: "未选择转化数据"
                    });
                    return;
                }

            },
            exportPromoteDataExcel() {
                if (this.queryTimes[0]) {
                    this.queryParams.startTime = this.queryTimes[0] + " 00:00:00"
                    this.queryParams.endTime = this.queryTimes[1] + " 23:59:59"
                }
                console.log(this.queryParams)
                axios({
                    method: "post",
                    url: "/api/manage/promote/data/exportPromoteDataExcel",
                    data: JSON.stringify(this.queryParams),
                    responseType: "blob",
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                        "version": localStorage.getItem("_version"),
                    },
                }).then(res => {
                    let now = new Date();
                    const blob = new Blob([res.data]);
                    const fileName = '支付宝留资数据' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
                    const elink = document.createElement('a')
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                })
            },
            findPromoteConfigList() {
                apiConfig.list().then(res => {
                    this.promoteConfigList = []
                    for (let tmp of res) {
                        let v = {}
                        v['id'] = tmp.id
                        v['name'] = tmp.name
                        this.promoteConfigList.push(v)
                    }
                })
            },
            findPromotePage(param) {
                apiPage.list(param).then(res => {
                    this.promoteList = res
                })
            },
            dateFormat(row, column) {
                var date = row[column.property];
                if (date == undefined) {
                    return "";
                }
                const dateMat = new Date(date);
                const year = dateMat.getFullYear();
                const month = dateMat.getMonth() + 1;
                const day = dateMat.getDate();
                const timeFormat = year + "-" + month + "-" + day;
                return timeFormat;
                return timeFormat;
            },
            resetQuery() {
                this.resetForm("queryForm");
                this.queryParams = {};
                this.queryTimes = []
                this.dataList();
            },
            /*
            * 获取列表数据
            * */
            dataList() {
                console.log(this.queryTimes)
                if (this.queryTimes && this.queryTimes[0]) {
                    this.queryParams.startTime = this.queryTimes[0] + " 00:00:00"
                    this.queryParams.endTime = this.queryTimes[1] + " 23:59:59"
                } else {
                    this.queryParams.startTime = null
                    this.queryParams.endTime = null
                }
                api.getList(this.queryParams).then(res => {
                    let configTable = res.data
                    let map = {}, prJson
                    for (let data of configTable) {
                        prJson = JSON.parse(data.propertyList)
                        data.propertyList = prJson
                        for (let p of prJson) {
                            map[p.key] = p.name
                        }
                    }

                    this.configTable = configTable
                    this.total = res.count
                    this.rowKey = map
                    this.rowKeyArray = prJson
                    this.configTable.rowKey = map

                });
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                api.deleteCurrent(id).then(res => {
                    this.dataList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            },
            updateData() {
                this.fullscreenLoading = true;
                api.updateData(this.times[0], this.times[1]).then(res => {
                    this.fullscreenLoading = false;
                    this.dataList()
                    this.$message({
                        type: "success",
                        message: res.msg
                    });
                })
            },
            turnPromoteData(id) {
                console.log(this.turnForm)
                if (this.times) {
                    this.turnForm.startTime = this.times[0] + " 00:00:00"
                    this.turnForm.endTime = this.times[1] + " 23:59:59"
                } else {
                    this.$message({
                        type: "error",
                        message: "请选择转化时间段"
                    });
                }

                console.log(this.turnForm)
                this.$confirm('是否转化数据?', '转化数据', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'success'
                }).then(() => {
                    api.turnPromoteData(this.turnForm).then(res => {
                        this.fullscreenLoading = false;
                        this.dataList()
                        this.turnForm = {}
                        this.$message({
                            type: "success",
                            message: res.msg
                        });
                    })
                });

            }
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>