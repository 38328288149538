<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="queryParams.name" placeholder="商品名称" clearable size="small"/>
      </el-form-item>
      <el-tooltip class="item" effect="dark"
                  content="小鹅通无法接口开通免费商品,并且只支持:图文，音频，视频，直播，会员，专栏，社群，大专栏，超级会员，训练营"
                  placement="top">
        <el-form-item label="小鹅通" prop="xiaoetong">
          <el-select v-model="queryParams.xiaoetong" size="small">
            <el-option :value="0" label="全部"></el-option>
            <el-option :value="1" label="仅可开通"></el-option>
          </el-select>
        </el-form-item>
      </el-tooltip>
      <el-form-item label="唯一标识" prop="possid">
        <el-select v-model="queryParams.possid" size="small">
          <el-option :value="null"></el-option>
          <el-option v-for="poss in possessList" :value="poss.id" :label="poss.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品类型" prop="type">
        <el-select v-model="queryParams.type" size="small">
          <el-option value=""></el-option>
          <el-option v-for="(k,v) in xiaoeType" :value="v" :label="k"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="list">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-button type="success" icon="el-icon-plus" @click="findOther(false)" size="mini" round>新增</el-button>
    <el-button type="success" icon="el-icon-plus" @click="findXiaoe" size="mini" round>小鹅通</el-button>
    <el-button v-permission="'mini:goods:addZebj'" type="success" icon="el-icon-plus" @click="findSg" size="mini"
               round>三格
    </el-button>
    <el-table :data="tableData" stripe style="width: 100%" max-height="800">
      <el-table-column prop="name" align="center" label="商品名称" width="200">
        <template slot-scope="scope">
          <!--          <el-row>-->
          <!--            <el-image class="goods-img" :src="scope.row.cover"></el-image>-->
          <!--          </el-row>-->
          <el-row>
            <span>{{ scope.row.name }}</span>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="possid" label="唯一标识" align="center">
        <template v-slot="scope">
          <span v-if="poss.id == scope.row.possid" v-for="poss in possessList">{{ poss.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="subjectId" label="项目" align="center">
        <template slot-scope="scope">
          <treeselect v-model="scope.row.subjectId" :options="parentList" :normalizer="normalizer"
                      :defaultExpandLevel="Infinity" disabled="disabled"
                      placeholder="未配置项目"/>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center">
        <template slot-scope="scope">
          {{ scope.row.price / 100 }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="商品类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">图文</span>
          <span v-else-if="scope.row.type == 2">音频</span>
          <span v-else-if="scope.row.type == 3">视频</span>
          <span v-else-if="scope.row.type == 4">直播</span>
          <span v-else-if="scope.row.type == 5">会员</span>
          <span v-else-if="scope.row.type == 6">专栏</span>
          <span v-else-if="scope.row.type == 7">圈子</span>
          <span v-else-if="scope.row.type == 8">大专栏</span>
          <span v-else-if="scope.row.type == 9">活动管理</span>
          <span v-else-if="scope.row.type == 16">付费打卡</span>
          <span v-else-if="scope.row.type == 20">电子书</span>
          <span v-else-if="scope.row.type == 21">实物商品</span>
          <span v-else-if="scope.row.type == 23">超级会员</span>
          <span v-else-if="scope.row.type == 25">训练营营期</span>
          <span v-else-if="scope.row.type == 29">线下课</span>
          <span v-else-if="scope.row.type == 31">小班课</span>
          <span v-else-if="scope.row.type == 34">练习</span>
          <span v-else-if="scope.row.type == 35">班课</span>
          <span v-else-if="scope.row.type == 37">大班课</span>
          <span v-else-if="scope.row.type == 41">有价优惠券</span>
          <span v-else-if="scope.row.type == 42">课时包</span>
          <span v-else-if="scope.row.type == 45">AI互动课</span>
          <span v-else-if="scope.row.type == 46">付费问答</span>
          <span v-else-if="scope.row.type == 47">付费问答-答主</span>
          <span v-else-if="scope.row.type == 50">训练营pro</span>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="channel" align="center" label="商品来源">
        <template v-slot="scope">
          <el-tag v-if="scope.row.channel === 'SYSTEM'">自创建</el-tag>
          <el-tag v-else-if="scope.row.channel === 'XIAOE'" type="info">小鹅通</el-tag>
          <el-tag v-else-if="scope.row.channel === 'SANGE'" type="success">三格</el-tag>
          <el-tag v-else type="danger">/</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-s-promotion"
              @click="putGoods(scope.row.id)"
          >上架商品
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="edit(scope.row,scope.row.id)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-paperclip"
              @click="bindList(scope.row,scope.row.id)"
          >查看绑定
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="delObj(scope.row.id)"
          >删除
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-more"
              v-permission="'mini:goods:openCourse'"
              v-if="scope.row.channel === 'SANGE'"
              @click="openCourse(1, scope.row)"
          >开课
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page.sync="queryParams.page"
                   @current-change="list"/>

    <el-dialog
        :title="formTitle"
        :visible.sync="goodsFormVisible"
        width="600px"
        top="20px"
        @close="resetFrom('goodsForm')"
    >
      <el-form
          :model="goodsForm"
          ref="goodsForm"
          label-width="120px"
      >
        <el-form-item label="封面">
          <el-upload
              class="avatar-uploader"
              action="/api/manage/file/uploadImg"
              :show-file-list="false"
              :headers="headers"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img style="max-width: 50%" v-if="goodsForm.cover" :src="goodsForm.cover" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="唯一配置">
          <el-select
              v-model="goodsForm.possid"
              filterable
              size="small"
          >
            <el-option v-for="poss in possessList" :value="poss.id"
                       :label="poss.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目">
          <treeselect v-model="goodsForm.subjectId" :options="parentList" :normalizer="normalizer"
                      :defaultExpandLevel="Infinity"/>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="goodsForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商品价格(分)">
          <el-input v-model="goodsForm.price"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsFormVisible = false">取消</el-button>
        <el-button type="success" @click="save">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog width="80%" :visible.sync="otherSync" title="绑定外部商品">
      <el-row>
        <el-col :span="22">
          <el-button type="success" @click="findOther">商品列表</el-button>
        </el-col>
      </el-row>
      <el-table height="400px" :data="otherTableList" stripe style="width: 100%">
        <el-table-column prop="name" label="商品名称">
          <template slot-scope="scope">
            <el-image class="goods-img" :src="scope.row.cover"></el-image>
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="channel" align="center" label="来源渠道">
          <template v-slot="scope">
            <el-tag v-if="scope.row.channel === 'SYSTEM'">自创建</el-tag>
            <el-tag v-else-if="scope.row.channel === 'XIAOE'" type="info">小鹅通</el-tag>
            <el-tag v-else-if="scope.row.channel === 'SANGE'" type="success">三格</el-tag>
            <el-tag v-else type="danger">/</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="商品类型"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                @click="look(scope.row,scope.row.id)"
            >查看详情
            </el-button>
            <el-button
                size="mini"
                type="text"
                @click="ubind(scope.row.id)"
            >取消绑定
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog width="80%" :visible.sync="otherDetailSync" title="商品详细信息">
      <div>
        <div class="des-body">
          <div class="des-body-al" v-for="(k,v) in otherDetail">
            <div class="des-body-la">{{ v }}</div>
            <div class="des-body-va">{{ k }}</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="80%" top="20px" :visible.sync="otherListSync" @close="otherParamsRestart" title="商品列表">
      <el-form :model="otherParams" ref="queryForm" :inline="true" label-width="100px">
        <el-form-item label="商品名称" prop="name">
          <el-input v-model="otherParams.name" placeholder="商品名称" clearable size="small"/>
        </el-form-item>
        <el-tooltip class="item" effect="dark"
                    content="小鹅通无法接口开通免费商品,并且只支持:图文，音频，视频，直播，会员，专栏，社群，大专栏，超级会员，训练营"
                    placement="top">
          <el-form-item label="小鹅通" prop="xiaoetong">
            <el-select v-model="otherParams.xiaoetong" size="small">
              <el-option :value="1" label="仅可开通"></el-option>
              <el-option :value="0" label="全部"></el-option>
            </el-select>
          </el-form-item>
        </el-tooltip>
        <el-form-item label="商品类型" prop="type">
          <el-select v-model="otherParams.type" size="small">
            <el-option value=""></el-option>
            <el-option v-for="(k,v) in xiaoeType" :value="v" :label="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" prop="type">
          <el-select v-model="otherParams.channel" size="small">
            <el-option value="SANGE" label="三格"></el-option>
            <el-option value="XIAOE" label="小鹅通"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="findOther">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table height="500px" :data="otherList" stripe style="width: 100%">
        <el-table-column prop="name" label="商品名称">
          <template slot-scope="scope">
            <el-image class="goods-img" :src="scope.row.cover"></el-image>
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="channel" align="center" label="来源渠道">
          <template v-slot="scope">
            <el-tag v-if="scope.row.channel === 'SYSTEM'">自创建</el-tag>
            <el-tag v-else-if="scope.row.channel === 'XIAOE'" type="info">小鹅通</el-tag>
            <el-tag v-else-if="scope.row.channel === 'SANGE'" type="success">三格</el-tag>
            <el-tag v-else type="danger">/</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="商品类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">图文</span>
            <span v-else-if="scope.row.type == 2">音频</span>
            <span v-else-if="scope.row.type == 3">视频</span>
            <span v-else-if="scope.row.type == 4">直播</span>
            <span v-else-if="scope.row.type == 5">会员</span>
            <span v-else-if="scope.row.type == 6">专栏</span>
            <span v-else-if="scope.row.type == 7">圈子</span>
            <span v-else-if="scope.row.type == 8">大专栏</span>
            <span v-else-if="scope.row.type == 9">活动管理</span>
            <span v-else-if="scope.row.type == 16">付费打卡</span>
            <span v-else-if="scope.row.type == 20">电子书</span>
            <span v-else-if="scope.row.type == 21">实物商品</span>
            <span v-else-if="scope.row.type == 23">超级会员</span>
            <span v-else-if="scope.row.type == 25">训练营营期</span>
            <span v-else-if="scope.row.type == 29">线下课</span>
            <span v-else-if="scope.row.type == 31">小班课</span>
            <span v-else-if="scope.row.type == 34">练习</span>
            <span v-else-if="scope.row.type == 35">班课</span>
            <span v-else-if="scope.row.type == 37">大班课</span>
            <span v-else-if="scope.row.type == 41">有价优惠券</span>
            <span v-else-if="scope.row.type == 42">课时包</span>
            <span v-else-if="scope.row.type == 45">AI互动课</span>
            <span v-else-if="scope.row.type == 46">付费问答</span>
            <span v-else-if="scope.row.type == 47">付费问答-答主</span>
            <span v-else-if="scope.row.type == 50">训练营pro</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" align="center" label="价格"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-if="goodsId"
                @click="bind(scope.row.id)"
            >绑定
            </el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="creaNew(scope.row.id)"
            >生成新商品
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :total="otherTotal" :current-page.sync="otherParams.page"
                     @current-change="findOther"/>
    </el-dialog>

    <el-dialog width="80%" top="10px" :visible.sync="xiaoeSync" @close="xiaoeParamsRestart" title="小鹅通商品列表">
      <el-form :model="xiaoeParams" ref="queryForm" :inline="true" label-width="100px">
        <el-form-item label="商品名称" prop="goods_name">
          <el-input v-model="xiaoeParams.goods_name" placeholder="商品名称" clearable size="small"/>
        </el-form-item>
        <el-form-item label="商品类型" prop="resource_type">
          <el-select v-model="xiaoeParams.resource_type" size="small">
            <el-option value=""></el-option>
            <el-option v-for="(k,v) in xiaoeType" :value="v" :label="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="sale_status">
          <el-select v-model="xiaoeParams.sale_status" size="small">
            <el-option value=""></el-option>
            <el-option :value="1" label="上架"></el-option>
            <el-option :value="0" label="下架"></el-option>
            <el-option :value="2" label="待上架"></el-option>
            <el-option :value="3" label="上架+待上架"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公开" prop="is_return_public">
          <el-select v-model="xiaoeParams.is_return_public" size="small">
            <el-option value=""></el-option>
            <el-option :value="1" label="公开"></el-option>
            <el-option :value="0" label="不公开"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="findXiaoe(1)">搜索</el-button>
          <el-button type="danger" icon="el-icon-download" size="mini" @click="batchImport()">批量导入
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="xiaoeList" height="500px" stripe style="width: 100%">
        <el-table-column width="130px" prop="goods_name" label="商品名称">
          <template slot-scope="scope">
            <el-image class="goods-img" :src="scope.row.goods_img[0]"></el-image>
            <span>{{ scope.row.goods_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="resource_id" align="center" label="商品ID"></el-table-column>
        <el-table-column prop="sale_status" align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.sale_status == 0">下架</span>
            <span v-else-if="scope.row.sale_status == 1">上架</span>
            <span v-else-if="scope.row.sale_status == 2">待上架</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="price_high" align="center" label="价格">
          <template slot-scope="scope">
            {{ scope.row.price_high / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="resource_type" align="center" label="商品类型">
          <template slot-scope="scope">
            <span v-if="scope.row.resource_type == 1">图文</span>
            <span v-else-if="scope.row.resource_type == 2">音频</span>
            <span v-else-if="scope.row.resource_type == 3">视频</span>
            <span v-else-if="scope.row.resource_type == 4">直播</span>
            <span v-else-if="scope.row.resource_type == 5">会员</span>
            <span v-else-if="scope.row.resource_type == 6">专栏</span>
            <span v-else-if="scope.row.resource_type == 7">圈子</span>
            <span v-else-if="scope.row.resource_type == 8">大专栏</span>
            <span v-else-if="scope.row.resource_type == 9">活动管理</span>
            <span v-else-if="scope.row.resource_type == 16">付费打卡</span>
            <span v-else-if="scope.row.resource_type == 20">电子书</span>
            <span v-else-if="scope.row.resource_type == 21">实物商品</span>
            <span v-else-if="scope.row.resource_type == 23">超级会员</span>
            <span v-else-if="scope.row.resource_type == 25">训练营营期</span>
            <span v-else-if="scope.row.resource_type == 29">线下课</span>
            <span v-else-if="scope.row.resource_type == 31">小班课</span>
            <span v-else-if="scope.row.resource_type == 34">练习</span>
            <span v-else-if="scope.row.resource_type == 35">班课</span>
            <span v-else-if="scope.row.resource_type == 37">大班课</span>
            <span v-else-if="scope.row.resource_type == 41">有价优惠券</span>
            <span v-else-if="scope.row.resource_type == 42">课时包</span>
            <span v-else-if="scope.row.resource_type == 45">AI互动课</span>
            <span v-else-if="scope.row.resource_type == 46">付费问答</span>
            <span v-else-if="scope.row.resource_type == 47">付费问答-答主</span>
            <span v-else-if="scope.row.resource_type == 50">训练营pro</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                @click="importGoods(scope.row)"
            >导入
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :total="xiaoeTotal" :current-page.sync="xiaoeParams.page"
                     @current-change="findXiaoe"/>
    </el-dialog>

    <el-dialog width="80%" top="10px" :visible.sync="sgSync" @close="sgQueryParamsRestart" title="三格商品列表">
      <el-form :model="sgQueryParams" ref="sgQueryForm" :inline="true" label-width="100px">
        <el-form-item label="商品名称" prop="name">
          <el-input v-model="sgQueryParams.name" placeholder="商品名称" clearable size="small"/>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="sgQueryParams.status" size="small" clearable>
            <el-option :value="0" label="上架"></el-option>
            <el-option :value="1" label="下架"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="findSg">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="sgTableData" height="500px" stripe style="width: 100%">
        <el-table-column prop="id" align="center" label="商品ID"/>
        <el-table-column prop="name" align="center" label="商品名称"/>
        <el-table-column prop="cover" align="center" label="封面">
          <template slot-scope="scope">
            <el-image class="goods-img" :src="scope.row.cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="sellPrice" align="center" label="售价">
          <template slot-scope="scope">
            {{ (scope.row.sellPrice / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" label="状态">
          <template v-slot="scope">
            <span v-if="scope.row.status === 0">上架</span>
            <span v-else-if="scope.row.status === 1">下架</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectType" align="center" label="商品科目">
          <template v-slot="scope">
            <el-tag v-if="scope.row.subjectType === 1">一建</el-tag>
            <el-tag type="success" v-else-if="scope.row.subjectType === 2">二建</el-tag>
            <el-tag type="info" v-else>/</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="createGoods(scope.row.id, 'SANGE')"
            >导入
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :total="sgQueryParams.total"
                     :current-page.sync="sgQueryParams.page"
                     @current-change="findSg"/>
    </el-dialog>

    <el-dialog width="50%" top="10px" :visible.sync="openCourseSync" title="商品开课" @open="openCourseForm.telephone = null">
      <el-form :model="openCourseForm" :inline="true" label-width="100px">
        <el-form-item label="开课手机号" prop="telephone">
          <el-input v-model="openCourseForm.telephone" placeholder="请输入开课手机号" clearable size="small"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="openCourse(2)">提交</el-button>
          <el-button type="info" icon="el-icon-search" size="mini" @click="openCourseSync = false">取消
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog width="80%" top="10px" :visible.sync="goodsSync" title="上架微页面">
      <el-form :model="putGoodsParams" ref="putGoodsForm" :inline="true" label-width="100px">
        <el-form-item label="页面名称" prop="pageName">
          <el-input v-model="putGoodsParams.pageName" placeholder="页面名称" clearable size="small"/>
        </el-form-item>
        <el-form-item label="网址" prop="domain">
          <el-input v-model="putGoodsParams.domain" placeholder="网址" clearable size="small"/>
        </el-form-item>
        <el-form-item label="标识" prop="possId">
          <el-select
              v-model="putGoodsParams.possId"
              filterable
              placeholder="标识"
              clearable
              size="small"
          >
            <el-option value="" label="全部"></el-option>
            <el-option v-for="poss in possessList" :value="poss.id"
                       :label="poss.name"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="12">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="putGoods()">搜索</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="success" icon="el-icon-upload2" size="mini" @click="online(true)">上架</el-button>
          <el-button type="danger" icon="el-icon-download" size="mini" @click="online(false)">下架</el-button>
        </el-col>
      </el-row>
      <el-table max-height="600"
                ref="tableRef"
                :data="putGoodsTables"
                stripe
                @selection-change="putGoodsSelectChanage"
                style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
            prop="pageName"
            label="页面名称">
        </el-table-column>
        <el-table-column
            prop="domain"
            label="网址">
        </el-table-column>
        <el-table-column
            prop="possId" align="center"
            label="数据唯一标识">
          <template slot-scope="scope">
            <span v-for="item in possessList" v-if="item.id === scope.row.possId">{{ item.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="exist" align="center" label="是否有课">
          <template v-slot="scope">
            <span v-if="scope.row.exist">存在</span>
            <span v-else>没有</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "@/api/mini/goods";
import * as xiaoeApi from "@/api/crm/xiaoetong";
import * as subjectApi from '@/api/system/subject'
import Treeselect from "@riophae/vue-treeselect";
import * as apiUtils from "@/utils/api";
import * as goodsApi from "@/api/mini/goods";

export default {
  name: "Goods",
  components: {
    Treeselect
  },
  data() {
    return {
      headers: {"version": localStorage.getItem("_version"),},
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10
      },
      tableData: [],
      goodsFormVisible: false,
      goodsForm: {
        id: null,
        possid: 0
      },
      formTitle: '修改商品',
      otherTableList: [],
      otherSync: false,
      otherDetailSync: false,
      otherDetail: {},
      goodsId: 0,
      otherListSync: false,
      otherParams: {
        page: 1,
        channel: "SANGE"
      },
      otherTotal: 0,
      otherList: [],
      xiaoeSync: false,
      xiaoeParams: {
        page: 1,
        page_size: 10
      },
      xiaoeTotal: 0,
      xiaoeList: [],
      xiaoeType: {
        1: "图文",
        2: "音频",
        3: "视频",
        4: "直播",
        5: "会员",
        6: "专栏",
        7: "圈子",
        8: "大专栏",
        9: "活动管理",
        16: "付费打卡",
        20: "电子书",
        21: "实物商品",
        23: "超级会员",
        25: "训练营营期",
        29: "线下课",
        31: "小班课",
        34: "练习",
        35: "班课",
        37: "大班课",
        41: "有价优惠券",
        42: "课时包",
        45: "AI互动课",
        46: "付费问答",
        47: "付费问答答主",
        50: "训练营pro"
      },
      parentList: [],
      goodsSync: false,
      putGoodsParams: {},
      possessList: [],
      putGoodsTables: [],
      putGoodsId: 0,
      pageselection: [],
      sgSync: false,
      sgQueryParams: {
        page: 1,
        limit: 10
      },
      sgTableData: [],
      openCourseSync: false,
      openCourseForm: {
        telephone: ''
      },
    }
  },
  created() {
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.findSubject()
    this.list()
    this.findPossessList()
  },
  methods: {
    sgQueryParamsRestart() {
      this.sgQueryParams = {
        page: 1,
        limit: 10
      }
    },
    xiaoeParamsRestart() {
      this.xiaoeParams = {
        page: 1,
        page_size: 10
      }
    },
    otherParamsRestart() {
      this.otherParams = {
        page: 1,
        channel: "SANGE"
      }
    },
    keyboard(e) {
      if (e.keyCode === 13) {
        // this.findXiaoe(1)
      }
    },
    openCourse(type, row) {
      switch (type) {
        case 1:
          this.openCourseSync = true
          this.openCourseForm.id = row.id
          break;
        case 2:
          goodsApi.openCourse(this.openCourseForm.id, this.openCourseForm.telephone).then(resp => {
            if (resp.success) {
              this.$message.success('开课成功')
              this.openCourseSync = false;
            } else {
              this.$message.error(resp.msg)
            }
          })
          break;
      }
    },
    createGoods(id, channel) {
      goodsApi.createGoods(id, channel).then(resp => {
        if (resp.success) {
          this.$message.success('创建成功')
          this.list()
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    //商品上线
    online(exits) {
      if (!this.pageselection) {
        this.$message.error('请选择页面');
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: '更新中....',
        spinner: 'el-icon-loading',
        background: '#171414B2'
      });

      let ids = this.pageselection.map(e => e.id);
      api.savePutGoods(exits, this.putGoodsId, ids).then(res => {
        loading.close();
        this.goodsSync = false
        if (res.success) {
          this.$message.success('页面操作成功');
          return;
        }
        this.$message.error(res.msg);
      })
    },
    putGoodsSelectChanage(selection) {
      this.pageselection = selection
    },
    findPossessList() {
      apiUtils.possessList().then(res => {
        this.possessList = res.data
      })
    },
    putGoods(id) {
      if (id) {
        this.putGoodsId = id
      } else {
        id = this.putGoodsId
      }
      this.goodsSync = true
      api.findPutGoods(id, this.putGoodsParams).then(res => {
        this.putGoodsTables = res.data
      })

    },
    handleAvatarSuccess(res, file) {
      this.$set(this.goodsForm, 'cover', res.msg)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传背景图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      this.$message({
        type: "success",
        message: "上传成功！"
      });
      return isJPG && isLt2M;
    },
    normalizer(node) {
      if (node.children == null || node.children == 'null') {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.subjectName,
        children: node.children
      };
    },
    delObj(id) {
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteCurrent(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.list()
        })
      })
    },
    creaNew(id) {
      api.createNew(id).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.list()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    importGoods(e) {
      api.otherImportXiaoe(e).then(res => {
        this.$message({
          type: 'success',
          message: '开始导入!请稍等,需要一些时间!'
        });
        this.list()
      })
    },
    batchImport() {
      xiaoeApi.goodsList(this.xiaoeParams).then(res => {
        let params = this.xiaoeParams;
        this.$confirm('是否确定导入全部:' + res.data.total + '条数据', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          api.batchOtherImportXiaoe(params).then(res => {
            this.$message({
              type: 'success',
              message: '开始导入!请稍等,需要一些时间!'
            });
            this.list()
          })
        })
      })
    },
    findXiaoe(page) {
      if (page === 1) {
        this.xiaoeParams['page'] = page
      }
      this.xiaoeSync = true
      xiaoeApi.goodsList(this.xiaoeParams).then(res => {
        this.xiaoeList = res.data.list
        this.xiaoeTotal = res.data.total
      })
    },
    findSg() {
      this.sgSync = true;
      goodsApi.querySgGoodsList(this.sgQueryParams).then(res => {
        if (res.success) {
          this.sgTableData = [].concat(res.data.data)
          this.sgQueryParams.total = res.data.total
        }
      })
    },
    findOther(b) {
      console.log("findOther", b)
      if (!b) {
        this.goodsId = 0
      }
      this.otherListSync = true
      let params = this.otherParams;
      params['id'] = this.goodsId
      api.findOther(params).then(res => {
        this.otherList = res.data.data
        this.otherTotal = res.data.total
      })
    },
    bind(oid) {
      api.bind(this.goodsId, oid).then(res => {
        console.log(res)
        if (res.success) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.bindList(null, this.goodsId)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    ubind(oid) {
      api.ubind(this.goodsId, oid).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.bindList(null, this.goodsId)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    look(e, id) {
      this.otherDetailSync = true
      let content = JSON.parse(e.content)
      this.otherDetail = content
    },
    bindList(e, id) {
      this.otherSync = true
      this.goodsId = id
      api.getChild(id).then(res => {
        this.otherTableList = res.data
      })
    },
    save() {
      api.save(this.goodsForm).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.goodsFormVisible = false
          this.list()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    list() {
      api.getList(this.queryParams).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    findSubject() {
      this.parentList = []
      subjectApi.getList().then(res => {
        this.parentList = res
      })
    },
    edit(row, id) {
      api.get(id).then(res => {
        this.goodsForm = res.data
        this.formTitle = '修改商品'
        this.goodsFormVisible = true
      })
    },
    //新增
    addGoods() {
      this.dialogTitle = "新增商品";
      this.goodsForm = {}
      this.goodsFormVisible = true;
    },
    //重置表单规则
    resetFrom(roleForm) {
      this.$refs[roleForm].clearValidate();
    },
  }
}
</script>

<style scoped>
.goods-img {
    max-width: 100px;
    max-height: 100px;
    margin-right: 5px;
}

.des-body {
    display: inline-block;
}

.des-body-al {
    float: left;
    display: inline-flex;
    width: 46%;
    height: 24px;
    align-items: center;
    text-align: left;
    font-size: 14px;
    padding: 1% 0;
}

.des-body-la {
    width: 20%;
    margin: 5px;
}

.des-body-va {
    width: 80%;
    padding: 0 20px;
}
</style>
