import router from '@/router';
import {getToken} from '@/utils/auth';

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
    const token = getToken()
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }
    //无论刷新还是跳转路由,第一个进入的就是这个路由前置钩子函数
    //验证当前路由所有的匹配中是否需要有登录验证的
    if (to.meta.requiredLogin && !token) {
        // 没有登录的时候跳转到登录界面
        // 携带上登陆成功之后需要跳转的页面完整路径
        next({
            path: 'login',
            query: {
                redirect: to.fullPath
            }
        })
    } else if (to.meta.redirectAlreadyLogin && token) {
        // 判断登录之后要进入当前页面 直接跳转首页
        next('/')
    } else {
        next();
    }
});

router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
})
