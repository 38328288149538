import request from '@/utils/request.js';

const url = "/manage/activated";

//添加列表数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: params
    })
}

//删除当前列表
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}

//修改状态
export function updateStatus(id, status) {
    return request.post(`${url}/updateStatus?id=${id}&status=${status}`);
}

//修改状态
export function genCode(id,size) {
    return request.get(`${url}/genCode?id=${id}&size=${size}`);
}

//获取列表数据
export function codeList(params) {
    return request({
        url: `/manage/activat/code/list`,
        method: 'get',
        params: params
    })
}