import request from '@/utils/request.js';

const url = "/manage/system/dept";

//获取列表数据
export function listDept(query) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: query
    })
}

//获取部门集合
export function list(param) {
    return request.get(`${url}/list`, param);
}

//获取列表数据
export function treeDept() {
    return request({
        url: `${url}/treeDept`,
        method: 'get'
    })
}

//通过ID获取
export function getDept(id) {
    return request.get(`${url}/getDept/${id}`);
}

//删除
export function deleteDept(id) {
    return request.get(`${url}/delete/${id}`);
}

//保存
export function saveDept(param) {
    return request.post(`${url}/save`, param);
}

//获取下级部门
export function findChild() {
    return request.get(`${url}/findChild`);
}