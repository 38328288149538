export default {
    namespaced: true,
    state: () => ({
        messageType_13_id: []
    }),
    mutations: {
        SET_MESSAGE_TYPE_13_ID(state, id) {
            if (state.messageType_13_id.indexOf(id) > -1) {
                return
            }
            state.messageType_13_id.push(Number(id))
        },
        CLEAR_MESSAGE_TYPE_13_ID(state, id) {
            const index = state.messageType_13_id.indexOf(id)
            if (index === -1) {
                return
            }
            state.messageType_13_id.splice(index, 1)
        }
    },
    actions: {
        changeMessageType13Id({ commit }, id) {
            commit('SET_MESSAGE_TYPE_13_ID', id)
        },
        clearMessageType13Id({ commit }, id) {
            commit('CLEAR_MESSAGE_TYPE_13_ID', id)
        }
    },
    getters: {
        messageType_13_id: state => state.messageType_13_id
    }
}