import request from '@/utils/request.js';
import id from "element-ui/src/locale/lang/id";

const url = "/manage/crm/trafficBussy";

//获取列表数据
export  function  findTrafficBusinessList(queryObj){
    return request({
        url: `${url}/list`,
        method: 'get',
        params: queryObj
    })
}

//查询话务商
export function findTrafficBusinessById(id){
    return request({
        url: `${url}/findOneById/` + id,
        method: 'get'
    })
}

//新增话务商
export function saveTrafficBusiness(query){
    return request.post(`${url}/save`,query)
}

//删除话务商
export function deleteTrafficBusiness(id) {
    return request.delete(`${url}/delete/` + id);
}

// export function updateTrafficBusiness(id){
//     return request.put(`${url}/update/` + id)
// }


export function updateTrafficBusiness(trafficBusinessById){
    return request({
        url: `${url}/update`,
        method: 'PUT',
        data: trafficBusinessById
    })
}
export function systemConfiguration(dragdata){
    return request({
        url: `${url}/sort`,
        method: 'post',
        data: dragdata
    })
}