import request from '@/utils/request.js';

const url = `/manage/crm/trafficRelation`;

//删除学习顾问
export function selectNewAdviser(accountId) {
    return request.get(`${url}/queryPage?accountId=${accountId}`);
}

export function deleteNewAdviser(ids) {
    return request.post(`${url}/remove`, ids);
}

/**
 * 条件查询
 * @param queryObj
 * @returns {AxiosPromise}
 */
export function selectAdviserForPage(queryObj) {
    return request({
        url: `${url}/queryPage`,
        method: 'get',
        params: queryObj
    })
}

/**
 * 条件查询
 * @param queryObj
 * @returns {AxiosPromise}
 */
export function queryTrafficCallSortPage(queryObj) {
    return request({
        url: `${url}/queryTrafficCallSortPage`,
        method: 'get',
        params: queryObj
    })
}

export function saveNewAdviser(queryObj) {
    return request({
        url: `${url}/save`,
        method: 'post',
        data: queryObj
    })
}

export function queryAllTrafficBusiness() {
    return request({
        url: `/manage/crm/trafficBussy/list`,
        method: 'get',
    })
}

/**
 * 删除绑定手机号
 * @param id
 */
export function deletePhone(id) {
    return request.delete(`${url}/deletePhone/${id}`)
}

/**
 * 查询咨询师绑定手机列表
 * @param params
 */
export function findAccountPhone(params) {
    return request({
        url: `${url}/findAccountPhone`,
        method: 'get',
        params: params
    })
}

/**
 * 添加咨询师手机号
 */
export function savePhone(params) {
    return request.post(`${url}/savePhone`, params)
}