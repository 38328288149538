<template>
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="留资订单" name="first">
            <promote-data></promote-data>
        </el-tab-pane>
        <el-tab-pane label="推广页配置" name="second">
            <promote-page></promote-page>
        </el-tab-pane>
        <el-tab-pane label="基础配置" name="third">
            <promote-config></promote-config>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import promoteConfig from "./promoteConfig";
    import promoteData from "./promoteData";
    import promotePage from "./promotePage";

    export default {
        name: "promote",
        data() {
            return {
                activeName: 'first'
            };
        },
        components: {
            'promote-config': promoteConfig,
            'promote-data': promoteData,
            'promote-page': promotePage
        },
        methods: {
            handleClick() {

            },
        }
    }
</script>

<style scoped>

</style>