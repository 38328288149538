<template>
    <div>
        <el-row>
            <el-col :span="22">
                <div class="mini-title">
                    请勾选需要在学习顾问页面展示的搜索内容，没勾选内容不会在学习顾问页面展现
                </div>

            </el-col>
            <el-col :span="2">
                <div style="text-align: end;">
                    <el-button type="success" icon="el-icon-check" size="mini"
                               @click="handleUpdate('CONSULTANT_SEARCH')">保存
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <table class="itxst" align="center">
                <draggable v-model="search" animation="500" force-fallback="true"
                           filter=".disable"
                           :move="onMove">
                    <tr v-for="o in search" :key="o.value"
                        :class="o.name === 'telephone'?'disable':''">
                        <td width="80px">
                            <template>
                                <el-checkbox size="medium" :disabled="o.name==='telephone'"
                                             v-model="o.status"></el-checkbox>
                            </template>
                        </td>
                        <td width="90%" @click="ischeck(o)">{{ o.label }}</td>
                        <td width="50px">
                            <el-tooltip class="item" effect="dark"
                                        content="按住拖动可改变展示顺序"
                                        placement="top">
                                <i class="el-icon-s-fold"></i>
                            </el-tooltip>
                        </td>
                    </tr>
                </draggable>
            </table>
        </el-row>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import * as searchApi from "@/api/system/searchDeploy";

export default {
    name: "ConsultantSearch",
    components: {draggable},
    data() {
        return {
            search: [
                {label: "手机号", value: "0", sort: "1", status: true}
                , {label: "机会类型", value: "2", sort: "2", status: true}
                , {label: "最后沟通时间", value: "3", sort: "3", status: true}
                , {label: "意向度", value: "4", sort: "4", status: true}
                , {label: "微信添加状态", value: "5", sort: "5", status: true}
                , {label: "领取方式", value: "6", sort: "6", status: true}
                , {label: "成单状态", value: "7", sort: "7", status: true}
                , {label: "回库时间", value: "8", sort: "8", status: true}
                , {label: "项目", value: "9", sort: "9", status: true}
                , {label: "年龄", value: "10", sort: "10", status: true}
                , {label: "来源", value: "11", sort: "11", status: true}
                , {label: "标签", value: "12", sort: "12", status: true}
                , {label: "地域", value: "13", sort: "13", status: true}
                , {label: "备注", value: "14", sort: "14", status: true}
                , {label: "创建时间", value: "15", sort: "15", status: true}
                , {label: "首次拨打时间", value: "16", sort: "16", status: true}
                , {label: "领取时间", value: "17", sort: "17", status: true}
            ],
        }
    },
    mounted() {
        this.list()
    },
    methods: {
        list() {
            searchApi.list({classify: "CONSULTANT_SEARCH"}).then(res => {
                this.search = res.data;
            });
        },
        handleUpdate(name) {
            const loading = this.$loading({
                lock: true,
                text: '保存中....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = []

            let search = this.search
            for (let i in search) {
                search[i].sort = i
                data.push(search[i])
            }
            searchApi.batchUpdate(data).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.list();
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close();
            }).catch(r => {
                loading.close();
            })

        },
        ischeck(o) {
            o.status = o.status ? false : true;
        },
        onMove(e) {
            if (e.relatedContext.element.name == "telephone") {
                return false;
            }
            return true
        },
    }
}
</script>

<style scoped>
/*定义要拖拽元素的样式*/
table.itxst {
    width: 100%;
    color: #333333;
    text-align: center;
    border-collapse: collapse;
}

table.itxst th {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #dedede;
}

table.itxst td {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #ffffff;
}

table.itxst tr {
    cursor: pointer;
}

table.itxst td.move:hover {
    cursor: move;
}

.mini-title {
    font-size: 12px;
    color: #909399;
}

.disable {
    background-color: #d6d6d6;
    color: #909399;
}
</style>