<template>
  <div>
    <el-form :inline="true" :model="enterprise" class="demo-form-inline">
      <el-form-item>
        <el-input v-model="enterprise.tagName" placeholder="请输入标签名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="enterpriseTagList">查询</el-button>
        <el-button type="primary" @click="resetInfo">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="insertTag">新增标签组</el-button>
    <el-button type="text" @click="syncTag = true"> <i class="el-icon-refresh"></i>{{syncJobStatus}}</el-button>
    <el-dialog
        title="提示"
        :visible.sync="syncTag"
        width="30%">
      <span>同步功能非频繁操作项，您确定要进行本次同步操作吗?</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="syncTag = false">取 消</el-button>
    <el-button type="primary" @click="syncGroupTag">确 定</el-button>
  </span>
    </el-dialog>

    <br><br>
<!-- 新增-->
    <el-dialog
        title="新增标签组"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
      <el-form :model="form" :rules="tagRules" >
        <el-form-item label="标签组名" prop="groupName" :label-width="formLabelWidth">
          <el-input v-model="form.groupName" autocomplete="off" placeholder="输入标签组名称" maxlength="15" show-word-limit style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="标签名称" prop="tagName" :label-width="formLabelWidth">
          <el-input v-model="tagName" autocomplete="off" placeholder="输入标签名称" maxlength="15" show-word-limit style="width: 300px"></el-input>
          <el-button @click="addTagNames" type="success" icon="el-icon-check" circle>添加</el-button>
        </el-form-item>
        <div v-if="form.tagNames">
          <el-tag closable @close="removeTagName(item)" v-for="item in form.tagNames" class="el-tag-mar">{{item}}</el-tag>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addTagGroup">确 定</el-button>
  </span>
    </el-dialog>
    <!--列表 -->
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="groupName" label="名称"></el-table-column>
      <el-table-column prop="tags" label="标签">
         <template v-slot="scope">
           <div v-if="scope.row.tags">
             <el-tag v-for="item in scope.row.tags.split(',')" >{{item}}</el-tag>
           </div>
         </template>
      </el-table-column>
      <el-table-column prop="operate" label="操作">
        <template v-slot="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="toUpdateApi(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="tagDeleteApi(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryParams.total">
    </el-pagination>

    <el-dialog
        title="编辑标签组"
        :visible.sync="centerDialogVisibleUpdate"
        width="30%"
        center>
      <el-form :model="form" :rules="tagRules" >
        <el-form-item label="标签组名" prop="groupName" :label-width="formLabelWidth">
          <el-input v-model="form.groupName" autocomplete="off" @input="handleInputChange" placeholder="输入标签组名称" maxlength="15" show-word-limit style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="标签名称" prop="tagName" :label-width="formLabelWidth">
          <el-input v-model="tagName" autocomplete="off" placeholder="输入标签名称" maxlength="15" show-word-limit style="width: 300px"></el-input>
          <el-button @click="addTagNames" type="success" icon="el-icon-check" circle></el-button>
        </el-form-item>
        <div v-if="form.tagNames">
          <el-tag closable @close="removeTagNameByUpdate(item)" v-for="item in form.tagNames" class="el-tag-mar">{{item}}</el-tag>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisibleUpdate = false">取 消</el-button>
    <el-button type="primary" @click="tagUpdateApi">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import * as enterPriseOperate from "@/api/sphere/enterpriseTag";

export default {
  mounted() {
    this.enterpriseTagList();
  },
  name: "EnterpriseTag",
  data(){
    return {
      centerDialogVisibleUpdate : false,
      formLabelWidth :"100px",
      updateTagAndGroup:{
        upRowData:{},
        groupName:"",
        addTags:[],
        removedTags:[]
      },
      isRewiriteEditor: false,
      queryParams:{
        page:0,
        size:10,
        total:0,
      },
      syncTag:false,
      syncJobStatus:"同步",
      enterprise:{
        tagName:""
      },
      tagName:null,
      form:{
        groupName:"",
        tagNames:[]
      },
      updateTag:{
        groupName:"",
        tagNames:[]
      },
      tableData:[],
      centerDialogVisible:false,
      tagRules: {
        groupName: [
          {required: true, message: '请输入标签组名称', trigger: 'blur'}
        ],
        // tagName:[
        //   {required: true, message: '请输入标签名称', trigger: 'blur'}
        // ]
      },
    }
  },
  methods:{
    /**
     * 添加标签
     */
    addTagNames(){
      if (this.tagName === ""){
        this.$message.error("请输入标签名")
      }else{
        this.form.tagNames.push(this.tagName)
        this.tagName = null
      }
    },
    /**
     * 删除标签
     */
    removeTagName(tag){
      const index = this.form.tagNames.indexOf(tag);
      if (index !== -1) {
        this.form.tagNames.splice(index, 1);
      }
    },
    removeTagNameByUpdate(item){
      console.log("16546984646"+item);
      enterPriseOperate.deleteByUpdate(item).then(res =>{
        if (res.success){
          const index = this.form.tagNames.indexOf(item);
          if (index !== -1) {
            this.form.tagNames.splice(index, 1);
          }
          this.$message.success(res.msg)
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //同步功能
    syncGroupTag(){
        enterPriseOperate.getTagList().then(res =>{
          this.syncTag = false
          if (res.success){
            this.queryParams.page = 0
            this.$message.success(res.msg)
            this.enterpriseTagList();
          }else{
            this.$message.error(res.msg)
          }
        })
    },
    //新增
    insertTag(){
      this.centerDialogVisible = true
      this.form.tagNames = []
      this.form.groupName = ""
    },
    //添加组
    addTagGroup(){
       enterPriseOperate.insertTagGroup(this.form).then(res =>{
         if (res.success){
           this.$message.success(res.msg)
           this.centerDialogVisible = false
           this.queryParams.page = 0
           this.enterpriseTagList();
         }else{
           this.$message.error(res.msg)
         }
       })
    },
    //修改
    toUpdateApi(obj){
          this.form.tagNames = []
          this.form.groupName = null
          this.centerDialogVisibleUpdate = true ;
          this.form.tagNames = obj.tags.split(',')
          this.form.groupName = obj.groupName
          this.updateTagAndGroup.upRowData = obj
    },
    //去修改
    tagUpdateApi(){
      if (this.isRewiriteEditor){
        this.updateTagAndGroup.groupName = this.form.groupName
      }
      if (JSON.stringify(this.updateTagAndGroup.upRowData.tagNames)!==JSON.stringify(this.form.tagNames)){
         //是否修改过
        // 有改动，判断是添加还是删除
        let tags = this.updateTagAndGroup.upRowData.tags.split(",");
        this.updateTagAndGroup.removedTags = tags.filter(tag =>!this.form.tagNames.includes(tag));
        this.updateTagAndGroup.addTags = this.form.tagNames.filter(tag =>!tags.includes(tag));
        // 打印添加和删除的标签
        console.log('添加的标签:', this.updateTagAndGroup.addTags);
        console.log('删除的标签:', this.updateTagAndGroup.removedTags);
      }
      console.log(this.updateTagAndGroup)
      enterPriseOperate.tagUpdate(this.updateTagAndGroup).then(res =>{
          if (res.success){
            this.centerDialogVisibleUpdate = false
            this.$message.success(res.msg)
            this.queryParams.page = 0
            this.enterpriseTagList()
          }else{
            this.$message.error(res.msg?res.msg:"修改失败")
          }
      })
      // enterPriseOperate.tagGroutUpdate(this.)
    },
    //删除
    tagDeleteApi(row){
      console.log('tagDeleteApi',row)
        enterPriseOperate.tagDelete(row.id).then(res =>{
           if (res.success){
             this.$message.success(res.msg)
             this.syncTag = false
             this.enterpriseTagList()
           }else{
             this.$message.error(res.msg)
           }
        })
    },
    //查询
    enterpriseTagList(){
      if (this.enterprise.tagName!==""){
        this.queryParams.page = 0
      }
      enterPriseOperate.queryEnterpriseTagList(this.enterprise,this.queryParams).then(res =>{
        this.tableData = res.data.data;
        this.queryParams.total = res.data.total;
        this.queryParams.page = res.data.pages;
        this.queryParams.size = res.data.size;
      })
    },
    //重置
    resetInfo() {
      this.enterprise = {}
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.queryParams.size = val;
      this.queryParams.page = 0;
      this.enterpriseTagList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.enterpriseTagList();
    },
    handleInputChange(){
      this.isRewiriteEditor = true
    }
  }
}
</script>

<style scoped>
.el-tag-mar {
  margin-right: 10px;
}
</style>