<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px" @submit.native.prevent>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="queryParams.subjectName" placeholder="项目名称" clearable size="small" @keyup.enter.native="list" @submit.prevent.native="false"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="list">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-button type="success" icon="el-icon-plus" @click="add(0)" size="mini" round>新增</el-button>

    <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        default-expand-all
        :tree-props="{children: 'children'}">
      <el-table-column
          prop="subjectName"
          label="项目名称"
          sortable>
        <template slot-scope="scope">
          {{ scope.row.subjectName }} <i @click="add(scope.row.id)" class="el-icon-plus"></i>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="status"-->
      <!--          label="状态">-->
      <!--      </el-table-column>-->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="edit(scope.row,scope.row.id)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="remove(scope.row.id)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="formTitle"
               :visible.sync="formVisible"
               width="600px"
               top="20px">
      <el-form
          :model="subjectForm"
          ref="subjectForm"
          :rules="subjectRef"
          label-width="120px"
      >
        <el-form-item label="上级项目" prop="parentId">
          <treeselect v-model="subjectForm.parentId" :options="parentList" :normalizer="normalizer"
                      :defaultExpandLevel="Infinity"
                      placeholder="选择上级项目"/>
        </el-form-item>
        <el-form-item label="项目名称" prop="subjectName">
          <el-input v-model="subjectForm.subjectName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formVisible = false">取消</el-button>
        <el-button type="success" @click="save">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import * as api from '@/api/system/subject'
import Treeselect from "@riophae/vue-treeselect";

export default {
  name: "Subject",
  data() {
    return {
      queryParams: {},
      tableData: [],
      formTitle: "",
      formVisible: false,
      subjectForm: {
        parentId: 0,
        subjectName: ""
      },
      parentList: [],
      subjectRef: {
        parentId: [{required: true, message: '请选择父级项目', trigger: 'change'}],
        subjectName: [{required: true, message: '请输入项目名称', trigger: 'change'}]
      }
    }
  },
  components: {
    Treeselect
  },
  created() {
    this.list()
  },
  methods: {
    list() {
      api.getList(this.queryParams).then(res => {
        this.tableData = res
      })
    },
    add(pid) {
      this.formVisible = true
      this.parentList = []
      api.getList().then(res => {
        this.parentList.push({id: 0, subjectName: '顶级项目', 'children': res})
      })
      this.subjectForm = {}
      this.subjectForm.parentId = pid
    },
    edit(row, id) {
      this.formVisible = true
      this.parentList = []
      api.getList().then(res => {
        this.parentList.push({id: 0, subjectName: '顶级项目', 'children': res})
      })
      this.subjectForm = row
    },
    remove(id) {
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.remove(id).then(res => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.list()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      })
    },
    save() {
      this.$refs['subjectForm'].validate((valid) => {
        if (valid) {
          console.log(this.subjectForm)
          api.save(this.subjectForm).then(res => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '保存成功!'
              });
              this.list()
              this.formVisible = false
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    normalizer(node) {
      if (node.children == null || node.children == 'null') {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.subjectName,
        children: node.children
      };
    },
  }
}
</script>

<style scoped>

</style>