<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="queryParams.name" placeholder="姓名" clearable size="small"/>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="queryParams.phone" placeholder="手机号" clearable size="small"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryParams.status" filterable class="condition-select" placeholder="请选择"
                   size="small">
          <el-option value="" label="全部"></el-option>
          <el-option :value="1" label="启用"></el-option>
          <el-option :value="0" label="禁用"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学习顾问" prop="accountId">
        <el-select v-model="queryParams.accountId" filterable class="condition-select" placeholder="请选择"
                   size="small">
          <el-option value="" label="全部"></el-option>
          <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标识" prop="possId">
        <el-select
            v-model="queryParams.possId"
            filterable
            placeholder="标识"
            clearable
            size="small"
            style="width: 240px"
        >
          <el-option value="" label="全部"></el-option>
          <el-option v-for="poss in possessList" :value="poss.id"
                     :label="poss.name"></el-option>
        </el-select>
      </el-form-item>
        <el-form-item label="线索池" prop="pondId">
            <el-select
                    v-model="queryParams.pondId"
                    filterable
                    placeholder="线索池"
                    clearable
                    size="small"
                    style="width: 240px"
            >
                <el-option value="" label="全部"></el-option>
                <el-option v-for="pond in pondList" :value="pond.id"
                           :label="pond.name"></el-option>
            </el-select>
        </el-form-item>
      <el-form-item label="部门" prop="deptId">
        <el-select
            v-model="queryParams.deptId"
            filterable
            placeholder="归属部门"
            clearable
            size="small"
            style="width: 240px"
        >
          <el-option v-for="dept in deptList" :value="dept.id"
                     :label="dept.title"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分配时间" prop="dealTimeArr" size="mini">
          <el-date-picker
                  v-model="dealTimeArr"
                  type="datetimerange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd">
          </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="salesList">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button type="success" icon="el-icon-plus" size="mini" @click="addRoles" round>新增</el-button>
    <el-button type="primary" icon="el-icon-s-tools" size="mini" @click="partManage" round>分配配置</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="name" label="学习顾问姓名"></el-table-column>
      <el-table-column prop="deptName" label="所属小组"></el-table-column>
      <el-table-column prop="possId" label="数据唯一标识" width="180">
        <template slot-scope="scope">
          <span v-for="item in possessList" :key="item.id" v-if="item.id === scope.row.possId">{{ item.name }}</span>
        </template>
      </el-table-column>
        <el-table-column align="center" label="线索池" prop="pondId">
            <template v-slot="scope">
                <el-tag :type="typeOptions[item.id % 5]" v-for="item in pondList" :key="item.id" v-if="scope.row.pondId === item.id">
                    {{item.name}}
                </el-tag>
            </template>
        </el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column
          prop="accountId"
          label="学习顾问">
        <template slot-scope="scope">
          <span v-for="item in accountList" :key="item.id" v-if="scope.row.accountId === item.id">{{
              item.nickName
            }}</span>
          <!--          <el-select v-model="scope.row.accountId" disabled filterable class="condition-select"-->
          <!--                     placeholder="请选择">-->
          <!--            <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">-->
          <!--            </el-option>-->
          <!--          </el-select>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="partId"
          label="分配配置">
        <template slot-scope="scope">
                    <span v-for="item in partList" :key="item.id"
                          v-if="item.id == scope.row.partId">{{ item.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="weixin"
          label="微信">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.weixin" placement="left">
            <div slot="content">
              <el-image style="width: 150px;height: 150px" :src="scope.row.weixin"></el-image>
            </div>
            <el-button size="mini">查看配置</el-button>
          </el-tooltip>
          <span v-else>未配置</span>
        </template>
      </el-table-column>
      <el-table-column prop="currentNum" label="今日分配数"></el-table-column>
      <el-table-column prop="sort" label="权重"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-switch @change="changSaleStatus(scope.row.id, scope.row.status)"
                     v-model="scope.row.status"
                     :active-value="1"
                     :inactive-value="0"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
<!--        <el-table-column prop="createTime" label="分配时间"></el-table-column>-->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="edit(scope.row,scope.row.id)"
          >修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page-size="10" :page.sync="queryParams.page"
                @pagination="salesList"
                :limit.sync="queryParams.limit"/>

    <el-dialog
        :title="dialogAdd?'添加微页面':'修改微页面'"
        :visible.sync="dialogFormVisible"
        width="600px"
        @close="resetFrom('roleForm')"
    >
      <el-form
          :model="dialogForm"
          ref="roleForm"
          label-width="120px"
      >
        <el-form-item label="学习顾问姓名">
          <el-input v-model="dialogForm.name"></el-input>
        </el-form-item>
          <el-form-item label="归属线索池：" prop="pondId">
              <el-select size="mini" v-model="dialogForm.pondId" width="100%" filterable>
                  <el-option
                          v-for="item in pondList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
              </el-select>
          </el-form-item>
        <el-form-item label="学习顾问" prop="accountId" size="mini">
          <el-select v-model="dialogForm.accountId" filterable class="condition-select" placeholder="请选择">
            <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="dialogForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="可领取数量">
          <el-select :filterable="true" v-model="dialogForm.partId" placeholder="请选择">
            <el-option
                v-for="item in partList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 50px;" icon="el-icon-edit" @click="partManage"
                     circle></el-button>
        </el-form-item>
        <el-form-item label="微信">
          <el-upload
              class="avatar-uploader"
              action="/api/manage/file/uploadImg"
              :show-file-list="false"
              :headers="headers"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="dialogForm.weixin" :src="dialogForm.weixin" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="唯一标识">
          <el-select v-model="dialogForm.possId" width="100%">
            <el-option
                v-for="item in possessList"
                :key="item.id"
                v-if="item.status === 0"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="dialogForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="success" @click="save('roleForm')">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="分配配置" :visible.sync="partManageVisible" width="80%">
      <div>
        <el-button type="success" icon="el-icon-plus" @click="editPart" round>新增</el-button>
        <el-button @click="partManageVisible = false" round>关闭</el-button>
      </div>
      <el-table :data="partPage" stripe style="width: 100%">
        <el-table-column prop="name" label="配置名称"></el-table-column>
        <el-table-column prop="partNum" label="控制数量"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-switch @change="changPartStatus(scope.row.id, scope.row.status)"
                       v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       active-color="#13ce66"
                       inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="editPart(scope.row,scope.row.id)"
            >修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="partParams.total" :page-size="10" :page.sync="partParams.page"
                  @pagination="partManage"
                  :limit.sync="partParams.limit"/>

    </el-dialog>

    <el-dialog
        title="配置修改"
        :visible.sync="partFormVisible"
        width="600px"
        @close="resetFrom('partForm')"
    >
      <el-form
          :model="partForm"
          ref="partForm"
          label-width="120px"
      >
        <el-form-item label="配置名称">
          <el-input v-model="partForm.name"></el-input>
        </el-form-item>
        <el-form-item label="控制数量">
          <el-input v-model="partForm.partNum" type="number"></el-input>
        </el-form-item>
        <el-form-item label="配置排序">
          <el-input v-model="partForm.sort" type="number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="partFormVisible = false">取消</el-button>
        <el-button type="success" @click="savePart">保存</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import * as api from "@/api/mini/sales";
import * as deptApi from "@/api/system/dept";
import * as possessApi from "@/api/crm/possess";
import * as partApi from "@/api/mini/salesPart";
import {list as listAccount} from '@/api/system/account';
import * as accountApi from "@/api/system/account";
import * as clueApi from "@/api/clue/cluePond";
import {listCluePond} from "@/api/clue/cluePond";

export default {
  name: "sales",
  data() {
    return {
        typeOptions: ['', 'success', 'warning', 'danger', 'info'],
        pondList:[],
        dealTimeArr:[],
        headers:{"version": localStorage.getItem("_version"),},
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        status: 1
      },
      tableData: [],
      dialogFormVisible: false,
      dialogAdd:true,
      dialogTitle: "",
      dialogForm: {
        name: "",
        detail: "",
        status: 1
      },
      dialogForm_null: Object.assign({}, this.dialogForm),
      rowIndex: 9999,
      imageUrl: '',
      adviserList: [],
      possessList: [],
      partList: [],
      partManageVisible: false,
      partPage: [],
      partParams: {
        page: 1,
        limit: 10,
        total: 0
      },
      partFormVisible: false,
      partForm: {},
      deptList: [],
      accountList: [],
        possList: [],
    }
  },
  mounted() {
      this.findPondList()
    this.salesList();
    this.getAdviserList();
    this.findPossessList();
    this.findPart();
    this.findDept();
    this.findAccount()
  },
  methods: {
      findPondList() {
          clueApi.listCluePond({}).then(res => {
              if (res.success) {
                  this.pondList = res.data
              }
            this.pondList.unshift({id:1,name:'基础池'})
          })
      },
    findAccount() {
      listAccount().then(res => {
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    findDept() {
      deptApi.treeDept().then(response => {
        let res = response.data
        this.getChild(res, 0)
      });
    },
    getChild(r, i) {
      let str = ""
      for (let j = 0; j < i; j++) {
        str += '——'
      }
      for (let c in r) {
        // console.log(str + r[c].title, r[c].id)
        this.deptList.push({"title": str + r[c].title, "id": r[c].id})
        if (r[c].children) {
          this.getChild(r[c].children, i + 1)
        }
      }
    },
    editPart(row) {
      if (row.id) {
        this.partForm = row
      } else {
        this.partForm = {}
      }
      this.partFormVisible = true;
    },
    savePart() {
      let status = this.partForm.status ? 1 : 0
      this.partForm.status = status
      partApi.save(this.partForm).then(res => {
        if (res.success) {
          this.$message({
            type: "success",
            message: this.partForm.id ? "修改成功！" : "新增成功！"
          });
          this.partManage()
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
        this.partFormVisible = false;
        this.findPart()
      })
    },
    changPartStatus(id, status) {
      status = status ? 1 : 0
      partApi.updatePartStatus(id, status).then(res => {
        this.findPart()
      })
    },
    partManage() {
      this.partManageVisible = true
      partApi.getPage(this.partParams).then(res => {
        this.partParams.total = res.data.count
        this.partPage = res.data.data
      })
    },
    findPart() {
      partApi.getList().then(res => {
        this.partList = res.data
      });
    },
    changSaleStatus(id, status) {
      status = status ? 1 : 0
      api.updateSaleStatus(id, status).then(res => {
        this.findPart()
      })
    },
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams = {};
      this.dealTimeArr = [];
      this.salesList();
    },
    findPossessList() {
      possessApi.possessAll().then(res => {
          this.possessList = res.data
      })
    },
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.$set(this.dialogForm, 'weixin', res.msg)
      // this.dialogForm.weixin = res.msg;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    /*
    * 获取列表数据
    * */
    salesList() {
      var startTime = this.dealTimeArr[0];
      var endTime = this.dealTimeArr[1];
      if (startTime &&  endTime){
          this.queryParams.startTime = this.dealTimeArr[0];
          this.queryParams.endTime = this.dealTimeArr[1];
      }
      console.log("this.queryParams",this.queryParams)
      api.getList(this.queryParams).then(res => {
        if (res.data.total){
            this.tableData = res.data.data
            this.total = res.data.count
        }else{
            this.tableData = []
            this.total = 0
        }
      });
    },
    //新增
    addRoles() {
      this.dialogAdd = true
      this.dialogForm = Object.assign({}, this.dialogForm_null);
      this.dialogFormVisible = true;
    },
    //重置表单规则
    resetFrom(roleForm) {
      this.$refs[roleForm].clearValidate();
    },
    handleButton(val) {
      //调用事件
      this[val.methods](val.row, val.index);
    },
    edit(row, index) {
      console.log(row);
      this.dialogAdd = false
      this.dialogForm = Object.assign({}, row);
      this.dialogFormVisible = true;
      this.rowIndex = index;
    },

    /*
    * 新增/修改 保存列表数据
    * */
    save(roleForm) {
      // console.log(this.dialogForm); //打印值到底存上了没有
      this.$refs[roleForm].validate(valid => {
        if (valid) {
          let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
          api.save(this.dialogForm).then(res => {
            console.log(res, 'ressss')
            this.salesList()
            this.$message({
              type: "success",
              message: id ? "修改成功！" : "新增成功！"
            });
            this.dialogFormVisible = false;
          });

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /*
    * 删除
    * */
    deleteCurrent(id) {
      api.deleteCurrent(id).then(res => {
        console.log(res)
        this.pagedomainList()
        this.$message({
          type: "success",
          message: "删除成功"
        });
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
