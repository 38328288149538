import request from '@/utils/request.js';

const url = "/manage/crm/mediatorCut";

//获取列表数据
export function listMediator(query) {
    return request({
        url: `${url}/mediatorListJson`,
        method: 'get',
        params: query
    })
}

//保存渠道
export function saveMediator(param) {
    return request.post(`${url}/save`, param);
}

//修改
export function updateMediator(param) {
    return request.post(`${url}/update`, param);
}

//删除
export function deleteMediator(id) {
    return request.get(`${url}/delete/${id}`);
}

//根据ID获取
export function getMediatorById(id) {
    return request.get(`${url}/getMediatorById/${id}`);
}