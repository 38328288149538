<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
            <el-form-item label="内容" prop="message">
                <el-input v-model="queryParams.message" placeholder="内容" clearable size="small"/>
            </el-form-item>
            <el-form-item label="渠道" prop="mediatorId">
                <el-select
                        v-model="queryParams.mediatorId"
                        filterable
                        placeholder="渠道"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="mediator in mediatorList" :value="mediator.id"
                               :label="mediator.mediatorName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标识" prop="possId">
                <el-select
                        v-model="queryParams.possId"
                        filterable
                        placeholder="标识"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="poss in possList" :value="poss.id"
                               :label="poss.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="页面" prop="fromId">
                <el-select
                        v-model="queryParams.fromId"
                        filterable
                        placeholder="页面"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="domain in domainList" :value="domain.id"
                               :label="domain.pageName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="线索时间">
                <el-date-picker
                        v-model="createTimeRange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                <el-button type="primary" icon="el-icon-download" size="mini" @click="exportLeavePhone">导出</el-button>
            </el-form-item>
        </el-form>
        <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeacher"
        >新增
        </el-button>
        <el-table
                :data="tableData"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="mediatorName"
                    label="渠道">
            </el-table-column>
            <el-table-column
                    prop="possName"
                    label="标识">
            </el-table-column>
            <el-table-column
                    prop="domainName"
                    label="来源页面">
            </el-table-column>
            <el-table-column
                    prop="domainUrl"
                    label="页面网址">
                <template slot-scope="scope">
                    <el-link :underline="false">{{scope.row.domainUrl}}{{scope.row.params}}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                    prop="storage"
                    label="状态">
            </el-table-column>
            <el-table-column
                    prop="telephone"
                    label="手机号">
            </el-table-column>
            <el-table-column
                    prop="accountId"
                    label="学习顾问ID">
            </el-table-column>
            <el-table-column
                    prop="accountName"
                    label="学习顾问">
            </el-table-column>
            <el-table-column
                    prop="telephone"
                    label="手机号">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    label="时间">
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">

                </template>
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page.sync="queryParams.page"
                    :limit.sync="queryParams.limit" @pagination="salesList"/>

    </div>

</template>

<script>
    import * as api from "@/api/applets/appletsTeacher";
    import * as domainApi from "@/api/mini/pagedomain";
    import * as apiUtils from "../../utils/api";
    import axios from "axios";
    import * as accountApi from "@/api/system/account";

    export default {
        name: "appletsTeacher",
        data() {
            return {
                // 总条数
                total: 0,
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10
                },
                tableData: [],
                dialogFormVisible: false,
                dialogTitle: "",
                dialogForm: {
                    name: "",
                    detail: ""
                },
                dialogForm_null: Object.assign({}, this.dialogForm),
                rowIndex: 9999,
                imageUrl: '',
                adviserList: [],
                domainList: [],
                mediatorList: [],
                possList: [],
                createTimeRange: null,
            }
        },
        mounted() {
            // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
            this.salesList();
            this.getAdviserList();
            this.getDomainList();
            this.getPossList();
        },
        methods: {
            keyboard(e) {
                if (e.keyCode === 13) {
                    this.handleQuery()
                }
            },
            addTeacher(){

            },
            exportLeavePhone() {
                console.log(111)
                if(this.createTimeRange){
                    this.queryParams['startTime'] = this.createTimeRange[0]
                    this.queryParams['endTime'] = this.createTimeRange[1]
                }
                axios({
                    method: "post",
                    url: "/api/manage/leave/phone/exportLeavePhone",
                    data: JSON.stringify(this.queryParams),
                    responseType: "blob",
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                        "version": localStorage.getItem("_version"),
                    },
                }).then(res => {
                    const blob = new Blob([res.data]);
                    const fileName = '线索信息.xlsx';
                    const elink = document.createElement('a')
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                })
            },
            getDomainList() {
                domainApi.getList().then(res => {
                    console.log(res, 'ressss')
                    this.domainList = res.data
                });
            },
            getPossList() {
                apiUtils.possessList().then(res => {
                    this.possList = res.data
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.salesList();
            },
            /** 重置按钮操作 */
            resetQuery() {
                this.createTimeRange = null
                this.resetForm("queryForm");
                this.queryParams = {};
                this.handleQuery();
            },
            getAdviserList: function () {
              accountApi.adviserList().then(res => {
                if (res.success) {
                  this.adviserList = res.data
                }
              })
            },
            /*
            * 获取列表数据
            * */
            salesList() {
                console.log(this.createTimeRange)
                if(this.createTimeRange){
                    this.queryParams['startTime'] = this.createTimeRange[0]
                    this.queryParams['endTime'] = this.createTimeRange[1]
                }
                api.getList(this.queryParams).then(res => {
                    console.log(res, 'ressss')
                    this.tableData = res.data.data
                    this.total = res.data.count
                });
            },
            //新增
            addRoles() {
                this.dialogTitle = "添加微页面";
                this.dialogForm = Object.assign({}, this.dialogForm_null);
                this.dialogFormVisible = true;
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            edit(row, index) {
                console.log(row);
                this.dialogTitle = "修改微页面";
                this.dialogForm = Object.assign({}, row);
                this.dialogFormVisible = true;
                this.rowIndex = index;
            },

            /*
            * 新增/修改 保存列表数据
            * */
            save(roleForm) {
                console.log(this.dialogForm); //打印值到底存上了没有
                this.$refs[roleForm].validate(valid => {
                    if (valid) {
                        let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                        api.save(this.dialogForm).then(res => {
                            console.log(res, 'ressss')
                            this.salesList()
                            this.$message({
                                type: "success",
                                message: id ? "修改成功！" : "新增成功！"
                            });
                            this.dialogFormVisible = false;
                        });

                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                api.deleteCurrent(id).then(res => {
                    console.log(res)
                    this.pagedomainList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            }
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
