import request from '@/utils/request.js';


const url = "/manage/crm/backdep";

//获取列表数据
export function listBack(query) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: query
    })
}

/**
 * 停止回库操作
 * @param query
 * @returns {AxiosPromise}
 */
export function stopBack(query) {
    return request({
        url: `${url}/stopBack`,
        method: 'get',
        params: query
    })
}


//删除
export function delBack(id) {
    return request.delete(`${url}/${id}`);
}

/**
 * 获取机会回库任务
 * @param opporId
 */
export function getByOpporId(opporId) {
    return request({
        url: `${url}/getByOpporId`,
        method: 'get',
        params: {'opporId': opporId}
    })
}

/**
 * 查询延迟任务
 * @param params
 * @returns {*}
 */
export function findTask(params) {
    return request({
        url: `${url}/findTask`,
        method: 'get',
        params: params
    })
}

/**
 * 保存任务
 * @param params
 */
export function saveTask(params){
    return request.post(`${url}/saveTask`, params);
}

/**
 * 停止任务
 * @param taskId
 */
export function stopTask(taskId){
    return request.put(`${url}/stopTask/${taskId}`);
}