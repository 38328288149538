import request from '@/utils/request.js';

const url = "/manage/applets/teacher";

//添加列表数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: params
    })
}

//删除当前列表
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}