<template>
    <div>

        <el-button type="primary" @click="createGoods(null, 1)">自创建</el-button>
        <el-button type="primary" @click="findXiaoe">小鹅通创建</el-button>
        <el-button type="primary" @click="findSg">三格创建</el-button>


        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="来源渠道" prop="source">
                <el-select v-model="queryParams.source" size="mini" placeholder="请选择来源渠道">
                    <el-option v-for="(item, index) in sourceArr" :key="index" :value="item.value"
                               :label="item.label"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="商品名称" prop="name">
                <el-input v-model.trim="queryParams.name" placeholder="请输入商品名称" clearable size="mini"/>
            </el-form-item>

            <el-form-item label="外部商品名称" prop="otherGoodsName" label-width="100px">
                <el-input v-model.trim="queryParams.otherGoodsName" placeholder="请输入外部商品名称" clearable
                          size="mini"/>
            </el-form-item>

            <el-form-item label="价格(元)" prop="priceArr" label-width="100">
                <el-input style="width: 100px" placeholder="最低价" v-model="queryParams.lowPrice" clearable
                          size="mini"></el-input>
                -----
                <el-input style="width: 100px" placeholder="最高价" v-model="queryParams.heightPrice" clearable
                          size="mini"></el-input>
            </el-form-item>

            <el-form-item label="品类" prop="goodsType">
                <el-select v-model="queryParams.goodsType" size="mini" clearable>
                    <el-option v-for="item in goodsTypeArr" :key="item.value" :value="item.value"
                               :label="item.label"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="项目/科目" prop="subjectIds" label-width="100px">
                <el-cascader
                        size="mini"
                        v-model="subjectIdArr"
                        :options="subjectArr"
                        :props="props"/>
            </el-form-item>

            <el-form-item label="启用/禁用" prop="status" label-width="100px">
                <el-select v-model="queryParams.status" size="mini" clearable>
                    <el-option value="1" label="启用"></el-option>
                    <el-option value="0" label="禁用"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="tableData" style="width: 100%" height="600">
            <el-table-column label="商品ID" prop="id"/>
            <el-table-column label="商品名称" prop="name"/>
            <el-table-column label="补录商品名称" prop="repairName"/>
            <el-table-column label="商品图片" prop="cover">
                <template v-slot="scope">
                    <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.cover ? scope.row.cover : noImgUrl"
                            fit="cover"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="来源渠道" prop="source">
                <template v-slot="scope">
                    {{ sourceLabel(scope.row.source) }}
                </template>
            </el-table-column>
            <el-table-column label="外部商品ID" prop="otherGoodsId"/>
            <el-table-column label="外部商品名称" prop="otherGoodsName" width="110"/>
            <el-table-column label="外部商品价格" prop="otherGoodsPrice" width="110">
                <template v-slot="scope">
                    {{ scope.row.otherGoodsPrice ? (scope.row.otherGoodsPrice / 100).toFixed(2) : '' }}
                </template>
            </el-table-column>
            <el-table-column label="价格" prop="price">
                <template v-slot="scope">
                    {{ (scope.row.price / 100).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column label="内容类型" prop="contentType">
                <template v-slot="scope">
                    {{ contentTypeLabel(scope.row.contentType) }}
                </template>
            </el-table-column>
            <el-table-column label="品类" prop="goodsType">
                <template v-slot="scope">
                    {{ goodsTypeLabel(scope.row.goodsType) }}
                </template>
            </el-table-column>
          <el-table-column label="班型" prop="pondId">
            <template v-slot="scope">
              <span v-for="item in pondList" :key="item.id" v-if="scope.row.pondId === item.id">{{item.name}}</span>
            </template>
          </el-table-column>
            <el-table-column label="科目/项目" prop="subjectId">
                <template v-slot="scope">
                    {{ subjectLabel(scope.row.subjectId) }}
                </template>
            </el-table-column>
            <el-table-column label="创建人" prop="createByName">
            </el-table-column>
            <el-table-column label="禁用/启用" prop="status">
                <template v-slot="scope">
                    <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-value="1"
                            :inactive-value="0"
                            @change="changeGoodsStatus(scope.row.id)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)">编辑
                    </el-button>
                    <el-popconfirm
                            confirm-button-text='好的'
                            cancel-button-text='不用了'
                            icon="el-icon-info"
                            icon-color="orange"
                            title="确认删除该商品吗？"
                            @confirm="handleDelete(scope.row)">
                        <el-button size="mini" type="danger" slot="reference">删除
                        </el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                background
                :total="queryParams.total"
                :page.sync="queryParams.current"
                :limit.sync="queryParams.size"
                @pagination="getList">
        </pagination>

        <el-dialog width="80%" top="10px" :visible.sync="dialog.xiaoeVisible" title="小鹅通商品列表" append-to-body>
            <el-form :model="xiaoeParams" ref="xiaoeQueryForm" :inline="true" label-width="100px">
                <el-form-item label="商品名称" prop="goods_name">
                    <el-input v-model="xiaoeParams.goods_name" placeholder="商品名称" clearable size="small"/>
                </el-form-item>
                <el-form-item label="商品类型" prop="resource_type">
                    <el-select v-model="xiaoeParams.resource_type" size="small">
                        <el-option v-for="item in xiaoeTypeArr" :key="item.value" :value="item.value"
                                   :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="sale_status">
                    <el-select v-model="xiaoeParams.sale_status" size="small">
                        <el-option value=""></el-option>
                        <el-option :value="1" label="上架"></el-option>
                        <el-option :value="0" label="下架"></el-option>
                        <el-option :value="2" label="待上架"></el-option>
                        <el-option :value="3" label="上架+待上架"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="公开" prop="is_return_public">
                    <el-select v-model="xiaoeParams.is_return_public" size="small">
                        <el-option value=""></el-option>
                        <el-option :value="1" label="公开"></el-option>
                        <el-option :value="0" label="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="findXiaoe(1)">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="xiaoeList" height="500px" stripe style="width: 100%">
                <el-table-column width="130px" prop="goods_name" label="商品名称">
                    <template slot-scope="scope">
                        <el-image class="goods-img" :src="scope.row.goods_img[0]"></el-image>
                        <span>{{ scope.row.goods_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="resource_id" align="center" label="商品ID"></el-table-column>
                <el-table-column prop="sale_status" align="center" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sale_status === 0">下架</span>
                        <span v-else-if="scope.row.sale_status === 1">上架</span>
                        <span v-else-if="scope.row.sale_status === 2">待上架</span>
                        <span v-else>其他</span>
                    </template>
                </el-table-column>
                <el-table-column prop="price_high" align="center" label="价格">
                    <template slot-scope="scope">
                        {{ scope.row.price_high / 100 }}
                    </template>
                </el-table-column>
                <el-table-column prop="resource_type" align="center" label="商品类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.resource_type === 1">图文</span>
                        <span v-else-if="scope.row.resource_type === 2">音频</span>
                        <span v-else-if="scope.row.resource_type === 3">视频</span>
                        <span v-else-if="scope.row.resource_type === 4">直播</span>
                        <span v-else-if="scope.row.resource_type === 5">会员</span>
                        <span v-else-if="scope.row.resource_type === 6">专栏</span>
                        <span v-else-if="scope.row.resource_type === 7">圈子</span>
                        <span v-else-if="scope.row.resource_type === 8">大专栏</span>
                        <span v-else-if="scope.row.resource_type === 9">活动管理</span>
                        <span v-else-if="scope.row.resource_type === 16">付费打卡</span>
                        <span v-else-if="scope.row.resource_type === 20">电子书</span>
                        <span v-else-if="scope.row.resource_type === 21">实物商品</span>
                        <span v-else-if="scope.row.resource_type === 23">超级会员</span>
                        <span v-else-if="scope.row.resource_type === 25">训练营营期</span>
                        <span v-else-if="scope.row.resource_type === 29">线下课</span>
                        <span v-else-if="scope.row.resource_type === 31">小班课</span>
                        <span v-else-if="scope.row.resource_type === 34">练习</span>
                        <span v-else-if="scope.row.resource_type === 35">班课</span>
                        <span v-else-if="scope.row.resource_type === 37">大班课</span>
                        <span v-else-if="scope.row.resource_type === 41">有价优惠券</span>
                        <span v-else-if="scope.row.resource_type === 42">课时包</span>
                        <span v-else-if="scope.row.resource_type === 45">AI互动课</span>
                        <span v-else-if="scope.row.resource_type === 46">付费问答</span>
                        <span v-else-if="scope.row.resource_type === 47">付费问答-答主</span>
                        <span v-else-if="scope.row.resource_type === 50">训练营pro</span>
                        <span v-else>其他</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="warning" @click="createGoods(scope.row, 2)">由此创建新商品</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :total="xiaoeTotal" :current-page.sync="xiaoeParams.page"
                           @current-change="findXiaoe"/>
        </el-dialog>

        <!--    三格商品列表组件    -->
        <el-dialog width="80%" top="10px" :visible.sync="dialog.sgVisible" title="三格商品列表" append-to-body @open="reset('sgQueryForm')">
            <el-form :model="sgGoodsParams" ref="sgQueryForm" :inline="true" label-width="100px">
                <el-form-item label="商品ID" prop="id">
                    <el-input v-model="sgGoodsParams.id" placeholder="商品ID" clearable size="small"/>
                </el-form-item>
                <el-form-item label="商品名称" prop="name">
                    <el-input v-model="sgGoodsParams.name" placeholder="商品名称" clearable size="small"/>
                </el-form-item>
                <el-form-item label="商品状态" prop="status">
                    <el-select v-model="sgGoodsParams.status" size="mini">
                        <el-option :value="0" label="上架"/>
                        <el-option :value="1" label="下架"/>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="findSg">搜索</el-button>
                    <el-button type="info" icon="el-icon-search" size="mini" @click="reset('sgQueryForm')">重置</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="sgGoodsList" height="500px" stripe style="width: 100%">
                <el-table-column prop="name" align="center" label="商品名称"/>
                <el-table-column width="130px" prop="cover" label="商品封面">
                    <template slot-scope="scope">
                        <el-image class="goods-img" :src="scope.row.cover"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="id" align="center" label="商品ID"/>
                <el-table-column prop="sellPrice" align="center" label="价格">
                    <template slot-scope="scope">
                        {{ (scope.row.sellPrice / 100).toFixed(2) }}
                    </template>
                </el-table-column>
              <el-table-column prop="goodsClassType" align="center" label="班型">
                <template v-slot="scope">
                  <span v-for="item in pondList" :key="item.id" v-if="item.id === scope.row.goodsClassType + 1" >{{item.name}}</span>
                </template>
              </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="warning" @click="createGoods(scope.row, 3)">由此创建新商品</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :total="sgGoodsParams.total" :current-page.sync="sgGoodsParams.pageNum"
                           @current-change="findSg"/>
        </el-dialog>

        <!-- 自创建 & 小鹅通 & 三格 -->

        <el-dialog :title="dialog.title" :visible.sync="dialog.crmFormVisible" destroy-on-close>
            <el-form ref="searchRules" :rules="searchRules" :model="form" label-width="120px">
                <el-form-item label="来源渠道" prop="source">
                    <el-select v-model="form.source" placeholder="请选择来源渠道" disabled>
                        <el-option
                                v-for="item in sourceArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品名称" prop="name">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="补录商品名称" prop="repairName">
                    <el-input v-model="form.repairName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="外部商品ID" prop="otherGoodsId" v-if="form.source !== 1">
                    <el-input v-model="form.otherGoodsId" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="外部商品名称" prop="otherGoodsName" v-if="form.source !== 1">
                    <el-input v-model="form.otherGoodsName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="外部商品价格" prop="otherGoodsPrice" v-if="form.source !== 1">
                    <el-input v-model="(form.otherGoodsPrice / 100).toFixed(2)" autocomplete="off" disabled/>
                </el-form-item>
                <el-form-item label="商品图片" prop="cover">
                    <el-upload
                            class="avatar-uploader"
                            action="/api/manage/file/uploadImg"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="form.cover" :src="form.cover" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"/>
                    </el-upload>
                </el-form-item>
                <el-form-item label="价格(元)" prop="price">
                    <el-input-number
                            v-model="form.price"
                            :precision="2"
                            :step="1"
                            :min="0"
                            :max="9999999"/>
                </el-form-item>
                <el-form-item label="科目/项目" prop="subjectId">
                    <el-cascader
                            placeholder="请选择 科目/项目"
                            v-model="form.subjectId"
                            :options="subjectArr"
                            :props="{ expandTrigger: 'hover' ,children: 'children', value: 'subjectId', label: 'title', emitPath: false}"/>
                </el-form-item>
                <el-form-item label="内容类型" prop="contentType">
                    <el-select v-model="form.contentType" placeholder="请选择内容类型">
                        <el-option v-for="item in contentTypeArr" :key="item.value" :label="item.label"
                                   :value="item.value"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="品类" prop="goodsType">
                    <el-select v-model="form.goodsType" placeholder="请选择品类">
                        <el-option v-for="item in goodsTypeArr" :key="item.value" :label="item.label"
                                   :value="item.value"/>
                    </el-select>
                </el-form-item>
              <el-form-item label="班型" prop="pondId">
                <el-select v-model="form.pondId" placeholder="请选择班型">
                  <el-option v-for="item in pondList" :key="item.id" :label="item.name"
                             :value="item.id"/>
                </el-select>
              </el-form-item>
                <el-form-item label="商品备注说明" prop="content">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="form.content">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog.crmFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import * as xiaoeApi from "@/api/crm/xiaoetong";
import * as api from "@/api/crm/crmGoods";
import {list} from "@/api/system/dictionary";
import noCover from "@/assets/images/noImg.png"
import {handleTree} from "@/utils/utils";
import * as pondApi from "@/api/clue/cluePond";

export default {
    name: "CrmAllGoods",
    data() {
        return {
            headers: {"version": localStorage.getItem("_version"),},
            queryParams: {
                page: 1,
                size: 10,
                current: 1,
                total: 0,
            },
            xiaoeParams: {
                page: 1,
                page_size: 10
            },
            sgGoodsParams: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
                status: null,
                name: null,
                id: null
            },
            searchRules: {
                source: [{required: true, message: '请选择来源渠道', trigger: 'blur'}],
                name: [{required: true, message: '请输入商品名称', trigger: 'blur'}],
              pondId: [{required: true, message: '请输入班型', trigger: 'blur'}],
                repairName: [{required: true, message: '请输入补录商品名称', trigger: 'blur'}],
                // cover: [{required: true, message: '请上传商品图片', trigger: 'blur'}],
                price: [{required: true, message: '请输入价格(元)', trigger: 'blur'}],
                subjectId: [{required: true, message: '请选择科目/项目', trigger: 'blur'}],
                contentType: [{required: true, message: '请选择内容类型', trigger: 'blur'}],
                goodsType: [{required: true, message: '请选择品类', trigger: 'blur'}],
            },
            sourceArr: [{value: 0, label: '全部'}, {value: 1, label: '自创建'}, {value: 2, label: '小鹅通'}, {value: 3, label: '三格'}],
            contentTypeArr: [],
            goodsTypeArr: [],
            xiaoeTypeArr: [{value: 1, label: '图文'},
                {value: 2, label: '音频'},
                {value: 3, label: '视频'},
                {value: 4, label: '直播'},
                {value: 5, label: '会员'},
                {value: 6, label: '专栏'},
                {value: 7, label: '圈子'},
                {value: 8, label: '大专栏'},
                {value: 9, label: '活动管理'},
                {value: 16, label: '付费打卡'},
                {value: 20, label: '电子书'},
                {value: 21, label: '实物商品'},
                {value: 23, label: '超级会员'},
                {value: 25, label: '训练营'},
                {value: 29, label: '线下课'},
                {value: 31, label: '小班课'},
                {value: 34, label: '练习'},
                {value: 35, label: '班课'},
                {value: 37, label: '大班课'},
                {value: 41, label: '有价优惠券'},
                {value: 42, label: '课时包'},
                {value: 45, label: 'AI互动课'},
                {value: 46, label: '付费问答'},
                {value: 47, label: '付费问答-答主'},
                {value: 50, label: '商品（训练营pro）'}],
            subjectArr: [],
            subjectList: [],
            tableData: [],
            dialog: {
                crmFormVisible: false,
                xiaoeVisible: false,
                sgVisible: false,
                title: '出bug啦'
            },
            form: {},
            xiaoeTotal: 0,
            xiaoeList: [],
            sgGoodsList: [],
            noImgUrl: noCover,
            props: {
                expandTrigger: 'hover',
                children: 'children',
                value: 'subjectId',
                label: 'title',
                emitPath: false,
                multiple: true
            },
            subjectIdArr: [],
          pondList:[],
        }
    },
    created() {
        // 初始化页面数据
        this.init();
        this.findPond()
        // 初次加载数据
        this.getList();
    },
    methods: {
      findPond() {
        pondApi.listCluePond().then(res => {
          if (res.success) {
            this.pondList = res.data
          }
          this.pondList.unshift({id:1,name:'基础池'})
        })
      },
        /**
         * 修改状态
         *
         * @param id
         */
        changeGoodsStatus(id) {
            api.changeGoodsStatus(id).then(resp => {
                if (resp.success) {
                    this.$message.success(resp.msg)
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        /**
         * 获取对应的科目名称
         *
         * @param subjectId
         * @returns {*|string}
         */
        subjectLabel(subjectId) {
            let subject = this.subjectList.filter(e => e.subjectId === subjectId)[0];
            if (subject) {
                return subject.title;
            } else return ''
        },
        /**
         * 获取对应的商品内容类型名称
         *
         * @param type
         * @returns {*|string}
         */
        contentTypeLabel(type) {
            let contentTypeLabel = this.contentTypeArr.filter(e => e.value === type)[0];
            if (contentTypeLabel) {
                return contentTypeLabel.label;
            } else return ''
        },
        /**
         * 获取相应的商品品类名称
         *
         * @param type
         * @returns {*|string}
         */
        goodsTypeLabel(type) {
            let contentTypeLabel = this.goodsTypeArr.filter(e => e.value === type)[0];
            if (contentTypeLabel) {
                return contentTypeLabel.label;
            } else return ''
        },
        /**
         * 获取来源名称
         *
         * @param source
         * @returns {string}
         */
        sourceLabel(source) {
            switch (source) {
                case 0:
                    return '全部';
                case 1:
                    return '自创建';
                case 2:
                    return '小鹅通';
                default :
                    return '其他';
            }
        },
        /**
         * 初始化的方法
         *
         */
        init() {
            /**
             * 从字典初始化加载商品内容类型数据
             */
            list({dictParent: 17}).then(resp => {
                if (resp.success) {
                    this.contentTypeArr = []
                    let dataArr = resp.data;
                    for (let data of dataArr) {
                        this.contentTypeArr.push({value: parseInt(data.dictValue), label: data.dictLabel})
                    }
                }
            });
            /**
             * 从字典初始化加载商品品类数据
             */
            list({dictParent: 18}).then(resp => {
                if (resp.success) {
                    this.goodsTypeArr = []
                    let dataArr = resp.data;
                    for (let data of dataArr) {
                        this.goodsTypeArr.push({value: parseInt(data.dictValue), label: data.dictLabel})
                    }
                }
            });
            /**
             * 从字典初始化加载商品科目数据
             */
            api.getSubjectList().then(resp => {
                if (resp.success) {
                    this.subjectList = resp.data;
                    let dataArr = resp.data;
                    this.subjectArr = handleTree(dataArr, 'subjectId', 'parentId')
                }
            });
        },
        /**
         * 获取页面数据
         *
         */
        getList() {
            if (this.subjectIdArr) {
                this.queryParams.subjectIds = this.subjectIdArr.join(',')
            }
            api.listPage(this.queryParams).then(resp => {
                if (resp.success) {
                    this.tableData = resp.data.data;
                    this.queryParams.total = resp.data.total;
                }
            })
        },
        /**
         * 提交商品新增或者修改的表单
         */
        submitForm() {
            this.$refs['searchRules'].validate((valid) => {
                if (valid) {
                    this.dialog.crmFormVisible = false;
                    this.form.price = this.form.price * 100;
                    if (this.dialog.type === 1) {
                        api.addGoods(this.form).then(resp => {
                            if (resp.success) {
                                this.$message.success(resp.msg)
                            } else {
                                this.$message.error(resp.msg)
                            }
                        })
                    } else if (this.dialog.type === 2) {
                        api.updateGoods(this.form).then(resp => {
                            if (resp.success) {
                                this.$message.success("修改商品" + this.form.name + "成功!")
                            } else {
                                this.$message.error(resp.msg)
                            }
                        })
                    }
                    this.getList();
                } else {
                    this.$message.error("请正确填写信息!")
                }
            })

        },
        /**
         * 从小鹅通创建商品的入口方法
         *
         * @param data
         * @param type
         */
        createGoods(data, type) {
            this.form = {
                price: 0,
            }
            this.dialog.crmFormVisible = true;
            this.dialog.type = 1

            switch (type) {
                case 1:
                    this.dialog.title = '自创建商品';
                    this.form.source = 1;
                    break;
                case 2:
                    this.dialog.title = '小鹅通创建商品';
                    this.form.source = 2;
                    this.form.otherGoodsId = data.resource_id;
                    this.form.otherGoodsName = data.goods_name;
                    this.form.otherGoodsPrice = data.price_high;
                    this.$set(this.form, 'cover', data.goods_img[0] ? data.goods_img[0] : '')
                    break;
                case 3:
                    this.dialog.title = '三格创建商品';
                    this.form.source = 3;
                    this.form.otherGoodsId = data.id;
                    this.form.pondId = data.goodsClassType + 1;
                    this.form.otherGoodsName = data.name;
                    this.form.otherGoodsPrice = data.sellPrice;
                    this.$set(this.form, 'cover', data.cover ? data.cover : '')
                    break;
            }
        },
        /**
         * 获取小鹅通商品数据
         *
         * @param page
         */
        findXiaoe(page) {
            if (page === 1) {
                this.xiaoeParams['page'] = page
            }
            this.dialog.xiaoeVisible = true
            xiaoeApi.goodsList(this.xiaoeParams).then(res => {
                this.xiaoeList = res.data.list
                this.xiaoeTotal = res.data.total
            })
        },
        findSg() {
            this.dialog.sgVisible = true
            api.finSgGoodsPage(this.sgGoodsParams).then(res => {
                if (res.success) {
                    this.sgGoodsList = res.data.data;
                    this.sgGoodsParams.total = res.data.total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        /**
         * 点击编辑的方法
         *
         * @param row
         */
        handleEdit(row) {
            this.form = {};
            api.getOne(row.id).then(resp => {
                if (resp.success) {
                    let data = resp.data
                    data.price = data.price / 100
                    this.form = data
                }
            })
            this.dialog.crmFormVisible = true;
            this.dialog.type = 2;
            this.dialog.title = '编辑商品'
        },
        /**
         * 删除的方法
         *
         * @param row
         */
        handleDelete(row) {
            api.deleteGoods(row.id).then(resp => {
                if (resp.success) {
                    this.$message.success(resp.msg)
                } else {
                    this.$message.error(resp.msg)
                }
            })
            this.getList();
        },
        /**
         * 图片上传成功的回调
         *
         * @param res
         * @param file
         */
        handleAvatarSuccess(res, file) {
            this.$set(this.form, 'cover', res.msg);
        },
        /**
         * 校验上传的图片
         * @param file
         * @returns {boolean}
         */
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.queryParams.lowPrice = null;
            this.queryParams.heightPrice = null;
            this.subjectIdArr = [];
            this.handleQuery();
        },
        reset(value) {
            this.resetForm(value);
            this.findSg();
        }
    }
}
</script>

<style scoped>

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.el-button {
    margin: 3px;
}
</style>