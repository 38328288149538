<template>
    <div>
        messageConfig{{data}}
    </div>
</template>

<script>
export default {
    name: "MessageConfig",
    data() {
        return {
            data: '还在写'
        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>