import request from '@/utils/request.js';

const url = "/manage/system/receive-btn";

/**
 * 分页获取列表数据
 * @param query
 */
export function listPage(query) {
    return request({
        url: `${url}/listPage`, method: 'get', params: query
    })
}

/**
 * 获取列表数据
 * @param query
 */
export function list(query) {
    return request({
        url: `${url}/list`, method: 'get', params: query
    })
}

/**
 * 获取详情
 * @param id
 */
export function infoReceive(id) {
    return request.get(`${url}/info/${id}`);
}

/**
 * 保存/修改
 * @param params
 */
export function saveReceive(params) {
    return request.post(`${url}/save`, JSON.stringify(params))
}

/**
 * 启用
 */
export function enableReceive(id) {
    return request.put(`${url}/enable/${id}`)
}

/**
 * 禁用
 * @param id
 */
export function disableReceive(id) {
    return request.put(`${url}/disable/${id}`)
}

/**
 * 删除
 * @param id
 */
export function removeReceive(id) {
    return request.delete(`${url}/${id}`)
}

/**
 * 查询我的按钮
 */
export function my() {
    return request.get(`${url}/my`)
}

/**
 * 查询机会：走缓存
 */
export function findOppor(id, query) {
    query['id'] = id
    return request({
        url: `${url}/findOppor`, method: 'get', params: query
    })
}

/**
 * 根据成单时间查询机会：不走缓存
 */
export function findOpporByTime(id, params = {}) {
    return request.post(`${url}/findOpporByTime/${id}`, JSON.stringify(params))
}

/**
 * 查询列表参数
 */
export function findOptions(code) {
    return request.get(`${url}/findOptions/${code}`)
}

/**
 * 列表参数原值
 * @param code
 */
export function findOptionCode(code) {
    return request.get(`${url}/findOptionCode/${code}`)
}

/**
 * 领取按钮领取机会
 * @param btnId
 * @param opporId
 * @returns {Promise<AxiosResponse<any>>}
 */
export function receiveOppor(btnId, opporId) {
    return request.put(`${url}/recevieOppor/${btnId}/${opporId}`)
}

/**
 * 查看已领取数量
 */
export function getReceiveSize(btnId) {
    return request.get(`${url}/getReceiveSize/${btnId}`)
}

/**
 * 查询该按钮领取数据
 * @param receiveBtnId
 * @param startDate
 * @param endDate
 */
export function getReceiveData(receiveBtnId, startDate, endDate) {
    return request({
        method: 'get',
        url: `${url}/receive-opppr-log/query`,
        params: {
            btnId: receiveBtnId,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function exportData() {
    return request({
        method: 'GET',
        url: `${url}/receive-opppr-log/exportData`,
        responseType: "blob",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
        }
    })
}

/**
 * 更新启用验证码状态
 * @param params
 * @returns {*}
 */
export function updateEnableVerify(params) {
    return request({
        method: 'PUT',
        url: `${url}/update-enableVerify`,
        data: params
    })
}

export function getCaptcha() {
    return request({
        method: 'GET',
        url: `${url}/get-captcha`,
        responseType: 'blob'
    })
}

export function verifyCaptcha(value) {
    return request({
        method: 'GET',
        url: `${url}/verify-captcha?input=${value}`,
    })
}

export function queryPageLog(params) {
    return request({
        method: 'GET',
        url: `${url}/receive-log`,
        params: params
    })
}

/**
 * 获取按钮当前领取各个等级数量
 * @param btnId
 */
export function findReceiveNumber(btnId){
    return request.get(`${url}/findReceiveNumber/${btnId}`)
}
