<template>
  <el-dialog v-if="showObj.isShow" :title="title" :visible.sync="showObj.isShow">
    <el-row>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <span>选择顾问机会</span>
          </div>
          <el-checkbox-group v-model="opporCheckboxGroup">
            <el-checkbox-button v-for="oppor in opportunityList" :key="oppor.index" :label="oppor.id">
              {{ oppor.telephone }}
            </el-checkbox-button>
          </el-checkbox-group>
        </el-card>
      </el-col>
      <el-col :span="7" :offset=2>
        <el-card>
          <div slot="header" class="clearfix">
            <span>{{ chooseTile }}</span>
          </div>
          <el-select v-if="type === 1" v-model="chooseObj" filterable class="condition-select" placeholder="请选择">
            <el-option v-for="item in chooseList" :key="item.id" :label="item.nickName" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-if="type === 2" v-model="chooseObj" filterable class="condition-select" placeholder="请选择">
            <el-option v-for="item in chooseList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-card>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
      <el-col :span="3" :offset="5">
        <el-button type="primary" size="mini" @click="rightNow">立即提交</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>

import * as api from "@/utils/api"

export default {
  name: "AllotOrUpdatePossess",
  props: {
    // 机会列表
    opportunityList: {
      type: Array,
      require: true
    },
    type: { //分配或是修改机会的唯一配置 1:分配 2:修改唯一配置
      type: Number,
      require: true
    },
    showObj: {
      type: Object,
      require: true
    },
    chooseList: {
      type: Array,
      require: true
    },
    pondId:{
        type: Number,
        default:1
    }
  },
  data() {
    return {
      dialogTableVisible: this.showObj.isShow,
      chooseObj: ""
    };
  },
  methods: {
    rightNow: function () {
      if (!this.opporCheckboxGroup.length) {
        this.$message.warning("未选择需要操作的机会")
        return
      }
      if (!this.chooseObj) {
        this.$message.warning("未选择需要操作的对象")
        return
      }
      const loading = this.$loading({
        lock: true,//同v-loading的修饰符
        text: '分配中~请稍等...',//加载文案
        backgroundColor: 'rgba(55,55,55,0.4)',//背景色
        spinner: 'el-icon-loading',//加载图标
        target: document.querySelector(".el-tabs__content")//loading需要覆盖的DOM节点，默认为body
      })
      let params = {}
      params["opportunities"] = this.opporCheckboxGroup
      if (this.type === 1) {
        //分配
        params["adviserId"] = this.chooseObj
        params["pondId"] = this.pondId
        api.allot(params).then(res => {
          if (res.success) {
            this.$message({
              showClose: true,
              message: res.msg,
              duration: 6000,
              type: 'success'
            });
            this.$emit("reload")
            this.showObj.isShow = false
            this.chooseObj = ""
          } else {
            this.$message.warning(res.msg)
          }
          loading.close()
        })
        return
      }
      if (this.type === 2) {
        //修改唯一配置
        params["possessId"] = this.chooseObj
        params["pondId"] = this.pondId
        api.setPossess(params).then(res => {
          if (res.success) {
            this.$message.success(res.msg)
            this.$emit("reload")
            this.showObj.isShow = false
            this.chooseObj = ""
          } else {
            this.$message.warning(res.msg)
          }
          loading.close()
        })
      }
    }
  },
  computed: {
    title: function () {
      if (this.type === 1) {
        return "分配顾问机会"
      }
      if (this.type === 2) {
        return "修改机会唯一配置"
      }
    },
    chooseTile: function () {
      if (this.type === 1) {
        return "选择学习顾问"
      }
      if (this.type === 2) {
        return "选择唯一配置"
      }
    },
    opporCheckboxGroup: {
      get() {
        return this.opportunityList.map(res => {
          return {opporId: res.id, preAdviserId: res.kcuserId}
        })
      },
      set(val) {
      }
    }


  },
  mounted() {
  }

}
</script>

<style scoped>

/deep/ .el-checkbox-button {
  margin: 3px;
}

</style>
