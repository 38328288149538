<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
            <el-form-item label="操作描述" prop="action">
                <el-input
                    v-model="queryParams.action"
                    clearable
                    size="small"
                />
            </el-form-item>
            <el-form-item label="操作人" prop="accountId">
                <el-select v-model="queryParams.accountId" filterable class="condition-select" clearable placeholder="请选择"
                           size="small">
                    <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="操作时间" prop="startTime">
                <el-date-picker
                    clearable
                    size="mini"
                    v-model="datetime"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table max-height="660" v-loading="loading" :data="configList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="操作描述" align="center" prop="action"/>
            <!--            <el-table-column label="请求地址" align="center" prop="url"/>-->
            <el-table-column label="请求地址" prop="url" align="center">
                <template slot-scope='scope'>
                    <el-popover
                        placement="top-start"
                        width="400"
                        trigger="hover">
                        <span>{{scope.row.url}}</span>
                        <span slot="reference">{{scope.row.url.substr(0,20) + '...'}}</span>
                    </el-popover>
                </template>
            </el-table-column>
            <!--            <el-table-column show-overflow-tooltip="show-overflow-tooltip" label="查询条件" align="center" prop="params"/>-->
            <el-table-column label="查询条件" prop="params" align="center">
                <template slot-scope='scope'>
                    <el-popover
                        placement="top-start"
                        width="400"
                        trigger="hover">
                        <span>{{scope.row.params}}</span>
                        <span slot="reference">{{scope.row.params.substr(1,20) + '...'}}</span>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="ip" align="center" prop="ip"/>
            <el-table-column label="操作人" align="center" prop="account"/>
            <el-table-column label="操作时间" align="center" prop="createTime"/>
            <el-table-column label="数据是否加密" align="center" prop="encryptStr"/>
        </el-table>


        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.limit"
            @pagination="getList"
        />
    </div>
</template>

<script>
import * as operationApi from "@/api/crm/downloadLog";
import * as accountApi from "@/api/system/account";

export default {
    name: "OperationLog",
    components: {},
    data() {
        return {
            adviserList: [],
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 参数配置表格数据
            configList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10,
                startTime: null,
                endTime: null,
                action: null,
                module: null,
                accountId: null
            },
            datetime: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {},
            dataRange: ""
        };
    },
    created() {
        this.getList();
        this.getAdviserList();
    },
    methods: {
        /**
         * 获取学习顾问列表
         */
        getAdviserList: function () {
            accountApi.adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        getList() {
            if (this.datetime && this.datetime[0] && this.datetime[1]) {
                this.queryParams['startTime'] = this.datetime[0] + " 00:00:00"
                this.queryParams['endTime'] = this.datetime[1] + " 23:59:59"
            } else {
                this.queryParams['startTime'] = null
                this.queryParams['endTime'] = null
            }
            this.loading = true;
            operationApi.listDownloadLog(this.queryParams).then(response => {
                this.configList = response.data.records;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.datetime = []
            this.handleQuery();
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.configId)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        }
    }
};
</script>
