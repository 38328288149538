var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":22}},[_c('div',{staticClass:"mini-title"},[_vm._v(" 请勾选需要在补录订单页面展示的列表内容，没勾选内容不会在补录订单页面展现 ")])]),_c('el-col',{attrs:{"span":2}},[_c('div',{staticStyle:{"text-align":"end"}},[_c('el-button',{attrs:{"type":"success","icon":"el-icon-check","size":"mini"},on:{"click":function($event){return _vm.handleUpdate()}}},[_vm._v("保存 ")])],1)])],1),_c('el-row',[_c('table',{staticClass:"itxst",attrs:{"align":"center"}},[_c('draggable',{attrs:{"animation":"200","force-fallback":"true","filter":".disable","move":_vm.onMove},model:{value:(_vm.shows),callback:function ($$v) {_vm.shows=$$v},expression:"shows"}},_vm._l((_vm.shows),function(s){return _c('tr',{key:s.value},[_c('td',{attrs:{"width":"80px"}},[[_c('el-checkbox',{attrs:{"size":"medium","disabled":s.name==='phone'
                                       || s.name==='goodsIds'|| s.name==='payPrice'
                                       || s.name==='payTime'|| s.name==='payChannel'
                                       || s.name==='orderNo'
                                       || s.name==='orderImgs'
                                       || s.name==='payType'|| s.name==='adviserId'},model:{value:(s.status),callback:function ($$v) {_vm.$set(s, "status", $$v)},expression:"s.status"}},[_vm._v("展示 ")])]],2),_c('td',{attrs:{"width":"80px"}},[[_c('el-checkbox',{attrs:{"size":"medium","disabled":s.name==='phone'
                                       || s.name==='goodsIds'|| s.name==='payPrice'
                                       || s.name==='payTime'|| s.name==='payChannel'
                                       || s.name==='orderNo'
                                       || s.name==='orderImgs'
                                       || s.name==='payType'
                                       || s.name==='adviserId'
                                       || s.name==='joinActivity'
                                       || s.name === 'isMailTxbooks'},model:{value:(s.required),callback:function ($$v) {_vm.$set(s, "required", $$v)},expression:"s.required"}},[_vm._v("必填 ")])]],2),_c('td',{attrs:{"width":"90%"}},[_vm._v(_vm._s(s.label))]),_c('td',{attrs:{"width":"50px"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"按住拖动可改变展示顺序","placement":"top"}},[_c('i',{staticClass:"el-icon-s-fold"})])],1)])}),0)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }