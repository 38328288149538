<template>
    <div>
        <el-row>
            <el-col :span="22">
                <div class="mini-title">
                    咨询师页面展示内容管理
                </div>
            </el-col>
            <el-col :span="2">
                <div style="text-align: end;">
                    <el-button type="success" icon="el-icon-check" size="mini"
                               @click="handleUpdate('DETAIL_INFO')">保存
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-form ref="detailsForm" :model="detailsForm" label-width="80px"
                     @submit.native.prevent>
                <el-form-item v-for="(detailItem,index) in detailInfo"
                              :label="detailItem.label">
                    <el-switch v-model="detailItem.status"></el-switch>
                    <br>
                    <template v-if="detailItem.name === 'communicationProgress'">
                        <el-tag
                                :key="item.tag"
                                v-for="item in communicationProgressTags"
                                closable
                                :disable-transitions="false"
                                @close="handleClose(item)">
                            {{ item.tag }}
                        </el-tag>
                        <el-input
                                class="input-new-tag"
                                v-if="inputVisible"
                                v-model="inputValue"
                                ref="saveTagInput"
                                size="small"
                                @keyup.enter.native="handleInputConfirm"
                                @blur="handleInputConfirm">
                        </el-input>
                        <el-button v-else class="button-new-tag" size="small"
                                   @click="showInput">+
                            添加
                        </el-button>
                    </template>
                    <template v-if="detailItem.name === 'studentInfo'">
                        <table class="itxst" align="center">
                            <draggable v-model="studentInfo" animation="200"
                                       force-fallback="true"
                                       filter=".disable"
                                       :move="onMove">
                                <tr v-for="s in studentInfo" :key="s.value">
                                    <td width="80px">
                                        <template>
                                            <el-checkbox
                                                    :disabled="s.name==='telephone' || s.name === 'weiXin'"
                                                    size="medium"
                                                    v-model="s.status"></el-checkbox>
                                        </template>
                                    </td>
                                    <td width="90%" @click="ischeck(s)">{{ s.label }}</td>
                                    <td width="50px">
                                        <el-tooltip class="item" effect="dark"
                                                    content="按住拖动可改变展示顺序"
                                                    placement="top">
                                            <i class="el-icon-s-fold"></i>
                                        </el-tooltip>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                    </template>
                    <template v-if="detailItem.name === 'tags'">
                        <el-row v-for="(item) in tagsList" :key="item.id">
                            <el-col :span="4">
                                <el-input
                                        class="input-new-tag"
                                        v-model="item.label"
                                        size="small">
                                </el-input>
                            </el-col>
                            <el-col :span="16">
                                <el-tag
                                        v-for="(tag,index) in item.datas"
                                        :key="index"
                                        closable
                                        :disable-transitions="false"
                                        @close="handleCloseCustomer(tag,item.datas)">
                                    {{ tag }}
                                </el-tag>
                                <el-input
                                        class="input-new-tag"
                                        v-if="inputTagVisible[item.id]"
                                        v-model="inputValueOne"
                                        :ref="`saveTagInput${item.id}`"
                                        size="small"
                                        @keyup.enter.native="handleInputConfirmCustomer(item.datas)"
                                        @blur="handleInputConfirmCustomer(item,item.datas)">
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small"
                                           @click="showInputCustomer(item.datas, `saveTagInput${item.id}`, item.id)">+
                                    添加
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                </el-form-item>
            </el-form>
        </el-row>
    </div>
</template>

<script>
import * as searchApi from "@/api/system/searchDeploy";
import draggable from 'vuedraggable'

export default {
    name: "DetailInfo",
    components: {draggable},
    data() {
        return {
            inputVisible: false, ////沟通进度
            inputValue: '', //沟通进度
            studentInfo: [],
            inputTagVisible: {},
            detailsForm: {},
            detailInfo: [],
            communicationProgressTags: [],
            inputValueOne: '', //标签1
            tagsList: [],
        }
    },
    mounted() {
        this.list()
    },
    methods: {
        list() {
            searchApi.list({classify: "DETAIL_INFO"}).then(res => {
                this.detailInfo = res.data
            })
            searchApi.list({classify: "STUDENT_INFO"}).then(res => {
                this.studentInfo = res.data
            })

            searchApi.list({classify: "COMMUNICATION_PROGRESS_TAG"}).then(res => {
                if (res.data) {
                    let tags = res.data[0]
                    this.communicationProgressTagObj = tags
                    this.communicationProgressTags = JSON.parse(tags.data) ? JSON.parse(tags.data) : []
                }
            })
            // 机会详情中学员信息
            searchApi.list({classify: "TAGS_LIST"}).then(res => {
                this.tagsList = res.data
                for (let obj of this.tagsList) {
                    obj['datas'] = JSON.parse(obj['data'])
                }
            })
        },
        handleInputConfirm() {
            if (this.communicationProgressTags.length >= 8) {
                this.$message.warning("沟通进度标签数量不可超过8个")
                this.inputVisible = false;
                return
            }
            if (this.inputValue.length > 4) {
                this.$message.warning("沟通进度标签字数不可超过4个")
                this.inputVisible = false;
                return
            }


            let inputValue = this.inputValue;
            if (inputValue) {
                let obj = {"tag": inputValue}
                this.communicationProgressTags.push(obj);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleCloseCustomer(tag, list) {
            // this.tabListObj[index].splice(this.tabListObj[index].indexOf(tag), 1);
            list.splice(list.indexOf(tag), 1)
            this.$forceUpdate()
        },
        handleInputConfirmCustomer(item, datas) {
            let inputValueCustomer
            if (this.inputValueOne && this.inputValueOne.length > 10) {
                this.$message.warning("标签字体长度不可超过10个")
                this.$set(this.inputTagVisible, item.id, false)
                this.inputValueOne = '';
                return
            }
            inputValueCustomer = this.inputValueOne;
            this.$set(this.inputTagVisible, item.id, false)
            this.inputValueOne = '';

            if (inputValueCustomer) {
                datas.push(inputValueCustomer)
            }
        },
        showInputCustomer(tagsList, ref, index) {
            if (tagsList.length >= 8) {
                this.$message.warning("标签数量不可超过8个")
                return
            }
            this.$set(this.inputTagVisible, index, true)

            this.$nextTick(_ => {
                console.log(this.$refs[ref])
                this.$refs[ref][0].focus();
            });
        },
        handleClose(tag) {
            this.communicationProgressTags.splice(this.communicationProgressTags.indexOf(tag), 1);
        },
        handleUpdate(name) {
            const loading = this.$loading({
                lock: true,
                text: '保存中....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = []

            let studentInfo = this.studentInfo
            for (let i in studentInfo) {
                studentInfo[i].sort = i
                data.push(studentInfo[i])
            }
            //合并机会详情
            data = data.concat(this.detailInfo)

            for (let tags of this.tagsList) {
                console.log(1111, tags)
                tags['data'] = JSON.stringify(tags['datas'])
                data.push(tags)
            }
            this.communicationProgressTagObj['data'] = JSON.stringify(this.communicationProgressTags)
            data.push(this.communicationProgressTagObj)

            searchApi.batchUpdate(data).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.list();
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close();
            }).catch(r => {
                loading.close();
            })

        },
        ischeck(o) {
            o.status = o.status ? false : true;
        },
        onMove(e) {
            if (e.relatedContext.element.name == "telephone") {
                return false;
            }
            return true
        },
    },
}
</script>

<style scoped>
/*定义要拖拽元素的样式*/
table.itxst {
    width: 100%;
    color: #333333;
    text-align: center;
    border-collapse: collapse;
}

table.itxst th {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #dedede;
}

table.itxst td {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #ffffff;
}

table.itxst tr {
    cursor: pointer;
}

table.itxst td.move:hover {
    cursor: move;
}

.mini-title {
    font-size: 12px;
    color: #909399;
}
</style>