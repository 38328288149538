import request from "@/utils/request";
const marketUri = '/manage/crm/configure-market';

export function getList(query) {
    return request({
        method: 'GET',
        url: `${marketUri}/query-market-list`,
        params: query
    })
}

export function getById(id) {
    return request({
        method: 'GET',
        url: `${marketUri}/query-market-one.id/${id}`,
    })
}

export function add(data) {
    return request({
        method: 'POST',
        url: `${marketUri}/add-market`,
        data: data
    })
}

export function update(data) {
    return request({
        method: 'PUT',
        url: `${marketUri}/update-market`,
        data: data
    })
}

export function deleteById(id) {
    return request({
        method: 'DELETE',
        url:`${marketUri}/delete-market?id=${id}`
    })
}