<template>
    <div>
        <el-row>
            <el-col :span="16">
                <div class="mini-title">请添加/勾选需要在学习顾问页面展示的内容
                    <el-link type="danger">注意注意注意!!!: 下单课程在第一位, 下单时间在第二位不可变</el-link>
                </div>
            </el-col>
            <el-col :span="8">
                <div style="text-align: end;">
                    <el-button type="primary" style="margin: 10px 0;" icon="el-icon-plus" size="mini"
                               @click="additionalInformation">添加
                    </el-button>
                    <el-button type="success" icon="el-icon-check" size="mini"
                               @click="handleUpdate('NEW_INFORMATION')">保存
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-table border
                      ref="NEW_INFORMATION"
                      :data="tableData"
                      row-key="id"
                      :row-class-name="tableRowClassName">
                <el-table-column align="center" min-width="120px" label="名称" prop="label"></el-table-column>
                <el-table-column align="center" width="50px" label="查询" prop="find">
                    <template v-slot="scope">
                                <span v-if="scope.row.find">
                                    <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                                </span>
                        <span v-else>
                                    <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="50px" label="列表" prop="array">
                    <template v-slot="scope">
                                <span v-if="scope.row.array">
                                    <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                                </span>
                        <span v-else>
                                    <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="50px" label="详情" prop="detail">
                    <template v-slot="scope">
                                <span v-if="scope.row.detail">
                                    <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                                </span>
                        <span v-else>
                                    <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="100px" label="添加/导入" prop="detail">
                    <template v-slot="scope">
                                <span v-if="scope.row.addition">
                                    <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                                </span>
                        <span v-else>
                                    <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="50px" label="领取条件" prop="receive">
                    <template v-slot="scope">
                                <span v-if="scope.row.receive">
                                    <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                                </span>
                        <span v-else>
                                    <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                                </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="类型" prop="type">
                    <template v-slot="scope">
                               <span v-for="t_list in displayTypeList" v-if="t_list.value === scope.row.type"
                                     :key="t_list.value">
                                  {{ t_list.label }}
                               </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="必填" prop="required">
                    <template v-slot="scope">
                              <span v-if="scope.row.required">
                                必填
                              </span>
                        <span v-else>
                                非必填
                              </span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="状态" prop="status">
                    <template v-slot="scope">
                        <el-switch
                            v-model="scope.row.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="提示信息" prop="tips"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template v-slot="scope">
                        <el-tooltip class="item" effect="dark" content="修改"
                                    placement="top">
                            <i class="el-icon-edit"
                               @click="editDictionary(scope.row)"></i>
                        </el-tooltip>
                        <div class="handle_move"
                             style="display: inline-block;margin-left: 16px;">
                            <el-tooltip class="item" effect="dark"
                                        content="按住拖动可改变展示顺序"
                                        placement="top">
                                <i class="el-icon-s-fold"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>

        <!--添加/修改额外信息配置 start-->
        <el-dialog
                v-if="additionalInformationVisible"
                :title="isAdd ? '修改额外信息配置' : '添加额外信息配置'"
                :visible.sync="additionalInformationVisible"
                width="40%"
                center>
            <div>
                <el-form :model="defaultAdditionalInformation" :rules="defaultAdditionalInformationRules" ref="ruleForm"
                         label-width="100px" class="demo-ruleForm">
                    <el-form-item label="字段名称" prop="label" required>
                        <el-input v-model="defaultAdditionalInformation.label"></el-input>
                    </el-form-item>
                    <el-form-item label="字段值(必须为英文字母)" prop="name" hidden>
                        <el-input v-model="defaultAdditionalInformation.name"></el-input>
                    </el-form-item>
                    <el-form-item label="是否必填" prop="required" required>
                        <el-radio-group v-model="defaultAdditionalInformation.required">
                            <el-radio v-model="defaultAdditionalInformation.required" :label="false">否</el-radio>
                            <el-radio v-model="defaultAdditionalInformation.required" :label="true">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否展示">
                        <el-checkbox v-model="defaultAdditionalInformation.find">查询</el-checkbox>
                        <el-checkbox v-model="defaultAdditionalInformation.array">列表</el-checkbox>
                        <el-checkbox v-model="defaultAdditionalInformation.detail">详情</el-checkbox>
                        <el-checkbox v-model="defaultAdditionalInformation.addition">添加</el-checkbox>
                        <el-checkbox v-model="defaultAdditionalInformation.receive">领取条件</el-checkbox>
                    </el-form-item>
                    <el-form-item label="展示类型" prop="type" required>
                        <el-radio-group v-model="defaultAdditionalInformation.type" @change="changeType">
                            <el-radio v-for="item in displayTypeList" :key="item.value"
                                      :label="item.value">
                                <el-tooltip v-if="item.tips" class="item" effect="dark" :content="item.tips"
                                            placement="top-start">
                                    <span>{{ item.label }}</span>
                                </el-tooltip>
                                <span v-else>{{ item.label }}</span>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="字典类型" prop="dictionaryType" v-if="dictTypeRequired">
                        <!--                        <el-select v-model="defaultAdditionalInformation.dictionaryType" placeholder="请选择字典类型">-->
                        <!--                            <option></option>-->
                        <!--                            <el-option v-for="item in dictionaryList" :key="item.id"-->
                        <!--                                       :label="item.dictName" :value="item.id">-->
                        <!--                            </el-option>-->
                        <!--                        </el-select>-->
                        <table>
                            <draggable v-model="dictionaryTypeList" animation="500" force-fallback="true"
                                       filter=".disable"
                                       :move="onMove">
                                <tr v-for="item in dictionaryTypeList" :key="item.id" style="display: flex">
                                    <td>
                                        <el-tooltip class="item" effect="dark" content="按住拖动可改变展示顺序"
                                                    placement="top">
                                            <i class="el-icon-s-fold dictionaryTypeListItem"></i>
                                        </el-tooltip>
                                    </td>
                                    <td style="flex: 1" draggable="false">
                                        <el-input v-model="item.value" placeholder="请输入内容" size="small"></el-input>
                                    </td>
                                    <td style="margin-right: 10px" draggable="false">
                                        <el-tooltip class="item" effect="dark" content="删除此选项"
                                                    placement="top">
                                            <i class="el-icon-delete" @click="deleteDictionaryItem(item.id)"></i>
                                        </el-tooltip>
                                    </td>
                                </tr>
                            </draggable>
                            <td>
                                <el-button type="primary" icon="el-icon-circle-plus" size="mini"
                                           @click="addDictionaryItem">新增
                                </el-button>
                            </td>
                        </table>
                    </el-form-item>
                    <el-form-item label="显示提示">
                        <el-switch
                                v-model="defaultAdditionalInformation.isTips">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="提示内容" v-if="defaultAdditionalInformation.isTips">
                        <el-input v-model="defaultAdditionalInformation.tips"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="additionalInformationVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
              </span>
        </el-dialog>
        <!--添加额外信息配置 end-->
    </div>
</template>

<script>
import * as searchApi from "@/api/system/searchDeploy";
import draggable from 'vuedraggable'
import * as dictApi from "@/api/system/dictionary";
import Sortable from "sortablejs"

export default {
    name: "NewInformation",
    components: {draggable},
    data() {
        return {
            isAdd: false,
            defaultAdditionalInformation: {
            },
            defaultAdditionalInformationRules: {
                label: [
                    {required: true, message: '请输入字段名称', trigger: 'blur'}
                ],
                /* name: [
                     { required: true, message: '仅限英文字母', trigger: 'blur',pattern:/^[A-Za-z]+$/ }
                 ],*/
                type: [
                    {required: true, message: '请选择展示类型', trigger: 'change'}
                ]
            },
            additionalInformationVisible: false,
            tableData: [],
            dictTypeRequired: false,
            //展示类型
            displayTypeList: [
                {label: "文本", value: "input"},
                {label: "日期", value: "date", tips: "数据格式:2023-12-30"},
                {label: "日期时间", value: "time", tips: "数据格式:2023-12-30 18:30:00"},
                {label: "单选", value: "radio"},
                {label: "多选", value: "checkbox"},
                {label: "字典类型", value: "DICT"},
                {label: "金额", value: "money", tips: "100.50"},
                {label: "整数", value: "number", tips: "15"},
            ],
            dictionaryTypeList: [],
        }
    },
    mounted() {
        this.list()
        this.initSortable()
    },
    watch: {
        additionalInformationVisible(newVal, oldVal) {
            if (newVal === false) {
                this.isAdd = false
                this.dictionaryTypeList = [
                    {value: "", id: 0}
                ]
                this.dictTypeRequired = false
            }
        }
    },
    methods: {
        /**
         * 添加额外信息配置
         * @param formName
         */
        submitForm(formName) {
            let that = this;
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    let t_data = []
                    let newInformation = this.defaultAdditionalInformation;
                    newInformation.classify = "NEW_INFORMATION";
                    newInformation.sort = this.defaultAdditionalInformation.sort || this.tableData.length
                    if (!this.defaultAdditionalInformation.id) {
                        let time = Math.ceil(new Date().getTime() / 1000)
                        newInformation.name = this.intToStr(time)
                    }
                    let data = {}
                    if (this.dictionaryTypeList) {
                        data['dictList'] = this.dictionaryTypeList
                    }
                    newInformation.data = JSON.stringify(data)
                    t_data.push(newInformation)
                    const loading = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    searchApi.batchUpdate(t_data).then(res => {
                        if (res.success) {
                            this.msgSuccess("添加成功");
                            this.list();
                            this.isAdd = false
                        } else {
                            this.$message.warning(res.msg)
                        }
                        loading.close();
                    }).catch(r => {
                        loading.close();
                    })
                    that.additionalInformationVisible = false
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        /**
         * 删除字典项
         * */
        deleteDictionaryItem(id) {
            for (let i = 0; i < this.dictionaryTypeList.length; i++) {
                if (this.dictionaryTypeList[i].id === id) {
                    this.dictionaryTypeList.splice(i, 1);
                }
            }
        },
        /**
         * 新增字典项
         * */
        addDictionaryItem() {
            let length = this.dictionaryTypeList.length
            let item = {value: "", id: length}
            this.dictionaryTypeList.push(item)
        },
        /**
         * 获取字段字段
         */
        dictionaryList() {
            dictApi.list().then((res) => {
                if (res) {
                    this.dictionaryList = res.data
                }
            })
        },
        changeType(row) {
            if (row === 'radio' || row === 'checkbox') {
                this.dictTypeRequired = true
            } else {
                this.dictTypeRequired = false
            }
        },
        list() {
            searchApi.list({classify: "NEW_INFORMATION"}).then(res => {
                if (res.data) {
                    this.tableData = res.data
                    for (let i in this.tableData) {
                        let entity = this.tableData[i]
                        let data = JSON.parse(entity.data)
                        this.tableData[i].tson = data;
                    }
                }
            })
        },
        /**
         * 修改额外信息配置
         * */
        editDictionary(e) {
            this.defaultAdditionalInformation = e
            // this.defaultAdditionalInformation["id"] = e.id
            // this.defaultAdditionalInformation["sort"] = e.sort
            // this.defaultAdditionalInformation["name"] = e.name
            // this.defaultAdditionalInformation["label"] = e.label
            this.isAdd = true;
            this.additionalInformationVisible = true
            this.dictionaryTypeList = e.tson.dictList
            this.changeType(e.type)

        },
        // 目标位置高亮
        // 给目标行 添加高亮样式
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === this.newIndex) {
                return "warning-row";
            }
            return "";
        },
        additionalInformation() {
            this.additionalInformationVisible = true;
            if (!this.isAdd) {
                this.defaultAdditionalInformation = Object.assign({}, undefined);//重置表单
            }
        },
        handleUpdate(name) {
            const loading = this.$loading({
                lock: true,
                text: '保存中....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = []
            //合并额外参数
            for (let i in this.tableData) {
                this.tableData[i].data = JSON.stringify(this.tableData[i].tson)
                data.push(this.tableData[i])
            }
            searchApi.batchUpdate(data).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.list();
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close();
            }).catch(r => {
                loading.close();
            })

        },
        ischeck(o) {
            o.status = o.status ? false : true;
        },
        onMove(e) {
            if (e.relatedContext.element.name == "telephone") {
                return false;
            }
            return true
        },
        initSortable() {
            // 1. 获取拖拽元素的父元素
            // 因为使用的element的table 所以可直接获取tbody
            // console.log(this.$refs[activeName])

            let el = this.$el.querySelector(".el-table__body-wrapper tbody");
            let _this = this
            // 2. 创建并配置相关参数
            let sortable = new Sortable(el, {
                // 此处进行配置 及 回调函数的使用

                // 因为我的需求是只有按住手柄才能进行拖拽，故而设置此参数
                handle: '.handle_move', // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
                animation: 2000,
                // 我的需求是行拖拽，所以该值设置为行class
                draggable: '.el-table__row', // 允许拖拽的项目类名


                // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
                // 拖拽中 回调函数
                onMove(customEvent) {
                    // console.log('onMove', customEvent)
                    // 禁止在拖拽过程中交换位置
                    // 可将初始位置及目标位置进行记录
                    _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
                    _this.newIndex = customEvent.related.rowIndex; // 目标位置
                    return false;  // 不进行交换
                    // 返回值有三个
                    // return false; — for cancel
                    // return -1; — insert before target
                    // return 1; — insert after target
                },

                // 拖拽结束，调整位置
                onEnd() {
                    // 在拖拽结束后，获取初始及目标位置
                    const {newIndex, oldIndex} = _this;
                    const currRow = _this.tableData.splice(oldIndex, 1)[0];
                    _this.tableData.splice(newIndex, 0, currRow);
                    _this.newIndex = null;
                    _this.oldIndex = null;
                    for (let i in _this.tableData) {
                        _this.tableData[i].sort = i
                    }
                },
            })
        },
    },
}
</script>

<style scoped>
.mini-title {
    font-size: 12px;
    color: #909399;
}

.fontSize_20 {
    font-size: 20px;
}

.font_Red {
    color: red;
}

.font_Green {
    color: green;
}
</style>