<template>
    <div>
        <el-row>
            <el-col :span="22">
                <el-table border
                          ref="ORDER_TABLES"
                          :data="tableData"
                          row-key="id"
                          :row-class-name="tableRowClassName">
                    <el-table-column label="名称" prop="label" align="center"></el-table-column>
                    <el-table-column label="列表" prop="query" width="50" align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.array">
                            <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                          </span>
                            <span v-else>
                            <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                          </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="查询" prop="param" width="50" align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.find">
                            <i class="el-icon-circle-check fontSize_20 font_Green"></i>
                          </span>
                            <span v-else>
                            <i class="el-icon-circle-close fontSize_20 font_Red"></i>
                          </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" prop="type" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 'INPUT'">文本</span>
                            <span v-if="scope.row.type === 'SELECT'">枚举</span>
                            <span v-if="scope.row.type === 'DATE'">日期</span>
                            <span v-if="scope.row.type === 'EXTRA'">额外参数</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="label" align="center">
                        <template v-slot="scope">
                            <el-switch
                                    v-model="scope.row.status"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                          <span class="item">
                            <i class="el-icon-edit"
                               @click="editOrder(scope.row)"></i>
                          </span>
                            <div class="handle_move"
                                 style="display: inline-block;margin-left: 16px;">
                                <el-tooltip class="item" effect="dark"
                                            content="按住拖动可改变展示顺序"
                                            placement="top">
                                    <i class="el-icon-s-fold"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row>

        </el-row>
        <!-- 修改订单查询 start -->
        <el-dialog
                title="订单查询配置"
                :visible.sync="orderConfigSync"
                width="40%"
                center>
            <div>
                <el-form :model="orderConfig" :rules="orderConfigRules" ref="orderConfigRef"
                         label-width="100px" class="demo-ruleForm">
                    <el-form-item label="字段名称" prop="label" required>
                        <el-input v-model="orderConfig.label"></el-input>
                    </el-form-item>
                    <el-form-item label="是否展示">
                        <el-checkbox v-model="orderConfig.find" v-if="orderConfig.type === 'EXTRA'">查询</el-checkbox>
                        <el-checkbox v-model="orderConfig.array">列表</el-checkbox>
                    </el-form-item>
                    <el-form-item label="提示内容">
                        <el-input v-model="orderConfig.tips"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="orderConfigSync = false">取 消</el-button>
        <el-button type="primary" @click="saveOrderConfig()">确 定</el-button>
      </span>
        </el-dialog>
        <!-- 修改订单查询 end-->
    </div>
</template>

<script>
import * as searchApi from "@/api/system/searchDeploy";
import Sortable from "sortablejs"

export default {
    name: "OrderTables",
    data() {
        return {
            orderConfigSync: false,
            tableData: [],
            orderConfig: {},
            orderConfigRules: {},
        }
    },
    mounted() {
        this.list()
        this.initSortable()
    },
    methods: {
        list() {
            searchApi.list({classify: "ORDER_TABLES"}).then(res => {
                this.tableData = res.data;
                for (let e of res.data) {
                    let tson = {}
                    if (e.data) {
                        tson = JSON.parse(e.data)
                    }
                    e.tson = tson
                }
            });
        },
        editOrder(row) {
            this.orderConfigSync = true
            this.orderConfig = row
        },
        saveOrderConfig() {
            searchApi.saveDeploy(this.orderConfig).then(res => {
                if (res.success) {
                    this.$message.success("更新成功!")
                    this.orderConfigSync = false
                } else {
                    this.$message.error(res.msg)
                }
            });
        },
        // 目标位置高亮
        // 给目标行 添加高亮样式
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === this.newIndex) {
                return "warning-row";
            }
            return "";
        },
        ischeck(o) {
            o.status = o.status ? false : true;
        },
        onMove(e) {
            if (e.relatedContext.element.name == "telephone") {
                return false;
            }
            return true
        },
        initSortable() {
            // 1. 获取拖拽元素的父元素
            // 因为使用的element的table 所以可直接获取tbody
            // console.log(this.$refs[activeName])

            let el = this.$el.querySelector(".el-table__body-wrapper tbody");
            let _this = this
            // 2. 创建并配置相关参数
            let sortable = new Sortable(el, {
                // 此处进行配置 及 回调函数的使用

                // 因为我的需求是只有按住手柄才能进行拖拽，故而设置此参数
                handle: '.handle_move', // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
                animation: 2000,
                // 我的需求是行拖拽，所以该值设置为行class
                draggable: '.el-table__row', // 允许拖拽的项目类名


                // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
                // 拖拽中 回调函数
                onMove(customEvent) {
                    // console.log('onMove', customEvent)
                    // 禁止在拖拽过程中交换位置
                    // 可将初始位置及目标位置进行记录
                    _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
                    _this.newIndex = customEvent.related.rowIndex; // 目标位置
                    return false;  // 不进行交换
                    // 返回值有三个
                    // return false; — for cancel
                    // return -1; — insert before target
                    // return 1; — insert after target
                },

                // 拖拽结束，调整位置
                onEnd() {
                    // 在拖拽结束后，获取初始及目标位置
                    const {newIndex, oldIndex} = _this;
                    const currRow = _this.tableData.splice(oldIndex, 1)[0];
                    _this.tableData.splice(newIndex, 0, currRow);
                    _this.newIndex = null;
                    _this.oldIndex = null;
                    for (let i in _this.tableData) {
                        _this.tableData[i].sort = i
                    }
                },
            })
        },
    },
}
</script>

<style scoped>
.fontSize_20 {
    font-size: 20px;
}

.font_Red {
    color: red;
}

.font_Green {
    color: green;
}
</style>