<template>
    <div class="app-container">
        <el-tabs type="border-card" @tab-click="changeCard" v-model="tagName">
            <el-tab-pane label="模板管理" name="template">
                <el-row :gutter="10" class="mb8">
                    <el-col :span="1.5">
                        <el-button
                                type="primary"
                                plain
                                icon="el-icon-plus"
                                size="mini"
                                @click="openDialogVisibleSign"
                        >签名列表
                        </el-button>
                        <el-button
                                type="primary"
                                plain
                                icon="el-icon-plus"
                                size="mini"
                                @click="openMsgTemplateDialog"
                        >新增模板
                        </el-button>
                    </el-col>
                </el-row>
                <el-table
                        v-loading="loading"
                        :data="templateTableData">
                    <el-table-column prop="templateCode" label="模板编码"/>
                    <el-table-column prop="orderId" label="工单ID"/>
                    <el-table-column prop="templateName" label="模板名称"/>
                    <el-table-column prop="templateContent" label="模板内容" width="280px"/>
                    <el-table-column prop="templateType" label="模板类型">
                        <template slot-scope="scope">
                            <el-tag type="success">{{ filterOuterTemplateType(scope.row.outerTemplateType) }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createDate" label="创建时间"/>
                    <el-table-column prop="auditStatus" label="审批状态">
                        <template slot-scope="scope">
                            {{ filterAuditStatus(scope.row.auditStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="reason" label="失败原因">
                        <template slot-scope="scope">
                            {{ scope.row.auditStatus !== 'AUDIT_STATE_PASS' ? scope.row.reason.rejectInfo : '无' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="未通过时间" align="center" prop="reason">
                        <template slot-scope="scope">
                            {{ scope.row.auditStatus !== 'AUDIT_STATE_PASS' ? scope.row.reason.rejectDate : '无' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="text"
                                    @click="opnUpdateMsgTemplate(scope.row)"
                                    v-if="scope.row.auditStatus !== 'AUDIT_STATE_PASS' && scope.row.auditStatus !== 'AUDIT_STATE_INIT'"
                            >修改
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    @click="deleteMsgTemplate(scope.row)"
                                    v-if="scope.row.auditStatus !== 'AUDIT_STATE_INIT' "
                            >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>

            <el-tab-pane label="批量发送短信" name="sendBatchMessagePage">
                <div>
                    <el-row>
                        <el-col :span="5">
                            <div class="grid-content bg-purple"><b>选择短信模板：</b></div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple-light">
                                <el-select v-model="selectedTemplateCode" placeholder="选择短信模板">
                                    <el-option
                                            v-for="item in templateList"
                                            :key="item.orderId"
                                            :label="item.templateName"
                                            :value="item.templateCode">
                                    </el-option>
                                </el-select>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <div class="grid-content bg-purple"><b>选择模板：</b></div>
                        </el-col>
                        <el-col :span="8">
                            <div class="grid-content bg-purple">{{ filterSelectedTemplateName }}</div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="2" style="margin-top: 10px">
                        <el-col :span="4">
                            <div class="grid-content bg-purple">
                                <el-button type="primary" @click="openSendMsg">手动发送消息</el-button>
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="grid-content bg-purple">
                                <el-button type="success" @click="openBatchSendMsgDialog">批量发送消息</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-tab-pane>

        </el-tabs>


        <!-- 添加或修改对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="30%" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="签名名称" prop="signName">
                    <el-input v-model="form.signName" placeholder="请输入签名名称"/>
                </el-form-item>
                <el-form-item label="签名来源" prop="signSource">
                    <el-select v-model="form.signSource" placeholder="请选择类型">
                        <el-option label="企事业单位的全称或简称" value="1"/>
                        <el-option label="工信部备案网站的全称或简称" value="2"/>
                        <el-option label="App应用的全称或简称" value="3"/>
                        <el-option label="公众号或小程序的全称或简称" value="4"/>
                        <el-option label="电商平台店铺名的全称或简称" value="5"/>
                        <el-option label="商标名的全称或简称" value="6"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="文件内容" prop="fileContents">
                    <el-input v-model="form.fileContents" controls-position="right"/>
                </el-form-item>
                <el-form-item label="文件后缀" prop="fileSuffix">
                    <el-input v-model="form.fileSuffix" controls-position="right"/>
                </el-form-item>
                <el-form-item label="备注" prop="orderNum">
                    <el-input v-model="form.remark" controls-position="right"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmAddSign">确 定</el-button>
                <el-button @click="open = cancelAddSign">取 消</el-button>
            </div>
        </el-dialog>


        <el-dialog
                title="签名列表"
                :visible.sync="dialogVisibleSign"
                width="80%"
                :close-on-click-modal="closeOnClickModalFalse"
                :show-close="false"
        >
            <el-table
                    v-loading="loading"
                    :data="signTableData">
                <el-table-column prop="orderId" label="工单ID"/>
                <el-table-column prop="signName" label="签名名称"/>
                <el-table-column prop="createDate" label="创建时间"/>
                <el-table-column prop="businessType" label="签名场景"/>
                <el-table-column prop="auditStatus" label="审批状态">
                    <template slot-scope="scope">
                        {{ filterAuditStatus(scope.row.auditStatus) }}
                    </template>
                </el-table-column>
                <el-table-column prop="reason" label="失败原因">
                    <template slot-scope="scope">
                        {{ scope.row.auditStatus !== 'AUDIT_STATE_PASS' ? scope.row.reason.rejectInfo : '无' }}

                    </template>
                </el-table-column>
                <el-table-column label="未通过时间" align="center" prop="reason">
                    <template slot-scope="scope">
                        {{ scope.row.auditStatus !== 'AUDIT_STATE_PASS' ? scope.row.reason.rejectDate : '无' }}
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer"></span>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeDialogVisibleSign">关 闭</el-button>
            </div>
        </el-dialog>

        <!--添加修改短信模版-->
        <el-dialog :close-on-click-modal="closeOnClickModalFalse" :title="msgTitle" :visible.sync="openMsgTemplate"
                   width="60%" append-to-body :destroy-on-close="true">
            <el-form ref="memoryCardRef" :model="msgTemplate" :rules="msgTemplateRules" label-width="120px">
                <el-form-item v-if="msgTemplate.templateCode" label="短信模板code" prop="templateCode">
                    <el-input v-model="msgTemplate.templateCode" placeholder="短信模板CODE" disabled/>
                </el-form-item>
                <el-form-item label="短信类型" prop="templateType">
                    <el-radio-group v-model="msgTemplate.templateType">
                        <el-radio :label="0">验证码</el-radio>
                        <el-radio :label="1">短信通知</el-radio>
                        <el-radio :label="2">推广短信</el-radio>
                        <el-radio :label="3">国际/港澳台消息</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="模板名称" prop="templateName">
                    <el-input v-model="msgTemplate.templateName" placeholder="请输入模板名称"/>
                </el-form-item>
                <el-form-item label="模板内容" prop="templateContent">
                    <el-input v-model="msgTemplate.templateContent" type="textarea" placeholder="请输入模板内容"/>
                </el-form-item>
                <el-form-item label="申请说明" prop="remark">
                    <el-input v-model="msgTemplate.remark" show-word-limit :maxlength="100" type="textarea"
                              placeholder="请输入内容,模板审核的参考信息之一。长度不超过100个字符。 "/>
                </el-form-item>

            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="submitMsgTemplate('memoryCardRef')">确 定</el-button>
                    <el-button @click="cancelMsgTemplate('memoryCardRef')">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <!--添加手机号-->
        <el-dialog
                title="手动发送短信"
                :visible.sync="openSendDialog"
                width="45%"
                :close-on-click-modal="false"
                :show-close="false">
            <el-row style="margin-top: 10px" :gutter="10">
                <el-col :span="10">
                    <el-input type="textarea"
                              placeholder="请输入手机号"
                              v-model="filterSelectedTemplateName"
                              disabled
                              :autosize="{ minRows: 2, maxRows: 4}"/>
                </el-col>
                <el-col :span="4">
                    <el-button type="warning" plain @click="addFromItem">添加</el-button>
                </el-col>
            </el-row>

            <el-row style="margin-top: 10px" :gutter="10" v-for="(item, index) in messageList" :key="index">
                <el-col :span="6">
                    <el-input v-model="item.phone" type="tel" placeholder="请输入手机号"/>
                </el-col>
                <el-col :span="6">
                    <el-select v-model="item.signName" placeholder="选择短信签名">
                        <el-option
                                v-for="op in signList"
                                :key="op.signName"
                                :label="op.signName"
                                :value="op.signName"
                        />
                    </el-select>
                </el-col>

                <el-col :span="12">
                    <el-input v-for="_code in codeSplitArr" :key="_code" v-model="item['group'][_code]"
                              :placeholder="`发送短信参数${_code}`"/>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="closeSendDialog">关 闭</el-button>
        <el-button type="primary" @click="sendMsg">发送</el-button>
        </span>
        </el-dialog>


        <el-dialog
                title="批量发送短信"
                :visible.sync="batchSendMsgDialog"
                width="30%"
                :show-close="false"
                :close-on-click-modal="false">
            <el-row>
                <el-select v-model="batchSendMsgForm.signName" placeholder="选择短信签名">
                    <el-option
                            v-for="(op, index) in signList"
                            :key="index"
                            :label="op.signName"
                            :value="op.signName"/>
                </el-select>
            </el-row>
            <el-divider/>
            <el-row>
                <el-col :span="5">
                    <el-button type="danger" @click="handleExportDemo">
                        下载模板
                    </el-button>
                </el-col>
            </el-row>
            <el-divider/>
            <el-row style="margin-top: 10px" :gutter="2">
                <el-col :span="12">
                    <el-upload
                            ref="upload"
                            class="upload-demo"
                            :action="actionUrl"
                            :headers="headers"
                            :limit="1"
                            :on-exceed="handleExceed"
                            :on-success="handleSuccess"
                            :auto-upload="false"
                            :data="batchSendMsgForm"
                    >
                        <el-row style="color: red;margin: 5px;">请选择对应模版上传</el-row>
                        <template #trigger>
                            <el-button type="primary">选择文件</el-button>
                        </template>
                        <el-divider/>
                        <el-button style="display: inline" type="success" @click="submitUpload">
                            上传文件，发送短信
                        </el-button>
                    </el-upload>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="CloseBatchSendMsgDialog">关 闭</el-button>
        </span>
        </el-dialog>


        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.current"
                :page-sizes="[10, 20, 30, 200]"
                :page-size="queryParams.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="queryParams.total"
                v-if="queryParams.total && this.tagName !== 'sendBatchMessagePage'">
        </el-pagination>
    </div>
</template>

<script>
import * as smsApi from "@/api/system/sms";
import axios from "axios";

export default {
    name: "Sms",
    data() {
        return {
            headers: {"version": localStorage.getItem("_version"),},
            // 遮罩层
            loading: false,
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {},
            // 表单参数
            form: {},
            templateTableData: [],
            signTableData: [],
            tagName: 'template',
            // 表单校验
            rules: {
                id: [{required: true, message: "上级部门不能为空", trigger: "blur"}],
            },
            closeOnClickModalFalse: false,
            openMsgTemplate: false,
            msgTitle: '',
            msgTemplate: {},
            msgTemplateRules: {
                templateType: [
                    {required: true, message: "短信类型不能为空", trigger: "change"}
                ],
                templateName: [
                    {required: true, message: "模板名称不能为空", trigger: "blur"}
                ],
                templateContent: [
                    {required: true, message: "模板内容不能为空", trigger: "blur"}
                ],
                remark: [
                    {required: true, message: "申请说明不能为空", trigger: "blur"}
                ]
            },
            templateList: [],
            signList: [],
            selectedTemplateCode: '',
            openSendDialog: false,
            msgForm: {},
            formListArr: [],
            batchSendMsgDialog: false,
            actionUrl: '/api/manage/system/sms/importData',
            dialogVisibleSign: false,
            batchSendMsgForm: {},
            messageList: []
        };
    },
    computed: {
        /*
        * 将当前模板里面的参数提取出来
        * */
        codeSplitArr() {
            const regex = /(?<=\$\{)(.+?)(?=\})/g;
            let codes = this.filterSelectedTemplateName.match(regex)
            return codes || []
        },
        /**
         * 过滤选中的模版显示
         * @returns {[{trigger: string, message: string, required: boolean}]}
         */
        filterSelectedTemplateName() {
            if (!this.selectedTemplateCode) {
                return ''
            } else {
                return this.templateList.filter(item => item.templateCode === this.selectedTemplateCode)[0].templateContent;
            }
        },
    },
    mounted() {
        this.getList('template');
        this.getInitList();
    },
    methods: {
        openDialogVisibleSign() {
            this.tagName = 'sign'
            this.dialogVisibleSign = true
            this.getList(this.tagName);

        },
        closeDialogVisibleSign() {
            this.tagName = 'template'
            this.dialogVisibleSign = false
            this.getList(this.tagName);
        },
        handleSizeChange(val) {
            this.queryParams.size = val
            this.getList(this.tagName);
        },
        handleCurrentChange(val) {
            this.queryParams.current = val
            this.getList(this.tagName);
        },
        changeCard() {
            this.queryParams.current = 1
            switch (this.tagName) {
                case 'sign':
                    this.getList(this.tagName);
                    break;
                case 'template':
                    this.getList(this.tagName);
                    break;
                case 'sendBatchMessagePage':
                    break;
            }
        },
        /** 查询部门列表 */
        getList(tagName) {
            this.loading = true;
            switch (tagName) {
                case 'sign':
                    smsApi.getSmsSignList(this.queryParams).then(resp => {
                        if (resp.success) {
                            this.signTableData = resp.data.records;
                            this.queryParams.total = resp.data.total
                        }
                    })
                    break;
                case 'template':
                    smsApi.getSmsTemplateList(this.queryParams).then(resp => {
                        if (resp.success) {
                            this.templateTableData = resp.data.records;
                            this.queryParams.total = resp.data.total
                        }
                    })
            }
            this.loading = false;
        },
        // 表单重置
        reset() {
            this.form = {};
            this.resetForm('form');
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList(this.tagName);
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm('queryForm');
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.open = true;
            this.title = '';
        },
        /** 提交按钮 */
        confirmAddSign() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    smsApi.applySmsSign(this.form).then(res => {
                        if (res.success) {
                            this.$message.success("申请签名成功")
                            this.form = {}
                            this.open = false
                        } else {
                            this.$message.info("申请签名失败")
                        }
                    })
                }
            });
        },
        cancelAddSign() {
            this.open = false;
            this.resetForm('form');
            this.form = {}
        },
        /**
         * 打开添加短信模版
         */
        openMsgTemplateDialog() {
            this.msgTitle = '添加短信模版';
            this.openMsgTemplate = true;
        },
        /**
         * 修改添加短信模版
         */
        submitMsgTemplate(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.msgTemplate.orderId) {
                        //存在 走修改
                        smsApi.editSmsTemplate(this.msgTemplate).then(resp => {
                            if (resp.success) {
                                this.openMsgTemplate = false;
                                this.msgTemplate = {}
                                this.$refs[formName].resetFields();
                                this.getList(this.tagName);
                                this.$message.success("修改成功！！！")
                            } else {
                                this.$message.error(resp.msg)
                            }
                        })
                    } else {
                        //不存在 新增
                        smsApi.applySmsTemplate(this.msgTemplate).then(resp => {
                            if (resp.success) {
                                this.openMsgTemplate = false;
                                this.msgTemplate = {}
                                this.$refs[formName].resetFields();
                                this.getList(this.tagName);
                                this.$message.success("修改成功！！！")
                            } else {
                                this.$message.error(resp.msg)
                            }
                        })
                    }
                } else {
                    return false;
                }
            });
        },
        /**
         * 取消添加短信模板
         */
        cancelMsgTemplate(formName) {
            this.openMsgTemplate = false;
            this.msgTemplate = {}
            this.$refs[formName].resetFields();
        },
        /**
         * 打开修改短信模板
         * @param row
         */
        opnUpdateMsgTemplate(row) {
            this.msgTemplate = row
            this.msgTitle = '修改短信模板';
            this.openMsgTemplate = true;
        },
        /**
         * 删除短信模板  templateCode
         */
        deleteMsgTemplate(row) {
            this.$confirm('真的要删除吗？', '系统提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                smsApi.deleteSmsTemplate({templateCode: row.templateCode}).then(resp => {
                    if (resp.success) {
                        this.$message.success("删除成功！！！")
                        this.getList('template');
                    }
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        /**
         * 打开添加短信
         */
        openSendMsg() {
            if (!this.selectedTemplateCode) {
                return this.$message.error("请选择短信模版")
            }
            this.messageList = []
            this.openSendDialog = true;
        },
        sendMsg() {
            if (this.messageList.length === 0){
                return this.$message.error("请先添加发短信信息！！！");
            }
            smsApi.batchSendSms(this.messageList).then(res => {
                if (res.success) {
                    this.$message.success('发送成功')
                    this.closeSendDialog();
                } else return this.$message.error(res.msg);
            })
        },
        addFromItem() {
            if (!this.selectedTemplateCode) {
                this.$message.warning('请选择短信模板')
                return;
            }
            this.messageList.push({
                templateCode: this.selectedTemplateCode,
                phone: '',
                signName: '',
                group: {}
            })
        },
        closeSendDialog() {
            this.openSendDialog = false
            this.formListArr = []
        },
        /**
         * 打开批量上传
         */
        openBatchSendMsgDialog() {
            if (!this.selectedTemplateCode) {
                return this.$message.error("请选择短信模版")
            }
            this.batchSendMsgDialog = true;
        },
        /**
         * 导出模版 exportTemplate
         */
        handleExportDemo() {
            if (!this.filterSelectedTemplateName) {
                this.$message.error("请选择短信模版");
                return;
            }
            const headNameArr = this.codeSplitArr;
            axios({
                method: "post",
                url: "/api/manage/system/sms/exportTemplate",
                data: headNameArr,
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                responseType: 'blob',
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '模版.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(error => {
                this.$message.error("导出模板失败");
            })
        },
        handleExceed() {

        },
        handleSuccess(res) {
            if(res.code === 500){
                return this.$message.error(res.msg);
            }
            this.$message.success("批量发送成功！！！")
            this.batchSendMsgDialog = false;
            this.batchSendMsgForm = {}
            this.$refs.upload.clearFiles()
        },
        submitUpload() {
            if (!this.batchSendMsgForm.signName) {
                return this.$message.error("请先选择签名");
            }
            this.batchSendMsgForm.templateCode = this.selectedTemplateCode;
            this.$refs.upload.submit();
        },

        CloseBatchSendMsgDialog() {
            this.batchSendMsgDialog = false;
            this.batchSendMsgForm = {}
            this.$refs.upload.clearFiles()
        },

        /**
         * 初始化 下拉按钮
         */
        getInitList() {
            smsApi.getSmsTemplateList({current: 1, size: 50}).then(resp => {
                if (resp.success) {
                    this.templateList = resp.data.records.filter(item => item.auditStatus === 'AUDIT_STATE_PASS');

                }
            })
            smsApi.getSmsSignList({current: 1, size: 50}).then(resp => {
                if (resp.success) {
                    this.signList = resp.data.records.filter(item => item.auditStatus === 'AUDIT_STATE_PASS');

                }
            })
        },
        /**
         * 短信类型
         * @param type
         * @returns {string}
         */
        filterOuterTemplateType(type) {
            switch (type) {
                case 0:
                    return '验证码短信'
                case 1:
                    return '通知短信'
                case 2:
                    return '推广短信'
                case 3:
                    return '国际/港澳台短信'
                case 7:
                    return '数字短信'
                default:
                    return '未知状态'
            }
        },
        /**
         * 签名审批状态
         * @param state
         * @returns {string}
         */
        filterAuditStatus(state) {
            switch (state) {
                case 'AUDIT_STATE_INIT':
                    return '审核中'
                case 'AUDIT_STATE_PASS':
                    return '审核通过'
                case 'AUDIT_STATE_NOT_PASS':
                    return '审核未通过'
                case 'AUDIT_STATE_CANCEL':
                    return '取消审核'
                default:
                    return '未知状态'
            }
        },
    }
};
</script>