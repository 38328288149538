<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="addTask">新任务</el-button>
      <el-button type="primary" icon="el-icon-s-ticket" size="mini" @click="shopCodeVisible = true">兑换码</el-button>
    </el-row>

    <el-row>
      <span style="color: #ff2222;font-size: 10px;font-family: 方正粗黑宋简体"><i class="el-icon-star-on"></i>执行时间都是针对于付款时间,且每个订单只会执行一次任务<i
          class="el-icon-star-on"></i></span>
    </el-row>
    <el-table :data="dataList"   max-height="600">
      <el-table-column type="selection" width="35" align="center"/>
      <el-table-column label="任务名称" align="center" prop="taskName"/>
      <el-table-column label="执行方式" align="center" prop="runType">
        <template slot-scope="scope">
          <el-select v-model="scope.row.runType" disabled>
            <el-option v-for="r in runList" :label="r.label" :value="r.value"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="执行时间" align="center" prop="runTime">
        <template slot-scope="scope">
          <span v-if="scope.row.runType === '0'">延迟{{ scope.row.runTime }}分钟</span>
          <span v-if="scope.row.runType === '1'">每天{{ scope.row.runTime }}</span>
          <span v-if="scope.row.runType === '2'">在{{ scope.row.runTime }}发送</span>
        </template>
      </el-table-column>
      <el-table-column label="短信签名" align="center" prop="smsSign"/>
      <el-table-column label="短信模板" align="center" prop="smsTemplate"/>
      <el-table-column label="任务状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-switch disabled
                     v-model="scope.row.status"
                     active-color="#ff4949"
                     inactive-color="#13ce66"
                     :active-value="1"
                     :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"/>
      <el-table-column label="操作" align="center"></el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <!--  新增短信任务  -->
    <el-dialog :modal-append-to-body="false" :visible.sync="taskVisible" title="任务详情" width="80%">
      <el-form :model="taskForm" label-width="120px" label-position="left">
        <el-row>
          <el-col :span="12">
            <el-form-item label="任务名称：" required>
              <el-input style="width:50%;" v-model="taskForm.taskName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务状态：" required>
              <el-switch
                  v-model="taskForm.status"
                  active-color="#ff4949"
                  inactive-color="#13ce66"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="关闭"
                  inactive-text="开启">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="执行方式：" required>
              <el-select v-model="taskForm.runType">
                <el-option v-for="r in runList" :label="r.label" :value="r.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="taskForm.runType === '0'" label="延迟时长：" required>
              <el-input style="width:50%;" v-model="taskForm.runTime">
                <template slot="append">分钟</template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item v-if="taskForm.runType === '1'" label="固定时间：" required>
              <el-time-select :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45'
              }" placeholder="选择时间" v-model="taskForm.runTime"></el-time-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item v-if="taskForm.runType === '2'" label="准确时间：" required>
              <el-date-picker v-model="taskForm.runTime"
                              type="datetime"
                              placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="短信签名：" required>
              <el-select v-model="taskForm.smsSign">
                <el-option v-for="r in signList" :label="r.sign" :value="r.sign"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="短信模板：" required>
              <el-select v-model="taskForm.smsTemplate" @change="changeSms">
                <el-option v-for="r in templateList" style="height: auto;width: 300px" :label="r.templateName"
                           :value="r.templateId">
                  <p style="word-break: break-all;white-space: pre-wrap; width: 100%">{{ r.templateContent }}</p>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="短信内容：" required>
              <span v-for="r in templateList" v-if="r.templateId == taskForm.smsTemplate"
                    v-text="r.templateContent"></span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item :label="'变量：'+o" label-width="150px" required v-for="o in options">
              <el-input placeholder="请输入变量值" v-model="sms[`sms_${o}`]" style="width: 300px;margin-right: 20px"
                        class="input-with-select" :ref="`sms_${o}`"></el-input>
              <el-select placeholder="插入参数" v-model="codes" @change="addCode(`sms_${o}`)">
                <el-option v-for="r in codeList" :label="r.label" :value="r.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" size="mini"
                       @click="addData">添加规则
            </el-button>
          </el-col>
        </el-row>

        <!--    规则数据    -->
        <el-row>
          <el-table :data="tsonList" style="width: 100%">
            <el-table-column prop="attrs" label="属性" align="center">
              <template slot-scope="scope">
                <span v-for="item of attrList" v-if="scope.row.attrs == item.value">{{ item.label }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ruleType" label="逻辑" align="center">
              <template slot-scope="scope">
                <span v-for="item of ruleList" v-if="scope.row.ruleType == item.value">{{ item.label }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="attrVal" label="比较值" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-form-item label-width="0" style="text-align: center">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="taskVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--  添加规则  -->
    <el-dialog :modal-append-to-body="false" :visible.sync="ruleVisible" title="规则详情" width="70%">
      <el-form :model="tsonForm" label-width="120px" label-position="left">

        <el-form-item label="属性：" required>
          <el-select v-model="tsonForm.attrs">
            <el-option v-for="r in attrList" :label="r.label" :value="r.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="逻辑：" required>
          <el-select v-model="tsonForm.ruleType">
            <el-option v-for="r in ruleList" :label="r.label" :value="r.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="比较值：" required>
          <el-input v-model="tsonForm.attrVal"></el-input>
        </el-form-item>

        <el-form-item label-width="0" style="text-align: center" required>
          <el-button type="primary" @click="addTson">确 定</el-button>
          <el-button @click="ruleVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :modal-append-to-body="false" :visible.sync="shopCodeVisible" title="兑换码" width="60%">
      <GoodsCode></GoodsCode>
    </el-dialog>
  </div>
</template>

<script>
import * as doudianApi from "@/api/crm/doudian";
import GoodsCode from "@/views/crm/other/xiaoetong/GoodsCode";

export default {
  name: "DoudianTask",
  components: {GoodsCode},
  data() {
    return {
      // 查询参数
      queryParams: {
        page: 1,
        size: 10
      },
      createTime: [],
      updateTime: [],
      total: 0,
      dataList: [],
      taskVisible: false,
      taskForm: {
        "status": 0
      },
      runList: [
        {"label": "延迟执行", "value": "0"},
        {"label": "固定时间", "value": "1"},
        {"label": "运行一次", "value": "2"},
      ],
      templateList: [],
      signList: [],
      ruleVisible: false,
      tsonForm: {},
      ruleList: [
        {"label": "包含", "value": "0"},
        {"label": "等于", "value": "1"},
        {"label": "大于", "value": "2"},
        {"label": "大于等于", "value": "3"},
        {"label": "小于", "value": "4"},
        {"label": "小于等于", "value": '5'},
      ],
      tsonList: [],
      attrList: [
        {"label": "订单类型", "value": 'orderTypeDesc'},
        {"label": "交易类型", "value": 'tradeTypeDesc'},
        {"label": "订单状态", "value": 'orderStatusDesc'},
        {"label": "商品名字", "value": 'productName'},
        {"label": "付款金额", "value": 'payAmount'},
      ],
      codeList: [
        {"label": "订单编号", "value": 'orderId'},
        {"label": "订单类型", "value": 'orderTypeDesc'},
        {"label": "订单状态", "value": 'orderStatusDesc'},
        {"label": "商品名字", "value": 'productName'},
      ],
      codes: "",
      shopCodeVisible: false,
      options: [],
      smsContent: "",
      sms: {}
    };
  },
  created() {
    this.findTemplate()
    this.findSign()
    this.getList()
  },
  watch: {},
  methods: {
    changeSms(s) {
      for (let t of this.templateList) {
        if (t.templateId === s) {
          var re = /\{(\w+)\}/g;
          let attrs = t.templateContent.match(re)
          this.options = []
          for (let a of attrs) {
            a = a.replace("\{", "").replace("\}", "");
            this.options.push(a)
          }
        }
      }
    },
    addCode(a) {
      let v = this.sms[a]
      if (!v) {
        v = ''
      }
      v += `{${this.codes}}`
      this.$set(this.sms, a, v)
    },
    addTask() {
      this.taskVisible = true
    },
    addTson() {
      this.tsonList.push(this.tsonForm)
      this.tsonForm = {}
      console.log(this.tsonList)
    },
    addData() {
      console.log(this.sms)
      this.tsonForm = {}
      // this.ruleVisible = true
    },
    findTemplate() {
      let p = {"size": 50, "page": 0}
      doudianApi.listTemplate(p).then(res => {
        this.templateList = res.data.templateSearchList
      })
    },
    findSign() {
      let p = {"size": 50, "page": 0}
      doudianApi.listSign(p).then(res => {
        this.signList = res.data.signSearchList
      })
    },
    submitForm() {
      this.taskForm['codes'] = this.codes
      this.taskForm['ruleData'] = JSON.stringify(this.tsonList)
      doudianApi.saveTask(this.taskForm).then(res => {
        console.log(res)
      })
    },
    dateFormat(row, column) {
      var date = row[column.property];
      if (date === undefined || date === 0) {
        return "";
      }
      let dateMat = new Date(date * 1000);
      let y = dateMat.getFullYear();
      let month = dateMat.getMonth() + 1;
      let day = dateMat.getDate();
      let hours = dateMat.getHours();
      let m = dateMat.getMinutes();
      let s = dateMat.getSeconds();
      return `${y}-${month}-${day} ${hours}:${m}:${s}`;
    },
    getList() {
      doudianApi.listPageTask(this.queryParams).then(res => {
        this.dataList = res.data.data
        this.total = res.data.total
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = {page: 1, size: 10}
      this.resetForm("queryForm");
      this.handleQuery();
    }
  }
}
</script>
<style scoped>
.text_ell {
  width: 10em; /*保证文字不会被半汉字截断,显示10个文字*/
  overflow: hidden; /*超出长度的文字隐藏*/
  text-overflow: ellipsis; /*文字隐藏以后添加省略号*/
  white-space: nowrap; /*强制不换行*/
}
</style>
