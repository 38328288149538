<template>
    <div>
        <el-dialog v-if="showObj.isShow" title="顾问公共配置" :visible.sync="showObj.isShow" width="60%">
            <el-card class="box-card">
                <div class="configure-item">
                    <el-form :model="configure" :rules="formRules" ref="formRules" label-width="200px">
                        <el-input v-if="type === 2" hidden v-model="configure.id"></el-input>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="name" label="配置名称" size="mini">
                                    <el-input v-model="configure.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">配置名称</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="bringline" label="机会保护期（天)" size="mini">
                                    <el-input v-model="configure.bringline" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">学习顾问在机会保护期内不能重新领取自己放弃的资源</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="opporMax" label="最大资源数（个)" size="mini">
                                    <el-input v-model="configure.opporMax" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">每位学习顾问可拥有的最大资源数量</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="drawDayMax" label="顾问机会的日领取限制（个)" size="mini">
                                    <el-input v-model="configure.drawDayMax" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">学习顾问每日可领取顾问机会上限</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="firstAdvisoryDayMax" label="顾问首咨的日领取限制（个)" size="mini">
                                    <el-input v-model="configure.firstAdvisoryDayMax" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">学习顾问每日可领取首咨机会上限</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="phoneCount" label="每日查看手机/微信数量（个)" size="mini">
                                    <el-input v-model="configure.phoneCount" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">顾问每日查看/手机/微信的数量</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="wakeOpportunityDayMax" label="每日领取唤醒机会数量（个)" size="mini">
                                    <el-input v-model="configure.wakeOpportunityDayMax" type="number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">顾问每日查看/手机/微信的数量</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="12">
                                <el-form-item prop="mainInlet" label="领取入口" size="mini">
                                    <el-checkbox-group v-model="configure.mainInlet" size="mini">
                                        <el-checkbox :label="1" border>自创建</el-checkbox>
                                        <el-checkbox :label="2" border size="mini">机会校验领取</el-checkbox>
                                        <el-checkbox :label="3" border size="mini">领取库存</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" :offset="1">
                                <span class="configure-des">注意:子配置只能操作已选项</span>
                            </el-col>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-form-item size="mini" label="其他领取入口"
                                          v-if="checkedBtnList && checkedBtnList.length > 0">
                                <draggable v-model="checkedBtnList"
                                           :move="onMove">
                                    <div style="display: inline-block"
                                         v-for="item in checkedBtnList"
                                         :key="item.id">
                                        <el-tag
                                                :type="typeList[item.id%5]"
                                                closable
                                                @close="delBtn(item.id)">
                                            {{ item.name }}
                                        </el-tag>
                                        <el-divider direction="vertical"></el-divider>
                                    </div>
                                </draggable>

                            </el-form-item>
                        </el-row>

                        <el-row type="flex" justify="start" align="center">
                            <el-col :span="18">
                                <el-form-item size="mini" label="选择其他领取入口">
                                    <template>
                                        <el-select v-model="checkBtn" filterable value-key="id" style="width: 56%">
                                            <el-option v-for="item in btnList"
                                                       :key="item.id"
                                                       :label="item.name"
                                                       :disabled="checkedBtnList.indexOf(item) > -1"
                                                       :value="item">
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" :offset="1">
                                <el-button size="mini" type="success" @click="addBtn" :disabled="!checkBtn">添加此按钮
                                </el-button>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-form-item>
                                <el-button size="mini" type="primary" @click="submitForm">确定</el-button>
                                <el-button size="mini" @click="showObj.isShow = false">取消</el-button>
                            </el-form-item>
                        </el-row>

                    </el-form>
                </div>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import * as receiveApi from '@/api/system/receive'
import draggable from 'vuedraggable'
import {getConfigure, saveOrUpdateConfigure} from "@/utils/api";

export default {
    props: {
        showObj: {
            type: Object,
            require: true
        },
        type: { //添加，编辑
            require: false,
            default: 1,
            type: Number
        },
        configureId: {
            require: false,
            default: 0,
            type: Number
        }
    },
    name: "ConfigureDetail",
    data() {
        const validateNumber = (rule, value, callback) => {
            if (value == null || value.length > 4) {
                callback(new Error('最大允许输入9999'))
            }
            if (!(/^(\d+)$/).test(value)) {
                callback(new Error('请输入数字值'))
            } else {
                callback()
            }
        };
        return {
            configure: {
                id: '',
                parentId: '',
                name: '',
                bringline: '',
                opporMax: '',
                drawDayMax: '',
                firstAdvisoryDayMax: '',
                phoneCount: '',
                wakeOpportunityDayMax: '',
                mainInlet: [],
            },
            checkBtn: null,
            checkedBtnList: [],
            btnList: [],
            formRules: {
                name: [{required: true, message: '请输入配置名称', trigger: 'blur'}],
                bringline: [{validator: validateNumber, trigger: 'blur'}],
                opporMax: [{validator: validateNumber, trigger: 'blur'}],
                drawDayMax: [{validator: validateNumber, trigger: 'blur'}],
                firstAdvisoryDayMax: [{validator: validateNumber, trigger: 'blur'}],
                phoneCount: [{validator: validateNumber, trigger: 'blur'}],
                wakeOpportunityDayMax: [{validator: validateNumber, trigger: 'blur'}]
            },
            typeList: ['', 'success', 'info', 'warning', 'danger']
        }
    },
    components: {draggable},
    mounted() {
        this.findReceiveList()
    },
    methods: {
        onMove(e) {
            return true;
        },
        getConfig() {
            if (this.configureId) {
                getConfigure(this.configureId).then(resp => {
                    this.configure.id = resp.data.id;
                    this.configure.parentId = resp.data.parentId;
                    this.configure.name = resp.data.name;
                    this.configure.bringline = resp.data.bringline;
                    this.configure.opporMax = resp.data.opporMax;
                    this.configure.drawDayMax = resp.data.drawDayMax;
                    this.configure.firstAdvisoryDayMax = resp.data.firstAdvisoryDayMax;
                    this.configure.phoneCount = resp.data.phoneCount;
                    this.configure.wakeOpportunityDayMax = resp.data.wakeOpportunityDayMax;
                    if (resp.data.mainInlet) {
                        this.configure.mainInlet = resp.data.mainInlet.split(',').map(e => Number(e));
                    }
                    if (resp.data.otherInlet) {
                        resp.data.otherInlet.split(',').forEach(id => {
                            this.checkedBtnList.push(this.btnList.filter(e => Number(e.id) === Number(id))[0])
                        })
                    }
                })
            }

        },
        /**
         * 删除一个按钮
         * @param id
         */
        delBtn(id) {
            const data = this.checkedBtnList.filter(e => e.id === id);
            const index = this.checkedBtnList.indexOf(data[0]);
            if (index > -1) {
                this.checkedBtnList.splice(index, 1)
            }
        },
        /**
         * 添加一个按钮
         */
        addBtn() {
            this.checkedBtnList.push(this.checkBtn);
            // this.checkedBtnList.sort((a, b) => a.id - b.id);
            this.checkBtn = null
        },
        /**
         * 获取所有按钮
         */
        findReceiveList() {
            receiveApi.list().then(res => {
                if (res.success) {
                    this.btnList = res.data
                    this.getConfig();
                }
            })
        },
        /**
         * 提交表单
         */
        submitForm() {
            this.$refs['formRules'].validate((valid) => {
                if (valid) {
                    const params = JSON.parse(JSON.stringify(this.configure))
                    params['mainInlet'] = this.configure.mainInlet.join(',')
                    const otherIntelIdArr = this.checkedBtnList.map(e => {
                        return e.id;
                    })
                    const otherInletArr = []
                    otherIntelIdArr.forEach(e => {
                        otherInletArr.push({
                                btnId: e,
                                enabled: 1,
                                receiveMax: 0,
                            }
                        )
                    });
                    params['otherInletJson'] = otherInletArr
                    if (!this.configure.parentId && this.configure.parentId === '') {
                        params['parentId'] = 0
                    }
                    saveOrUpdateConfigure(params, this.type).then(resp => {
                        if (resp.success) {
                            this.$message.success(resp.msg)
                            this.$emit("getConfigure")
                            this.showObj.isShow = false
                        } else {
                            this.$message.warning(resp.msg)
                        }
                    })
                } else {
                    this.$message.warning("请输入正确的内容")
                }

            })
        }
    }

}
</script>

<style scoped>

.configure-des {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #909399;
}

</style>
