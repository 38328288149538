<template>
    <div>
        <el-table
                :data="tableData"
                stripe
                style="width: 100%">
            <el-table-column align="center"
                             prop="possName"
                             label="唯一值">
            </el-table-column>
            <el-table-column align="center"
                             min-width="110px"
                             prop="telephone"
                             label="手机号">
            </el-table-column>
            <el-table-column align="center"
                             min-width="110px"
                             prop="ip"
                             label="学员IP">
            </el-table-column>
            <el-table-column align="center"
                             min-width="110px"
                             prop="type"
                             label="访问方式">
                <template v-slot="scope">
                    <span v-if="scope.row.type === 'APPLET'">小程序</span>
                    <span v-else-if="scope.row.type === 'PC'">电脑</span>
                    <span v-else>手机</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="线索池" prop="pondId">
                <template v-slot="scope">
                    <el-tag :type="typeOptions[item.id % 5]" v-for="item in pondList" :key="item.id" v-if="scope.row.pondId === item.id">
                        {{item.name}}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center"
                             prop="accountId"
                             label="学习顾问ID">
            </el-table-column>
            <el-table-column align="center"
                             prop="accountName"
                             label="学习顾问">
            </el-table-column>
            <el-table-column align="center"
                             prop="deptName"
                             label="所属团队">
            </el-table-column>
            <el-table-column prop="mediatorName" align="center"
                             label="渠道">
            </el-table-column>
            <el-table-column prop="mediatorAccount"
                             align="center"
                             label="渠道账号">
            </el-table-column>
            <el-table-column
                    align="center"
                    min-width="140px"
                    prop="createTime"
                    label="留资时间">
            </el-table-column>
            <el-table-column
                    align="center"
                    prop="domainName"
                    label="来源页面">
            </el-table-column>
            <el-table-column
                    align="center"
                    prop="domainUrl"
                    label="额外参数">
                <template slot-scope="scope">
                    <el-link :underline="false">{{ scope.row.domainUrl }}{{ scope.row.params }}</el-link>
                </template>
            </el-table-column>
            <el-table-column
                    align="center"
                    prop="storage"
                    label="状态">
            </el-table-column>
            <!--            <el-table-column label="操作" align="center">-->
            <!--                <template slot-scope="scope">-->

            <!--                </template>-->
            <!--            </el-table-column>-->
        </el-table>
        <pagination :total="total" :page-size="10" :page.sync="queryParams.page"
                    :limit.sync="queryParams.limit" @pagination="salesList"/>

    </div>

</template>

<script>
import * as api from "@/api/mini/leavePhone";
import * as domainApi from "@/api/mini/pagedomain";
import * as apiUtils from "../../utils/api";
import axios from "axios";
import * as clueApi from "@/api/clue/cluePond";
import {listCluePond} from "@/api/clue/cluePond";

export default {
    name: "sales",
    props: {
        queryParams: {
            page: 1,
            limit: 10
        },
    },
    data() {
        return {
            typeOptions: ['', 'success', 'warning', 'danger', 'info'],
            pondList: [],
            // 总条数
            total: 0,
            tableData: [],
            dialogFormVisible: false,
            dialogTitle: "",
            dialogForm: {
                name: "",
                detail: ""
            },
            dialogForm_null: Object.assign({}, this.dialogForm),
            rowIndex: 9999,
            imageUrl: '',
            adviserList: [],
            domainList: [],
            mediatorList: [],
            possList: [],
        }
    },
    mounted() {
        this.findPondList()
        this.salesList();
    },
    methods: {
        findPondList() {
            clueApi.listCluePond({}).then(res => {
                if (res.success) {
                    this.pondList = res.data
                }
              this.pondList.unshift({id: 1, name: '基础池'})
            })
        },
        exportLeavePhone() {
            axios({
                method: "post",
                url: "/api/manage/leave/phone/exportLeavePhone",
                data: JSON.stringify(this.queryParams),
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                },
            }).then(res => {
                let now = new Date();
                const blob = new Blob([res.data]);
                const fileName = '留资数据' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.salesList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams = {};
            this.handleQuery();
        },
        /*
        * 获取列表数据
        * */
        salesList() {
            api.getList(this.queryParams).then(res => {
                this.tableData = res.data.data
                this.total = res.data.count
            });
        },
        //重置表单规则
        resetFrom(roleForm) {
            this.$refs[roleForm].clearValidate();
        },
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
