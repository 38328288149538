<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" v-show="showSearch" :inline="true" @submit.native.prevent>
      <el-form-item label="角色名称" prop="roleName">
        <el-input
            v-model="queryParams.roleName"
            placeholder="请输入角色名称"
            clearable
            size="small"
            style="width: 240px"
            @keyup.enter.native="handleQuery"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-permission="'sys:role:add'"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            v-permission="'sys:role:delete'"
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
        >删除
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="roleList" @selection-change="handleSelectionChange" max-height="600">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="角色编号" prop="id" width="120"/>
      <el-table-column label="角色名称" prop="roleName" :show-overflow-tooltip="true" width="150"/>
      <el-table-column label="状态" align="center" width="100">
        <template slot-scope="scope">
          <el-switch disabled
                     v-model="scope.row.status"
                     :active-value="1"
                     :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime" width="180">
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="'sys:role:update'"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="'sys:role:delete'"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />

    <!-- 添加或修改角色配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入角色名称"/>
        </el-form-item>
        <el-form-item label="角色状态" prop="status">
          <el-switch
              v-model="form.status"
              :active-value="1"
              :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="权限控制" prop="menuIds">
          <el-tree
              ref="menuForm"
              :data="menuTree"
              show-checkbox
              node-key="id"
              :default-checked-keys="checkedMenu"
              :default-expand-all="expandall"
              :props="defaultProps">
          </el-tree>
        </el-form-item>
        <el-form-item label="领取成单机会" prop="isDrawOrder">
          <el-radio-group v-model="form.isDrawOrder">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as roleApi from "@/api/system/role";
import * as api from "@/utils/api";

export default {
  name: "Role",
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [],
      // 数据范围选项
      dataScopeOptions: [
        {
          value: "1",
          label: "全部数据权限"
        },
        {
          value: "2",
          label: "自定数据权限"
        },
        {
          value: "3",
          label: "本部门数据权限"
        },
        {
          value: "4",
          label: "本部门及以下数据权限"
        },
        {
          value: "5",
          label: "仅本人数据权限"
        }
      ],
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "title"
      },
      // 表单校验
      rules: {
        roleName: [
          {required: true, message: "角色名称不能为空", trigger: "blur"}
        ]
      },
      menuTree: [],
      checkedMenu: [],
      expandall: false
    };
  },
  created() {
    this.getList();
    this.getMenuTree();
  },
  methods: {
    getMenuTree(param) {
      api.menuTree(param).then(res => {
        this.menuTree = res.data;
      });
    },
    /** 查询角色列表 */
    getList() {
      this.loading = true;
      roleApi.listRole(this.queryParams).then(
          response => {
            this.roleList = response.data.records;
            this.total = response.data.total;
            this.loading = false;
          }
      );
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
      this.form.status = 1
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let menuIds = [];
          let nodes = this.$refs.menuForm.getCheckedNodes(false, true);
          for (let i in nodes) {
            menuIds.push(nodes[i].id)
          }
          this.form.menuIds = menuIds;
          if (this.form.id != undefined) {
            roleApi.updateRole(this.form).then(response => {
              if (response.success) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.$message.warning(response.msg)
              }
            });
          } else {
            roleApi.saveRole(this.form).then(response => {
              if (response.success) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.$message.warning(response.msg)
              }
            });
          }
        }
      });
    },
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    handleUpdate(row) {
      this.reset();
      const id = row.id
      roleApi.getRole(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改用户";

        this.$nextTick(function () {
          this.$refs.menuForm.setCheckedKeys(this.form.menuIds);
        })
      });
    },
    handleDelete(row) {
      const ids = row.id || this.ids;
      roleApi.deleteRole(ids).then(res => {
        console.log(res)
      });
    }
  }
};
</script>
