<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="110px">
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="shopCodeVisible = true">导入兑换码
                </el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="兑换码名称" align="center" prop="sign"/>
            <el-table-column label="剩余兑换码" align="center" prop="sign"/>
            <el-table-column label="截止时间" align="center" prop="sign"/>
            <el-table-column label="操作" align="center">

            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.size"
                @pagination="getList"
        />

        <el-dialog :visible.sync="shopCodeVisible" :modal-append-to-body="false" v-if="shopCodeVisible">
            <el-form :model="SCForm" label-width="80px">
                <el-form-item label="名称">
                    <el-input v-model="SCForm.name"></el-input>
                </el-form-item>
                <el-form-item label="截止时间">
                    <el-date-picker
                            v-model="SCForm.stopTime"
                            type="datetime"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="导入数据">
                    <el-row>
                        <el-col :span="18">
                            <el-upload
                                    ref="upload"
                                    :limit="1"
                                    accept=".xlsx, .xls"
                                    :headers="upload.headers"
                                    :action="upload.url"
                                    :disabled="upload.isUploading"
                                    :on-progress="handleFileUploadProgress"
                                    :on-success="handleFileSuccess"
                                    :auto-upload="false"
                                    drag
                                    style="width: 100%"
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">
                                    将文件拖到此处，或
                                    <em>点击上传</em>
                                </div>
                            </el-upload>

                        </el-col>
                        <el-col :span="6">
                            <el-link type="success" style="font-size:20px" @click="importTemplate">下载模板</el-link>
                        </el-col>
                    </el-row>

                    <div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即创建</el-button>
                    <el-button @click="shopCodeVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import * as xiaoetongApi from "@/api/crm/xiaoetong";

export default {
    name: "GoodsCode",
    data() {
        return {
            dataList: [],
            total: 0,
            queryParams: {
                page: 1,
                size: 10
            },
            shopCodeVisible: false,
            SCForm: {},
            upload: {
                isUploading: false,
                url: "",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                }
            },
        }
    },
    created() {

    },
    methods: {
        importTemplate() {

        },
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            let msg = "导入成功";
            if (response.data.msgList) {
                msg = "";
                for (let m of response.data.msgList) {
                    msg += `<span>${m}</span><br/>`;
                }
            }
            this.$alert(msg, "导入结果", {dangerouslyUseHTMLString: true});
            this.getList();
        },
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        importShopCode() {

        },
        onSubmit() {
            doudianApi.applySign(this.SCForm).then(res => {
                if (res.data.code === 0) {
                    this.$message({type: 'success', message: "已提交申请!"});
                    this.addSignVisible = false
                } else {
                    this.$message({type: 'error', message: res.data.message});
                }
            })
        },
        getList() {
            doudianApi.listSign(this.queryParams).then(res => {
                console.log(res)
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
    }
}
</script>

<style scoped>

</style>
