<template>
  <el-dialog
      title="查看手机号"
      v-if="showObj.isShow"
      :visible.sync="showObj.isShow"
      width="40%">
    <el-card class="box-card">
      <div v-for="opportunity in opportunityList" :key="opportunity.id" class="text item">
        手机号:{{ opportunity.telephone}} -- 机会ID:{{opportunity.id}}
      </div>
    </el-card>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="showObj.isShow= false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    opportunityList: {
      type: Array,
      require: true
    },
    showObj: {
      type: Object,
      require: true
    }
  },
  name: "ShowTelephone"
}
</script>

<style scoped>

</style>
