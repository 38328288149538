<template>
    <div>
        <el-row>
            <el-col :span="22">
                <div class="mini-title">
                    请勾选需要在补录订单页面展示的列表内容，没勾选内容不会在补录订单页面展现
                </div>
            </el-col>
            <el-col :span="2">
                <div style="text-align: end;">
                    <el-button type="success" icon="el-icon-check" size="mini"
                               @click="handleUpdate()">保存
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <table class="itxst" align="center">
                <draggable v-model="shows" animation="200" force-fallback="true"
                           filter=".disable"
                           :move="onMove">
                    <tr v-for="s in shows" :key="s.value">
                        <td width="80px">
                            <template>
                                <el-checkbox size="medium"
                                             v-model="s.status" :disabled="s.name==='phone'
                                           || s.name==='goodsIds'|| s.name==='payPrice'
                                           || s.name==='payTime'|| s.name==='payChannel'
                                           || s.name==='orderNo'
                                           || s.name==='orderImgs'
                                           || s.name==='payType'|| s.name==='adviserId'">展示
                                </el-checkbox>
                            </template>
                        </td>
                        <td width="80px">
                            <template>
                                <el-checkbox size="medium"
                                             v-model="s.required" :disabled="s.name==='phone'
                                           || s.name==='goodsIds'|| s.name==='payPrice'
                                           || s.name==='payTime'|| s.name==='payChannel'
                                           || s.name==='orderNo'
                                           || s.name==='orderImgs'
                                           || s.name==='payType'
                                           || s.name==='adviserId'
                                           || s.name==='joinActivity'
                                           || s.name === 'isMailTxbooks'">必填
                                </el-checkbox>
                            </template>
                        </td>
                        <td width="90%">{{ s.label }}</td>
                        <td width="50px">
                            <el-tooltip class="item" effect="dark"
                                        content="按住拖动可改变展示顺序"
                                        placement="top">
                                <i class="el-icon-s-fold"></i>
                            </el-tooltip>
                        </td>
                    </tr>
                </draggable>
            </table>
        </el-row>
    </div>
</template>

<script>
import * as searchApi from "@/api/system/searchDeploy";
import draggable from 'vuedraggable'

export default {
    name: "RepairInfo",
    components: {draggable},
    data() {
        return {
            shows: [
                {label: "手机号", value: "0", sort: "1", status: true}
                , {label: "类型", value: "2", sort: "2", status: true}
                , {label: "意向度", value: "3", sort: "3", status: true}
                , {label: "微信", value: "4", sort: "4", status: true}
                , {label: "项目", value: "5", sort: "5", status: true}
                , {label: "最后沟通时间", value: "6", sort: "6", status: true}
                , {label: "最后拨打时间", value: "7", sort: "7", status: true}
                , {label: "回库时间", value: "8", sort: "8", status: true}
                , {label: "领取时间", value: "9", sort: "9", status: true}
                , {label: "当日拨打状态", value: "10", sort: "10", status: true}
                , {label: "当日沟通时长", value: "11", sort: "11", status: true}
                , {label: "当日拨打次数", value: "12", sort: "12", status: true}
                , {label: "30天内拨打次数", value: "13", sort: "13", status: true}
                , {label: "领取方式", value: "14", sort: "14", status: true}
                , {label: "备注", value: "15", sort: "15", status: true}
                , {label: "年龄", value: "16", sort: "16", status: true}
                , {label: "姓名", value: "17", sort: "17", status: true}
                , {label: "地域", value: "18", sort: "18", status: true}
                , {label: "来源", value: "19", sort: "19", status: true}
                , {label: "支付状态", value: "20", sort: "20", status: true}
                , {label: "今日预约", value: "21", sort: "21", status: true}
            ],
        }
    },
    mounted() {
        this.list()
    },
    methods: {
        list() {
            searchApi.list({classify: "REPAIR_INFO"}).then(res => {
                this.shows = res.data;
            });
        },
        handleUpdate() {
            const loading = this.$loading({
                lock: true,
                text: '保存中....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = []

            let shows = this.shows
            for (let i in shows) {
                shows[i].sort = i
                if (shows[i].name == 'opporId' || shows[i].name == 'price' || shows[i].name == 'hasPrinter'
                    || shows[i].name == 'notes' || shows[i].name == 'mailAddr'
                    || shows[i].name == 'mailTxbooks' || shows[i].name == 'payAccount' || shows[i].name == 'orderChannel'
                    || shows[i].name == 'rebateAmount' || shows[i].name == 'studentName') {
                    if (shows[i].required == true && shows[i].status == false) {
                        shows[i].status = true
                    }
                }
                data.push(shows[i])
            }

            searchApi.batchUpdate(data).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.list();
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close();
            }).catch(r => {
                loading.close();
            })

        },
        ischeck(o) {
            o.status = o.status ? false : true;
        },
        onMove(e) {
            if (e.relatedContext.element.name == "telephone") {
                return false;
            }
            return true
        },
    },
}
</script>

<style scoped>
/*定义要拖拽元素的样式*/
table.itxst {
    width: 100%;
    color: #333333;
    text-align: center;
    border-collapse: collapse;
}

table.itxst th {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #dedede;
}

table.itxst td {
    border: #ddd solid 1px;
    padding: 8px;
    background-color: #ffffff;
}

table.itxst tr {
    cursor: pointer;
}

table.itxst td.move:hover {
    cursor: move;
}

.mini-title {
    font-size: 12px;
    color: #909399;
}
</style>