<template>
    <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
            <el-form-item label="渠道名称" prop="mediatorName">
                <el-input
                        v-model="queryParams.mediatorName"
                        placeholder="请输入渠道名称"
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="渠道账号" prop="accountName">
                <el-input
                        v-model="queryParams.accountName"
                        placeholder="渠道账号"
                        size="small"
                        @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button
                        v-permission="'crm:mediator:add'"
                        type="primary"
                        plain
                        icon="el-icon-plus"
                        size="mini"
                        @click="handleAdd"

                >新增
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button
                        v-permission="'crm:mediator:delete'"
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        size="mini"
                        :disabled="multiple"
                        @click="handleDelete"
                >删除
                </el-button>
            </el-col>
        </el-row>

        <el-table max-height="600" v-loading="loading" :data="mediatorList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="主键" align="center" prop="id"/>
            <el-table-column label="渠道名称" align="center" prop="mediatorName"/>
            <el-table-column label="渠道类型" align="center" prop="mediatorType">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.mediatorType" filterable placeholder="未选择渠道类型" disabled
                               size="small">
                        <el-option v-for="type in mediatorTypeList" :label="type.label" :value="type.value"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="默认标识" align="center" prop="possessId" width="200">
                <template slot-scope="scope">
<!--                    {{scope.row}}-->
                    <el-select v-model="scope.row.possessId" filterable placeholder="未配置默认标识" disabled
                               size="small">
                        <el-option v-for="poss in possessList" :label="poss.name" :value="poss.id" ></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="重复数据标签" align="center" prop="myTags" width="120">
                <template v-slot="scope">
                    <div v-for="mt in scope.row.myTags" :key="mt">
                        <div v-for="item in tagList" :key="item.id" >
                            <el-tag v-if="item.id === mt">{{ item.name }}</el-tag>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="科目" align="center" prop="buildSubjectId" v-if="isShow">
                <template v-slot="scope">
                    <el-tag  type="success" v-if="scope.row.buildSubjectId === 7">一建</el-tag>
                    <el-tag type="info" v-if="scope.row.buildSubjectId === 8">二建</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="账号名称" align="center" prop="accountName"/>
            <el-table-column label="计划名称" align="center" prop="campaignName"/>
            <el-table-column label="单元名称" align="center" prop="unitName"/>
<!--            <el-table-column label="落地页地址" align="center" prop="url"/>-->
            <el-table-column label="落地页地址" align="center" prop="url">
            <template slot-scope="scope">
            <el-tooltip effect="dark" content="点击复制落地页地址" placement="bottom">
                <i v-if="scope.row.url" class="el-icon-document-copy btn-click-open" @click="copyUrl(scope.row.url)"> {{scope.row.url}}</i>
            </el-tooltip>
            </template>
            </el-table-column>
            <el-table-column label="创建人" align="center" prop="createBy"/>
            <el-table-column label="创建时间" align="center" prop="createTime"/>
            <el-table-column label="修改人" align="center" prop="updateBy"/>
            <el-table-column label="修改时间" align="center" prop="updateTime"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(scope.row)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(scope.row)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination
                v-show="total>0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.limit"
                @pagination="getList"
        />

        <!-- 添加或修改参数配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="50%"  append-to-body>
            <el-form ref="form" :model="form" :rules="rules"  label-width="120px">
                <el-form-item label="渠道名称" prop="mediatorName">
                    <el-input v-model="form.mediatorName" placeholder="请输入渠道名称"/>
                </el-form-item>
<!--                <el-form-item label="科目" prop="buildSubjectId">-->
<!--                    <el-select v-model="form.buildSubjectId"  placeholder="科目" size="small">-->
<!--                        <el-option label="一建" :value="1"></el-option>-->
<!--                        <el-option label="二建" :value="2"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
<!--                {{tableParams}}-->
                <template v-for="(d,index) in tableParams">
                    <el-form-item v-if="d.tson" :label="d.label" size="mini">
                        <el-select v-model="form.buildSubjectId">

                            <el-option v-for="(item,index) in d.dictList" :key="index" :label="item.value" :value="item.id"  />
                        </el-select>
                    </el-form-item>
                </template>


                <el-form-item label="渠道类型" prop="mediatorType">
                    <el-select v-model="form.mediatorType" filterable placeholder="请选择渠道类型" size="small">
                        <el-option v-for="type in mediatorTypeList" :label="type.label" :value="type.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="默认标识" prop="possessId">
                    <el-select v-model="form.possessId" filterable placeholder="请选择默认标识" size="small">
                        <el-option v-for="possess in possessList" :value="possess.id" :label="possess.name" :disabled="possess.status === 1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="重复数据标签" prop="repeatTags">
                    <el-select size="small" v-model="myTags" multiple>
                        <el-option v-for="item in tagList" :key="item.id" :label="item.name"
                                   :value="item.id" v-if="!item.status"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推广平台" prop="promotionPlatform">
                    <el-input v-model="form.promotionPlatform" placeholder="请输入推广平台"/>
                </el-form-item>
                <el-form-item label="账号名称" prop="accountName">
                    <el-input v-model="form.accountName" placeholder="请输入账号名称"/>
                </el-form-item>
                <el-form-item label="计划名称" prop="campaignName">
                    <el-input v-model="form.campaignName" placeholder="请输入计划名称"/>
                </el-form-item>
                <el-form-item label="单元名称" prop="unitName">
                    <el-input v-model="form.unitName" placeholder="请输入单元名称"/>
                </el-form-item>
                <el-form-item label="页面名称" prop="pageName">
                    <el-input v-model="form.pageName" placeholder="请输入页面名称"/>
                </el-form-item>
                <el-form-item label="落地页url" prop="url">
                    <el-input v-model="form.url" placeholder="请输入落地页url"/>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as mediatorApi from "@/api/crm/mediatorCut";
import * as possessApi from "@/api/crm/possess";
import * as tagsApi from "@/api/crm/tags"
import {copy} from "@/utils/utils";
import * as deployApi from "@/api/system/searchDeploy";
export default {
    name: "MediatorCut",
    components: {},
    data() {
        return {
            isShow: false,
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 参数配置表格数据
            mediatorList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10,
                mediatorName: null,
                mediatorType: "",
                possessId: null,
                accountName: null,
                campaignName: null,
                unitName: null,
                pageName: null,
                url: null
            },
            myTags: [],
            tableParams: [],
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                mediatorName:  [
                    { required: true, message: '请输入渠道名称', trigger: 'blur' },
                ],
                // buildSubjectId:  [
                //     { required: true, message: '请选择专业科目', trigger: 'change' },
                // ],
                mediatorType:  [
                    { required: true, message: '请选择渠道类型', trigger: 'change' },
                ],
                possessId:  [
                    { required: true, message: '请选择默认标识', trigger: 'change' },
                ],
                promotionPlatform:  [
                    { required: true, message: '请输入推广平台', trigger: 'blur' },
                ]
            },
            possessList: [],
            mediatorTypeList: [
                {
                    label: "SEM",
                    value: 1,
                }
                , {
                    label: "信息流",
                    value: 2,
                }
                , {
                    label: "短视频",
                    value: 3,
                }
                , {
                    label: "CPT",
                    value: 4,
                }
                , {
                    label: "KOL",
                    value: 5,
                }
            ],
            tagList: [],
        };
    },
    created() {
        // this.findSearch();
        this.getList();
        this.getPossessList();
        this.findTags()
    },
    methods: {
        /**
         * 获取科目的下拉框
         */
        findSearch() {
            deployApi.findByClassify("NEW_INFORMATION").then(res => {
                this.tableParams = res.data
                for (let e of this.tableParams) {
                    if (e.data) {
                        e.tson = JSON.parse(e.data)
                        if (e.type === 'radio' &&  e.name === 'BGGHDHADDH') {
                            // e.tson.params = e.status;
                            this.isShow = e.status;
                            // this.tableParams.push(e.tson)
                        }
                    }
                }
                console.log("获取一建二建！！！",this.tableParams)
            })
        },
        findTags() {
            tagsApi.options().then(res => {
                if (res.success) {
                    this.tagList = res.data
                }
            })
        },
        //获取唯一值下拉数据
        getPossessList(param) {
            possessApi.possessAll(param).then(res => {
                this.possessList = res.data;
            })
        },
        /** 查询参数配置列表 */
        getList() {
            this.loading = true;
            mediatorApi.listMediator(this.queryParams).then(response => {
                this.mediatorList = response.data.records;
                this.mediatorList.map(e => {
                    if (e.repeatTags) {
                        e['myTags'] = e.repeatTags.split(",").map(e => e * 1)
                    }
                })
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: null,
                mediatorName: null,
                mediatorType: null,
                possessId: null,
                accountName: null,
                campaignName: null,
                unitName: null,
                pageName: null,
                url: null,
                buildSubjectId:null
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
            this.myTags = []
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.form.buildSubjectId = row.buildSubjectId
            const id = row.id
            mediatorApi.getMediatorById(id).then(response => {
                this.form = response.data;
                this.myTags = []
                if (this.form.repeatTags) {
                    this.myTags = this.form.repeatTags.split(",").map(e => e * 1)
                }
                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if(this.isShow && !this.form.buildSubjectId){
                    return this.$message.warning("请选择一建二建")
                }
                if (valid) {
                    if (this.myTags) {
                        this.form['repeatTags'] = this.myTags.join(",")
                    }
                    if (this.form.id != null) {
                        mediatorApi.updateMediator(this.form).then(response => {
                            if (response.success) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.$message.warning(response.msg)
                            }
                        });
                    } else {
                        mediatorApi.saveMediator(this.form).then(response => {
                            if (response.success) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.getList();
                            } else {
                                this.$message.warning(response.msg)
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return mediatorApi.deleteMediator(ids);
            }).then(() => {
                this.getList();
                this.msgSuccess("删除成功");
            })
        },
        copyUrl(url){
            if (url){
                this.$message.success("复制成功")
                copy(url)
            }
        }
    }
};
</script>
