<template>
    <div id="top-container">
        <div style="display: flex;flex-direction: row">
            <GgdCard background="#2456bb" title="页面PV" :content="pvCount"></GgdCard>
            <GgdCard background="#ffd200" title="页面UV" :content="uvCount"></GgdCard>
            <GgdCard background="#e73333" title="合计数据" :content="sumCount"></GgdCard>
            <GgdCard background="#49d179" title="有效数据" :content="sucCount"></GgdCard>
        </div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
          <el-form-item label="手机号" prop="telephone">
            <el-input v-model="queryParams.telephone"
                      maxlength="11"
                      placeholder="手机号"
                      clearable
                      size="small"
                      style="width: 240px"></el-input>
          </el-form-item>
            <el-form-item label="线索池" prop="pondId">
                <el-select size="mini" v-model="queryParams.pondId" width="100%" clearable filterable>
                    <el-option value="" label="全部"></el-option>
                    <el-option
                            v-for="item in pondList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="渠道" prop="mediatorId">
                <el-select
                        v-model="queryParams.mediatorId"
                        filterable
                        placeholder="渠道"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="mediator in mediatorList" :key="mediator.id" :value="mediator.id"
                               :label="mediator.mediatorName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="唯一值" prop="possId">
                <el-select
                        v-model="queryParams.possId"
                        filterable
                        placeholder="唯一值"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="poss in possList" :key="poss.id" :value="poss.id"
                               :label="poss.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="页面" prop="fromId">
                <el-select
                        v-model="queryParams.fromId"
                        filterable
                        placeholder="页面"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="domain in domainList" :key="domain.id" :value="domain.id"
                               :label="domain.pageName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="访问方式" prop="type">
                <el-select
                        v-model="queryParams.type"
                        filterable
                        placeholder="访问方式"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="" label="全部"></el-option>
                    <el-option value="MOBILE" label="手机"></el-option>
                    <el-option value="PC" label="电脑"></el-option>
                    <el-option value="APPLET" label="小程序"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数据状态" prop="storage">
                <el-select
                        v-model="queryParams.storage"
                        filterable
                        placeholder="数据状态"
                        clearable
                        size="small"
                        style="width: 240px"
                >
                    <el-option value="提交成功" label="提交成功"></el-option>
                    <el-option value="您已提交" label="您已提交"></el-option>
                    <el-option value="操作失败！" label="操作失败！"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="留资时间">
                <el-date-picker
                        size="small"
                        v-model="createTimeRange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-row>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    <el-button type="primary" icon="el-icon-download" size="mini" @click="exportLeavePhone">导出
                    </el-button>
                    <el-button type="primary" icon="el-icon-download" size="mini" @click="exportRecord">导出统计
                    </el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="留言电话" name="leave">
                <leavePhone ref="leave" :queryParams="queryParams"></leavePhone>
            </el-tab-pane>
            <el-tab-pane label="访问记录" name="record">
                <recordLog ref="record" :queryParams="queryParams"></recordLog>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

    import * as apiUtils from "../../utils/api";
    import * as domainApi from "@/api/mini/pagedomain";
    import * as recordApi from "@/api/mini/recordLog";
    import * as clueApi from "@/api/clue/cluePond";
    import {listCluePond} from "@/api/clue/cluePond";

    const GgdCard = () => import("../../components/GgdCard");
    const leavePhone = () => import ("./leavePhone")
    const recordLog = () => import ("./RecordLog")
    export default {
        name: "Statistics",
        data() {
            return {
                pondList:[],
                activeName: 'leave',
                queryParams: {},
                createTimeRange: null,
                pvCount: 0,
                uvCount: 0,
                sucCount: 0,
                sumCount: 0,
                adviserList: [],
                domainList: [],
                possList: [],
                mediatorList: [],
                statistics: {}
            }
        },
        components: {
            GgdCard,
            leavePhone,
            recordLog
        },
        mounted() {
            this.findPondList()
            this.getDomainList();
            this.getPossList();
            this.getMediatorList();
            this.statistic();
        },
        methods: {
            findPondList() {
                clueApi.listCluePond({}).then(res => {
                    if (res.success) {
                        this.pondList = res.data
                    }
                  this.pondList.unshift({id:1,name:'基础池'})
                })
            },
            exportRecord() {
                if (this.createTimeRange) {
                    this.queryParams['startTime'] = this.createTimeRange[0]
                    this.queryParams['endTime'] = this.createTimeRange[1]
                }
                this.$refs.record.exportRecord();
            },
            statistic() {
                recordApi.statistics(this.queryParams).then(res => {
                    this.pvCount = res.data.pv;
                    this.uvCount = res.data.uv;
                    this.sumCount = res.data.countData;
                    this.sucCount = res.data.successData;
                });
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            getDomainList() {
                domainApi.getList().then(res => {
                    this.domainList = res.data
                });
            },
            getPossList() {
                apiUtils.possessList().then(res => {
                    this.possList = res.data
                });
            },
            getMediatorList() {
                apiUtils.allMediators().then(res => {
                    this.mediatorList = res.data
                });
            },
            handleQuery() {
                if (this.createTimeRange) {
                    this.queryParams['startTime'] = this.createTimeRange[0]
                    this.queryParams['endTime'] = this.createTimeRange[1]
                }
                this.$refs.leave.salesList();
                this.$refs.record.recordList();
                this.statistic();
            },
            resetQuery() {
                this.createTimeRange = null;
                this.queryParams = {};
                this.$refs.leave.resetQuery();
            },
            exportLeavePhone() {
                if (this.createTimeRange) {
                    this.queryParams['startTime'] = this.createTimeRange[0]
                    this.queryParams['endTime'] = this.createTimeRange[1]
                }
                this.$refs.leave.exportLeavePhone();
            }
        }
    }
</script>

<style scoped>

</style>
