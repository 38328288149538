import request from '@/utils/request.js';

const url = "/manage/system/sysAccount";

//获取列表数据
export function listAccount(query) {
    return request({
        url: `${url}/oneListJson`,
        method: 'get',
        params: query
    })
}

//获取所有用户列表
export function list(query) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: query
    })
}

//保存
export function saveAccount(param) {
    return request.post(`${url}/save`, param);
}

//修改
export function updateAccount(param) {
    return request.post(`${url}/update`, param);
}

//修改密码
export function passwordUpdate(data) {
    return request.post(`${url}/passwordUpdate`, data);
}

// 重置密码
export function resetPwd(userId, pwd) {
    return request.put(`${url}/reset-pwd?userId=${userId}&pwd=${pwd}`);
}

//修改密码
export function updatePwdByUser(param) {
    return request.put(`${url}/update-pwd-by-user`, param);
}

//密码重置
export function passwordResetUpdate(accountId, password, checkPassword) {
    const data = {
        accountId: accountId,
        password: password,
        newPassword: password,
        checkPassword: checkPassword
    }
    return request.post(`${url}/passwordResetUpdate/${accountId}`, data);
}

//删除用户
export function deleteAccount(id) {
    return request.delete(`${url}/delete/${id}`);
}

//通过ID获取
export function getTrafficById(id) {
    return request.get(`${url}/getTrafficById/${id}`);
}

//通过ID获取
export function getAccount(id) {
    return request.get(`${url}/getOneAccount/${id}`);
}

//设为学习顾问
export function setAdviser(params, body) {
    return request({
        method: 'post',
        url: `${url}/setAdviser`,
        params: params,
        data: body
    })
}

//取消学习顾问
export function notAdviser(ids) {
    return request.post(`${url}/notAdviser`, ids);
}

//人员离职
export function resign(ids) {
    return request.post(`${url}/resign`, ids);
}

//修改人员加密权限
export function encodePhone(id) {
    return request.put(`${url}/encodePhone/${id}`);
}

//绑定话务
export function bindTraffic(accountId, tarId) {
    return request.put(`${url}/bindTraffic?accountId=${accountId}&tarId=${tarId}`)
}

//获取所有学习顾问
export function adviserList(query) {
    return request({
        url: `${url}/adviserList`,
        method: 'get',
        params: query
    })
}

//获取所有学习顾问(包含已离职)
export function adviserAll(query) {
    return request({
        url: `${url}/adviser-all`,
        method: 'get',
        params: query
    })
}

//获取所有学习顾问
export function adviserPage(query) {
    return request({
        url: `${url}/adviserPage`,
        method: 'get',
        params: query
    })
}

export function configureCountApi(params) {
    return request({
        url: `${url}/configure-count`,
        method: 'get',
        params: params
    })
}

/**
 * 修改用户手机号
 * @param accountId
 * @param telephone
 */
export function updateTelephone(accountId, telephone) {
    return request.put(`${url}/updateTelephone/${accountId}/${telephone}`);
}

/**
 * 修改学习顾问双呼配置
 * @param ids
 * @param doubleName
 */
export function updateDouble(ids, doubleName) {
    return request.put(`${url}/updateDouble?ids=${ids}&doubleName=${doubleName}`);
}

/**
 * 发送短信验证码
 * @param mobile
 */
export function sendCode(mobile) {
    return request.get(`/common/sendCode?mobile=${mobile}`);
}

/**
 * 解锁
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export function unlock(id) {
    return request.put(`${url}/unlock?id=${id}`)
}

/**
 * 校验手机号是否是账户绑定的
 * @param username
 * @param mobile
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkSendCode(username, mobile) {
    return request.get(`${url}/check-send-code?username=${username}&mobile=${mobile}`)
}

/**
 * 绑定个人手机号
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export function bindingPersonaPhone(data) {
    return request.post(`${url}/binding-personal-phone`, data)
}

/**
 * 手动退出登录
 * @param userid
 * @returns {Promise<AxiosResponse<any>>}
 */
export function logout(userid) {
    return request.put(`${url}/logout?id=${userid}`)
}

/**
 * 根据用户登录名称自动回显手机号
 * @param name
 * @returns {Promise<AxiosResponse<any>>}
 */
export function queryPhoneByName(name) {
    return request.get(`/query-phone.name?name=${name}`);
}

/**
 * 分页查询老师
 * @param params
 */
export function findTeacherPage(params) {
    return request({
        url: `${url}/findTeacherPage`, method: 'get', params: params
    })
}

/**
 * 保存为老师
 * @param params
 */
export function saveTeacher(params) {
    return request.put(`${url}/saveTeacher`, JSON.stringify(params))
}