<template>
   <div>
        <div>
            <div>
                <el-form ref="externalOrder" :model="externalOrder" label-width="80px" :inline="true">
                    <el-form-item label="渠道：">
                        <el-select v-model="externalOrder.channel" placeholder="请选择渠道" size="mini">
                            <el-option label="渠道一" value="shanghai"></el-option>
                            <el-option label="渠道二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" plain size="mini" @click="addOther">管理</el-button>
                    </el-form-item>
                    <el-form-item label="日期：">
                        <el-date-picker
                                size="mini"
                                v-model="createTimeRange"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="onSubmit">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table v-loading="loading" :data="ordersList">
                <el-table-column label="名字" align="center" prop="name"/>
                <el-table-column label="展示信息" align="center" prop="address"/>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">转义</el-button>
                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--<pagination
                    v-show="total>0"
                    :total="total"
                    :page.sync="queryParams.page"
                    :limit.sync="queryParams.limit"
                    @pagination="getList"
            />-->

            <!--            内部弹窗-->
            <el-dialog
                    v-if="innerOthervisible"
                    width="40%"
                    title="配置信息管理"
                    :visible.sync="innerOthervisible"
                    append-to-body>
                <ManagementChannels></ManagementChannels>
            </el-dialog>
        </div>
       <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="close">确 定</el-button>
        </span>-->
   </div>
</template>

<script>
import ManagementChannels from "@/components/order/ManagementChannels";
export default {
    name: "ExternalOrder",
    components: {
        ManagementChannels
    },
    props:{
        othervisible: {
            type: Boolean,
            require: false,
            default: () => {
                return {
                    othervisible: false
                }
            }
        }
    },
    data() {
        return {
            innerOthervisible: false,
            externalOrder: {},
            // 遮罩层
            loading: false,
            //时间
            createTimeRange:null,
            //表格数据
            ordersList: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
        }
    },
    methods:{
        /**
         * 提交搜索表单
         * 2022年7月19日15:58:06
        * */
        onSubmit(){
            if (this.createTimeRange) {
                this.externalOrder.createStarTime = this.createTimeRange[0] + " 00:00:00";
                this.externalOrder.createEndTime = this.createTimeRange[1] + " 59:59:59";
            }else{
                delete this.externalOrder.createStarTime
                delete this.externalOrder.createEndTime
            }
            console.log(this.createTimeRange)
            console.log(this.externalOrder)
        },
        /**
         * 新增选项
         * 2022年7月19日15:58:29
         * */
        addOther(){
            this.innerOthervisible = true
        },
        /**
         * 关闭弹窗
         * 2022年7月19日16:21:25
         * */
        close(){
            console.log(111)
            this.$emit("update:othervisible", false);
        },
        /**
         * 修改
         * */
        handleEdit(index, row) {
            console.log(index, row);
        },
        /**
         * 删除
         * */
        handleDelete(index, row) {
            console.log(index, row);
        }
    },
}
</script>

<style scoped>

</style>
