import request from '@/utils/request.js';

const url = "/manage/system/operationLog";

//获取列表数据
export function listOperationLog(query) {
    return request({
        url: `${url}/getOperatorLogList`,
        method: 'get',
        params: query
    })
}