<template>
    <el-tabs v-model="activeName" @tab-click="tabsClick">
        <el-tab-pane label="话务管理" name="first">
            <div class="app-container">
                <el-form
                        :model="queryParams"
                        ref="queryForm"
                        :inline="true"
                        v-show="showSearch"
                        label-width="68px"
                >
                    <el-form-item label="学习顾问姓名" prop="accountName" label-width="100">
                        <el-select v-model="queryParams.accountName" size="mini" filterable >
                            <el-option v-for="item in adviserList" :key="item.id" :value="item.nickName"
                                       :label="item.nickName"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工号" prop="user">
                        <el-input
                                v-model="queryParams.user"
                                placeholder="请输入工号"
                                clearable
                                size="small"
                                @keyup.enter.native="handleQuery"
                        />
                    </el-form-item>
                    <el-form-item label="使用状态" prop="isUse">
                        <el-select
                                v-model="queryParams.isUse"
                                placeholder="请选择使用状态"
                                filterable
                                clearable
                                size="small"
                        >
                            <el-option v-for="u in useStatus" :value="u.value" :label="u.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索
                        </el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>

                <el-row :gutter="10" class="mb8">
                    <el-col :span="1.5">
                        <el-button
                                v-permission="'crm:traffic:add'"
                                type="primary"
                                plain
                                icon="el-icon-plus"
                                size="mini"
                                @click="handleAdd"
                        >新增
                        </el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button
                                v-permission="'crm:traffic:delete'"
                                type="danger"
                                plain
                                icon="el-icon-delete"
                                size="mini"
                                :disabled="multiple"
                                @click="handleDelete"
                        >删除
                        </el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button
                                v-permission="'crm:traffic:unbind'"
                                type="danger"
                                plain
                                icon="el-icon-delete"
                                size="mini"
                                :disabled="multiple"
                                @click="unBind"
                        >解绑
                        </el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button
                                v-permission="'crm:traffic:import'"
                                type="primary"
                                plain
                                icon="el-icon-upload"
                                size="mini"
                                @click="handleImport"
                        >导入
                        </el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button
                                v-permission="'crm:traffic:config'"
                                type="info"
                                plain
                                icon="el-icon-s-tools"
                                size="mini"
                                @click="openShuangHu"
                        >双呼配置
                        </el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button
                                v-permission="'crm:traffic:export'"
                                type="success"
                                plain
                                icon="el-icon-download"
                                size="mini"
                                @click="exportData"
                        >导出
                        </el-button>
                    </el-col>
                </el-row>

                <el-table v-loading="loading" :data="trafficList" height="500px" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center"/>
                    <el-table-column label="主键" align="center" prop="id"/>
                    <el-table-column label="服务器地址" align="center" prop="host"/>
                    <el-table-column label="企业集团号" align="center" prop="enterpriseNum"/>
                    <el-table-column label="端口号" align="center" prop="port"/>
                    <el-table-column label="工号" align="center" prop="user"/>
                    <el-table-column label="密码" align="center" prop="passwd"/>
                    <el-table-column label="学习顾问姓名" align="center" prop="nickName"/>
<!--                    <el-table-column label="电话号码" align="center" prop="telephone"/>-->
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="handleUpdate(scope.row)"
                            >修改
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <pagination
                        v-show="total>0"
                        :total="total"
                        :page.sync="queryParams.page"
                        :limit.sync="queryParams.limit"
                        @pagination="getList"
                />

                <!-- 添加或修改参数配置对话框 -->
                <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
                    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                        <el-form-item label="服务器地址" prop="host">
                            <el-input v-model="form.host" placeholder="请输入服务器地址"/>
                        </el-form-item>
                        <el-form-item label="企业集团号" prop="enterpriseNum">
                            <el-input v-model="form.enterpriseNum" placeholder="请输入企业集团号"/>
                        </el-form-item>
                        <el-form-item label="端口号" prop="port">
                            <el-input v-model="form.port" placeholder="请输入端口号"/>
                        </el-form-item>
                        <el-form-item label="工号" prop="user">
                            <el-input v-model="form.user" placeholder="请输入工号"/>
                        </el-form-item>
                        <el-form-item label="密码" prop="passwd">
                            <el-input v-model="form.passwd" placeholder="请输入密码"/>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="submitForm">确 定</el-button>
                        <el-button @click="cancel">取 消</el-button>
                    </div>
                </el-dialog>

                <el-dialog :visible.sync="upload.open" :title="upload.title" width="400px" append-to-body>
                    <el-upload
                            ref="upload"
                            :limit="1"
                            accept=".xlsx, .xls"
                            :headers="upload.headers"
                            :action="upload.url"
                            :disabled="upload.isUploading"
                            :on-progress="handleFileUploadProgress"
                            :on-success="handleFileSuccess"
                            :auto-upload="false"
                            drag
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或
                            <em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">
                            <el-link type="info" style="font-size:20px" @click="importTemplate">下载模板</el-link>
                        </div>
                        <div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！
                        </div>
                    </el-upload>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="submitFileForm">确 定</el-button>
                        <el-button @click="upload.open = false">取 消</el-button>
                    </div>
                </el-dialog>

                <el-dialog title="双呼配置" :visible.sync="dialogVisible" width="80%">
                    <el-button
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="addTrafficBusiness()"
                    >添加新配置
                    </el-button>
                    <el-table :data="trafficBusinessList" ref="listTable" row-key="id" height="600">
                        <el-table-column property="configName" label="配置名称"></el-table-column>
                        <el-table-column property="companyCode" label="线路商">
                            <template v-slot="scope">
                                <span v-for="item in providers"
                                      :key="item.code" v-if="scope.row.companyCode === item.code">{{ item.name }}</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="sortWeights" label="权重"></el-table-column> -->
                        <el-table-column property="dayCount" label="每天拨打"></el-table-column>
                        <el-table-column property="weekCount" label="每周拨打"></el-table-column>
                        <el-table-column property="monthCount" label="每月拨打"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="updateTrafficBusiness(scope.row.id)">编辑
                                </el-button>
                                <el-button type="text" size="small" @click="deleteTrafficBusiness(scope.row.id)">删除
                                </el-button>
                                <div
                                        class="handle_move"
                                        style="display: inline-block;margin-left: 16px;"
                                >
                                    <el-tooltip class="item" effect="dark" content="按住拖动可改变展示顺序"
                                                placement="top">
                                        <i class="el-icon-s-fold"></i>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                </el-dialog>
                <!--      添加话务商对话框-->
                <el-dialog :title="trafficTitle" :visible.sync="addTrafficBusinessDialog" width="500px">
                    <el-form
                            status-icon
                            :model="trafficBusiness"
                            :rules="rulesTraffic"
                            ref="trafficBusiness"
                            label-width="120px"
                            class="demo-ruleForm"
                    >
                        <el-form-item label="配置名称" prop="configName">
                            <el-input size="mini" v-model="trafficBusiness.configName" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="公司编号" prop="companyCode">
                            <el-select size="mini" style="width: 100%" v-model="trafficBusiness.companyCode">
                                <el-option
                                        v-for="item in providers"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{
                                        item.company
                                        }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="企业标识" v-if="trafficShow" prop="appKey">
                            <el-input size="mini" v-model="trafficBusiness.appKey"></el-input>
                        </el-form-item>
                        <el-form-item label="企业秘钥" v-if="trafficShow" prop="appSecret">
                            <el-input size="mini" v-model="trafficBusiness.appSecret"></el-input>
                        </el-form-item>
                        <el-form-item label="备用字段" v-if="trafficShow" prop="spare">
                            <el-input size="mini" v-model="trafficBusiness.spare"></el-input>
                        </el-form-item>
                        <el-form-item label="每天拨打次数">
                            <template #label>
                                <el-tooltip class="item" effect="dark" content="每天的拨打限制次数,0为无限制"
                                            placement="top-start">
                                    <span><i class="el-icon-question"></i>每天拨打次数</span>
                                </el-tooltip>
                            </template>
                            <el-input size="mini" v-model="trafficBusiness.dayCount"></el-input>
                        </el-form-item>
                        <el-form-item label="每周拨打次数">
                            <template #label>
                                <el-tooltip class="item" effect="dark" content="每周拨打限制次数,0为无限制"
                                            placement="top-start">
                                    <span><i class="el-icon-question"></i>每周拨打次数</span>
                                </el-tooltip>
                            </template>
                            <el-input size="mini" v-model="trafficBusiness.weekCount"></el-input>
                        </el-form-item>
                        <el-form-item label="每月拨打次数">
                            <template #label>
                                <el-tooltip class="item" effect="dark" content="每月拨打限制次数,0为无限制"
                                            placement="top-start">
                                    <span><i class="el-icon-question"></i>每月拨打次数</span>
                                </el-tooltip>
                            </template>
                            <el-input size="mini" v-model="trafficBusiness.monthCount"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="addTrafficBusinessDialog = false">取 消</el-button>
                        <el-button type="primary" @click="saveTrafficBusiness()">保存</el-button>
                    </div>
                </el-dialog>
            </div>
        </el-tab-pane>
        <el-tab-pane label="所有配置" name="second">
            <div class="app-container">
                <el-form
                        :model="queryObj"
                        :inline="true"
                        label-width="68px"
                >
                    <el-form-item label="学习顾问姓名" prop="accountId" label-width="100">
                        <el-select v-model="queryObj.accountId" size="mini" filterable >
                            <el-option v-for="item in adviserList" :key="item.id" :value="item.id"
                                       :label="item.nickName"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机号" prop="phone" label-width="100">
                        <el-input
                            v-model="queryObj.phone"
                            clearable
                            size="small"
                        />
                    </el-form-item>
                    <el-form-item label="话务商" prop="companyCode" label-width="100">
                        <el-select v-model="queryObj.companyCode" size="mini" filterable >
                            <el-option v-for="item in providers" :key="item.code" :value="item.code"
                                       :label="item.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="searchTraffic">搜索
                        </el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="queryObj={}">重置</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="counselorList"  height="500px">
                    <!--                <el-table-column-->
                    <!--                        type="selection"-->
                    <!--                        width="55">-->
                    <!--                </el-table-column>-->
                    <el-table-column property="accountId" label="咨询师">
                        <template v-slot="scope">
                            <span v-for="item in adviserList" :key="item.id"
                                  v-if="scope.row.accountId === item.id">{{ item.nickName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="phone" label="手机号"></el-table-column>
                    <el-table-column property="companyCode" label="话务商">
                        <template v-slot="scope">
                        <span v-for="item in providers" :key="item.code"
                              v-if="item.code === scope.row.companyCode">{{ item.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="dayCount" label="每日拨打上限"></el-table-column>
                    <el-table-column property="weekCount" label="每周拨打上限"></el-table-column>
                    <el-table-column property="monthCount" label="每月拨打上限"></el-table-column>
                    <el-table-column property="createTime" label="创建时间"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <!--                        <el-button type="primary" size="mini" @click="editTraffic(scope.row)">编辑</el-button>-->
                            <el-button type="danger" size="mini" @click="delTraffic(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="queryObj.current"
                        :page-size="10"
                        layout="total, prev, pager, next, jumper"
                        :total="myTotal"
                ></el-pagination>
            </div>
        </el-tab-pane>
        <!--        <el-tab-pane label="角色管理" name="third">角色管理</el-tab-pane>-->
        <!--        <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>-->
    </el-tabs>
</template>

<script>
import * as trafficApi from "@/api/crm/traffic";
import * as trafficBusinessApi from "@/api/crm/trafficBusiness";
import {resetForm} from "@/utils/utils";
import {deleteNewAdviser, selectAdviserForPage,queryTrafficCallSortPage} from "@/api/crm/newAdviser";
import Sortable from "sortablejs";
import {adviserList} from "@/api/system/account"
import axios from "axios";

export default {
    name: "Traffic",
    components: {},
    data() {
        return {
            activeName: "first",
            rulesTraffic: {
                configName: [
                    {required: true, message: '请设置配置名称', trigger: 'change'}
                ],
                companyCode: [
                    {required: true, message: '请选择支持的线路商', trigger: 'change'}
                ],
                appKey: [
                    {required: true, message: '请输入线路商提供的Key', trigger: 'change'}
                ],
                appSecret: [
                    {required: true, message: '请输入线路商提供的Secret', trigger: 'change'}
                ],
            },
            addTrafficBusinessDialog: false,
            trafficTitle: "添加新配置",
            trafficShow: true,
            dialogVisible: false,
            trafficBusinessList: [],
            providers: [],
            doubleList: [],
            doubleSync: false,
            useStatus: [
                {value: null, label: "选择使用状态"},
                {value: 1, label: "已使用"},
                {value: 0, label: "未使用"}
            ],
            // 遮罩层
            loading: true,
            // 导出遮罩层
            exportLoading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 参数配置表格数据
            trafficList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                page: 1,
                limit: 10,
                accountName: null,
                user: null,
                isUse: null
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {},
            accountIds: [],
            upload: {
                open: false,
                title: "",
                isUploading: false,
                url: `/api/manage/crm/traffic/importTraffic`
            },
            queryObj: {
                current: 1,
                accountPhone: ""
            },
            counselorList: [],
            myTotal: 0,
            trafficBusiness: {
                configName: null,
                companyCode: null,
                companyFullName: null,
                appKey: null,
                appSecret: null,
                spare: null,
                dayCount: 5,
                weekCount: 5,
                monthCount: 5
            },
            adviserList: []
        };
    },

    created() {
        this.findTrafficProviders();
        this.getList();
        this.findAdviserList()
    },
    mounted() {

    },
    methods: {
        exportData() {
            axios({
                method: 'POST',
                url: `/api/manage/crm/traffic/export`,
                params: this.queryParams,
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "version": localStorage.getItem("_version"),
                },
            }).then(res => {
                let now = new Date();
                const blob = new Blob([res.data]);
                const fileName = '话务记录' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            })
        },
        findAdviserList() {
            adviserList().then(res => {
                if (res.success) {
                    this.adviserList = res.data
                }
            })
        },
        /**
         * 标签切换
         * @param tab
         * @param event
         */
        tabsClick(tab, event) {
            if (this.activeName == 'first') {
                this.getList();
            }
            if (this.activeName == 'second') {
                this.queryTrafficForPage();
            }
        },
        restartTraffic() {
            this.trafficBusiness = {
                configName: null,
                companyCode: null,
                companyFullName: null,
                appKey: null,
                appSecret: null,
                spare: null,
                dayCount: 5,
                weekCount: 5,
                monthCount: 5
            }
        },
        openShuangHu() {
            this.dialogVisible = true
            this.queryTrafficBusiness()
        },
        addTrafficBusiness() {
            this.addTrafficBusinessDialog = true;
            this.trafficTitle = "添加新配置";
            this.trafficShow = true
            this.restartTraffic()
        },
        findTrafficProviders() {
            trafficApi.trafficProviders().then(res => {
                this.providers = res;
            });
        },
        saveTrafficBusiness() {
            this.$refs["trafficBusiness"].validate((valid) => {
                if (valid) {
                    if (this.trafficBusiness.id) {
                        trafficBusinessApi.updateTrafficBusiness(this.trafficBusiness)
                            .then(res => {
                                if (res.success) {
                                    this.$message.success(res.msg);
                                    this.queryTrafficBusiness();
                                    this.addTrafficBusinessDialog = false;
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                    } else {
                        trafficBusinessApi.saveTrafficBusiness(this.trafficBusiness).then(res => {
                            if (res.success) {
                                this.$message.success(res.msg);
                                this.queryTrafficBusiness();
                                this.addTrafficBusinessDialog = false;
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    }
                }
            })
        },
        updateTrafficBusiness(id) {
            this.trafficTitle = "修改配置";
            this.trafficShow = false
            // this.$refs["trafficBusiness"].resetFields();
            this.trafficBusiness = trafficBusinessApi.findTrafficBusinessById(id)
                .then(res => {
                    this.trafficBusiness = res.data;
                    this.addTrafficBusinessDialog = true;
                });
        },

        deleteTrafficBusiness(id) {
            this.$confirm("此操作将永久删除该话务信息, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })

                .then(() => {
                    trafficBusinessApi.deleteTrafficBusiness(Number(id)).then(res => {
                        if (res.success) {
                            this.$message.success(res.msg);
                            this.queryTrafficBusiness();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })

                .catch(() => {
                    console.log('111111')
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        addDouble: function () {
            if (!this.doubleList) {
                this.doubleList = [];
            }
            let key = new Date().Format(`KEYyyyyMMddhhmmss`);
            this.doubleList.push({
                name: key,
                title: "双呼" + new Date().Format("yyyy-MM-dd")
            });
        },
        /**
         * 查询双呼信息
         */
        queryTrafficBusiness() {
            trafficBusinessApi.findTrafficBusinessList({}).then(res => {
                if (res.code === 200) {
                    if (res.data.length > 0) {
                        this.trafficBusinessList = res.data;
                        this.rouDrop()
                    } else {
                        this.$message.error(res.msg);
                    }
                }
            });
        },
        rouDrop() {
            console.log("rouDrop", 111)
            let el = this.$refs.listTable.$el.querySelector(
                ".el-table__body-wrapper tbody"
            );
            let _this = this;
            let sortable = new Sortable(el, {
                handle: ".handle_move",
                draggable: '.el-table__row', // 允许拖拽的项目类名
                animation: 1000,

                onMove(evt) {
                    evt.oldIndex = evt.dragged.rowIndex;
                    evt.newIndex = evt.related.rowIndex;
                    // return false;  // 不进行交换
                },
                onEnd(evt) {
                    const currRow = _this.trafficBusinessList.splice(evt.oldIndex, 1)[0]
                    _this.trafficBusinessList.splice(evt.newIndex, 0, currRow);
                    let dragdata = [];
                    console.log("onEnd", _this.trafficBusinessList)


                    const new_arr = _this.trafficBusinessList.map((item, index) => {
                        //    _this.trafficBusinessList[index].sortWeights=evt.newIndex
                        return {
                            weight: index,
                            id: item.id
                        }
                    });


                    trafficBusinessApi.systemConfiguration(new_arr).then(res => {
                        _this.queryTrafficBusiness()
                    })
                        .catch(error => {
                            console.error(error, "2");
                        });
                },


            });


        },


        /** 查询参数配置列表 */
        getList() {
            this.loading = true;
            trafficApi.listTraffic(this.queryParams).then(response => {
                this.trafficList = response.data.records;
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {};
            // resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.page = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            // resetForm("queryForm");
            this.$refs.queryForm.resetFields();
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.accountIds = selection.map(item => item.accountId);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },
        unBind(row) {
            this.reset();
            const ids = row.accountId || this.accountIds;
            trafficApi.unBind(ids).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.open = false;
                    this.getList();
                } else {
                    this.$message.warning("操作失败:" + res.msg);
                    this.getList();
                }
            });
        },
        handleDelete(row) {
            const ids = row.id || this.ids;
            trafficApi.deleteTraffic(ids).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.open = false;
                    this.getList();
                } else {
                    this.$message.warning("操作失败:" + res.msg);
                    this.getList();
                }
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id;
            trafficApi.getTrafficById(id).then(response => {
                this.form = response.data;
                this.open = true;
                this.title = "修改";
            });
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    trafficApi.saveOrUpdate(this.form).then(response => {
                        if (response.success) {
                            this.msgSuccess("修改成功");
                            this.open = false;
                            this.getList();
                        } else {
                            this.$message.warning("操作失败:" + response.msg);
                            this.getList();
                        }
                    });
                }
            });
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = "话务导入";
            this.upload.open = true;
        },
        /** 下载模板操作 */
        async importTemplate() {
            let url = "../excel/traffic_template.xlsx";
            const res = await fetch(url);
            const blob = await res.blob();
            const elink = document.createElement("a");
            elink.download = url.substr(url.lastIndexOf("/") + 1);
            elink.style.display = "none";
            elink.href = window.URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            window.URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            let msg = "导入成功";
            if (response.data.msgList) {
                msg = "";
                for (let m of response.data.msgList) {
                    msg += `<span>${m}</span><br/>`;
                }
            }
            this.$alert(msg, "导入结果", {dangerouslyUseHTMLString: true});
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        },
        /**
         * 默认分页查询下
         */
        queryTrafficForPage() {
            queryTrafficCallSortPage(this.queryObj)
                .then(res => {
                    if (res.success) {
                        this.counselorList = res.data.data;
                        this.myTotal = res.data.total
                    }
                })
                .catch(error => {
                    this.$message.info("没有数据，亲稍后再试");
                });
        },
        /**
         * 条件查询
         */
        searchTraffic() {
            this.queryObj.current = 1;
            this.queryTrafficForPage();
        },
        handleCurrentChange(pageNo) {
            this.queryObj.current = pageNo;
            this.queryTrafficForPage();
        },
        //删除操作
        delTraffic(row) {
            const ids = [];
            ids.push(row.id);
            this.$confirm("真的要删除这条记录吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    deleteNewAdviser(ids)
                        .then(res => {
                            this.$message.success("删除成功");
                        })
                        .catch(error => {
                            this.$message.info("删除失败，亲稍后再试");
                        })
                        .finally(() => {
                            this.ids = [];
                            this.queryObj.current = 1;
                            this.queryObj.accountPhone = "";
                            this.queryTrafficForPage();
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        }
    }
};
</script>
<style scoped>
.handle_move {
    cursor: pointer;
}
</style>
