<template>
    <div>
        <el-dialog :destroy-on-close="true"
                   append-to-body
                   title="申请退款"
                   :visible="openRefundDetail"
                   @close="cancel"
                   width="80%">
            <el-form ref="refForm" :rules="formRules" :inline="true" label-position="right" :model="form"
                     label-width="130px">
                <div style="font-size: 18px;font-weight: 600;margin-bottom: 10px">||基本信息</div>
                <el-form-item label="机会ID" prop="opportunityId">
                    <el-input v-model="form.opportunityId"
                              size="mini"
                              type="number"
                              :disabled="refundUpdate"
                              @blur="getOrderNumberList(1)"/>
                </el-form-item>

                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="form.phone"
                              size="mini"
                              :disabled="refundUpdate"
                              @blur="getOrderNumberList(2)"/>
                </el-form-item>

                <div style="font-size: 18px;font-weight: 600;margin-bottom: 10px">||订单信息</div>

                <el-form-item label="订单编号" prop="orderNumber">
                    <el-select v-model="form.orderNumber" size="mini" :disabled="refundUpdate"
                               @change="changeOrderNumber"
                               placeholder="请选择退款订单">
                        <el-option-group v-for="group in orderNumberList" :key="group.status" :label="group.label">
                            <el-option v-for="(item, index) in group.list" :value="item" :key="index" :label="item"/>
                        </el-option-group>
                    </el-select>
                    <el-tooltip class="item" effect="dark" content="复制" placement="top-start">
                        <el-button style="margin-left: 10px" icon="el-icon-document-remove" circle size="mini"
                                   v-clipboard:copy="form.orderNumber"
                                   v-clipboard:success="onCopy"
                                   v-clipboard:error="onError"/>
                    </el-tooltip>
                </el-form-item>

                <el-form-item label="支付金额" prop="payPrice">
                    <el-input-number v-model="form.payPrice" disabled size="mini" type="number"
                                     label="支付金额"/>
                </el-form-item>

                <el-form-item label="购买商品名称" prop="goodsName">
                    <el-input v-model="form.goodsName" size="mini" disabled/>
                </el-form-item>

<!--                <el-form-item label="支付渠道" prop="payType">-->
<!--                    <el-select v-model="form.payType" size="mini" disabled>-->
<!--                        <el-option v-for="item in payTypeArr" :key="item.id" :value="item.dictValue"-->
<!--                                   :label="item.dictLabel"-->
<!--                                   :disabled="item.dictStatus === 1"/>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->

                <el-form-item label="支付时间" prop="payTime">
                    <el-date-picker
                            v-model="form.payTime"
                            type="date"
                            disabled
                            size="mini"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="归属学习顾问" prop="adviserId">
                    <el-select style="width: 100%" v-model="form.adviserId" disabled
                               placeholder="请选择学习顾问" size="mini" filterable>
                        <el-option
                                v-for="item in adviserList"
                                :key="item.id"
                                :label="item.nickName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

<!--                <el-form-item label="订单分类" prop="orderChannel">-->
<!--                    <el-select v-model="form.orderChannel" size="mini" disabled>-->
<!--                        <el-option v-for="item in orderChannelArr"-->
<!--                                   :key="item.id"-->
<!--                                   :value="item.dictValue"-->
<!--                                   :label="item.dictLabel"-->
<!--                                   :disabled="item.dictStatus === 1"/>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->

                <el-form-item label="提交人" prop="submitId">
                    <div v-for="item in adviserList">
                        <el-tag v-if="item.id === form.submitId">{{ item.nickName }}</el-tag>
                    </div>
                </el-form-item>


                <div style="font-size: 18px;font-weight: 600;margin-bottom: 10px">||退款信息</div>

                <el-form-item label="退款金额" prop="refundPrice">
                    <el-input-number v-model="form.refundPrice" :disabled="refundUpdate" size="mini" :min="1"
                                     label="描述文字" :max="form.payPrice"/>
                </el-form-item>

                <el-form-item label="退款方式" prop="refundType">
                    <el-input :row="10" autosize placeholder="请输入退款方式"
                              maxlength="100" size="mini" v-model="form.refundType" :disabled="refundUpdate"/>
                </el-form-item>

                <el-form-item label="退款备注" prop="refundReason">
                    <el-input type="textarea" :row="10" autosize placeholder="必要时可输入备注内容"
                              maxlength="100" v-model="form.refundReason" :disabled="refundUpdate"/>
                </el-form-item>


                <el-row>
                    <el-form-item label="退款截图" prop="refundImages">

                        <div style="display: flex;flex-direction: row;">

                            <el-upload :disabled="refundUpdate"
                                       class="upload-demo"
                                       action="/api/manage/file/uploadImg"
                                       :headers="headers"
                                       :on-success="(res,fileList)=>handleAvatarSuccess(res,fileList,0)"
                                       :on-preview="handlePictureCardPreview"
                                       :on-remove="(res,fileList)=>handleRemove(res, fileList,0)"
                                       :file-list="fileList"
                                       :on-error="handleError"
                                       list-type="picture-card">
                                <el-button size="small" type="primary" :disabled="refundUpdate">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.jpg/.png文件，且不超过5MB</div>
                            </el-upload>

                        </div>


                    </el-form-item>
                </el-row>

                <div v-if="refundUpdate" style="font-size: 18px;font-weight: 600;margin-bottom: 10px">||审核信息</div>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="一审审批意见" prop="firstSuggest" v-if="firstSuggestSync">
                            <el-input style="width: 50vw" type="textarea" :rows="5" placeholder="请输入意见"
                                      :disabled="firstSuggestDisabled"
                                      v-model="form.firstSuggest"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="一审意见回执" prop="firstSuggestImages" v-if="firstSuggestSync">
                            <el-upload :disabled="firstSuggestDisabled"
                                       class="upload-demo"
                                       action="/api/manage/file/uploadImg"
                                       :headers="headers"
                                       :on-success="(res,fileListBack1)=>handleAvatarSuccess(res,fileListBack1,1)"
                                       :on-preview="handlePictureCardPreview"
                                       :on-remove="(res,fileListBack1)=>handleRemove(res, fileListBack1,1)"
                                       :file-list="fileListBack1"
                                       :on-error="handleError"
                                       list-type="picture-card">
                                <el-button size="small" :disabled="firstSuggestDisabled">点击上传<br><br>回执图片
                                </el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.jpg/.png文件，且不超过5MB</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <!--          二审可见可编辑-->
                    <el-col :span="24">
                        <el-form-item label="财务审批意见" prop="secondSuggest" v-if="secondSuggestSync">
                            <el-input style="width: 50vw" type="textarea" :rows="5" placeholder="请输入意见"
                                      :disabled="secondSuggestDisabled"
                                      v-model="form.secondSuggest"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="二审意见回执" prop="secondSuggestImages" v-if="secondSuggestSync">
                            <el-upload :disabled="secondSuggestDisabled"
                                       class="upload-demo"
                                       action="/api/manage/file/uploadImg"
                                       :headers="headers"
                                       :on-success="(res,fileListBack2)=>handleAvatarSuccess(res,fileListBack2,2)"
                                       :on-preview="handlePictureCardPreview"
                                       :on-remove="(res,fileListBack2)=>handleRemove(res, fileListBack2,2)"
                                       :file-list="fileListBack2"
                                       :on-error="handleError"
                                       list-type="picture-card">
                                <el-button size="small" :disabled="secondSuggestDisabled">点击上传<br><br>回执图片
                                </el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.jpg/.png文件，且不超过5MB</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <div v-if="showHistoryData" style="font-size: 18px;font-weight: 600;margin-bottom: 10px">||退款记录</div>

            <refund-sync-data v-if="showHistoryData" :order-number="form.orderNumber" :adviser-list="adviserList"/>

            <div slot="footer" style="text-align: end" class="dialog-footer">
                <el-popconfirm
                        v-if="examineYes"
                        title="审核通过后，将对此单子的真实性负责到底。请认真核对!"
                        @confirm="examineSubmit"
                        style="margin-right: 10px">
                    <el-button slot="reference" type="primary"
                               size="mini">通 过
                    </el-button>
                </el-popconfirm>


                <el-button type="primary" @click="examineBack"
                           v-if="examineNo"
                           size="mini">
                    驳
                    回
                </el-button>
                <el-button type="primary" @click="submitRepair" v-if="!refundUpdate" size="mini">提 交</el-button>
                <el-button type="primary" @click="complaintSubmitRepair" v-if="complaintSubmit" size="mini">申诉提交
                </el-button>
                <el-button @click="cancel" size="mini">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="imgDialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

        <el-dialog
                title="扫码添加业务审核人员企业微信"
                :visible.sync="addFirstSuggestPeopleDialogVisible"
                width="60%" append-to-body>
            <el-image :src="require('@/assets/images/addFirstSuggestPeople2.png')" fit="contain"></el-image>
            <span slot="footer"><el-button type="primary" @click="submitRepair">确 定</el-button></span>
        </el-dialog>

    </div>
</template>

<script>
import * as refundApi from "@/api/crm/refundRepair"
import * as ordersApi from "@/api/crm/orders";
import * as dictApi from "@/api/system/dictionary"
import RefundSyncData from "@/views/crm/orders/RefundSyncData.vue"

export default {
    name: "RefundRepairDetail",
    components: {RefundSyncData},
    props: {
        refundId: {
            type: Number,
            required: false
        },
        refundStatus: {
            type: Number,
            required: false
        },
        openRefundDetail: {
            type: Boolean,
            default: false
        },
        examineType: {
            type: Number,
            required: false
        },
        adviserList: {
            type: Array,
            required: true,
            default: []
        },
        orderNumber: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        var validateNumber = (rule, value, callback) => {
            if (value.length > 9) {
                callback(new Error('请输入正确的机会ID'))
            }
            if (!(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(value)) {
                callback(new Error('请输入数字值'))
            } else {
                callback()
            }
        };
        return {
            headers: {"version": localStorage.getItem("_version"),},
            form: {
                opportunityId: '',
                phone: '',
                orderNumber: '',
                payPrice: 0,
                goodsName: null,
                payType: null,
                payTime: null,
                adviserId: null,
                orderChannel: null,
                submitId: null,
                refundPrice: 0,
                refundType: '',
                refundReason: '',
                refundImages: '',
                firstSuggestText: '',
                firstSuggestImages: '',
                secondSuggestText: '',
                secondSuggestImages: '',
            },
            refundUpdate: false,
            orderNumberList: [],
            payTypeArr: [],
            orderChannelArr: [],
            fileList: [],
            fileListBack1: [],
            fileListBack2: [],
            firstSuggestSync: true,
            secondSuggestSync: true,
            firstSuggestDisabled: true,
            secondSuggestDisabled: true,
            examineYes: true,
            examineNo: true,
            complaintSubmit: false,
            imgDialogVisible: false,
            dialogImageUrl: '',
            formRules: {
                opportunityId: [
                    {required: true, message: '请输入机会ID', trigger: 'blur'},
                    {validator: validateNumber, trigger: 'blur'}
                ],
                phone: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                ],
                orderNumber: [
                    {required: true, message: '请输入订单编号', trigger: 'blur'},
                ],
                refundPrice: [
                    {required: true, message: '请输入退款金额', trigger: 'blur'},
                ]
            },
            addFirstSuggestPeopleDialogVisible: false,
            showHistoryData: false,
        }
    },
    mounted() {
        // 支付渠道数据
        dictApi.list({dictParent: 15}).then(resp => {
            if (resp.success) {
                this.payTypeArr = resp.data
            } else this.$message.error(resp.msg)
        })
        // 订单分类数据
        dictApi.list({dictParent: 16}).then(resp => {
            if (resp.success) {
                this.orderChannelArr = resp.data
            } else this.$message.error(resp.msg)
        })
        this.init();
    },
    methods: {
        init() {
            // 更新需要禁用页面数据修改,只允许编辑审核意见
            this.refundUpdate = true // 是否是更新类操作
            this.firstSuggestSync = false // 一审不显示
            this.secondSuggestSync = false // 二审不显示
            this.examineYes = false // 是否展示通过
            this.examineNo = false // 是否展示驳回
            if (this.refundId) {
                this.getInfo();
            }
            switch (this.refundStatus) {
                case 0: // 新增
                    console.log('新增')
                    this.$nextTick(() => {
                        console.log('重置')
                        this.$refs['refForm'].resetFields();
                    })
                    this.refundUpdate = false;
                    if (this.orderNumber) {
                        this.form.orderNumber = this.orderNumber
                        this.changeOrderNumber();
                    }
                    break;
                case 1: // 一审
                    console.log('一审')
                    this.firstSuggestSync = true // 一审显示
                    this.firstSuggestDisabled = false
                    this.examineYes = true
                    this.examineNo = true
                    break;
                case 2: // 二审
                    console.log('二审')
                    this.firstSuggestSync = true // 一审显示
                    this.secondSuggestSync = true // 二审显示
                    this.examineYes = true
                    this.examineNo = true
                    this.firstSuggestDisabled = true
                    this.secondSuggestDisabled = false
                    break;
                case 3: // 详情
                    console.log('详情')
                    this.firstSuggestSync = true // 一审显示
                    this.secondSuggestSync = true // 二审显示
                    break;
                case 4: // 修改
                    console.log('修改')
                    this.refundUpdate = false;
                    this.firstSuggestSync = true // 一审显示
                    this.secondSuggestSync = true // 二审显示
                    break
            }
        },
        changeOrderNumber() {
            this.showHistoryData = false
            ordersApi.getByOrderNumber(this.form.orderNumber).then(res => {
                if (res.success) {
                    this.form.payPrice = res.data.paymentAmount
                    this.form.goodsName = res.data.goodsName
                    let payType = res.data.paymentType;
                    if (payType === 'WEIXIN') {
                        payType = 'WECHAT'
                    } else if (payType === 'COMPANY') {
                        payType = 'BANK'
                    }
                    this.form.payType = payType
                    this.form.payTime = res.data.paymentTime
                    this.form.adviserId = res.data.adviserAccountId
                    this.form.orderChannel = res.data.orderChannel
                    this.form.submitId = res.data.submitterAccountId
                    this.form.opportunityId = res.data.opporId
                    this.form.phone = res.data.studentPhone
                    this.showHistoryData = true;
                }
            })
        },
        getOrderNumberList(type) {
            const params = {
                opportunityId: '',
                phone: ''
            }
            if (type === 1) {
                params.opportunityId = this.form.opportunityId
            } else if (type === 2) {
                params.phone = this.form.phone
            }
            refundApi.getOrderNumberList(params).then(res => {
                if (res.success) {
                    this.$refs['refForm'].resetFields();
                    this.orderNumberList = res.data.orderNumberList
                    this.form.opportunityId = res.data.opportunityId
                    this.form.phone = res.data.phone
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        cancel() {
            console.log('关闭')
            this.$refs['refForm'].resetFields();
            this.fileList = []
            this.fileListBack1 = []
            this.fileListBack2 = []
            this.$emit('handleQuery');
            this.$emit('changeDetail', false);
        },
        handleAvatarSuccess(res, file, type) {
            let sp = res.msg.split("/");
            switch (type) {
                case 0:
                    this.fileList.push({"name": sp[sp.length - 1], "url": res.msg})
                    break;
                case 1:
                    this.fileListBack1.push({"name": sp[sp.length - 1], "url": res.msg})
                    break;
                case 2:
                    this.fileListBack2.push({"name": sp[sp.length - 1], "url": res.msg})
                    break;
            }
        },
        handleError(event) {
            this.$message.error("图片上传失败")
        },
        onCopy(e) {    // 复制成功
            this.$message({
                message: '复制成功！',
                type: 'success'
            })
        },
        onError(e) {   // 复制失败
            this.$message({
                message: '复制失败！',
                type: 'error'
            })
        },
        /**
         * 图片预览
         * @param file
         */
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.imgDialogVisible = true;
        },
        handleRemove(file, fileList, type) {
            switch (type) {
                case 0:
                    this.fileList = fileList;
                    break;
                case 1:
                    this.fileListBack1 = fileList;
                    break;
                case 2:
                    this.fileListBack2 = fileList;
                    break;
            }

        },
        // 通过
        examineSubmit() {
            const images = []
            if (this.form.status === 1) {
                console.log('一审', this.fileListBack1)
                for (let item of this.fileListBack1) {
                    images.push(item.url)
                }
                this.form.firstSuggestImages = images.join(',')
            } else if (this.form.status === 2) {
                console.log('二审', this.fileListBack2)
                for (let item of this.fileListBack2) {
                    images.push(item.url)
                }
                this.form.secondSuggestImages = images.join(',')
            } else {
                this.$message.error("状态异常")
                return;
            }
            console.log('通过', this.form);
            refundApi.examinePass(this.form).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.cancel();
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 驳回
        examineBack() {
            const images = []
            if (this.form.status === 1) {
                console.log('一审', this.fileListBack1)
                for (let item of this.fileListBack1) {
                    images.push(item.url)
                }
                this.form.firstSuggestImages = images.join(',')
            } else if (this.form.status === 2) {
                console.log('二审', this.fileListBack2)
                for (let item of this.fileListBack2) {
                    images.push(item.url)
                }
                this.form.secondSuggestImages = images.join(',')
            } else {
                this.$message.error("状态异常")
                return;
            }
            console.log('驳回', this.form);
            const params = Object.assign({}, this.form)
            params.payPrice = params.payPrice * 100
            params.refundPrice = params.refundPrice * 100
            refundApi.examineBack(params).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.cancel();
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 预提交
        beforeSubmitRepair() {
            console.log("预提交")
            this.$refs['refForm'].validate((valid) => {
                if (valid) {
                    this.addFirstSuggestPeopleDialogVisible = true
                } else {
                    this.$message.error("请正确填写信息!")
                }
            })
        },
        // 提交
        submitRepair() {
            console.log("提交")
            if (this.form.refundPrice === 0) {
                this.$message.error('退款金额必须大于0')
                return;
            }
            console.log(0, this.form)
            const params = Object.assign({}, this.form)
            params.payPrice = this.form.payPrice * 100;
            params.refundPrice = this.form.refundPrice * 100;
            params.refundImages = this.fileList.map(item => item.url).join(',')
            refundApi.submitRefundRepair(params).then(res => {
                if (res.success) {
                    this.$message.success("提交成功!")
                    this.cancel();
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 申诉提交
        complaintSubmitRepair() {
        },
        // 获取申请详情
        getInfo() {
            refundApi.getById(this.refundId).then(res => {
                this.form = res.data;
                this.showHistoryData = true;
                this.form.refundPrice = res.data.refundPrice / 100
                this.form.payPrice = res.data.payPrice / 100
                this.fileList = []
                if (this.form.refundImages) {
                    if (this.form.refundImages && this.form.refundImages.length > 1) {
                        let refundImageArr = this.form.refundImages.split(",")
                        if (refundImageArr) {
                            for (let item of refundImageArr) {
                                let sp = item.split("/");
                                this.fileList.push({"name": sp[sp.length - 1], "url": item})
                            }
                        }
                    }
                }

                this.fileListBack1 = []
                if (this.form.firstSuggestImages !== null && this.form.firstSuggestImages.length > 1) {
                    let firstSuggestImageArr = this.form.firstSuggestImages.split(",")
                    if (firstSuggestImageArr) {
                        for (let item of firstSuggestImageArr) {
                            let sp = item.split("/");
                            this.fileListBack1.push({"name": sp[sp.length - 1], "url": item})
                        }
                    }
                }

                this.fileListBack2 = []
                if (this.form.secondSuggestImages !== null && this.form.secondSuggestImages.length > 1) {
                    let secondSuggestImageArr = this.form.secondSuggestImages.split(",")
                    if (secondSuggestImageArr) {
                        for (let item of secondSuggestImageArr) {
                            let sp = item.split("/");
                            this.fileListBack2.push({"name": sp[sp.length - 1], "url": item})
                        }
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
#abc {
    width: 100%;
    height: 100vh;
    background: url("../../assets/images/background.png") 50% 0 /cover;
}

.custom--order-info-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #eeeeee;
    padding: 10px 20px 20px 20px;

    .order-item-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .order-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 400px;
            font-size: 16px;
        }
    }
}

/deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/deep/ ._update-label {
    .el-form-item__label {
        color: red !important;
    }
}
</style>