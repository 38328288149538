import { render, staticRenderFns } from "./ExternalOrder.vue?vue&type=template&id=9ada8726&scoped=true&"
import script from "./ExternalOrder.vue?vue&type=script&lang=js&"
export * from "./ExternalOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ada8726",
  null
  
)

export default component.exports