import request from '@/utils/request.js';

const url = "/manage/promote/config";

//添加列表数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: params
    })
}

//获取列表数据
export function list(params) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: params
    })
}

//删除当前列表
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}

//删除当前列表
export function updateStatus(id, status) {
    return request.get(`${url}/updateStatus/${id}/${status}`);
}