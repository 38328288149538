<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="标识" prop="code">
        <el-input v-model="queryParams.code" placeholder="标识" clearable size="small"/>
      </el-form-item>
      <el-form-item label="获客链接" prop="customerUrl">
        <el-input v-model="queryParams.customerUrl" placeholder="获客链接" clearable size="small"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="findList">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button type="success" icon="el-icon-plus" size="mini" @click="addConfig">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" stripe style="width: 100%">
      <el-table-column prop="id" label="主键"></el-table-column>
      <el-table-column prop="code" label="标识"></el-table-column>
      <el-table-column prop="code" label="请求地址">
        <template v-slot="scope">
          <span>{{ "/wework/qr/" + scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerUrl" label="对应获客链接地址"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="edit(scope.row,scope.row.id)"
          >修改
          </el-button>
          <!--          <el-button-->
          <!--              size="mini"-->
          <!--              type="text"-->
          <!--              icon="el-icon-delete"-->
          <!--              @click="deleteCurrent(scope.row.id)"-->
          <!--          >删除-->
          <!--          </el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30, 40]" :page.sync="queryParams.current"
                @pagination="findList"
                :limit.sync="queryParams.size"/>

    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogFormVisible"
        width="600px"
        @close="resetFrom('roleForm')"
    >
      <el-form
          :model="dialogForm"
          ref="roleForm"
          label-width="120px"
      >
        <el-form-item label="标识">
          <el-input :disabled="dialogForm.id" v-model="dialogForm.code"></el-input>
        </el-form-item>
        <el-form-item label="获客链接地址">
          <el-input v-model="dialogForm.customerUrl"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="success" @click="save('roleForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import * as weworkQrcodeApi from "@/api/wework/weworkQrcode";

export default {
  name: "weworkQrcode",
  data() {
    return {
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        current: 1,
        size: 10
      },
      dataList: [],
      dialogFormVisible: false,
      dialogTitle: "",
      dialogForm: {
        name: "",
        detail: ""
      },
      dialogForm_null: Object.assign({}, this.dialogForm),
    }
  },
  mounted() {
    this.findList();
  },
  methods: {
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams = {};
      this.findList();
    },
    /*
    * 获取列表数据
    * */
    findList() {
      weworkQrcodeApi.pageWeworkQrcode(this.queryParams).then(res => {
        this.dataList = res.data.data
        this.total = res.data.total
      });
    },
    //新增
    addConfig() {
      this.dialogTitle = "添加配置";
      this.dialogForm = Object.assign({}, this.dialogForm_null);
      this.dialogFormVisible = true;
    },
    //重置表单规则
    resetFrom(roleForm) {
      this.$refs[roleForm].clearValidate();
    },
    handleButton(val) {
      //调用事件
      this[val.methods](val.row, val.index);
    },
    edit(row, index) {
      this.dialogTitle = "修改配置";
      this.dialogForm = Object.assign({}, row);
      this.dialogFormVisible = true;
    },

    /*
    * 新增/修改 保存列表数据
    * */
    save(roleForm) {
      this.$refs[roleForm].validate(valid => {
        if (valid) {
          let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
          weworkQrcodeApi.saveWeworkQrcode(this.dialogForm).then(res => {
            this.$message({
              type: "success",
              message: id ? "修改成功！" : "新增成功！"
            });
            this.dialogFormVisible = false;
            this.findList()
          });

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /*
    * 删除
    * */
    deleteCurrent(id) {
      weworkQrcodeApi.deleteWeworkQrcode(id).then(res => {
        this.$message({
          type: "success",
          message: "删除成功"
        });
      })
    },
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>