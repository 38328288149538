<template>
  <div>
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group v-model="checkedConfigureList">
      <el-checkbox v-for="item in configureList" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
    </el-checkbox-group>

    <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>

    </el-form>
    <div
        style="display: flex;flex-direction: row;justify-content: flex-end;align-items: center;margin-bottom: 10px;padding-right: 20px">
      <span style="color: #606266;font-size: 14px;margin:0 10px">隐藏空数据:</span>
      <el-switch
          @change="handleQuery"
          v-model="queryParams.invalid"
          :active-value="true"
          :inactive-value="0">
      </el-switch>
    </div>

    <el-table
        v-loading="loading"
        element-loading-text="拼命加载中...日志查询时间较长 ┓(;´_｀)┏ 请稍等... (*▼ｰ(｡-_-｡)"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        height="750"
        :stripe="true"
        :expand-row-keys="['1-null']"
        :border="true"
        highlight-current-row
        :row-key="getRowKeys"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="deptName"
          label="部门"
          :highlight-current-row="true"
          width="210"
          align="left">
      </el-table-column>
      <el-table-column
          prop="nickName"
          label="姓名"
          :highlight-current-row="true"
          align="center"/>
      <el-table-column
          prop="configId"
          label="顾问配置"
          :highlight-current-row="true"
          align="center">
        <template v-slot="scope">
          <span v-for="item in configureList" v-if="item.id === scope.row.configId">{{ item.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
          v-for="item in checkedConfigureList"
          :prop="'DEP_' + item"
          :label="tableLabel(item)"
          align="center">
        <template v-slot="scope">
          <span v-html="filterColumn(scope.row,item)">
            {{ item }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {configureList} from "@/utils/api";
import {configureCountApi} from "@/api/system/account";

const flag = 'DEP_'
export default {
    name: "ConfigureCount",
    data() {
        return {
            queryParams: {
                invalid: true
            },
            searchRules: {},
            payTimeArr: [],
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        end.setTime(end.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            checkAll: false,
            checkedConfigureList: [],
            configureList: [],
            isIndeterminate: true,
            loading: false,
            tableData: [],
        }
    },
    created() {
        this.getConfigureList();
        this.getData();
    },
    computed: {
        tableLabel() {
            return (id) => {
                for (let e of this.configureList) {
                    if (e.id === id) {
                        return e.name
                    }
                }
                return ''
            }
        }
    },
    methods: {
        filterColumn(row, col) {
            return row.count === null ? (row.configId === col ? 1 : '') : row.count["conf_" + col]
        },
        getRowKeys(row) {
            return row.deptId + '-' + row.accountId
        },
        /**
         * 获取数据
         */
        getData() {
            this.loading = true;
            if (this.payTimeArr && this.payTimeArr.length > 0) {
                this.queryParams['startDate'] = this.payTimeArr[0]
                this.queryParams['endDate'] = this.payTimeArr[1]
            }
            // delete this.queryParams['configureIdList'];
            // if (this.checkedConfigureList && this.checkedConfigureList.length > 0) {
            //   this.queryParams['configureIdList'] = this.checkedConfigureList.join(',')
            // }
            this.tableData = []
            configureCountApi(this.queryParams).then(resp => {
                    if (resp.success) {
                        let data = resp.data
                        data.forEach(e => {
                            e['confId'] = flag + e['configId']
                        })
                        this.tableData = data
                        console.log(this.tableData)
                        this.loading = false
                    }
                }
            ).catch(e => {
                this.$message.error(e)
            })
        },
        getConfigureList: function () {
            const params = {};
            if (!params.parentId) {
                params['parentId'] = -1;
            }
            configureList(1, 5000, params).then(resp => {
                if (resp.success) {
                    this.configureList = resp.data.records
                    let ids = this.configureList.slice(0, 5).map(e => e.id);
                    this.checkedConfigureList = ids
                }
            })
        },
        handleCheckAllChange(val) {
            const configIdList = this.configureList.map(e => e.id);
            this.checkedConfigureList = val ? configIdList : [];
            this.isIndeterminate = false;
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.$refs['queryForm'].validate((valid) => {
                if (valid) {
                    this.getData()
                }
            });

        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.payTimeArr = [];
            this.handleQuery();
        },
    },
}
</script>

<style scoped>

</style>
