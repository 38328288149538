<template>
  <div>
    <el-form :inline="true" :model="weworkLiveCode" class="demo-form-inline">
      <el-form-item label="连接名称">
        <el-input v-model="weworkLiveCode.linkName" placeholder="请输入链接名称"></el-input>
      </el-form-item>
      <el-form-item label="链接地址">
        <el-input v-model="weworkLiveCode.linkAddress" placeholder="请输入链接地址"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="linkListApi">查询</el-button>
        <el-button type="primary" @click="resetInfo">重置</el-button>
        <el-button type="primary" @click="goBack">返回列表</el-button>
        <!--             <el-image-->
        <!--                 style="width: 100px; height: 100px"-->
        <!--                 :src="'https://gegeda-crm-public.oss-cn-beijing.aliyuncs.com/image/zhengerbajing/240703D0RK99ZPSW.jpg'"-->
        <!--                 :fit="fit"></el-image>-->
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="linkName" label="链接名称"></el-table-column>
      <el-table-column prop="linkAddress" label="链接地址">
        <template v-slot="scope">
          <el-link @click="toHref(scope.row.linkAddress) "> {{ getFullLink(scope.row.linkAddress) }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="linkExpiryDate" label="链接有效期">
        <template v-slot="scope">
          <span>{{ scope.row.linkExpiryDate !== "9999-12-30 00:00:00" ? scope.row.linkExpiryDate : '永久有效' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="linkHits" label="点击量"></el-table-column>
      <el-table-column prop="linkScanVolume" label="扫码量"></el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="query.total">
    </el-pagination>
  </div>
</template>

<script>
import * as linkStatisticsListOperate from "@/api/sphere/poster";
import * as linkOperate from "@/api/sphere/liveCodeLink";

export default {
  mounted() {
    this.linkListApi();
  },
  name: "CodeLinkStatistics",
  data() {
    return {
      query: {
        size: 10,
        page: 0,
        total: 0,
      },
      weworkLiveCode: {},
      tableData: [],
    }
  },
  methods: {
    toHref(path) {
      let _address = path.replace("/api/workTest/sphere/liveCode/getImgPath/", "/api/workTest/sphere/liveCode/getImageHtml/")
      //暂时本地不测（测试加:{:8080}）
      var protocol = window.location.protocol;
      let to = protocol + "//" + window.location.hostname + ":" + window.location.port + _address
      window.open(to);
      // window.location.href = this.liveCodeLinkPath;
    },
    getFullLink(address) {
      let _address = address.replace("/api/workTest/sphere/liveCode/getImgPath/", "/api/workTest/sphere/liveCode/getImageHtml/")
      //暂时本地不测（测试加:{:8080}）
      return window.location.hostname + ":" + window.location.port + _address;
    },
    linkListApi() {
      linkOperate.queryLinkList(this.weworkLiveCode, this.query).then(res => {
        console.log(res)
        this.tableData = res.data.data;
        this.query.total = res.data.total;
        this.query.page = res.data.pages;
        this.query.size = res.data.size;
        console.log(this.tableData)
      })
    },
    resetInfo() {
      this.weworkLiveCode = {}
    },
    goBack() {
      this.$router.back()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.query.size = val;
      this.query.page = 0;
      this.linkListApi();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.query.page = val;
      this.linkListApi();
    },
  }
}
</script>

<style scoped>

</style>
