import axios from "axios";

// 创建axios实例
const $ajax = axios.create({
    baseURL: process.env.VUE_APP_BASEURL_API, // api 的 base_url
    timeout: 1000 * 50, // 请求超时时间
    // transformRequest: [
    //     function (data) {
    //         return qs.stringify(data);
    //     },
    // ],
    // 设置post请求头
    headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Version":localStorage.getItem("_version")
    },
});

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
$ajax.interceptors.request.use(
    (config) => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        //  const token = store.state.user.token;
        //  token && (config.headers.token = token);
        return config;
    },
    (error) => Promise.error(error)
);

window.addEventListener('mousedown', (event) => {
    function findButtonAncestor(element) {
        // 递归查找父元素，直到找到包含 el-button 类名的按钮元素
        while (element) {
            if (element.classList.contains('el-button')) {
                return element;
            }else if (element.classList.contains('el-radio-button')) {
                return element;
            }else if (element.classList.contains("btn-click-open")){
                return element;
            }
            element = element.parentElement;
        }
        return null;
    }
    // 判断点击的元素是否是按钮或其子元素
    const button = findButtonAncestor(event.target);
    if (button) {
        let version = localStorage.getItem("_version")
        $ajax.get("/manage/crm/operate-count");

    }
})
