/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import store from "@/store/index";
import qs from "qs";
import {Message, MessageBox} from "element-ui";
import router from "@/router";
import {removeToken} from '@/utils/auth';// 验权
import {initVersion} from "@/utils/api";
// 接口调取情况(成功根据返回值判断)
const handleStatus = (res) => {
  //根据接口返回
  return res.data;
};
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 302: 未登录状态，跳转登录页
    case 302:
      //跳转登录
      router.push("/login")
      window.location.reload()

      break;
    case 304:

      initVersion().then(res => {
        if (res.success) {
          // this.$notify({"title": "刷新"})
          localStorage.setItem("_version", res.data)
          location.reload()
        }
      }).catch(ex => {
        Message.error("当前正在维护中,请稍等")
      })
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      Message.error("登录过期，请重新登录");
      removeToken()
      store.commit("SET_TOKEN", null);
      setTimeout(() => {
        //跳转登录
        router.push("/login")
        window.location.reload()
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      Message.error("请求的资源不存在");
      break;
    case 500:
      if (other.code === 501) {
        setTimeout(() => {
          //跳转登录
          router.push("/login")
          window.location.reload()
        })
      }
      break;
    default:
      console.log(other);
  }
};
// 创建axios实例
const $ajax = axios.create({
  baseURL: process.env.VUE_APP_BASEURL_API, // api 的 base_url
  timeout: 1000 * 50, // 请求超时时间
  // transformRequest: [
  //     function (data) {
  //         return qs.stringify(data);
  //     },
  // ],
  // 设置post请求头
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "version": localStorage.getItem("_version"),
  },
});

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
$ajax.interceptors.request.use(
  (config) => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    //  const token = store.state.user.token;
    //  token && (config.headers.token = token);
    return config;
  },
  (error) => Promise.error(error)
);

// 响应拦截器
$ajax.interceptors.response.use(
  // 请求成功
  (res) => handleStatus(res),
  // 请求失败
  (error) => {
    const {response} = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data);
      return Promise.reject(response);
    } else {
      return Promise.reject(error);
    }
  }
);
export default $ajax;
