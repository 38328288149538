import request from '@/utils/request.js';

const url = "/manage/system/searchDeploy";

//查询数据
export function list(query) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: query
    });
}

//修改数据
export function batchUpdate(param) {
    return request.post(`${url}/batchUpdate`, param)
}

/**
 * 根据类型获取数据
 * @param type
 * @returns {any}
 */
export function findByClassify(type) {
    return request.get(`${url}/findByClassify/${type}`);
}


export function updateStatus(id, status) {
    return request.get(`${url}/updateStatus?id=${id}&status=${status}`);
}

//删除配置
export function delDeploy(id) {
    return request.get(`${url}/delDeploy?id=${id}`);
}

//修改数据
export function saveDeploy(param) {
    return request.post(`${url}/saveDeploy`, param)
}


/**
 * 根据类型获取数据  返回map结构
 * @param type
 * @returns {any}
 */
export function findByClassifyForMap(type) {
    return request.get(`${url}/findByClassifyForMap/${type}`);
}