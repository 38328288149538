<template>
  <div>
    <el-row>
      <el-form v-model="condition" :inline="true" label-position="right" label-width="130px" size="mini">
        <div :style="{height:searchCollapseHeight}" class="transition-box">
          <el-form-item label="作废数据" prop="isAbandon" size="mini" v-permission="'crm:opportunity:isAbandon'">
            <el-switch
                    v-model="condition.isAbandon"
                    active-color="#13ce66"
                    :active-value="true"
                    inactive-color="#ff4949"
                    :inactive-value="false">
            </el-switch>
          </el-form-item>
          <el-form-item label="虚拟手机号" prop="isFictitiousPhone" v-permission="'crm:opportunity:fictitiousPhone'">
            <el-select v-model="condition.isFictitiousPhone" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"/>
              <el-option label="是" :value="true"/>
              <el-option label="否" :value="false"/>
            </el-select>
          </el-form-item>

          <el-form-item label="手机号" prop="telephone">
            <el-input v-model="condition.telephone" class="condition-input" placeholder="手机号"></el-input>
          </el-form-item>

          <el-form-item label="机会ID" prop="studentName">
            <el-input v-model="condition.id" class="condition-input" placeholder="机会ID"></el-input>
          </el-form-item>
            <el-form-item label="领取次数" prop="receiveNum">
                <el-col :span="11">
                    <el-input type="number" v-model="condition.receiveStart" class="condition-input"></el-input>
                </el-col>
                <el-col :span="2" style="text-align: center">
                    至
                </el-col>
                <el-col :span="11">
                    <el-input type="number" v-model="condition.receiveEnd" class="condition-input"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="添加微信" prop="addWechat">
                <el-select v-model="condition.addWechat" filterable class="condition-select"
                           placeholder="请选择">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option label="已添加" :value="true"></el-option>
                    <el-option label="未添加" :value="false"></el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="是否页面分享" prop="isPageShare">
            <el-select v-model="condition.isPageShare" filterable class="condition-select"
                       placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="机会标签" prop="tagList">
            <el-select v-model="condition.tagList" multiple filterable class="condition-select" placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="item in tagsOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="学习顾问" prop="adviserId">
            <el-select v-model="condition.kcuserId" filterable class="condition-select" placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <!--          <el-form-item label="成单学习顾问" prop="adviserId">-->
          <!--            <el-select v-model="condition.adviserAccountId" filterable class="condition-select" placeholder="请选择">-->
          <!--              <el-option label="全部" :value="null"></el-option>-->
          <!--              <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">-->
          <!--              </el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->

          <el-form-item label="唯一配置" prop="possessId">
            <el-select v-model="condition.possessId" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="item in possessList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="渠道" prop="mediatorId">
            <el-select v-model="condition.mediatorId" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="item in mediatorList" :key="item.id" :label="item.mediatorName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资源类别" prop="possessId">
            <el-select v-model="condition.opporType" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option :key="1" label="首咨" value="1"></el-option>
              <el-option :key="2" label="个人" value="2"></el-option>
              <el-option :key="3" label="常规" value="3"></el-option>
              <el-option :key="4" label="库存" value="4"></el-option>
              <el-option :key="5" label="唤醒" value="5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目" prop="subjectIds">
            <el-select v-model="condition.subjectIds" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option v-for="item in subjectList" :key="item.id" :label="item.subjectName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="团队" prop="deptId">
              <el-cascader
                      :options="deptList"
                      :props="{ multiple: true, checkStrictly: false, label: 'title', value: 'id', children: 'children', emitPath: false}"
                      v-model="condition.deptIdList"
                      filterable
                      clearable></el-cascader>
<!--            <el-select v-model="condition.deptId" class="condition-select" filterable placeholder="请选择">-->
<!--              <el-option label="全部" :value="null"></el-option>-->
<!--              <el-option v-for="item in deptList" :key="item.id" :label="item.deptName"-->
<!--                         :value="item.id"></el-option>-->
<!--            </el-select>-->
          </el-form-item>

          <el-form-item label="入库方式" prop="initialStatus">
            <el-select v-model="condition.initialStatus" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="微页面推广" value="微页面推广"></el-option>
              <el-option label="学习顾问创建" value="学习顾问页面创建"></el-option>
              <el-option label="微页面开课" value="微页面开课"></el-option>
              <el-option label="导入" value="Excel导入"></el-option>
              <el-option label="网校推送" value="网校推送"></el-option>
              <el-option label="首咨推广" value="首咨推广"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="上传时间" prop="uploadTime">
            <el-date-picker v-model="condition.uploadTime"
                            unlink-panels type="datetimerange" range-separator="至"
                            start-placeholder="上传日期开始" :default-time="['00:00:00', '23:59:59']"
                            end-placeholder="上传日期结束">
            </el-date-picker>
          </el-form-item>
          <!--          <el-form-item label="商品名称" prop="adviserId">-->
          <!--            <el-input v-model="condition.goodsName" class="condition-input" placeholder="商品名称"-->
          <!--                      suffix-icon=“xxxx”></el-input>-->
          <!--          </el-form-item>-->

          <el-form-item label="分配时间" prop="allotTime">
            <el-date-picker v-model="condition.allotTime" unlink-panels type="datetimerange" range-separator="至"
                            start-placeholder="分配日期开始" :default-time="['00:00:00', '23:59:59']"
                            end-placeholder="分配日期结束">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="领取时间" prop="collectionTime">
            <el-date-picker v-model="condition.collectionTimeRange" unlink-panels type="datetimerange" range-separator="至"
                            start-placeholder="领取日期开始" :default-time="['00:00:00', '23:59:59']"
                            end-placeholder="领取日期结束">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="成单状态" prop="orderStatus">
            <el-select v-model="condition.orderStatus" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="null"></el-option>
              <el-option :key="0" label="未成单" value="0"></el-option>
              <el-option :key="1" label="已成单" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否冻结" prop="isfreeze">
            <el-select v-model="condition.isfreeze" class="condition-select" filterable placeholder="请选择">
              <el-option label="全部" :value="''"></el-option>
              <el-option label="正常" :value="1"></el-option>
              <el-option label="冻结" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <!-- 额外信息 start -->
          <template v-for="(d,index) in SearchNewInformationList" v-if="d.find === true">
            <template v-if="d.type === 'input'">
              <el-form-item :label="d.label">
                <el-input v-model="e[d.id]" class="condition-input" suffix-icon=“xxxx”></el-input>
              </el-form-item>
            </template>
            <template v-if="d.type === 'radio'">
              <el-form-item :label="d.label">
                <el-select v-model="e[d.id]"
                           class="condition-select" clearable
                           placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="未指定" value="ISNULL"></el-option>
                  <el-option v-if="d.tson.dictList" v-for="(item, i) in d.tson.dictList" :key="i"
                             :label="item.value" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-if="d.type === 'checkbox'">
              <el-form-item :label="d.label">
                <el-select v-model="e[d.id]" clearable
                           class="condition-select"
                           placeholder="请选择">
                  <el-option v-if="d.tson.dictList" v-for="(item,idx) in d.tson.dictList" :key="idx"
                             :label="item.value" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <template v-if="d.type === 'date'">
              <el-form-item :label="d.label">
                <el-date-picker v-model="extra[d.id]" type="daterange" range-separator="至"
                                start-placeholder="日期开始"
                                value-format="yyyy-MM-dd"
                                end-placeholder="日期结束">
                </el-date-picker>
              </el-form-item>
            </template>
            <template v-if="d.type === 'time'">
              <el-form-item :label="d.label">
                <el-date-picker v-model="extra[d.id]" type="datetimerange" range-separator="至"
                                start-placeholder="日期开始" :default-time="['00:00:00', '23:59:59']"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                end-placeholder="日期结束">
                </el-date-picker>
              </el-form-item>
            </template>
            <template v-if="d.type === 'DICT'">
                <el-form-item :label="d.label">
                    <el-select v-model="e[d.id]"
                               class="condition-select" clearable
                               placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="无" value="ISNULL"></el-option>
                        <el-option v-if="extraMap[d.name]" v-for="item in extraMap[d.name]" :key="item.id"
                                   :label="item.dictLabel" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>
          </template>
        </div>
        <div class="button-container">
          <!-- 额外信息 end -->
          <el-form-item>
            <el-button :icon="searchIcon" type="text" @click="showMore">{{ searchText }}</el-button>
            <el-button type="primary" icon="el-icon-search" @click="doSearch">搜索</el-button>
            <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-row>
    <el-row>
      <el-button-group :style="{marginBottom:'10px'}">
        <el-button v-permission="'crm:opportunity:allot'" type="primary" size="mini" @click="toAllot">分配</el-button>
        <el-button v-permission="'crm:opportunity:backLibrary'" type="primary" size="mini" @click="backLibrary()">回库</el-button>

        <el-button v-permission="'crm:opportunity:repeats'" type="primary" size="mini" @click="">筛选</el-button>
        <el-button v-permission="'crm:opportunity:showTelephone'" type="primary" size="mini" @click="showTelephone">
          查看手机
        </el-button>
        <el-button v-permission="'crm:opportunity:updatePossessId'" type="primary" size="mini" @click="toUpdatePossess">
          修改唯一配置
        </el-button>
        <el-button v-permission="'crm:opportunity:exportDrawLog'" type="primary" size="mini" @click="exportDrawLog">导出
        </el-button>
        <el-button v-permission="'crm:opportunity:search'" type="primary" size="mini" @click="isAllot(1)">已分配
        </el-button>
        <el-button v-permission="'crm:opportunity:search'" type="primary" size="mini" @click="isAllot(2)">未分配
        </el-button>
        <el-button v-permission="'crm:opportunity:exportDrawLog'" type="primary" size="mini" @click="exportWechatLog">
          导出添加微信
        </el-button>
      </el-button-group>

      <el-button v-permission="'crm:opportunity:tags'" type="success" size="mini"
                 style="float: right;margin-left:10px"
                 @click="tagsSync = true">修改机会标签
      </el-button>
      <el-button v-permission="'crm:opportunity:updatePossessId'" type="primary" size="mini"
                 style="float: right;margin-left:10px"
                 @click="BatchUpdateShow">批量修改唯一配置
      </el-button>
      <el-button v-permission="'crm:opportunity:updatePossessId'" type="primary" size="mini"
                 style="float: right;margin-left:10px"
                 @click="handleOpenUploadDia">批量修改机会数据
      </el-button>
      <el-button v-permission="'crm:opportunity:updatePossessId'" type="primary" size="mini"
                 style="float: right;margin-left:10px"
                 @click="handleAllotSync">批量指派
      </el-button>
      <el-button v-permission="'crm:opportunity:exportDrawLog'" type="primary" size="mini"
                 style="float: right;margin-left:10px"
                 @click="uploadFindAdviserSync = true">学习顾问查询
      </el-button>
    </el-row>
    <el-row>
      <el-table :data="opporTableData" style="width: 100%" stripe fit @select="selectRow" @select-all="selectAll"
                max-height="640"
                @selection-change="selectChange" v-loading.fullscreen.lock="loading">
        <el-table-column align="center" type="selection" fixed="left"></el-table-column>
        <el-table-column align="center" label="添加微信">
          <template slot-scope="scope">
            <span v-if="scope.row.wechatAdviser"><el-button type="primary" size="mini"
                                                            @click="adviserWechat(scope.row.id)">已添加</el-button></span>
            <span v-else>未添加</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="页面分享工号码">
          <template slot-scope="scope">
            {{ findShareAdviser(scope.row.pageShareAdviserIds) }}
          </template>
        </el-table-column>
        <el-table-column v-for="(column,index) in bankOpporColumns" :align="column.align"
                         :fixed="column.fixed" :label="column.label" :width="110">
          <template slot-scope="slot">
<!--              <div v-if="column.name === 'consultationSubjects'">-->
<!--                  <span v-for="item in subjectList" v-if="item.id+'' === scope.row.subjectIds">{{item.subjectName}}</span>-->
<!--              </div>-->
            <div v-if="column.prop === 'type'">
              <span v-if="slot.row.type === 1">首咨</span>
              <span v-else-if="slot.row.type === 2">个人</span>
              <span v-else-if="slot.row.type === 3">常规</span>
              <span v-else-if="slot.row.type === 4">库存</span>
              <span v-else-if="slot.row.type === 5">唤醒</span>
            </div>
            <div v-else-if="column.prop ==='orderStatus'">
              <span v-if="slot.row.orderStatus === 1">已成单</span>
              <span v-else>未成单</span>
            </div>
            <div v-else-if="column.prop === 'sex' ">
              <span v-if="slot.row.sex === 2">男</span>
              <span v-else-if="slot.row.sex === 1">女</span>
              <span v-else>保密</span>
            </div>
            <div v-else-if="column.prop === 'tags'">
              <template v-for="item in splitTags(slot.row.tags)">
                <el-tag v-for="(it,inx) in tagsOptions" :key="inx" :type="typeOptions[inx % 5]" v-if="it.id === item">
                  {{ it.name }}
                </el-tag>
              </template>
            </div>
            <div v-else-if="column.prop === 'isfreeze'">
                <el-tag v-if="slot.row.isfreeze === 0" type="danger">冻结</el-tag>
                <el-tag v-else-if="slot.row.isfreeze === 1">正常</el-tag>
                <el-tag v-else type="info">/</el-tag>
            </div>
            <div v-else-if="column.prop === 'isFictitiousPhone'">
                <el-tag v-if="slot.row.isFictitiousPhone" type="warning">是</el-tag>
                <el-tag v-else-if="!slot.row.isFictitiousPhone">否</el-tag>
                <el-tag v-else type="info">/</el-tag>
            </div>
            <div v-else-if="extraMap[column.prop]">
                <span v-for="item in extraMap[column.prop]" v-if="item.id === slot.row[column.prop]">{{item.dictLabel}}</span>
            </div>
            <div v-else-if="column.prop === 'receiveFirstAccountId'">
              <el-tag v-if="slot.row.receiveFirstAccountId">{{slot.row.receiveFirstAccountName}}</el-tag>
            </div>
            <div v-else-if="column.prop === 'receiveFirstDeptId'">
              <el-tag v-if="slot.row.receiveFirstDeptId">{{slot.row.receiveFirstDeptName}}</el-tag>
            </div>
            <div v-else>
              <span> {{ slot.row[column.prop] }} </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="200">
          <template slot-scope="slot">
            <el-button size="mini" @click="showOperation(slot.row.id)">操作记录</el-button>
            <el-button v-permission="'crm:opportunity:allotOne'" size="mini" type="success" @click="allot(slot.row)">
              分配
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </el-row>

    <AllotOrUpdatePossess v-if="allotOrUpdateOrPossessObj.showObj.isShow"
                          :opportunity-list="allotOrUpdateOrPossessObj.opportunityList"
                          :type="allotOrUpdateOrPossessObj.type"
                          :show-obj="allotOrUpdateOrPossessObj.showObj"
                          :choose-list="allotOrUpdateOrPossessObj.chooseList"
                          @reload="reload">
    </AllotOrUpdatePossess>

    <ShowTelephone v-if="showTelephoneObj.showObj.isShow" :opportunity-list="showTelephoneObj.opportunityList"
                   :show-obj="showTelephoneObj.showObj">
    </ShowTelephone>

    <el-dialog v-if="isShowOperation" :visible.sync="isShowOperation">
      <History :id="chooseId"></History>
    </el-dialog>
    <BatchUpdateOnlyConfigure :BatchUpdateObj="BatchUpdateObj"
                              :pond-id="1" v-if="BatchUpdateObj.isShow"></BatchUpdateOnlyConfigure>
    <el-dialog title="修改机会数据" :visible.sync="extraSync">
      <el-upload
          ref="extraUpload"
          :headers="headers"
          :file-list="extraList"
          action="/api/manage/crm/opporManage/importExtra?pondId=1"
          :on-success="uploadExtraSuccess">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button size="small" type="success" @click="downloadExtraTemp">下载模板</el-button>
        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
      </el-upload>
      <el-divider content-position="left">导入结果</el-divider>
      <div v-if="importUpdateVo.successCount > 0">
          <el-link type="primary">一共导入: {{importUpdateVo.totalCount}}条数据, 成功导入: {{importUpdateVo.successCount}}条数据.</el-link>
      </div>
      <div v-if="importUpdateVo.msgList.length > 0" v-for="(item,index) in importUpdateVo.msgList" :key="index">
          <el-link :type="typeOptions[index % 5]">{{ item }}</el-link>
      </div>
    </el-dialog>

    <el-dialog title="批量修改机会标签" :visible.sync="tagsSync">
      <el-upload
              :headers="headers"
          ref="tagsTemplateUpload"
          :file-list="tagsFileList"
          action="/api/manage/crm/opporManage/importOpporTags?pondId=1"
          :on-success="uploadTagsSuccess">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button size="small" type="success" @click="downloadTagsTemp">下载模板</el-button>
        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
      </el-upload>
      {{ tagsUploadText }}
    </el-dialog>

    <el-dialog title="批量指派" :visible.sync="allotSync">
        <el-switch v-model="forceAllot" :active-value="1" :inactive-value="0" active-text="强指派" inactive-text="弱指派" active-color="green" inactive-color="red"/>
        <div>
            <el-alert title="强指派(默认):" description="不论该机会当前有无咨询师, 都直接指派给指定的咨询师" type="success" :closable="false"/>

            <el-alert title="弱指派:" description="如果该机会当前有咨询师, 则不指派" type="error" :closable="false"/>
        </div>
      <el-upload
          ref="allotUpload"
          :headers="headers"
          :file-list="allotList"
          :action="`/api/manage/crm/opporManage/importAllot?forceAllot=${forceAllot}&pondId=1`"
          :on-success="uploadallotSuccess">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button size="small" type="success" @click="downloadAllotTemp">下载模板</el-button>
        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
      </el-upload>
        <div>
            <div v-if="uploadResponse.totalCount > 0">导入数据共 {{ uploadResponse.totalCount }} 条</div>
            <div v-if="uploadResponse.successCount > 0">指派成功 {{ uploadResponse.successCount }} 条</div>
            <div v-if="uploadResponse.msgList.length > 0" v-for="item in uploadResponse.msgList"> {{ item }}</div>
        </div>
    </el-dialog>

    <el-dialog title="批量查询学习顾问" :visible.sync="uploadFindAdviserSync">
      <el-upload
          ref="findAdviserUpload"
          :headers="headers"
          :file-list="findAdviserList"
          action="/api/manage/crm/opporManage/uploadFindAdviser?pondId=1"
          :on-success="uploadFindAdviserSuccess">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button size="small" type="success" @click="downloadFindAdviser">下载数据</el-button>
        <el-button size="small" type="success" @click="downloadFindAdviserTemp">下载模板</el-button>
        <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件</div>
      </el-upload>
    </el-dialog>

    <el-dialog title="机会回库" :visible.sync="backSync">
      <el-row v-for="item in backData">
        <el-col :span="12">机会ID:{{ item }}</el-col>
      </el-row>
      <el-row style="margin: 20px 0">
        <b style="color: #dd6161;font-size: 20px">请选择回库原因:</b>
      </el-row>
      <el-row>
        <el-radio-group v-model="backIntention">
          <el-radio-button v-for="data in intentionDatas" :label="data.label" border></el-radio-button>
        </el-radio-group>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="backSync = false">关 闭</el-button>
        <el-button type="success" @click="batchGiveUp">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BatchUpdateOnlyConfigure from "@/views/opportunity/components/BatchUpdateOnlyConfigure";
import AllotOrUpdatePossess from "@/views/opportunity/components/AllotOrUpdatePossess";
import * as api from "@/utils/api";
import axios from "axios";
import ShowTelephone from "@/views/opportunity/components/ShowTelephone";
import History from "@/components/details/History";
import * as deptApi from "@/api/system/dept";
import * as dictApi from "@/api/system/dictionary";
import * as searchApi from "@/api/system/searchDeploy";
import * as accountApi from "@/api/system/account";
import * as tagsApi from "@/api/crm/tags";
import * as receiveApi from "@/api/system/receive";

export default {
  name: "OpporAll",
  components: {
    History,
    ShowTelephone,
    AllotOrUpdatePossess,
    BatchUpdateOnlyConfigure,
  },
  data() {
    return {
        headers:{"version": localStorage.getItem("_version"),},
      tagsUploadText: "",
      typeOptions: ['', 'success', 'warning', 'danger', 'info'],
      tagsOptions: [],
      extra: {},
      backSync: false,
      backData: [],
      backIntention: "",
      intentionDatas: [],
      searchShow: true,
      searchCollapseHeight: "auto",
      searchIcon: "",
      searchText: "",
      contentType: 1,
      SearchNewInformationList: {},
      extraList: [],
      tagsFileList: [],
      findAdviserList: [],
      allotList: [],
      bankOpporColumns: [],
      opporTableColumns: [],
      extraSync: false,
      tagsSync: false,
      allotSync: false,
      orderTableColumns: [
        {align: "center", prop: "id", fixed: "left", label: "机会ID", width: 100},
        {align: "center", prop: "telephone", label: "手机号", width: 145},
        {align: "center", prop: "orderAdviserName", label: "成单学习顾问", width: 145},
        {align: "center", prop: "nickName", label: "归属学习顾问", width: 145},
        {align: "center", prop: "possessName", label: "唯一配置", width: 160},
        {align: "center", prop: "type", label: "类型", width: 100},
        {align: "center", prop: "subjectNames", label: "名片项目", width: 100},
        {align: "center", prop: "createTime", label: "上传时间", width: 162},
        {align: "center", prop: "wakeTime", label: "唤醒时间", width: 162},
        {align: "center", prop: "collectionTime", label: "领取时间", width: 173},
        {align: "center", prop: "vallotTime", label: "分配日期", width: 173},
        {align: "center", prop: "paymentTime", label: "支付日期", width: 173},
        {align: "center", prop: "amountReceivable", label: "定价", width: 100},
        {align: "center", prop: "paymentAmount", label: "支付价", width: 100},
        {align: "center", prop: "orderNumber", label: "订单编号", width: 100},
        {align: "center", prop: "goodsName", label: "商品名称", width: 120},
        {align: "center", prop: "mediatorName", label: "渠道", width: 100},
        {align: "center", prop: "verifyAge", label: "年龄", width: 120},
        {align: "center", prop: "weiXin", label: "微信", width: 100},
      ],
      opporTableData: [],
      orderTableData: [],
      allotOrUpdateOrPossessObj: {
        opportunityList: [],//分配或修改唯一配置的list
        chooseList: [],// 学习顾问列表，或者 唯一配置列表
        type: 1,
        showObj: {
          isShow: false
        }
      },
      showTelephoneObj: {
        opportunityList: [],//查看手机号
        showObj: {
          isShow: false
        }
      },
      adviserList: [],
      possessList: [],
      subjectList: [],
      condition: {
        isFictitiousPhone: false,
        isfreeze: ''
      },
      page: {current: 1, size: 10, total: 0},
      orderPage: {current: 1, size: 10, total: 0},
      multipleSelection: [],
      loading: true,
      chooseId: "",
      isShowOperation: false,
      BatchUpdateObj: {
        isShow: false,
      },
      mediatorList: [],
      deptList: [],
      dictionaryList: [],
      e: {},
      uploadFindAdviserSync: false,
      findAdviserCode: null,
        uploadResponse: {
            totalCount: 0,
            successCount: 0,
            msgList: [],
        },
        // 是否强分配, 默认强分配
        forceAllot: 1,
        extraMap:{
            "BHBIIGGHFE":[],
            "BHBIGCFBGB":[],
            "BHBIGCFCIH":[],
        },
      importUpdateVo: {
        successCount: 0,
        totalCount: 0,
        msgList: [],
      }
    };
  },
  methods: {
      handleOpenUploadDia() {
          this.extraSync = true;
          this.importUpdateVo = {
              successCount: 0,
              totalCount: 0,
              msgList: [],
          }
      },
      /**
       * 组装额外参数字典
       */
      findExtraMap(){
          dictApi.findExtraDictMap().then(res=>{
              if(res.success){
                  this.extraMap = res.data
              }
          })
          // dictApi.findChildrenByCode("BHBIIGGHFE").then(res=>{
          //     this.extraMap['BHBIIGGHFE'] = res.data
          // })
          // dictApi.findChildrenByCode("BHBIGCFBGB").then(res=>{
          //     this.extraMap['BHBIGCFBGB'] = res.data
          // })
          // dictApi.findChildrenByCode("BHBIGCFCIH").then(res=>{
          //     this.extraMap['BHBIGCFCIH'] = res.data
          // })
      },
      /**
       * 批量指派按钮
       */
      handleAllotSync() {
          this.allotSync = true;
          this.uploadResponse = {
              totalCount: 0,
              successCount: 0,
              msgList: [],
          }
      },
    findShareAdviser(ids) {
      if (ids) {
        let _ids = ids.split(",");
        let names = []
        for (let adviser of this.adviserList) {
          for (let _id of _ids) {
            if (_id && adviser.id === Number(_id)) {
              names.push(adviser.nickName)
            }
          }
        }
        return names.join(",")
      }
      return ids
    },
    splitTags(tags) {
      return tags?.split(",").filter(e => e && e.trim()).map(Number) || []
    },
    findTagsOptions() {
      tagsApi.options().then(res => {
        this.tagsOptions = res.data
      })
    },
    findIntention() {
      receiveApi.findOptionCode('intention').then(res => {
        this.intentionDatas = res.data
      })
    },
    keyboard(e) {
      if (e.keyCode === 13) {
        this.doSearch()
      }
    },
    exportFindAdviser() {

    },
    /**
     * 批量回库
     */
    batchGiveUp() {
      if (!this.backIntention) {
        this.$message.error("请选择回库原因!");
        return
      }
      let params = {"intention": this.backIntention}
      api.giveMessageBack(this.backData, params).then(res => {
        if (res.success) {
          this.$message.success("操作成功")
          this.backSync = false
          this.reload()
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.error("批量回库失败");
      })
    },
    adviserWechat(id) {
      api.findAdviserWechat(id).then(res => {
        if (res.data) {
          let names = ''
          for (let k of res.data) {
            names += k + '<br/>'
          }
          this.$alert(names, '当前加过微信学习顾问:', {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true
          });
        }
      })
    },
    /**
     * 下载批量指派模板
     */
    downloadFindAdviserTemp() {
        axios({
            method: "get",
            url: "/api/manage/crm/opporManage/downloadFindAdviserTemp",
            headers: {
                "version": localStorage.getItem("_version"),
            },
            responseType: 'blob',
        }).then(res => {
            const blob = new Blob([res.data]);
            const fileName = '批量指派模板.xlsx';
            const elink = document.createElement('a')
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        }).catch(error => {
            this.$message.error("导出模板失败");
        })
    },
    /**
     * 下载批量指派模板
     */
    downloadAllotTemp() {
        axios({
            method: "get",
            url: "/api/manage/crm/opporManage/exportAllotTemplate",
            headers: {
                "version": localStorage.getItem("_version"),
            },
            responseType: 'blob',
        }).then(res => {
            const blob = new Blob([res.data]);
            const fileName = '批量指派模板.xlsx';
            const elink = document.createElement('a')
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        }).catch(error => {
            this.$message.error("导出模板失败");
        })
    },
    /**
     * 下载额外参数模板
     */
    downloadTagsTemp() {
        axios({
            method: "get",
            url: "/api/manage/crm/opporManage/downloadTagsTemp",
            headers: {
                "version": localStorage.getItem("_version"),
            },
            responseType: 'blob',
        }).then(res => {
            const blob = new Blob([res.data]);
            const fileName = '机会标签模板.xlsx';
            const elink = document.createElement('a')
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        }).catch(error => {
            this.$message.error("导出模板失败");
        })
    },
    /**
     * 下载额外参数模板
     */
    downloadExtraTemp() {
        axios({
            method: "get",
            url: "/api/manage/crm/opporManage/exportExtraTemplate",
            headers: {
                "version": localStorage.getItem("_version"),
            },
            responseType: 'blob',
        }).then(res => {
            const blob = new Blob([res.data]);
            const fileName = '额外参数模板.xlsx';
            const elink = document.createElement('a')
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        }).catch(error => {
            this.$message.error("导出模板失败");
        })
    },
    /**
     * 下载文件
     */
    downloadFindAdviser() {
      if (this.findAdviserCode) {
          axios({
              method: "get",
              url: "/api/manage/crm/opporManage/downloadFindAdviser/" + this.findAdviserCode,
              headers: {
                  "version": localStorage.getItem("_version"),
              },
              responseType: 'blob',
          }).then(res => {
              const blob = new Blob([res.data]);
              const fileName = '学习顾问数据.xlsx';
              const elink = document.createElement('a')
              elink.download = fileName;
              elink.style.display = 'none';
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
          }).catch(error => {
              this.$message.error("导出模板失败");
          })
      } else {
        this.$message.warning("请先上传数据!");
      }
    },
    uploadFindAdviserSuccess(res) {
      if (res.success) {
        this.findAdviserCode = res.msg
          axios({
              method: "get",
              url: "/api/manage/crm/opporManage/downloadFindAdviser/" + res.msg,
              headers: {
                  "version": localStorage.getItem("_version"),
              },
              responseType: 'blob',
          }).then(res => {
              const blob = new Blob([res.data]);
              const fileName = '学习顾问数据.xlsx';
              const elink = document.createElement('a')
              elink.download = fileName;
              elink.style.display = 'none';
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
          }).catch(error => {
              this.$message.error("导出模板失败");
          })
      } else {
        this.$message.warning(res.msg);
      }
    },
    uploadTagsSuccess(res) {
      this.tagsFileList = []
      this.$refs.tagsTemplateUpload.clearFiles();
      if (res.success) {
        let ht = ""
        ht += "成功数量:" + res.data.success
        for (let t in res.data.errMsg) {
          ht += "\n" + t
        }
        ht += "\n" + "失败数量" + res.data.error
        this.tagsUploadText = ht
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    uploadExtraSuccess(res) {
      this.extraList = []
      this.$refs.extraUpload.clearFiles();
      if (res.success) {
          this.importUpdateVo = res.data
        // let ht = `成功数据：${res.data.succCount}<br/>`
        // ht += `失败数据：${res.data.errCount}<br/>`
        // ht += `失败数据详细：${res.data.errList}<br/>`
        // this.$alert(ht, '导入提示', {
        //   dangerouslyUseHTMLString: true
        // });
      } else {
        this.$message(res.msg)
      }
    },
    uploadallotSuccess(res) {
      this.allotList = []
      this.$refs.allotUpload.clearFiles();
      if (res.success) {
          this.uploadResponse = res.data
      } else {
        this.$message(res.msg)
      }
    },
    /**
     * 获取字典数据
     * */
    /*
      * 获取字段字段
      * */
    getdictionaryList(id) {
      dictApi.findParent(id).then((res) => {
        if (res) {
          this.dictionaryList = res.data
        }
      })
    },
    showMore() {
      this.searchShow = !this.searchShow
      this.searchIcon = this.searchShow ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
      this.searchText = this.searchShow ? '收起搜索条件' : '展示搜索条件'
      this.searchCollapseHeight = this.searchShow ? 'auto' : '85px'
    },
    findDept() {
      deptApi.treeDept().then(res => {
        this.deptList = res.data
      })
    },
    findMediator() {
      api.allMediators().then(res => {
        this.mediatorList = res.data
      })
    },
    handleSizeChange(val) {
      this.page.size = val
      this.loading = true
      this.getOpportunityList(this.condition)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.loading = true
      this.getOpportunityList(this.condition)
    },
    selectRow(select, row) {
      this.multipleSelection.push({id: row.id, telephone: row.telephone, kcuserId: row.kcuserId})
    },
    selectAll(select) {
      this.multipleSelection = select.map(res => {
        return {id: res.id, telephone: res.telephone, kcuserId: res.kcuserId}
      })
    },
    /**
     * 取消选择
     */
    selectChange(selection) {
      this.multipleSelection = selection.map(res => {
        return {id: res.id, telephone: res.telephone, kcuserId: res.kcuserId}
      })
    },
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
          this.adviserList.unshift({"id": 0, "nickName": "未归属"})
        }
      })
    },
    /**
     * 获取唯一配置列表
     */
    getPossessList: function () {
      api.possessList().then(res => {
        if (res.success) {
          this.possessList = res.data
        }
      })

    },
    /**
     * 获取专业列表
     */
    getSubjectList: function () {
      api.subjectList().then(res => {
        if (res.success) {
          this.subjectList = res.data
        }
      })
    },
    /**
     * 获取普通机会列表
     * @param params
     */
    getOpportunityList: function (params) {
      this.loading = true
      this.bankOpporColumns = this.opporTableColumns
      this.basicOpportunity(params)
    },
    basicOpportunity: function (params) {
      if(!params){
          params = {}
      }
      if (params.telephone) {
        let telephone = params.telephone.trim()
        telephone = telephone.replace("​", "")
        // if (telephone.length > 11) {
        //   this.$message.warning("手机号长度错误!");
        //   this.loading = false
        //   return
        // }
        params.telephone = telephone
      }
      // params['isFreeze'] = 9
      params['pondId'] = 1
      api.opporListJson(this.page.current, this.page.size, params).then(res => {
        if (res.success) {
          let resEntity = res.data
          this.page.current = resEntity.current
          this.page.size = resEntity.size
          this.page.total = resEntity.total
          let e = resEntity.records
          for (let i in e) {
            let ent = e[i]
            if (ent['extra']) {
              let tson = JSON.parse(ent['extra'])
              for (let j in tson) {
                ent[j] = tson[j]
              }
            }
          }
          this.opporTableData = e
        } else {
          this.$message.warning(res.msg);
        }
        this.loading = false
      }).catch(error => {
        this.$message.error("获取机会列表失败");
        this.loading = false
      })
    },
    dueConditionTime: function () {
      if (this.condition.uploadTime) {
        let uploadTime = this.condition.uploadTime
        let createTimeStart = uploadTime[0].format("yyyy-MM-dd HH:mm:ss")
        let createTimeEnd = uploadTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.createTimeStart = createTimeStart
        this.condition.createTimeEnd = createTimeEnd
      } else {
        this.condition.createTimeStart = ""
        this.condition.createTimeEnd = ""
      }
      if (this.condition.allotTime) {
        let allotTime = this.condition.allotTime
        let vallotTimeStart = allotTime[0].format("yyyy-MM-dd HH:mm:ss")
        let vallotTimeEnd = allotTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.vallotTimeStart = vallotTimeStart
        this.condition.vallotTimeEnd = vallotTimeEnd
      } else {
        this.condition.vallotTimeStart = ""
        this.condition.vallotTimeEnd = ""
      }
      if (this.condition.collectionTimeRange) {
        let collectionTime = this.condition.collectionTimeRange
        let collectionTimeStart = collectionTime[0].format("yyyy-MM-dd HH:mm:ss")
        let collectionTimeEnd = collectionTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.collectionTimeStart = collectionTimeStart
        this.condition.collectionTimeEnd = collectionTimeEnd
      } else {
        this.condition.collectionTimeStart = ""
        this.condition.collectionTimeEnd = ""
      }

      if (this.condition.benginFrozenTimeRange) {
        let beginFrozenTime = this.condition.benginFrozenTimeRange
        let beginFreezeTimeStart = beginFrozenTime[0].format("yyyy-MM-dd HH:mm:ss")
        let beginFreezeTimeEnd = beginFrozenTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.condition.beginFreezeTimeStart = beginFreezeTimeStart
        this.condition.beginFreezeTimeEnd = beginFreezeTimeEnd
      } else {
        this.condition.beginFreezeTimeStart = ""
        this.condition.beginFreezeTimeEnd = ""
      }
        this.condition.pondId = 1
    },
    /**
     * 搜索
     */
    doSearch() {
      this.page.current = 1
      this.dueConditionTime()

      for (let item in this.extra) {
        if (this.extra[item]) {
          this.e[item] = this.extra[item][0] + ',' + this.extra[item][1]
        } else {
          delete this.e[item]
        }
      }
      this.condition['e'] = this.e
      this.getOpportunityList(this.condition)
    },
    /**
     * 分配
     * @param row
     */
    allot(row) {
      this.allotOrUpdateOrPossessObj.opportunityList = [{id: row.id, telephone: row.telephone, kcuserId: row.kcuserId}]
      this.allotOrUpdateOrPossessObj.chooseList = this.adviserList
      this.allotOrUpdateOrPossessObj.type = 1
      this.allotOrUpdateOrPossessObj.showObj.isShow = true
    },
    /**
     * 分配
     */
    toAllot() {
      if (!this.multipleSelection.length) {
        this.$message.warning("当前未选择任何分配的机会")
        return
      }
      this.allotOrUpdateOrPossessObj.chooseList = this.adviserList
      this.allotOrUpdateOrPossessObj.opportunityList = this.multipleSelection
      this.allotOrUpdateOrPossessObj.type = 1
      this.allotOrUpdateOrPossessObj.showObj.isShow = true
    },
    /**
     * 回库
     */
    backLibrary() {
      this.backData = this.multipleSelection.map(res => {
        return res.id
      })
      if (this.backData.length == 0) {
        this.$message.warning("请至少选择一行数据")
        return
      }
      this.backSync = true
    },
    /**
     * 修改唯一配置
     */
    toUpdatePossess() {
      if (!this.multipleSelection.length) {
        this.$message.warning("当前未选择任何修改的机会")
        return
      }
      this.allotOrUpdateOrPossessObj.chooseList = this.possessList
      this.allotOrUpdateOrPossessObj.opportunityList = this.multipleSelection
      this.allotOrUpdateOrPossessObj.type = 2
      this.allotOrUpdateOrPossessObj.showObj.isShow = true
    },
    exportWechatLog() {

      //处理form参数
      this.dueConditionTime()
      this.loading = true
      axios({
        method: "post",
        url: "/api/manage/crm/opporManage/exportWechatLog",
        data: JSON.stringify(this.condition),
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
        },
      }).then(res => {
        const blob = new Blob([res.data]);
        const fileName = '微信添加记录.xlsx';
        const elink = document.createElement('a')
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        this.loading = false
      }).catch(error => {
        this.$message.error("导出微信添加记录失败");
      })
    },
    /**
     * 导出领取记录
     */
    exportDrawLog() {
      if (!this.condition.collectionTimeRange && !this.condition.uploadTime && !this.condition.allotTime) {
        this.$message.warning("必须选择上传时间或者分配时间")
        return
      }
      //处理form参数
      this.dueConditionTime()
      this.loading = true
      axios({
        method: "post",
        url: "/api/manage/crm/opporManage/importDrawLog",
        data: JSON.stringify(this.condition),
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
        },
      }).then(res => {
        const blob = new Blob([res.data]);
        const fileName = '领取分配记录.xlsx';
        const elink = document.createElement('a')
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        this.loading = false
      }).catch(error => {
        this.$message.error("导出领取记录失败");
      })

    },
    /**
     * 查看手机号
     */
    showTelephone() {
      if (!this.multipleSelection.length) {
        this.$message.warning("未选择要查看的手机号的机会")
        return
      }
      let idArr = this.multipleSelection.map(res => {
        return res.id
      })
      api.showTelephone(idArr).then(res => {
        if (res.success) {
          this.showTelephoneObj.opportunityList = res.data
          this.showTelephoneObj.showObj.isShow = true
        } else {
          this.$message.warning("查看手机号失败")
        }
      })

    },
    /**
     * 重新刷新页面
     * @param params
     */
    reload() {
      this.loading = true
      this.getOpportunityList(this.condition)
    },
    /**
     *
     * @param value
     */
    isAllot(value) {
      this.condition['isAllot'] = value
      this.doSearch(this.condition)
    },
    /**
     * 展示操作记录
     * @param id
     */
    showOperation(id) {
      this.chooseId = id
      this.isShowOperation = true
    },
    /**
     * 重置查询
     */
    resetQuery() {
      this.condition = {}
      this.reload()
    },
    updateTagsShow() {

    },
    /**
     * 批量修改唯一配置
     * @constructor
     */
    BatchUpdateShow() {
      this.BatchUpdateObj.isShow = true;
    },
    getColumn: function () {
      searchApi.findByClassify("MANAGE_TABLES").then(res => {
        if (res.data) {
          let entity = res.data;
          for (let k of entity) {
            let e = {align: "center"}
            e['prop'] = k.name
            e['label'] = k.label
            this.opporTableColumns.push(e)
            if (k.type === 'EXTRA') {
              this.orderTableColumns.unshift(e)
            }
          }
        }

      });
    },
    getSearchNewInformationList: function () {
      searchApi.findByClassify("NEW_INFORMATION").then(res => {
        for (let t of res.data) {
          let tson = JSON.parse(t.data)
          t["tson"] = tson
        }
        this.SearchNewInformationList = res.data;
      });
    }
  },
  mounted() {
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.findExtraMap()
    this.showMore()
    this.getColumn()
    this.getAdviserList()
    this.getSubjectList()
    this.getOpportunityList(this.condition)
    this.getPossessList()
    this.findMediator()
    this.findDept()
    this.getSearchNewInformationList()
    this.findTagsOptions()
    this.findIntention()
  }
}
</script>

<style scoped>

/deep/ .el-form-item {
    margin-bottom: 0;
    vertical-align: middle;
}

.button-container {
    text-align: center;
}

.transition-box {
    transition: ease 0.5s;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-right: 20px;
}
.el-pagination {
    bottom: 10px;
    position: fixed;
    margin-left: 60px;
}
</style>
