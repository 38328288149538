<template>
    <div>
        <el-row>
            <el-form :model="queryParams" ref="queryParams" :inline="true" label-position="right" label-width="80px"
                     @submit.prevent.native>
                <el-form-item label="配置名称" prop="name" size="mini">
                    <el-input v-model="queryParams.name" class="condition-input" placeholder="配置名称"
                              @keyup.enter.native="getList" @submit.prevent.native="false"/>
                </el-form-item>
                <el-form-item label="状态" prop="status" size="mini">
                    <el-select v-model="queryParams.status" placeholder="配置状态" class="condition-input" size="mini">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="启用" value="1"></el-option>
                        <el-option label="禁用" value="0"></el-option>
                    </el-select>
                </el-form-item>

            </el-form>
        </el-row>
        <el-row>
            <el-button-group>
                <el-button type="primary" size="mini" @click="getList">查询</el-button>
                <el-button type="primary" size="mini" @click="resetForm('queryParams')">重置</el-button>
                <el-button type="primary" size="mini" @click="add">添加</el-button>
            </el-button-group>
        </el-row>
        <el-row>
            <el-table :data="tableData" max-height="800" style="width: 100%" stripe fit>
                <!--                <el-table-column align="center" min-width="120" prop="id" fixed="left" label="配置ID"/>-->
                <el-table-column align="center" min-width="120" prop="name" fixed="left" label="配置名称"/>
                <el-table-column align="center" min-width="120" prop="description" fixed="left" label="配置描述"/>
                <el-table-column align="center" min-width="100" prop="status" label="状态">
                    <template v-slot="score">
                        <el-tag v-if="score.row.status === 0" type="success">启用</el-tag>
                        <el-tag v-else-if="score.row.status === 1" type="danger">禁用</el-tag>
                        <el-tag v-else type="info">未知</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" min-width="100" prop="createBy" label="创建人"/>
                <el-table-column align="center" min-width="100" prop="createTime" label="创建时间"/>
                <el-table-column align="center" min-width="100" fixed="right" label="操作">
                    <template slot-scope="slot">
                        <el-button type="primary" size="mini" @click="getOne(slot.row.id)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="deleteById(slot.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleNumChange"
                    :current-page="queryParams.pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="queryParams.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="queryParams.total">
            </el-pagination>
        </el-row>

        <el-dialog v-loading="loading" :title="dialogForm.title" :visible.sync="dialogForm.visible" width="50%">
            <el-form :model="form">
                <el-form-item label="配置名称" label-width="100px">
                    <el-input v-model="form.name" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="配置描述" label-width="100px">
                    <el-input type="textarea" v-model="form.description" autocomplete="off"/>
                </el-form-item>
                <el-form-item label="状态" label-width="100px">
                    <el-select v-model="form.status" placeholder="请选择状态">
                        <el-option label="启用" :value="0"></el-option>
                        <el-option label="禁用" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item size="mini" label="批次信息" v-if="batchList && batchList.length > 0">
                    <el-divider/>
                    <div v-for="item in batchList">
                        <el-tag :type="typeList[item.batchId%5]"> {{ item.name }}</el-tag>
                        <el-divider direction="vertical"/>
                        <el-switch
                                v-model="item.status"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                active-text="启用"
                                inactive-text="禁用"
                                :active-value="0"
                                :inactive-value="1"
                                @change="changeBatchStatus($event, item)">
                        </el-switch>
                        <el-divider direction="vertical"/>
                        <el-input-number
                                :disabled="item.status === 1"
                                v-model="item.count"
                                controls-position="right"
                                :min="0" :max="item.totalCount - item.usedCount">
                        </el-input-number>
                        <el-link icon="el-icon-warning" type="warning">
                            可发放数量 {{ item.totalCount - item.usedCount }} 张
                        </el-link>
                        <el-checkbox-group v-model="item.grantTypeArr" size="mini"
                                           :disabled="item.status === 1">
                            <el-checkbox :label="1">发放优惠券</el-checkbox>
                            <el-checkbox :label="2">分享批次链接</el-checkbox>
                        </el-checkbox-group>
                        <el-divider/>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogForm.visible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import * as configApi from '@/api/crm/configuration'
import * as batchApi from '@/api/crm/crmBatch'
import {resetForm} from "@/utils/utils";

export default {
    name: "ConfigurationMarket",
    data() {
        return {
            loading: false,
            queryParams: {
                pageSize: 10,
                pageNum: 1,
                total: 0,
                name: '',
                status: '',
            },
            tableData: [],
            dialogForm: {
                visible: false,
                title: '',
                type: 0 // 0:新增 1:修改
            },
            form: {
                id: null,
                name: null,
                description: null,
                status: 0,
                batchList: []
            },
            batchList: [],
            typeList: ['', 'success', 'info', 'warning', 'danger']
        }
    },
    methods: {
        changeBatchStatus(val, obj) {
            if (val === 0) {
                obj.grantTypeArr = [1, 2]
            } else if (val === 1) {
                obj.grantTypeArr = []
            }
        },
        resetForm(val) {
            this.$refs[val].resetFields();
            this.getList();
        },
        findBatchList(list) {
            batchApi.getValidBatchList().then(res => {
                this.loading = false;
                if (res.success) {
                    const dataList = res.data;
                    dataList.forEach(e => {
                        e['grantTypeArr'] = []
                        e['status'] = 1
                        e['count'] = 0
                        e['batchId'] = e.id
                        e.id = null
                    })
                    if (list && list.length > 0) {
                        dataList.forEach(e1 => {
                            list.forEach(e2 => {
                                if (e1.batchId === e2.batchId) {
                                    e1.grantTypeArr = e2.grantTypes.split(',').map(Number);
                                    e1.status = e2.status;
                                    e1.count = e2.count;
                                }
                            })
                        })
                    }
                    this.batchList = dataList
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        submitForm() {
            const data = this.form;
            data["batchList"] = []
            this.batchList.forEach(e => {
                if (e.status === 0) {
                    const item = {}
                    item.batchId = e.batchId;
                    item.name = e.name;
                    item.type = e.type;
                    item.grantTypes = e.grantTypeArr.join(',')
                    item.discount = e.discount;
                    item.fullAmount = e.fullAmount;
                    item.count = e.count;
                    item.status = e.status;
                    item.goodsIds = e.goodsIds;
                    item.description = e.description;
                    data.batchList.push(item)
                }
            })
            switch (this.dialogForm.type) {
                case 0:
                    // todo 处理数据
                    configApi.add(data).then(resp => {
                        if (resp.success) {
                            this.dialogForm.visible = false;
                            this.$message.success(resp.msg);
                            this.getList();
                        } else {
                            this.$message.error(resp.msg)
                        }
                    })
                    break;
                case 1:
                    // todo 处理数据
                    configApi.update(data).then(resp => {
                        if (resp.success) {
                            this.dialogForm.visible = false;
                            this.$message.success(resp.msg);
                            this.getList();
                        } else {
                            this.$message.error(resp.msg)
                        }
                    })
                    break;
            }
        },
        getList() {
            configApi.getList(this.queryParams).then(resp => {
                if (resp.success) {
                    this.tableData = resp.data.data;
                    this.queryParams.pageSize = resp.data.size;
                    this.queryParams.pageNum = resp.data.current;
                    this.queryParams.total = resp.data.total;
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.getList();
        },
        handleNumChange(val) {
            this.queryParams.pageNum = val
            this.getList();
        },
        add() {
            this.dialogForm.type = 0;
            this.dialogForm.visible = true;
            this.dialogForm.title = "新增营销配置";
            this.findBatchList();
        },
        deleteById(id) {
            configApi.deleteById(id).then(resp => {
                if (resp.success) {
                    this.$message.success(resp.msg);
                    this.getList();
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        getOne(id) {
            this.loading = true;
            configApi.getById(id).then(resp => {
                if (resp.success) {
                    this.form = resp.data;
                    this.dialogForm.type = 1;
                    this.dialogForm.visible = true;
                    this.dialogForm.title = "修改营销配置";
                    this.findBatchList(this.form.batchList);
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
    },
    created() {
        this.getList();
    }
}
</script>

<style scoped>

</style>
