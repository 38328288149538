<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="操作描述" prop="action">
        <el-input
            v-model="queryParams.action"
            clearable
            size="small"
        />
      </el-form-item>
      <el-form-item label="模块名" prop="module">
        <el-input
            v-model="queryParams.module"
            clearable
            size="small"
        />
      </el-form-item>
      <el-form-item label="操作人" prop="accountId">
        <el-select v-model="queryParams.accountId" filterable class="condition-select" clearable placeholder="请选择"
                   size="small">
          <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="操作时间" prop="startTime">
        <el-date-picker
            clearable
            size="mini"
            v-model="datetime"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
        >修改
        </el-button>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--            type="primary"-->
      <!--            plain-->
      <!--            icon="el-icon-download"-->
      <!--            size="mini"-->
      <!--            @click="exportData"-->
      <!--        >导出-->
      <!--        </el-button>-->
      <!--      </el-col>-->
    </el-row>

    <el-table max-height="660" v-loading="loading" :data="configList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="主键" align="center" prop="id"/>
      <el-table-column label="操作描述" align="center" prop="action"/>
      <el-table-column label="模块名" align="center" prop="module"/>
      <el-table-column label="请求方法" align="center" prop="requestMethod"/>
      <el-table-column label="请求地址" align="center" prop="url"/>
      <el-table-column label="ip" align="center" prop="ip"/>
      <el-table-column label="操作人" align="center" prop="account"/>
      <el-table-column label="执行时间" align="center" prop="runTime"/>
      <el-table-column label="操作时间" align="center" prop="createTime"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="参数名称" prop="configName">
          <el-input v-model="form.configName" placeholder="请输入参数名称"/>
        </el-form-item>
        <el-form-item label="参数键名" prop="configKey">
          <el-input v-model="form.configKey" placeholder="请输入参数键名"/>
        </el-form-item>
        <el-form-item label="参数键值" prop="configValue">
          <el-input v-model="form.configValue" type="textarea" placeholder="请输入内容"/>
        </el-form-item>
        <el-form-item label="系统内置" prop="configType">
          <el-select v-model="form.configType" filterable placeholder="请选择系统内置">
            <el-option label="请选择字典生成" value=""/>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as operationApi from "@/api/crm/operationLog";
import axios from "axios";
import * as api from "@/utils/api";
import * as accountApi from "@/api/system/account";

export default {
  name: "OperationLog",
  components: {},
  data() {
    return {
      adviserList: [],
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 参数配置表格数据
      configList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        startTime: null,
        endTime: null,
        action: null,
        module: null,
        accountId: null
      },
      datetime: [],
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      dataRange: ""
    };
  },
  created() {
    this.getList();
    this.getAdviserList();
  },
  methods: {
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    exportData() {
      if (this.datetime && this.datetime[0] && this.datetime[1]) {
        this.queryParams['startTime'] = this.datetime[0] + " 00:00:00"
        this.queryParams['endTime'] = this.datetime[1] + " 23:59:59"
      } else {
        this.$message.error("导出信息必须选择日期!每次最大导出1万")
        return
      }
      this.$message({message: "下载中,请稍后...", type: 'success'})
      axios({
        method: "post",
        url: "/api/manage/system/operationLog/exportData",
        data: JSON.stringify(this.queryParams),
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
        },
      }).then(res => {
        const blob = new Blob([res.data]);
        const fileName = '操作日志.xlsx';
        const elink = document.createElement('a')
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).catch(error => {
        this.$message.error(error.msg);
      })
    },
    /** 查询参数配置列表 */
    getList() {
      if (this.datetime && this.datetime[0] && this.datetime[1]) {
        this.queryParams['startTime'] = this.datetime[0] + " 00:00:00"
        this.queryParams['endTime'] = this.datetime[1] + " 23:59:59"
      } else {
        this.queryParams['startTime'] = null
        this.queryParams['endTime'] = null
      }
      this.loading = true;
      operationApi.listOperationLog(this.queryParams).then(response => {
        this.configList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        configId: null,
        configName: null,
        configKey: null,
        configValue: null,
        configType: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        remark: null
      };
      this.datetime = []
      this.queryParams = {
        page: 1,
        limit: 10,
        startTime: null,
        endTime: null,
        action: null,
        module: null,
        accountId: null
      }
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.configId)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加参数配置";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const configId = row.configId || this.ids
      getConfig(configId).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改参数配置";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.configId != null) {
            updateConfig(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addConfig(this.form).then(response => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const configIds = row.configId || this.ids;
      this.$confirm('是否确认删除参数配置编号为"' + configIds + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delConfig(configIds);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    }
  }
};
</script>
