<template>
    <div v-loading="isCreatePage" class="custom--assembly-form">
        <el-page-header @back="goBack" content="列表页面"></el-page-header>
        <el-row :gutter="10">
            <el-col :span="15">
                <div style="margin-bottom: 10px;color: #fc4b4b">按钮条件匹配</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" size="small">
                    <el-form-item prop="name">
                        <template #label>
                            <el-tooltip effect="dark" content="管理名称,学习顾问不可见,其他配置可见名称"
                                        placement="top">
                                <span>按钮名称<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <template #default>
                            <el-input v-model="ruleForm.name" placeholder="请输入按钮名称" type="text"></el-input>
                        </template>
                    </el-form-item>

                    <el-form-item prop="label">
                        <template #label>
                            <el-tooltip effect="dark" content="学习顾问实际可见的按钮显示名称" placement="top">
                                <span> 按钮展示名称<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.label" placeholder="请输入按钮展示名称" type="text"></el-input>
                    </el-form-item>

                    <el-form-item prop="remarks">
                        <template #label>
                            <el-tooltip effect="dark" content="备注/描述" placement="top">
                                <span>按钮功能描述<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.remarks" placeholder="请输入按钮功能描述"
                                  type="textarea"></el-input>
                    </el-form-item>

                    <el-form-item prop="first">
                        <template #label>
                            <el-tooltip effect="dark" content="筛选首咨数据还是库存数据" placement="top">
                                <span> 机会是否被分配过<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="ruleForm.first">
                            <el-radio :label="1">首咨</el-radio>
                            <el-radio :label="0">库存</el-radio>
                            <el-radio :label="2">全部</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="descField">
                        <template #label>
                            <el-tooltip effect="dark" content="按照哪个字段进行排序" placement="top">
                                <span>排序字段<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-select v-model="ruleForm.descField" style="width: 100%;">
                            <el-option label="创建时间" value="opportunity.id"></el-option>
                            <el-option :key="item.name" :value="item.name" v-for="item in selectCreateFormOptionsArr"
                                       :label="item.label"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="descRule">
                        <template #label>
                            <el-tooltip effect="dark" content="排序方式" placement="top">
                                <span>排序规则<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-select v-model="ruleForm.descRule" style="width: 100%;"
                                   placeholder="请选择机会创建时间排序">
                            <el-option label="正序" :value="0"></el-option>
                            <el-option label="倒叙" :value="1"></el-option>
                            <el-option label="随机" :value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="poss">
                        <template #label>
                            <el-tooltip effect="dark" content="如果唯一配置生效,则数据会取学习顾问有权限的数据和当前按钮配置的条件的数据的交集' +
              ';否则,如果不生效,则只按照当前按钮配置" placement="top">
                                <span>启用唯一配置限制<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="ruleForm.poss">
                            <el-radio :label="true">生效</el-radio>
                            <el-radio :label="false">不生效</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item prop="tags">
                        <template #label>
                            <el-tooltip effect="dark" content="选择标签" placement="top">
                                <span>标签 <i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-select multiple v-model="ruleForm.tags" style="width: 100%;" placeholder="请选择标签">
                            <el-option v-for="item in tagOptions" :key="item.id" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="size">
                        <template #label>
                            <el-tooltip effect="dark" content="每次展示的数据条数" placement="top">
                                <span> 分页数量<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.size" placeholder="请填写" type="number"></el-input>
                    </el-form-item>

                    <el-form-item prop="protect">
                        <template #label>
                            <el-tooltip effect="dark" content="机会创建后,需要等待多久,可被本按钮展示出来"
                                        placement="top">
                                <span> 创建保护时间(分钟) <i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.protect" placeholder="请填写" type="number"></el-input>
                    </el-form-item>

                    <el-form-item prop="again">
                        <template #label>
                            <el-tooltip effect="dark" content="机会被(拨打、查看手机号)后,可被本按钮再次展示出来"
                                        placement="top">
                                <span>操作保护时间(天)  <i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.again" placeholder="请填写" type="number"></el-input>
                    </el-form-item>

                    <el-form-item prop="receiveWait">
                        <template #label>
                            <el-tooltip effect="dark" content="用户领取后,需要等待多久后,才可以再次领取"
                                        placement="top">
                                <span>领取间隔时间(秒)<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.receiveWait" placeholder="请填写" type="number"></el-input>
                    </el-form-item>

                    <el-form-item prop="protectBack">
                        <template #label>
                            <el-tooltip effect="dark" content="机会被回库后,需要等待多久,才可被本按钮展示出来"
                                        placement="top">
                                <span>回库间隔时间(天)<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.protectBack" placeholder="请填写" type="number"></el-input>
                    </el-form-item>

                    <el-form-item prop="receiveSize">
                        <template #label>
                            <el-tooltip effect="dark"
                                        content="按钮每日的领取上限,另外可对不同学习顾问在学习顾问配置针对按钮单独配置领取上限"
                                        placement="top">
                                <span>默认每日领取上限<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-input v-model="ruleForm.receiveSize" placeholder="请填写此按钮默认每日领取上限"
                                  type="number"></el-input>
                    </el-form-item>

                    <el-form-item prop="phone">
                        <template #label>
                            <el-tooltip effect="dark" content="查看手机号按钮" placement="top">
                                <span> 显示查看手机号按钮<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="ruleForm.phone">
                            <el-radio :label="true">展示</el-radio>
                            <el-radio :label="false">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item prop="calls">
                        <template #label>
                            <el-tooltip effect="dark" content="拨打按钮" placement="top">
                                <span>显示拨打按钮<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="ruleForm.calls">
                            <el-radio :label="true">展示</el-radio>
                            <el-radio :label="false">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item prop="receive">
                        <template #label>
                            <el-tooltip effect="dark" content="领取按钮" placement="top">
                                <span>显示领取按钮<i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="ruleForm.receive">
                            <el-radio :label="true">展示</el-radio>
                            <el-radio :label="false">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item prop="freeze">
                        <template #label>
                            <el-tooltip effect="dark" content="冻结按钮" placement="top">
                                <span>显示冻结按钮 <i class="el-icon-warning"/></span>
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="ruleForm.freeze">
                            <el-radio :label="true">展示</el-radio>
                            <el-radio :label="false">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>


        <el-divider></el-divider>
        <div style="margin-bottom: 10px;color: #fc4b4b">动态配置项</div>
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="150px" size="small">

            <el-form-item label="添加配置项">
                <el-select size="small" v-model="selectCreateForm" placeholder="请选择表单项">
                    <el-option
                            v-for="item in selectCreateFormOptionsArr"
                            :key="item.name"
                            :label="item.label"
                            :value="item.name">
                    </el-option>
                </el-select>

                <el-button style="margin-left: 10px" size="small" @click="addFormItem(selectCreateForm)">添加
                </el-button>
            </el-form-item>

            <div v-for="item in originalCreateFormData2" :key="item.id">
                <el-form-item v-if="isShowFormItem(item)" :label="item.label">

                    <assembly-form-item v-model="ruleForm2[item.id]" :item="item" :style="item.style"/>

                    <el-button v-if="item.isShowDeleteBtn" style="margin-left: 20px" @click="onDeleteFormItem(item)">删除
                    </el-button>
                </el-form-item>
            </div>


            <el-form-item>
                <el-button type="primary" @click="submitForm">保存</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
import AssemblyFormItem from "@/components/formItem/assembly-form-item.vue";
import * as receiveApi from "@/api/system/receive";
import * as tagsApi from "@/api/crm/tags";
import * as searchApi from "@/api/system/searchDeploy";
import * as api from "@/utils/api";

function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default {
    components: {AssemblyFormItem},
    data() {
        return {
            // 页面是否正在构建中
            isCreatePage: false,
            /*
            *
            * prop: 数据绑定名称
            * type: 表单类型
            * label: 表单左侧名称
            * placeholder: 为空默认显示
            * multiple: 是否多选
            * optionData: 子项的数据数组
            * rules：校验规则
            *
            *
            * linkage： 联动项目
            *   prop：联动哪项
            *   value：值相等时才显示
            *
            *
            * isShowDeleteBtn： 是否展示删除按钮
            *
            * */
            // 构造表单的原始数据集合
            originalCreateFormData: [],
            ruleForm: {
                first: 1,
                cached: false,
                calls: false,
                phone: false,
                receive: true,
                descField: "opportunity.id",
                receiveWait: 0,
                protectBack: 0,
                protect: 0,
                descRule: 0,
                cacheSize: 3000,
                receiveSize: 100,
                again: 1,
                size: 10,
                freeze: false,
            },
            rules: {
                "name": [
                    {required: true, message: '请输入按钮名称', trigger: 'blur'}
                ],
                "label": [
                    {required: true, message: '请输入按钮展示名称', trigger: 'blur'}
                ],
                "remarks": [
                    {required: true, message: '请输入按钮功能描述', trigger: 'blur'}
                ],
                "first": [
                    {required: true, message: '请选择', trigger: 'change'}
                ],
                "descRule": [
                    {required: true, message: '请选择机会创建时间排序', trigger: 'change'}
                ],
                "poss": [
                    {required: true, message: '请选择', trigger: 'change'}
                ],
                "size": [
                    {required: true, message: '请输入分页数量间保护间隔', trigger: 'blur'},
                ],
                "protect": [
                    {required: true, message: '请输入机会创建时间保护间隔', trigger: 'blur'},
                ],
                "again": [
                    {required: true, message: '请输入操作保护保护间隔', trigger: 'blur'},
                ],
                "receiveWait": [
                    {required: true, message: '请输入领取等待间隔', trigger: 'blur'},
                ],
                "protectBack": [
                    {required: true, message: '请输入回库间隔时间', trigger: 'blur'},
                ],
                "receiveSize": [
                    {required: true, message: '请填写此按钮默认每日领取上限', trigger: 'blur'},
                ],
                "phone": [
                    {required: true, message: '请选择', trigger: 'change'}
                ],
                "calls": [
                    {required: true, message: '请选择', trigger: 'change'}
                ],
                "receive": [
                    {required: true, message: '请选择', trigger: 'change'}
                ],
                "freeze": [
                    {required: true, message: '请选择', trigger: 'change'}
                ],
            },
            // selectCreateForm
            selectCreateForm: '',
            /*
            * code 用来请求findOptions方法 构建选项数据
            *       只有intention/select/select-like使用
            *       非必填
            *
            *
            * type 模板类型,对应 ComparisonTable
            *
            * customConfig
            * */

            selectCreateFormOptionsArr: [
                {
                    "name": "opportunity.possessId",
                    "label": "唯一配置",
                    "code": "possess",
                    "type": "select"
                },
                {
                    "name": "opportunity.mediatorId",
                    "label": "渠道",
                    "code": "mediator",
                    "type": "select"
                },
                {
                    "name": "opportunity.subjectIds",
                    "label": "项目",
                    "code": "subject",
                    "type": "select",
                    "customConfig": {
                        allowCreate: true,
                        filterable: true,
                        defaultFirstOption: true
                    }
                },
                {
                    "name": "opportunity.createTime",
                    "label": "机会创建时间",
                    "code": "dateTime",
                    "type": "date"
                },
                {
                    "name": "opportunity.haveTime",
                    "label": "最后指派时间",
                    "code": "dateTime",
                    "type": "date"
                },
                {
                    "name": "opportunity.intention",
                    "label": "意向度",
                    "code": "intention",
                    "type": "intention"
                },
                {
                    "name": "opportunity.dealAmount",
                    "label": "成单金额",
                    "code": "money",
                    "type": "number"
                },
                {
                    "name": "opportunity.pageShareAdviserIds",
                    "label": "页面分享工号码 ",
                    "code": "",
                    "type": "input",
                    "customConfig": {
                        disabled: true
                    }
                },
                {
                    "name": "opportunity.verifyEducation",
                    "label": "学历",
                    "code": "",
                    "type": "select",
                    "multiple": true,
                    "allowCreate": true,
                    "filterable": true,
                    "customConfig": {
                        allowCreate: true,
                        filterable: true,
                        multiple: true,
                    },
                    "optionData": [
                        {"value": "博士", "label": "博士"},
                        {"value": "硕士", "label": "硕士"},
                        {"value": "研究生", "label": "研究生"},
                        {"value": "本科", "label": "本科"},
                        {"value": "大专", "label": "大专"},
                        {"value": "高中", "label": "高中"},
                        {"value": "其他", "label": "其他"}
                    ]
                },
            ],
            ComparisonTable: {
                "DICT": {
                    templateName: 'DICT',
                    prop: '',
                    type: 'DICT',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "date": {
                    templateName: 'date',
                    prop: '',
                    type: 'combo',
                    label: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择比较条件',
                            multiple: false,
                            optionData: [
                                {label: '<', value: 'LT'},
                                {label: '>', value: 'GT'}
                            ]
                        },
                        {
                            prop: 'compareType',
                            type: 'select',
                            placeholder: '请选择类型',
                            multiple: false,
                            optionData: [
                                {label: '日期选择', value: 0},
                                {label: '距离今日', value: 1}
                            ]
                        },
                        {
                            prop: 'date',
                            type: 'datePicker',
                            dateType: 'datetime',
                            placeholder: '请选择日期',
                            linkage: {
                                prop: 'compareType',
                                value: 0
                            }
                        },
                        {
                            prop: 'day',
                            type: 'input',
                            placeholder: '请填写距离今日多少天',
                            linkage: {
                                prop: 'compareType',
                                value: 1
                            }
                        }
                    ]
                },
                "select": {
                    templateName: 'select',
                    prop: '',
                    type: 'select',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "radio": {
                    templateName: 'radio',
                    prop: '',
                    type: 'select',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "input": {
                    templateName: 'input',
                    prop: '',
                    type: 'input',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "select-like": {
                    templateName: 'select-like',
                    prop: '',
                    type: 'select',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                // 学习进度使用
                "intention": {
                    templateName: 'intention',
                    prop: '',
                    type: 'combo',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择类型',
                            multiple: false,
                            optionData: [
                                {label: '包含', value: 'IN'},
                                {label: '不包含', value: 'NOTIN'}
                            ]
                        },
                        {
                            prop: 'tag',
                            type: 'select',
                            placeholder: '请选择类型',
                            multiple: true,
                            optionData: []
                        },
                    ]
                },
                "number": {
                    templateName: 'number',
                    prop: '',
                    type: 'combo',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择比较条件',
                            multiple: false,
                            optionData: [
                                {label: '=', value: 'EQ'},
                                {label: '<', value: 'LT'},
                                {label: '<=', value: 'LE'},
                                {label: '>=', value: 'GE'},
                                {label: '>', value: 'GT'},
                                {label: '动态范围', value: 'BETWEEN'},
                            ]
                        },
                        {
                            prop: 'start',
                            type: 'input',
                            placeholder: ''
                        },
                        {
                            prop: 'end',
                            type: 'input',
                            placeholder: '',
                            linkage: {
                                prop: 'compare',
                                value: 'BETWEEN'
                            }
                        }
                    ]
                },
                "money": {
                    templateName: 'money',
                    prop: '',
                    type: 'combo',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择比较条件',
                            multiple: false,
                            optionData: [
                                {label: '=', value: 'EQ'},
                                {label: '<', value: 'LT'},
                                {label: '<=', value: 'LE'},
                                {label: '>=', value: 'GE'},
                                {label: '>', value: 'GT'},
                                {label: '动态范围', value: 'BETWEEN'},
                            ]
                        },
                        {
                            prop: 'start',
                            type: 'input',
                            placeholder: ''
                        },
                        {
                            prop: 'end',
                            type: 'input',
                            placeholder: '',
                            linkage: {
                                prop: 'compare',
                                value: 'BETWEEN'
                            }
                        }
                    ]
                },
            },

            originalCreateFormData2: [],
            ruleForm2: {},
            rules2: {},
            id: '',
            tagOptions: [],
        };
    },
    computed: {
        // 是否展示表单项
        isShowFormItem() {
            return (item) => {
                if (item.hasOwnProperty('linkage')) {
                    if (!item.linkage.prop) {
                        return false
                    }
                    //
                    if (this.ruleForm[item.linkage.prop] === item.linkage.value) {
                        return true
                    }
                    return false
                }
                return true
            }
        }
    },
    async mounted() {
        this.id = this.$route.query?.id
        await this.findTagsOptions()
        this.initPage()
        await this.findSelectList()
        // this.getDeptTree()

        this.isCreatePage = false

        if (this.id) {
            this.setFromData()
        }
    },
    methods: {
        /**
         * 添加下拉选项
         */
        async findSelectList() {
            await searchApi.list({classify: "NEW_INFORMATION"}).then(res => {
                if (res.success) {
                    let infoList = res.data.filter(e => e.receive === true).forEach(item => {
                        let data = JSON.parse(item.data)
                        let dictList = data.dictList || []
                        this.selectCreateFormOptionsArr.push({
                            "name": '$.' + item.name,
                            "label": item.label,
                            "code": item.name,
                            "type": item.type,
                            "optionData": dictList,
                        })
                    });
                } else {
                    this.$message.error(res.msg)
                }
            });
        },
        async findTagsOptions() {
            const {data} = await tagsApi.options()
            if (data) {
                this.tagOptions = data.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            }
        },
        goBack() {
            //跳转到上一次浏览的页面
            this.$router.go(-1)
        },
        /*
        * 数据回显
        * */
        async setFromData(data = {}) {

            const _res = await receiveApi.infoReceive(this.id)
            // console.log(_res)
            data = _res.data || {}

            let _obj = Object.assign({}, data)
            delete _obj.children


            this.$nextTick(() => {
                this.ruleForm = Object.assign({}, _obj)
            })
            // 回显外层数据
            _obj.tags = data.tags?.split(',')?.map(Number) || []

            // 回显部门数据
            // this.checkedDept = data.deptIds?.split(',') || []


            // 回显动态表单数据
            data.children?.map(async item => {
                const id = await this.addFormItem(item.name)

                let params = {}
                switch (item.type) {
                    case 'time':
                        params.compare = item.compare
                        params.compareType = item.compareType
                        item.compareType === 1 ? params.day = item.value : params.date = item.value
                        break
                    case 'date':
                        params.compare = item.compare
                        params.compareType = item.compareType
                        item.compareType === 1 ? params.day = item.value : params.date = item.value
                        break
                    case 'input':
                        params = item.value || ''
                        break
                    case 'select':
                        params = item.value?.split(',') || []
                        break
                    case 'radio':
                        params = item.value?.split(',') || []
                        break
                    case 'select-like':
                        params = item.value?.split(',') || []
                        break
                    case 'number':
                        params.compare = item.compare
                        if (item.compare === 'BETWEEN') {
                            let v = item.value.split(",")
                            if (v) {
                                params.start = v[0]
                                params.end = v[1]
                            }
                        } else {
                            params.start = item.value
                        }
                        break
                    case 'money':
                        params.compare = item.compare
                        if (item.compare === 'BETWEEN') {
                            let v = item.value.split(",")
                            if (v) {
                                params.start = v[0]
                                params.end = v[1]
                            }
                        } else {
                            params.start = item.value
                        }
                        break
                    case 'intention':
                        params.compare = item.compare
                        params.tag = item.value?.split(',') || []
                        break
                    case 'DICT':
                        params = item.value?.split(',').map(Number) || []
                        break
                }
                // this.ruleForm2[id] = params
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.ruleForm2[id] = params
                    })
                }, 500)


            })

            // console.log('最后构建的数据', this.ruleForm2)

        },
        async findSubjectList () {
            const res = await api.subjectList()
            const arr = res.data?.map(item => {
                console.log(item)
                let v = {}
                v['value'] = String(item.id)
                v['label'] = item.subjectName
                return v
            }) || []
            return arr
        },
        /**
         * 根据code获取下拉框参数
         * @param code
         */
        async findOptions(code) {
            const res = await receiveApi.findOptions(code)
            const arr = res.data?.map(item => {
                item.value = String(item.value)
                return item
            }) || []
            return arr
        },
        /*
        * 构建最终数据结果
        *
        * 调用提交接口前 过滤并构造数据
        *
        * */
        createSubmitData() {
            // 构建动态表单的数据结果
            const childrenForm = []
            this.originalCreateFormData2.map(item => {

                const _res = {
                    name: item.prop,
                    label: item.label,
                    type: item.templateName
                }

                switch (item.templateName) {
                    case 'time':
                        _res.compare = this.ruleForm2[item.id].compare
                        _res.compareType = this.ruleForm2[item.id].compareType
                        _res.value = _res.compareType === 1 ? this.ruleForm2[item.id].day : this.ruleForm2[item.id].date
                        break
                    case 'date':
                        // console.log(item, 1231321231, this.ruleForm2[item.id])
                        _res.compare = this.ruleForm2[item.id].compare
                        _res.compareType = this.ruleForm2[item.id].compareType
                        _res.value = _res.compareType === 1 ? this.ruleForm2[item.id].day : this.ruleForm2[item.id].date
                        break
                    case 'input':
                        _res.compare = 'LIKE'
                        _res.value = this.ruleForm2[item.id] || ''
                        break
                    case 'select':
                        _res.compare = 'IN'
                        _res.value = (this.ruleForm2[item.id] || []).join(',')
                        break
                    case 'radio':
                        _res.compare = 'IN'
                        _res.value = (this.ruleForm2[item.id] || []).join(',')
                        break
                    case 'select-like':
                        _res.compare = 'LIKEIN'
                        _res.value = (this.ruleForm2[item.id] || []).join(',')
                        break
                    case 'number':
                        _res.compare = this.ruleForm2[item.id].compare
                        _res.value = _res.compare === 'BETWEEN' ? this.ruleForm2[item.id].start + "," + this.ruleForm2[item.id].end : this.ruleForm2[item.id].start
                        break
                    case 'money':
                        _res.compare = this.ruleForm2[item.id].compare
                        _res.value = _res.compare === 'BETWEEN' ? this.ruleForm2[item.id].start + "," + this.ruleForm2[item.id].end : this.ruleForm2[item.id].start
                        break
                    case 'intention':
                        _res.compare = this.ruleForm2[item.id].compare
                        _res.value = this.ruleForm2[item.id].tag.join(',')
                        break
                    case 'DICT':
                        _res.code = this.ruleForm2[item.id].code
                        _res.compare = 'LIKEIN'
                        _res.value = (this.ruleForm2[item.id] || []).join(',')
                        break

                }

                childrenForm.push(_res)
            })
            // console.log(childrenForm)

            // 构建总的数据结果
            const form = Object.assign({}, this.ruleForm)
            form.children = childrenForm

            // 过滤tags数组数据
            form.tags = form.tags?.join(',') || null

            // 组合部门的数据结果
            // const selectDeptForm = this.$refs.deptForm.getCheckedKeys() || []

            // console.log(selectDeptForm)
            // form.deptIds = selectDeptForm.join(',')

            return form
        },
        async addFormItem(selectCreateForm) {
            const _id = guid()

            if (!selectCreateForm) {
                this.$message.warning('请选择表单项')
                return
            }

            const selectObj = this.selectCreateFormOptionsArr.filter(item => item.name === selectCreateForm)[0] || {}
            if (!this.ComparisonTable[selectObj.type]) {
                this.$message.warning('无当前表单对照数据')
                return;
            }


            // if (this.ruleForm2.hasOwnProperty(selectObj.name)) {
            //   this.$message.warning('请勿重复添加')
            //   return;
            // }


            let params = Object.assign({}, this.ComparisonTable[selectObj.type])

            if (selectObj.hasOwnProperty('customConfig')) {
                params = {...params, ...selectObj.customConfig}
            }

            params.prop = selectObj.name
            params.label = selectObj.label
            params.id = _id

            this.rules2[params.id] = params.rules || []

            if (selectObj.optionData) {
                params.optionData = selectObj.optionData
            } else {
                // 如果是select 构建选项数据
                if (params.templateName === 'select' || params.templateName === 'select-like') {
                    const arr = await this.findOptions(selectObj.code)
                    // console.log(arr)
                    params.optionData = arr
                }
            }

            // 如果是select 构建选项数据
            if (params.templateName === 'intention') {
                const arr = await this.findOptions(selectObj.code)
                // console.log(arr)
                params.comboList[1].optionData = arr
            }
            console.log('templateName',params)
            if (params.prop === "opportunity.subjectIds") {
                const arr = await this.findSubjectList()
                // console.log(arr)
                params.optionData = arr
            }

            if (params.templateName === 'DICT') {
                params.code = selectObj.code
            }

            this.originalCreateFormData2.push(params)

            return _id
        },
        initPage() {
            this.isCreatePage = true
            this.createRules()

        },
        // 创建rules
        createRules() {
            this.originalCreateFormData.map(item => {
                if (item.rules) {
                    this.rules[item.prop] = item.rules
                }
            })
        },
        // 是否删除某个表单项目
        onDeleteFormItem(item) {
            this.originalCreateFormData2.map((_item, index) => {
                if (_item.prop === item.prop) {
                    this.originalCreateFormData2.splice(index, 1)
                }
            })
        },
        submitForm(formName) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // console.log(this.createSubmitData())
                    receiveApi.saveReceive(this.createSubmitData()).then(res => {
                        // console.log(res)
                        if (res.success) {
                            this.$router.back()
                            this.$message.success('保存成功')
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style scoped lang="less">
.custom--assembly-form {
  background-color: #FFFFFF;
  padding: 20px 30px;
  min-height: 100vh;
}
</style>
