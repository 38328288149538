<template>
  <el-row>
    <h4>历史记录</h4>
    <el-row class="history">
      <el-row>
        <el-button class="intention" icon="el-icon-menu" plain round @click="history('0')">全部</el-button>
        <el-button class="intention" type="success" icon="el-icon-phone-outline" plain round
                   @click="history('3')">拨打
        </el-button>
        <el-button class="intention" type="primary" icon="el-icon-s-management" plain round
                   @click="history('4')">备注
        </el-button>
        <el-button class="intention" type="warning" icon="el-icon-s-flag" plain round @click="history('14')">标签
        </el-button>
      </el-row>
      <el-row class="historyList">
        <el-row :span="24" v-for="(item, index) in historyList" :key="index">
          <el-col :span="3">
            <!--/*：1领取，2，回库，3，拨打，4，沟通，5，下单，6，成单，7，分配',*/-->
            <template v-if="item.status == 3 && item.contextMap.callStatus == 1">
              <el-tooltip class="item" effect="dark" content="已接通" placement="bottom">
                <i class="el-icon-phone-outline green"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 3 && item.contextMap.callStatus == 0">
              <el-tooltip class="item" effect="dark" content="未接通" placement="bottom">
                <i class="el-icon-phone-outline grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 5">
              <el-tooltip class="item" effect="dark" content="创建订单" placement="bottom">
                <i class="el-icon-money grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 6">
              <el-tooltip class="item" effect="dark" content="成交订单" placement="bottom">
                <i class="el-icon-money pink"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 1 && item.opporType == 1">
              <el-tooltip class="item" effect="dark" content="首咨" placement="bottom">
                <i class="el-icon-printer grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 1 && item.opporType == 4">
              <el-tooltip class="item" effect="dark" content="库存" placement="bottom">
                <i class="el-icon-printer grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 1 && item.opporType == 5">
              <el-tooltip class="item" effect="dark" content="唤醒" placement="bottom">
                <i class="el-icon-printer grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 4">
              <el-tooltip class="item" effect="dark" content="备注信息" placement="bottom">
                <i class="el-icon-chat-dot-round yellow"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 2 && item.contextMap">
              <el-tooltip class="item" effect="dark" content="回库" placement="bottom">
                <i class="el-icon-refresh-left red"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 7 && item.contextMap">
              <el-tooltip class="item" effect="dark" content="分配" placement="bottom">
                <i class="el-icon-thumb violet"></i>
              </el-tooltip>
            </template>
            <template
                v-else-if="item.status == 8 || item.status == 11 || item.status == 12 || item.status == 13">
              <el-tooltip class="item" effect="dark" content="上传方式" placement="bottom">
                <i class="el-icon-s-promotion grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 10">
              <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                <i class="el-icon-delete-solid red"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 15">
              <el-tooltip class="item" effect="dark" content="唤醒" placement="bottom">
                <i class="el-icon-refresh grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 16">
              <el-tooltip class="item" effect="dark" content="冻结" placement="bottom">
                <i class="el-icon-close-notification grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 19">
              <el-tooltip class="item" effect="dark" content="修正机会" placement="bottom">
                <i class="el-icon-edit grey"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 24">
              <el-tooltip class="item" effect="dark" content="机会标签" placement="bottom">
                <i class="el-icon-collection-tag blue" style="font-size: 26px;color: #9191ef;"></i>
              </el-tooltip>
            </template>
            <template v-else-if="item.status == 27">
                <el-tooltip class="item" effect="dark" content="延迟回库" placement="bottom">
                    <i class="el-icon-magic-stick" style="font-size: 26px;color: #9191ef;"></i>
                </el-tooltip>
            </template>
            <template v-else>
              <el-tooltip class="item" effect="dark" content="标签" placement="bottom">
                <i class="el-icon-s-flag yellow"></i>
              </el-tooltip>
            </template>
          </el-col>
          <el-col :span="20" :offset="1" class="historyList_fontSize">
            <div>{{ item.createTime }}</div>
            <template v-if="item.status == 3 && item.contextMap.callStatus == 1">
              <div>拨打接通</div>
              <div v-if="item.adviserName">拨打坐席：{{ item.adviserName }}</div>
              <template v-if="item.contextMap.recordingUrl.indexOf('http') != -1">
                <el-tooltip class="item" effect="dark" content="下载录音至本地" placement="bottom">
                  <div class="positionIcon">
                    <i class="el-icon-download violet"
                       @click="downLoads(item.contextMap.recordingUrl)"></i>
                  </div>
                </el-tooltip>
              </template>
              <template v-else>
                <div>通话时长：{{ secondToTimeStr(item.contextMap.timeCount) }}</div>
                <div style="height: 30px">
                  <el-tooltip class="item" effect="dark" content="暂停" placement="bottom"
                              v-if="playActive === index">
                    <div class="positionIcon">
                      <i class="el-icon-video-pause violet"
                         @click="bindPause(item.contextMap.recordingUrl,index)"></i>
                    </div>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="播放" placement="bottom" v-else>
                    <div class="positionIcon">
                      <i class="el-icon-video-play violet"
                         @click="isLink(item.contextMap.recordingUrl,item.opportunityId,index)"></i>
                    </div>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="点击下载录音" placement="bottom">
                    <div class="positionIcon" style="left: 30px">
                      <i type="success" class="el-icon-download violet"
                         @click="isLinkDownload(item.contextMap.recordingUrl)">
                      </i>
                    </div>
                  </el-tooltip>
                  <div style="margin-left: 80px" v-if="playActive === index">
                    <div class="speedTime">{{ secondToTimeStr(audioCurrentTime) }} /
                      {{ secondToTimeStr(item.contextMap.timeCount) }}
                    </div>
                    <el-slider v-model="audioCurrentTime" class="sliderClass" :show-tooltip="false"
                               :min="audioMinDuration" :max="audioAllDuration"
                               @change="changeAudioDuration" @mousedown.native="isChange = true"
                               @mouseup.native="isChange = false"></el-slider>
                  </div>
                </div>
              </template>
              <div v-if="item.adviserName">拨打坐席：{{ item.adviserName }}</div>
            </template>
            <template v-else-if="item.status == 3 && item.contextMap.callStatus == 0">
              <div>拨打未接通</div>
              <div v-if="item.adviserName">拨打坐席：{{ item.adviserName }}</div>
            </template>
            <template v-else-if="item.status == 5">
              <div>创建订单商品：{{ item.contextMap.goodsName }}</div>
            </template>
            <template v-else-if="item.status == 6">
              <div>订单支付成功</div>
              <div>订单商品：{{ item.contextMap.goodsName }}</div>
              <div>商品支付价格：{{ item.contextMap.paymentAmount }}元</div>
              <el-link type="danger">渠道：{{ getColumn(item.content, 'mediatorName') }}</el-link>
            </template>
            <template v-else-if="item.status == 1 && item.opporType == 1">
              <div>领取 首咨: {{ item.adviserName || '--' }}</div>
              <el-tag v-if="item.btnId" size="mini">{{item.btnName || null}}</el-tag>
            </template>
            <template v-else-if="item.status == 1 && item.opporType == 4">
              <div>领取 库存: {{ item.adviserName || '--' }}</div>
              <el-tag v-if="item.btnId" size="mini">{{item.btnName || null}}</el-tag>
            </template>
            <template v-else-if="item.status == 1 && item.opporType == 5">
              <div>领取 唤醒: {{ item.adviserName || '--' }}</div>
              <el-tag v-if="item.btnId" size="mini">{{item.btnName || null}}</el-tag>
            </template>
            <template
                v-else-if="item.status == 4 && (item.contextMap.intention || item.contextMap.talkingSkills)">
              <div>标签： {{ item.contextMap.intention }}、{{ item.contextMap.talkingSkills }}</div>
              <div v-if="item.contextMap.startDate && item.contextMap.endDate">
                预约时间：{{ getLocalTime(item.contextMap.startDate) }} -
                {{ getLocalTime(item.contextMap.endDate) }}
              </div>
            </template>
            <template v-else-if="item.status ==4 && item.contextMap.remark">
              <div>沟通备注：{{ item.contextMap.remark }}</div>
              <div v-if="item.contextMap.startDate && item.contextMap.endDate">
                预约时间：{{ getLocalTime(item.contextMap.startDate) }} -
                {{ getLocalTime(item.contextMap.endDate) }}
              </div>
            </template>
            <template v-else-if="item.status ==4 && !item.contextMap.remark">
              <div>沟通备注：无</div>
              <div v-if="item.contextMap.startDate && item.contextMap.endDate">
                预约时间：{{ getLocalTime(item.contextMap.startDate) }} -
                {{ getLocalTime(item.contextMap.endDate) }}
              </div>
            </template>
            <template v-else-if="item.status == 2 && item.contextMap">
              <div>回库人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status == 7 && item.contextMap">
              <div>{{ item.createBy || '--' }}
                分配给：{{ item.adviserName || '--' }}
              </div>
            </template>
            <template v-else-if="item.status == 8">
              <div>上传方式：学习顾问添加</div>
              <div>操作人：{{ item.adviserName || '--' }}</div>
            </template>
            <template v-else-if="item.status == 10">
              <div>此机会删除</div>
              <div>删除人：{{ item.adviserName || '--' }}</div>
            </template>
            <template v-else-if="item.status == 11">
              <div>上传方式：批量导入{{ item.opporType == 1 ? '首咨' : '库存' }}</div>
              <div>操作人：{{ item.adviserName || '--' }}</div>
              <div v-if="isShowTag(item.content)">
                <el-link type="primary">标签操作: {{ getColumn(item.content, 'tagSource') }}</el-link><br>
                <el-link type="warning">修改前标签: {{ getColumn(item.content, 'oldTagName') ? getColumn(item.content, 'oldTagName').split(',') : [] }}</el-link><br>
                <el-link type="success">修改后标签: {{ getColumn(item.content, 'newTagName') ? getColumn(item.content, 'newTagName').split(',') : [] }}</el-link>
              </div>
              <div v-if="getColumn(item.content, 'classname')">
                <el-link v-if="getColumn(item.content, 'classname')" type="primary">下单课程：{{ getColumn(item.content, 'classname') }}</el-link><br>
                <el-link v-if="getColumn(item.content, 'classPrice')" type="primary">下单金额：{{ getColumn(item.content, 'classPrice') }} 元</el-link><br>
                <el-link v-if="getColumn(item.content, 'mediatorName')" type="danger">渠道：{{ getColumn(item.content, 'mediatorName') }}</el-link>
              </div>
            </template>
            <template v-else-if="item.status == 12">
              <div>上传方式：后台管理添加</div>
              <div>操作人：{{ item.adviserName || '--' }}</div>
            </template>
            <template v-else-if="item.status == 13">
              <div>上传方式：推广渠道</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status == 15">
              <div>机会唤醒</div>
            </template>
            <template v-else-if="item.status == 16">
              <div>机会冻结</div>
              <div>操作人：{{ item.adviserName || '--' }}</div>
              <div>冻结原因：{{ item.contextMap.freezeRemark || '--' }}</div>
              <div>冻结期限：{{ item.contextMap.freezePeriod || '--' }}</div>
            </template>
            <template v-else-if="item.status == 17">
              <div>沟通程度</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
              <div>标签：{{ item.contextMap.tag || '--' }}</div>
            </template>
            <template v-else-if="item.status == 18">
              <div>添加微信</div>
              <div>学习顾问：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status == 19">
              <div>数据修正</div>
              <div>学习顾问：{{ item.createBy || '--' }}</div>
              <div>触发行为：{{ item.contextMap.action || '--' }} ---
                {{ item.contextMap.scriptName }}
              </div>
              <div>触发时间：{{ item.createTime || '--' }}</div>
            </template>
            <template v-else-if="item.status === 20">
              <div>用户咨询</div>
                <div v-if="isShowTag(item.content)">
                    <el-link type="primary">标签操作: {{ getColumn(item.content, 'tagSource') }}</el-link><br>
                    <el-link type="warning">修改前标签: {{ getColumn(item.content, 'oldTagName') ? getColumn(item.content, 'oldTagName').split(',') : [] }}</el-link><br>
                    <el-link type="success">修改后标签: {{ getColumn(item.content, 'newTagName') ? getColumn(item.content, 'newTagName').split(',') : [] }}</el-link>
                </div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 21">
              <div>获取机会详情</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 22">
              <div>校验机会</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 23">
              <div>复制手机号</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>

            <template v-else-if="item.status === 24">
              <div>标签修改</div>
              <div>操作方式: {{ JSON.parse(item.content).operate }}</div>
                <div v-if="isShowTag(item.content)">
                    <el-link type="primary">标签操作: {{ getColumn(item.content, 'tagSource') }}</el-link><br>
                    <el-link type="warning">修改前标签: {{ getColumn(item.content, 'oldTagName') ? getColumn(item.content, 'oldTagName').split(',') : [] }}</el-link><br>
                    <el-link type="success">修改后标签: {{ getColumn(item.content, 'newTagName') ? getColumn(item.content, 'newTagName').split(',') : [] }}</el-link>
                </div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 25">
              <div>发放优惠券</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 26">
              <div>领取优惠券</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 27">
                <div>延迟回库</div>
                <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 28">
                <div>机会恢复</div>
                <div>操作人：{{ item.createBy || '--' }}</div>
            </template>
            <template v-else-if="item.status === 99">
              <div>订单撤回</div>
              <div>操作人：{{ item.createBy || '--' }}</div>
            </template>

            <template v-else>
              <div>操作人：{{ item.createBy || '--' }}</div>
              <div v-for="tag in tagList" v-if="item.contextMap[tag.name]">
                {{ tag.label }}: {{ item.contextMap[tag.name] }}
              </div>
              <!--                            <div v-if="item.contextMap.intention">-->
              <!--                                {{ tagList[1]['oneName'] }}:{{ item.contextMap.intention }}-->
              <!--                            </div>-->
              <!--                            <div v-if="item.contextMap.talkingSkills">-->
              <!--                                {{ tagList[1]['twoName'] }}：{{ item.contextMap.talkingSkills }}-->
              <!--                            </div>-->
              <!--                            <div v-if="item.contextMap.learning">-->
              <!--                              {{ tagList[1]['twoName'] }}：{{ item.contextMap.learning }}-->
              <!--                            </div>-->
            </template>
          </el-col>
        </el-row>
        <audio :src="audioLinkBefore" id="audio" ref="audio" @canplay="getDuration"
               @timeupdate="durationUpdate"></audio>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import * as api from "@/utils/api";
import request from '@/utils/request.js';
import * as searchApi from "@/api/system/searchDeploy";


export default {
  name: "History",
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      historyList: {},
      playActive: -1,
      audioLinkBefore: '',
      audioOpportunityId: '',
      tagList: [],
      aa: null,
      playProcess: 0,
      sliderTime: 0,
      audioCurrentTime: 0,// 音频当前播放时长
      audioAllDuration: 0,// 音频最大播放时长
      audioMinDuration: 0,
      isChange: false,
      opporUrl: null,
    }
  },
  watch: {
    id: function (newId) {
      this.history('0')
    }
  },
  methods: {
    getColumn(item, column) {
      if (!item) return;
      const json = JSON.parse(item);
      if (json) {
        return json[column]
      } else {
        return item
      }
    },
    isShowTag(item) {
      if (!item) return false;
      const json = JSON.parse(item);
      if (json) {
        const a = json['oldTagName']
        const b = json['newTagName']
        return a !== b
      }
      return false;
    },
    history(type) {
      setTimeout(() => {
        api.history(this.id, type).then(res => {
          let data = res.data;
          this.historyList = data.map(item => {
            // 变更数据
            item.contextMap = item.content ? JSON.parse(item.content) : {}
            return item
          })
        })
      }, 300)

    },
    //秒-转换-时间格式
    secondToTimeStr(t) {
      if (!t) return;
      if (t < 60) return "00:" + (Math.round((i = t)) < 10 ? "0" + Math.round(i) : Math.round(i));
      if (t < 3600) return "" + (Math.round((a = parseInt(t / 60))) < 10 ? "0" + Math.round(a) : Math.round(a)) + ":" + (Math.round((i = t % 60)) < 10 ? "0" + Math.round(i) : Math.round(i));
      if (3600 <= t) {
        var a, i, e = parseInt(t / 3600);
        return (e < 10 ? "0" + e : e) + ":" + (Math.round((a = parseInt(t % 3600 / 60))) < 10 ? "0" + Math.round(i) : Math.round(i)) + ":" + (Math.round((i = t % 60)) < 10 ? "0" + Math.round(i) : Math.round(i));
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    getLocalTime(nS) {
      var time = new Date(nS);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + this.add0(m) + '-' + this.add0(d) + ' ' + this.add0(h) + ':' + this.add0(mm) + ':' + this.add0(s);
    },
    //获取总时长
    getDuration() {
      this.audioAllDuration = this.$refs.audio.duration
    },
    //更新当前时长
    durationUpdate() {
      if (this.isChange === true) return
      this.audioCurrentTime = this.$refs.audio.currentTime
    },
    //鼠标拖拽松开时
    changeAudioDuration() {
      if (this.audioAllDuration === 0) return
      this.$refs.audio.currentTime = this.audioCurrentTime
    },
    //播放
    bindPlay(url, audioOpportunityId, index, thiz) {
      thiz.playActive = index;
      let audio = document.getElementById('audio');
      if (thiz.audioOpportunityId === '') {
        thiz.opporUrl = url;
        thiz.audioOpportunityId = audioOpportunityId;
        thiz.audioLinkBefore = 'https://call.qinglanx.com:8443/pscc/sounds/record/' + url;
        setTimeout(function () {
          audio.play();// 播放bai
        }, 300)
      } else {
        if (thiz.opporUrl === url) {
          audio.play();// 播放bai
        } else {
          thiz.audioLinkBefore = 'https://call.qinglanx.com:8443/pscc/sounds/record/' + url;
          thiz.opporUrl = url;
          thiz.audioOpportunityId = audioOpportunityId;
          setTimeout(function () {
            audio.play();// 播放bai
          }, 300)
        }
      }
      audio.loop = false;
      audio.addEventListener('pause', function () {
        //监听到播放结束后，在此处可调用自己的接口
        console.log('播放完毕')
        thiz.playActive = -1;
        thiz.audioCurrentTime = 0;
      }, false);
    },
    //暂停
    bindPause(url, audioOpportunityId, index) {
      this.playActive = -1;
      let audio = document.getElementById('audio');
      audio.pause();// 暂停
    },
    //下载录音
    downLoads(url) {
      if (url.indexOf('http') === -1) {
        let httpUrl = "https://call.qinglanx.com:8443/pscc/sounds/record/"
        url += httpUrl + url
      }
      let a = document.createElement('a');
      let httpUrl = url;
      let filename = 'XXX.zip';
      a.href = httpUrl;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(httpUrl);
    },
    //下载录音
    downloadRadio(href) {
      let index = href.lastIndexOf("\/");
      let str = href.substring(index + 1, href.length);
      fetch(href).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        // 使用获取到的blob对象创建的url
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = str;
        a.click();
        document.body.removeChild(a)
        // 移除blob对象的url
        window.URL.revokeObjectURL(url);
      });
    },
    //判断链接是否存在在进行播放
    async isLink(url, audioOpportunityId, index) {
      let thiz = this
      await request.get('https://call.qinglanx.com:8443/pscc/sounds/record/' + url).then(response => { // 请求成功以后的回调函数
        thiz.bindPlay(url, audioOpportunityId, index, thiz)
      }).catch(error => {   // 请求失败以后的回调函数
        this.$message.closeAll();
        this.$message.error('该音频文件已损坏  !!!∑(ﾟДﾟノ)ノ 无法播放~')
      });
    },
    //判断链接是否存在在进行下载
    async isLinkDownload(url) {
      let thiz = this;
      let link = 'https://call.qinglanx.com:8443/pscc/sounds/record/' + url;
      await request.get(link).then(response => { // 请求成功以后的回调函数
        thiz.downloadRadio(link)
      }).catch(error => {   // 请求失败以后的回调函数
        thiz.$message.closeAll();
        thiz.$message.error('该音频文件已损坏 o(╥﹏╥)o 无法下载~')
      });
    },
    reload() {
      this.history('0')
    },
    initConfig() {
      searchApi.list({classify: "TAGS_LIST"}).then(res => {
        if (res.data) {
          for (let tags of res.data) {
            tags['datas'] = JSON.parse(tags.data)
          }
          this.tagList = res.data
        }
      })
    }
  },
  mounted() {
    this.initConfig()
    this.history('0')
  }

}
</script>

<style lang="less" scoped>
.intention {
  padding: 4px !important;
}

.history {
  height: 256px;
  border: 1px solid #8CC5FF;
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;

  .historyList {
    height: 200px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;


    .historyList_fontSize {
      font-size: 12px;
      line-height: 18px;
      border-bottom: 1px solid #f1f0f0;
      padding: 5px 0;

      > div {
        min-height: 18px;
        position: relative;

        .speedTime {
          position: absolute;
          left: 44%;
          bottom: 16px;
        }

        .sliderClass {
          width: 96%;
        }

        /deep/ .el-slider__button-wrapper {
          height: 16px;
          width: 16px;
          top: -6px;
        }

        /deep/ .el-slider__button {
          width: 10px;
          height: 10px;
          border-color: violet;
        }

        /deep/ .el-slider__bar {
          background: violet;
        }
      }
    }
  }

  .green {
    color: #3BB186;
    font-size: 26px;
  }

  .grey {
    color: grey;
    font-size: 26px;
  }

  .pink {
    color: deeppink;
    font-size: 26px;
  }

  .yellow {
    color: orange;
    font-size: 26px;
  }

  .violet {
    display: block;
    color: violet;
    font-size: 26px;
    cursor: pointer;
  }

  .red {
    color: red;
    font-size: 26px;
  }

  .positionIcon {
    position: absolute;
    //top: 30%;
    left: 0;
  }

  #audio {
    position: absolute;
    top: -2000px;
    right: -2000px;
  }

  .el-button--success {
    color: #67C23A;
    background: #f0f9eb;
    border-color: #c2e7b0;
  }
}
</style>
