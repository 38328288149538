import request from '@/utils/request.js';

const url = "/manage/wework/qrcode";


/**
 * 全部查询
 */
export function listWeworkQrcode(params) {
  return request({
    url: `${url}/list`,
    method: 'get',
    params: params
  })
}

/**
 * 分页查询
 */
export function pageWeworkQrcode(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

/**
 * 获取详情
 */
export function infoWeworkQrcode(id) {
  return request({
    url: `${url}/${id}`,
    method: 'get'
  })
}

/**
 * 新增
 */
export function saveWeworkQrcode(params) {
  return request.post(`${url}/save`, JSON.stringify(params));
}

/**
 * 删除
 */
export function deleteWeworkQrcode(params) {
  return request.delete(`${url}`, JSON.stringify(params));
}