import request from '@/utils/request.js';

const url = "/manage/system/sms";

/**
 * 获取签名列表
 * @param query
 * @returns {AxiosPromise}
 */
export function getSmsSignList(query) {
    return request({
        url: `${url}/getSmsSignList`,
        method: 'get',
        params: query
    })
}

/**
 * 获取短信模版列表
 * @param query
 * @returns {*}
 */
export function getSmsTemplateList(query) {
    return request({
        url: `${url}/getSmsTemplateList`,
        method: 'get',
        params: query
    })
}

export function listSms(query) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: query
    })
}

/**
 * 发消息
 * @param query
 * @returns {AxiosPromise}
 */
export function sendSms(query) {
    return request({
        url: `${url}/SendSms`,
        method: 'get',
        params: query
    })
}

/**
 * 短信模板功能
 */

/**
 * 添加短信模板
 */
export function applySmsTemplate(data) {
    return request({
        url: `${url}/applySmsTemplate`,
        method: 'POST',
        data: data
    })
}
/**
 * 修改短信模板内容
 */
export function editSmsTemplate(data) {
    return request({
        url: `${url}/editSmsTemplate`,
        method: 'POST',
        data: data
    })
}

/**
 * 删除短信模板 templateCode 必须传递
 */
export function deleteSmsTemplate(data) {
    return request({
        url: `${url}/deleteSmsTemplate`,
        method: 'POST',
        data: data
    })
}

/**
 * 批量发送短信 sendBatchSms
 */

export function sendBatchSms(data) {
    return request({
        url: `${url}/sendBatchSms`,
        method: 'POST',
        data: data
    })
}

export function batchSendSms(data) {
    return request({
        url: `${url}/send-sms`,
        method: 'POST',
        data: data
    })
}

/**
 * 申请签名
 */
export function applySmsSign(data) {
    return request({
        url: `${url}/applySmsSign`,
        method: 'POST',
        data: data
    })
}



