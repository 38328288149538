import request from '@/utils/request.js';

const url = "/manage/msg/myMessageManage/";

//获取列表数据
export function listMessage(query) {
    return request({
        url: `${url}/myMessageListJson`,
        method: 'get',
        params: query
    })
}

//通过ID获取
export function getMessage(id) {
    return request.get(`${url}/getMessage/${id}`);
}

//标记已读
export function markedRead(id) {
    return request.get(`${url}/markedRead/${id}`);
}