<template>
    <div>
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="refundNo" label="退款单号" width="180"/>
            <el-table-column prop="commitTime" label="提交时间" width="180"/>
            <el-table-column prop="submitId" label="提交人">
                <template v-slot="scope">
                    <el-tag effect="dark" v-for="item in adviserList" :key="item.id"
                            v-if="scope.row.submitId === item.id">
                        {{ item.nickName }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="refundPrice" label="退款金额">
                <template v-slot="scope">
                    {{ (scope.row.refundPrice / 100).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column prop="secondSuggestLastTime" label="退款时间"/>
        </el-table>
    </div>
</template>
<script>
import * as refundApi from "@/api/crm/refundRepair"

export default {
    name: "RefundSyncData",
    props: {
        orderNumber: {
            type: String,
            required: true,
        },
        adviserList: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            tableData: []
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            refundApi.queryRefundLog(this.orderNumber).then(res => {
                if (res.success) {
                    this.tableData = res.data
                }
            })
        }
    }
}
</script>
<style scoped>

</style>