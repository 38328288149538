<template>
  <div>
    <el-tabs @tab-click="tabChange" type="border-card">
      <el-tab-pane label="页面配置">
        <el-tooltip class="item-tabs" effect="dark" placement="bottom" slot="label">
          <div slot="content">
            <span>学习顾问页面内容个性化展示及排序</span>
          </div>
          <span>页面配置</span>
        </el-tooltip>
        <div id="search-head">
          <div class="search-tab">
            <el-collapse v-model="activeNames" @change="changeCollapse" class="tab-title" accordion>
              <el-collapse-item title="操作内容配置" name="CONSULTANT_OPERATE">
                  <ConsultantOption v-if="activeNames === 'CONSULTANT_OPERATE'"></ConsultantOption>
              </el-collapse-item>
              <el-collapse-item title="查询项配置" name="CONSULTANT_SEARCH">
                  <ConsultantSearch v-if="activeNames === 'CONSULTANT_SEARCH'"></ConsultantSearch>
              </el-collapse-item>
              <el-collapse-item title="学习顾问列表" name="CONSULTANT_TABLES">
                  <ConsultantTables v-if="activeNames === 'CONSULTANT_TABLES'"></ConsultantTables>
              </el-collapse-item>
              <el-collapse-item title="补录订单列表" name="REPAIR_INFO">
                  <RepairInfo v-if="activeNames === 'REPAIR_INFO'"></RepairInfo>
              </el-collapse-item>
              <el-collapse-item title="名片首咨配置" name="FIRST_TABLES">
                  <FirstTables v-if="activeNames === 'FIRST_TABLES'"></FirstTables>
              </el-collapse-item>
              <el-collapse-item title="名片公海配置" name="SEA_TABLES">
                  <SeaTables v-if="activeNames === 'SEA_TABLES'"></SeaTables>
              </el-collapse-item>
              <el-collapse-item title="机会管理配置" name="MANAGE_TABLES">
                  <ManageTables v-if="activeNames === 'MANAGE_TABLES'"></ManageTables>
              </el-collapse-item>
              <el-collapse-item title="机会详情配置" name="DETAIL_INFO">
                  <DetailInfo v-if="activeNames === 'DETAIL_INFO'"></DetailInfo>
              </el-collapse-item>
              <el-collapse-item title="额外信息配置" name="NEW_INFORMATION">
                  <NewInformation v-if="activeNames === 'NEW_INFORMATION'"></NewInformation>
              </el-collapse-item>
              <el-collapse-item title="订单列表配置" name="ORDER_TABLES">
                  <OrderTables v-if="activeNames === 'ORDER_TABLES'"></OrderTables>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane @tab-click="tabChange" label="机构配置">
        <el-row :gutter="20">
          <el-col :span="3">
            网校名称:
          </el-col>
          <el-col :span="4">
            {{ BASE_INFO.netName }}
          </el-col>
          <el-col :span="8">
            <el-button size="mini" type="primary" @click="setName" icon="el-icon-edit" circle></el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            crm标语:
          </el-col>
          <el-col :span="4">
            {{ BASE_INFO.title }}
          </el-col>
          <el-col :span="8">
            <el-button size="mini" type="primary" @click="setTitle" icon="el-icon-edit" circle></el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            小程序网址:
          </el-col>
          <el-col :span="4">
            {{ APPLET_HTTPS_URL.url }}
          </el-col>
          <el-col :span="8">
            <el-button size="mini" type="primary" @click="setAppletUrl" icon="el-icon-edit"
                       circle></el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            二维码:
          </el-col>
          <el-col :span="4">
            <el-switch
                v-model="qrcodes"
                active-text="ON"
                active-value="ON"
                inactive-text="OFF"
                inactive-value="OFF"
                @change="changeOrderQrcode">
            </el-switch>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            百度统计:
          </el-col>
          <el-col :span="8">
            {{ BAIDU_STATISTICS.params }}
          </el-col>
          <el-col :span="8">
            <el-button size="mini" type="primary" @click="setBaiduStatics" icon="el-icon-edit"
                       circle></el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="network-logo">
          <el-col :span="3"> 公司logo:</el-col>
          <el-col :span="8">
            <el-image style="width: 150px; height: 74px" :src="BASE_INFO.logoPath"></el-image>
            <span style="color: #d6d6d6;font-size: 12px">像素：150px * 74px</span>
          </el-col>
          <el-col :span="8">
            <!--                        <el-button size="mini" type="primary" class="upload" icon="el-icon-edit" circle></el-button>-->
            <el-upload
                class="network-logo-upload"
                action="/api/manage/file/uploadImg"
                list-type="picture"
                :headers="headers"
                :show-file-list="false"
                :on-success="networkSuccess">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle></el-button>
            </el-upload>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="network-small-logo">
          <el-col :span="3">
            网页小logo:
          </el-col>
          <el-col :span="8">
            <el-image style="width: 32px; height: 32px" :src="BASE_INFO.smallLogoPath"></el-image>
            <span style="color: #d7d7d7;font-size: 12px">像素：32px * 32px</span>
          </el-col>
          <el-col :span="8">
            <el-upload
                class="small-logo-upload"
                action="/api/manage/file/uploadImg"
                list-type="picture"
                :headers="headers"
                :show-file-list="false"
                :on-success="smallLogoSuccess">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle></el-button>
            </el-upload>
          </el-col>
        </el-row>

        <el-row :gutter="20" type="flex" justify="start">
          <el-col :span="3">
            首咨领取时间:
          </el-col>
          <el-col :span="12">
            <el-time-select
                placeholder="起始时间"
                eval
                v-model="CONSULTATION_TIME.startTime"
                :picker-options="{
                        start: '00:00',
                         step: '00:15',
                         end: '23:59'
                        }">
            </el-time-select>
            <el-time-select
                placeholder="结束时间"
                v-model="CONSULTATION_TIME.endTime"
                :picker-options="{
                         start: '00:00',
                         step: '00:15',
                         end: '23:59'
                         }">
            </el-time-select>
          </el-col>

          <el-col :span="8">
            <el-button size="mini" type="success" @click="setConsultationTime" icon="el-icon-check"
                       circle></el-button>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="3">
            磐石云外呼:
          </el-col>
          <el-col :span="4">
            <el-switch
                v-model="CALL_TYPE_PAN_SHI.qrcode"
                active-text="ON"
                active-value="ON"
                inactive-text="OFF"
                inactive-value="OFF"
                @change="changeCallPanshi">
            </el-switch>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="3">
            双呼方式:
          </el-col>
          <el-col :span="4">
            <el-switch
                v-model="CALL_TYPE_DOUBLE.qrcode"
                active-text="ON"
                active-value="ON"
                inactive-text="OFF"
                inactive-value="OFF"
                @change="changeCallDouble">
            </el-switch>
          </el-col>
        </el-row>

        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="3">-->
        <!--            手机加密:-->
        <!--          </el-col>-->
        <!--          <el-col :span="4">-->
        <!--            <el-switch-->
        <!--                v-model="TELEPHONE_ENCODE.encode"-->
        <!--                active-text="开启"-->
        <!--                active-value="ON"-->
        <!--                inactive-text="关闭"-->
        <!--                inactive-value="OFF"-->
        <!--                @change="changeTelEncode">-->
        <!--            </el-switch>-->
        <!--          </el-col>-->
        <!--        </el-row>-->

        <el-row :gutter="20" hidden>
          <el-col :span="3">
            成单:
          </el-col>
          <el-col :span="4">
            <el-switch
                v-model="ORDER_CONTROL.switch"
                active-text="ON"
                active-value="ON"
                inactive-text="OFF"
                inactive-value="OFF"
                @change="changeOrderControl">
            </el-switch>
          </el-col>
        </el-row>

        <el-row v-if="APPLET_HREF" :gutter="20">
          <el-col :span="3">
            试听课网址:
          </el-col>
          <el-col :span="8">
            {{ APPLET_HREF.url }}
          </el-col>
          <el-col :span="4">
            <el-button size="mini" type="primary" @click="setApplet" icon="el-icon-edit" circle></el-button>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="3">
            抖店云配置:
          </el-col>
          <el-col :span="4">
            <el-button size="mini" type="primary" @click="setDoudian" icon="el-icon-edit"
                       circle></el-button>
          </el-col>
          <!--  配置抖店信息对接  -->
          <el-dialog :visible.sync="doudianSync" title="配置抖店信息">
            <div>
              <el-form :model="doudianData">
                <el-form-item>


                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="3">
            验证码配置:
          </el-col>
          <el-col :span="4">
            <el-button size="mini" type="primary" @click="smsCodeSync = true" icon="el-icon-edit"
                       circle></el-button>
          </el-col>
          <!--  配置短信验证码发送  -->
          <el-dialog :visible.sync="smsCodeSync" title="配置短信验证码">
            <el-form :model="smsCodeData" label-width="100px">
              <el-form-item label="类型：" prop="type">
                <el-row>
                  <el-col :span="16">
                    <el-select v-model="smsCodeData.type" style="width: 100%">
                      <el-option value="ALIYUN" label="阿里云"></el-option>
                      <el-option value="DOUDIANYUN" label="抖店云"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="用户ID：" prop="accessId">
                <el-row>
                  <el-col :span="16">
                    <el-input v-model="smsCodeData.accessId" type="text"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="用户秘钥：" prop="accessId">
                <el-row>
                  <el-col :span="16">
                    <el-input v-model="smsCodeData.accessId" type="text"></el-input>
                  </el-col>
                  <el-col :span="2">
                    <el-tooltip class="item" effect="dark" content="Right Center 提示文字" placement="right">
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="短信签名：" prop="sign">
                <el-row>
                  <el-col :span="16">
                    <el-input v-model="smsCodeData.sign" type="text"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="短信模板：" prop="tempCode">
                <el-row>
                  <el-col :span="16">
                    <el-input v-model="smsCodeData.tempCode" type="text"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="模板参数：" prop="tempAttr">
                <el-row>
                  <el-col :span="16">
                    <el-input v-model="smsCodeData.tempAttr" type="text"></el-input>
                  </el-col>
                </el-row>
              </el-form-item>

            </el-form>
          </el-dialog>
        </el-row>



        <el-row :gutter="20">
          <el-col :span="3">
            补录订单添加企业微信:
          </el-col>
          <el-col :span="4">
            <el-switch
                v-model="REPAIR_ADD_WECHAT.qrcode"
                active-text="ON"
                active-value="ON"
                inactive-text="OFF"
                inactive-value="OFF"
                @change="changeRepairAddCode">
            </el-switch>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="3">
            补录订单审核通知:
          </el-col>
          <el-col :span="4">
              一审/主管审核通知
            <el-switch
                v-model="ORDER_REPAIR_EXAMINE_MESSAGE.first_examine"
                active-text="ON"
                active-value="ON"
                inactive-text="OFF"
                inactive-value="OFF"
                @change="changeOrderRepairMessageSwitch($event, 'first_examine')">
            </el-switch>
          </el-col>
          <el-col :span="4">
              二审/财务审核通知
              <el-switch
                      v-model="ORDER_REPAIR_EXAMINE_MESSAGE.second_examine"
                      active-text="ON"
                      active-value="ON"
                      inactive-text="OFF"
                      inactive-value="OFF"
                      @change="changeOrderRepairMessageSwitch($event, 'second_examine')">
              </el-switch>
          </el-col>
          <el-col :span="4">
              <el-button type="primary" @click="checkRepairOtherUserDialog = true">配置额外人员通知</el-button>
          </el-col>
        </el-row>

          <el-row :gutter="20">
              <el-col :span="3">
                  短信配置:
              </el-col>
              <el-col :span="1">
                  <el-button type="primary" @click="openSmsDialogVisible()">添加短信配置</el-button>
              </el-col>
          </el-row>

        <el-row :gutter="20">
            <el-col :span="3">
                重复录入数据标签:
            </el-col>
            <el-col :span="5">
                <el-form v-model="tags">
                      <el-form-item prop="id">
                          <el-select v-model="tags.id" class="condition-select" clearable i filterable placeholder="请选择" @change="changeTags">
                              <el-option
                                      v-for="item in tags"
                                      :key="item.id"
                                      :label="item.name"
                                      :value="item.id">
                              </el-option>
                          </el-select>
                      </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="4">
                <el-button style="margin-left: 10px;" size="small" type="success" @click="saveTags">保存标签</el-button>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="3">
                喜报参数设置:
            </el-col>
            <el-col :span="4">
                <el-button style="margin-left: 10px;" size="small" type="success" plain @click="goodsNewParamsDialog = true">设置喜报参数</el-button>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="3">
                登录验证码开关:
            </el-col>
            <el-col :span="4">
                <el-switch v-model="ENABLE_LOGIN_VERIFICATION_CODE.value"
                           :active-value="true" :inactive-value="false"
                           active-text="ON"
                           inactive-text="OFF"
                           @change="saveEnableLoginVerificationCode"/>
            </el-col>
            <el-col :span="13">
                <el-link icon="el-icon-warning" type="danger">开启后, 用户登录必须通过手机号 + 验证码的方式登录系统</el-link>
            </el-col>
        </el-row>


      </el-tab-pane>
    </el-tabs>

    <!-- 修改选择补录额外通知人员 start -->
    <el-dialog
      title="修改选择补录额外通知人员"
      :visible.sync="checkRepairOtherUserDialog"
      width="30%"
      center>
        <el-select v-model="checkedAccountList"
         filterable
         multiple
         placeholder="请选择">
          <el-option
            v-for="item in accountList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id">
          </el-option>
        </el-select>
  <span slot="footer" class="dialog-footer">
  <el-button @click="checkRepairOtherUserDialog = false">取 消</el-button>
  <el-button type="primary" @click="changeOrderRepairMessageSwitch(null, 'other_user')">确 定</el-button>
</span>
    </el-dialog>
    <el-dialog
            title="短信配置"
            :visible.sync="smsDialogVisible"
            width="30%"
            :before-close="handleClose">
            <el-form  label-width="180px" :model="SMS_CONFIG_KEY_SECRET" ref="smsForm" >
                <el-form-item label="短信配置键" prop="accessKey" >
                    <el-input v-model="SMS_CONFIG_KEY_SECRET.accessKey"></el-input>
                </el-form-item>
                <el-form-item label="短信配置秘钥" prop="accessSecret">
                    <el-input v-model="SMS_CONFIG_KEY_SECRET.accessSecret"></el-input>
                </el-form-item>
                <el-form-item label="签名" prop="signName">
                    <el-input v-model="SMS_CONFIG_KEY_SECRET.signName"></el-input>
                </el-form-item>
                <el-form-item label="短信验证码模板CODE" prop="SMS_CODE">
                    <el-input v-model="SMS_CONFIG_KEY_SECRET.SMS_CODE"></el-input>
                </el-form-item>
                <el-form-item label="总管理员通知CODE" prop="ADMIN_SMS_NOTIFY">
                    <el-input v-model="SMS_CONFIG_KEY_SECRET.ADMIN_SMS_NOTIFY"></el-input>
                </el-form-item>
            </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="smsDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmSmsDialogVisible">确 定</el-button>
    </span>
    </el-dialog>

    <el-dialog title="喜报通知参数" :visible.sync="goodsNewParamsDialog" width="30%" center>
        <el-form v-model="goodsNewParamsForm">
            <el-form-item prop="priceStart" label="通知成交最低价">
                <el-input-number :min="0" v-model="goodsNewParamsForm.priceStart"/>
            </el-form-item>
        </el-form><div slot="footer" class="dialog-footer">
        <el-button @click="goodsNewParamsDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitGoodsNewForm">确 定</el-button>
    </div>

    </el-dialog>

  </div>
</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable'
import Sortable from "sortablejs"
import * as searchApi from "@/api/system/searchDeploy";
import * as configApi from "@/api/system/config";
import {list as accountListApi} from "@/api/system/account"
import * as tagsApi from "@/api/crm/tags";
import ConsultantOption from "@/views/system/configuration/detail/ConsultantOption"
import ConsultantSearch from "@/views/system/configuration/detail/ConsultantSearch"
import ConsultantTables from "@/views/system/configuration/detail/ConsultantTables"
import DetailInfo from "@/views/system/configuration/detail/DetailInfo"
import FirstTables from "@/views/system/configuration/detail/FirstTables"
import ManageTables from "@/views/system/configuration/detail/ManageTables"
import NewInformation from "@/views/system/configuration/detail/NewInformation"
import OrderTables from "@/views/system/configuration/detail/OrderTables"
import RepairInfo from "@/views/system/configuration/detail/RepairInfo"
import SeaTables from "@/views/system/configuration/detail/SeaTables"

export default {
  //注册draggable组件
  components: {
    draggable,
      ConsultantOption,
      ConsultantSearch,
      ConsultantTables,
      DetailInfo,
      FirstTables,
      ManageTables,
      NewInformation,
      OrderTables,
      RepairInfo,
      SeaTables
  },
  data() {
    return {
      headers:{"version": localStorage.getItem("_version"),},
      inputTagVisible: {},
      activeNames: "CONSULTANT_OPERATE",
      drag: false,
      //定义要被拖拽对象的数组
      contents: [
        {label: "领首咨", value: "0", status: true}
        , {label: "领库存", value: "1", status: true}
        , {label: "问题反馈", value: "2", status: true}
        , {label: "机会校验", value: "3", status: true}
        , {label: "批量回库", value: "4", status: true}
        , {label: "添加机会", value: "5", status: true}
      ],
      content: [],
      search: [
        {label: "手机号", value: "0", sort: "1", status: true}
        , {label: "机会类型", value: "2", sort: "2", status: true}
        , {label: "最后沟通时间", value: "3", sort: "3", status: true}
        , {label: "意向度", value: "4", sort: "4", status: true}
        , {label: "微信添加状态", value: "5", sort: "5", status: true}
        , {label: "领取方式", value: "6", sort: "6", status: true}
        , {label: "成单状态", value: "7", sort: "7", status: true}
        , {label: "回库时间", value: "8", sort: "8", status: true}
        , {label: "项目", value: "9", sort: "9", status: true}
        , {label: "年龄", value: "10", sort: "10", status: true}
        , {label: "来源", value: "11", sort: "11", status: true}
        , {label: "标签", value: "12", sort: "12", status: true}
        , {label: "地域", value: "13", sort: "13", status: true}
        , {label: "备注", value: "14", sort: "14", status: true}
        , {label: "创建时间", value: "15", sort: "15", status: true}
        , {label: "首次拨打时间", value: "16", sort: "16", status: true}
        , {label: "领取时间", value: "17", sort: "17", status: true}
      ],
      shows: [
        {label: "手机号", value: "0", sort: "1", status: true}
        , {label: "类型", value: "2", sort: "2", status: true}
        , {label: "意向度", value: "3", sort: "3", status: true}
        , {label: "微信", value: "4", sort: "4", status: true}
        , {label: "项目", value: "5", sort: "5", status: true}
        , {label: "最后沟通时间", value: "6", sort: "6", status: true}
        , {label: "最后拨打时间", value: "7", sort: "7", status: true}
        , {label: "回库时间", value: "8", sort: "8", status: true}
        , {label: "领取时间", value: "9", sort: "9", status: true}
        , {label: "当日拨打状态", value: "10", sort: "10", status: true}
        , {label: "当日沟通时长", value: "11", sort: "11", status: true}
        , {label: "当日拨打次数", value: "12", sort: "12", status: true}
        , {label: "30天内拨打次数", value: "13", sort: "13", status: true}
        , {label: "领取方式", value: "14", sort: "14", status: true}
        , {label: "备注", value: "15", sort: "15", status: true}
        , {label: "年龄", value: "16", sort: "16", status: true}
        , {label: "姓名", value: "17", sort: "17", status: true}
        , {label: "地域", value: "18", sort: "18", status: true}
        , {label: "来源", value: "19", sort: "19", status: true}
        , {label: "支付状态", value: "20", sort: "20", status: true}
        , {label: "今日预约", value: "21", sort: "21", status: true}
      ],

      firsts: [],
      seas: [],
      manages: [],
      qrcodes: "",
      CALL_TYPE_PAN_SHI: {},
      REPAIR_ADD_WECHAT: {},
      BAIDU_STATISTICS: {},
      CALL_TYPE_DOUBLE: {},
      TELEPHONE_ENCODE: {},
      BASE_INFO: {},
      CONSULTATION_TIME: {},
      ORDER_CONTROL: {},
      ORDER: {},
      ORDER_REPAIR_EXAMINE_MESSAGE: {
          first_examine: "OFF",
          second_examine: "OFF",
          other_user: '',
          configType: "ORDER_REPAIR_EXAMINE_MESSAGE"
      },
      SMS_CONFIG_KEY_SECRET: {
          accessKey: "",
          accessSecret: "",
          signName: "",
          SMS_CODE: "",
          ADMIN_SMS_NOTIFY: "",
          configType: "SMS_CONFIG_KEY_SECRET"
      },
      DUPLICATE_TAGS: {
          tag: "",
          configType: "DUPLICATE_TAGS"
      },
        ENABLE_LOGIN_VERIFICATION_CODE: {
            configType: "ENABLE_LOGIN_VERIFICATION_CODE",
            value: false
        },
      tags: [
          {
              id: 0,
              createTime: null,
              updateTime: null,
              name: null,
              remarks: null,
              status: 0
          }
      ],
      detailsForm: {},
      detailInfo: [],
      studentInfo: [],
      repareFeildArr:["phone","goodsIds","payPrice","payTime","payChannel","orderNo","orderImgs","payType","adviserId"],
      communicationProgressTags: [],
      communicationProgressTagObj: {},
      inputVisibleOne: false, //标签1
      inputValueOne: '', //标签1
      inputVisibleTwo: false, //标签2
      inputValueTwo: '', //标签2
      tagOne: '',
      tagTwo: '',
      tabListObj: {}, //标签内容
      tabObj: {}, //更新时传递的标签对象
      APPLET_HREF: {},
      APPLET_HTTPS_URL: {},
      tableData: [],
      oldIndex: null,
      newIndex: null,
      col: [
        {label: "名称", prop: "label", width: ""},
        {label: "查询", prop: "queryDisplay", width: "50"},
        {label: "列表", prop: "listDisplay", width: "50"},
        {label: "详情", prop: "detailDisplay", width: "50"},
        // {label: "效果", prop: "displayMode", width: ""},
        {label: "类型", prop: "displayType", width: ""},
        {label: "必填", prop: "need", width: ""},
        {label: "状态", prop: "status", width: ""},
        {label: "提示信息", prop: "tips", width: ""},
        {label: "操作", prop: "sort", width: "120"}
      ],
      //展示类型
      displayTypeList: [
        {label: "文本", value: "input"},
        {label: "日期", value: "date", tips: "数据格式:2023-12-30"},
        {label: "时间", value: "time", tips: "数据格式:2023-12-30 18:30:00"},
        {label: "单选", value: "radio"},
        {label: "多选", value: "checkbox"},
        {label: "字典类型", value: "DICT"},
      ],
      multipleSelection: [],
      dictTypeRequired: false,
      dictionaryTypeList: [],
      doudianSync: false,
      doudianData: {},
      smsCodeData: {},
      smsCodeSync: false,
      orderTables: [],
      orderConfigSync: false,
      tagsList: [],
      activeName: "CONSULTANT_OPERATE",
      tabs: "0",
      accountList: [],
      checkedAccountList: [],
      checkRepairOtherUserDialog: false,
      smsDialogVisible:false,
      smsForm:{},
      smsFormRules:{
          accessKey: [
              { required: true, message: '请输入key', trigger: 'blur' },
          ],
          accessSecret: [
              { required: true, message: '请输入secret', trigger: 'blur' }
          ],
      },
      goodsNewParamsDialog: false,
      goodsNewParamsForm: {
          priceStart: 0
      },
      GOODS_NEW_PARAMS_FORM: {
          priceStart: 0
      },
    };
  },

  created() {
    this.initData()
  },
  mounted() {
    //字典加载
    this.getTags()
  },
  methods: {
      submitGoodsNewForm() {
          this.GOODS_NEW_PARAMS_FORM.priceStart = this.goodsNewParamsForm.priceStart * 100;
          this.GOODS_NEW_PARAMS_FORM.configType = 'GOODS_NEW_PARAMS_FORM';
          configApi.saveConfig(this.GOODS_NEW_PARAMS_FORM).then(res => {
              if (res.success) {
                  this.msgSuccess(res.msg);
              }
          })
          this.goodsNewParamsDialog = false;
      },
      changeTags(val) {
          this.$forceUpdate()
      },
    /**
     * 获取标签
     */
    getTags: function () {
        tagsApi.options().then(res=>{
            this.tags = res.data
        })
    },
    saveTags: function (){
        this.DUPLICATE_TAGS.tag = this.tags.id;
        this.DUPLICATE_TAGS.configType = "DUPLICATE_TAGS";
        configApi.saveConfig(this.DUPLICATE_TAGS).then(resp => {
            if (resp.success) {
                this.$message.success(resp.msg);
            }else {
                this.$message.error(resp.msg);
            }
        })
    },
    initData() {
      if (this.tabs === "1") {
        this.findConfig();
        accountListApi().then(resp => {
            this.accountList = resp.data
        })
      }
    },
    tabChange(tabs, a, b, c) {
      this.tabs = tabs.index
      this.initData()
    },
    async changeCollapse(activeNames) {
      this.activeName = activeNames
      this.initSortable(activeNames)
    },
    changeType(row) {
      if (row === 'radio' || row === 'checkbox') {
        this.dictTypeRequired = true
      } else {
        this.dictTypeRequired = false
      }
    },
    setDoudian() {
      this.doudianSync = true
    },
    setApplet() {
      this.$prompt('请设置试听课地址', "设置", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({value}) => {
        if (value.indexOf("http") >= 0) {
          if (value.lastIndexOf("/") > 0) {
            value += "/"
          }
          configApi.saveConfig({"url": value, "configType": "APPLET_COURSE_HREF"}).then(res => {
            if (res.success) {
              this.msgSuccess("修改成功");
              this.findConfig();
            }
          });
        } else {
          this.$message.error("请提供完整网址!");
        }
      });
    },
    ischeck(o) {
      o.status = o.status ? false : true;
    },
    onMove(e) {
      if (e.relatedContext.element.name == "telephone") {
        return false;
      }
      return true
    },
    //获取配置列表
    findConfig() {
      configApi.findConfig().then(res => {
        if (res.success) {
          this.CALL_TYPE_PAN_SHI = res.data.CALL_TYPE_PAN_SHI ? res.data.CALL_TYPE_PAN_SHI : {};
          this.REPAIR_ADD_WECHAT = res.data.REPAIR_ADD_WECHAT ? res.data.REPAIR_ADD_WECHAT : {};
          this.BAIDU_STATISTICS = res.data.BAIDU_STATISTICS ? res.data.BAIDU_STATISTICS : {};
          this.CALL_TYPE_DOUBLE = res.data.CALL_TYPE_DOUBLE ? res.data.CALL_TYPE_DOUBLE : {};
          this.BASE_INFO = res.data.BASE_INFO ? res.data.BASE_INFO : {};
          this.CONSULTATION_TIME = res.data.CONSULTATION_TIME ? res.data.CONSULTATION_TIME : {};
          this.ORDER_CONTROL = res.data.ORDER_CONTROL ? res.data.ORDER_CONTROL : {};
          this.ORDER = res.data.ORDER ? res.data.ORDER : {};
          this.APPLET_HREF = res.data.APPLET_COURSE_HREF;
          this.APPLET_HTTPS_URL = res.data.APPLET_HTTPS_URL ? res.data.APPLET_HTTPS_URL : {};
          this.TELEPHONE_ENCODE = res.data.TELEPHONE_ENCODE ? res.data.TELEPHONE_ENCODE : {};
          this.ORDER_REPAIR_EXAMINE_MESSAGE = res.data.ORDER_REPAIR_EXAMINE_MESSAGE ? res.data.ORDER_REPAIR_EXAMINE_MESSAGE : {};
          this.DUPLICATE_TAGS = res.data.DUPLICATE_TAGS ? res.data.DUPLICATE_TAGS : {};
          this.tags.id = (this.DUPLICATE_TAGS.tag && Number(this.DUPLICATE_TAGS.tag)) || null;
          if (this.ORDER_REPAIR_EXAMINE_MESSAGE.other_user) {
            this.checkedAccountList = this.ORDER_REPAIR_EXAMINE_MESSAGE.other_user.split(',').map(Number)
          }
          this.SMS_CONFIG_KEY_SECRET = res.data.SMS_CONFIG_KEY_SECRET ? res.data.SMS_CONFIG_KEY_SECRET : {};
          if (res.data.GOODS_NEW_PARAMS_FORM) {
              this.goodsNewParamsForm = res.data.GOODS_NEW_PARAMS_FORM ? res.data.GOODS_NEW_PARAMS_FORM : {};
              this.goodsNewParamsForm.priceStart = this.goodsNewParamsForm.priceStart / 100
          }
          this.ENABLE_LOGIN_VERIFICATION_CODE = res.data.ENABLE_LOGIN_VERIFICATION_CODE.value === 'true' ? {configType: "ENABLE_LOGIN_VERIFICATION_CODE", value: true} : {configType: "ENABLE_LOGIN_VERIFICATION_CODE", value: false}
        }
      });
    },
    changeOrderRepairMessageSwitch(val, type) {
      // console.log(val, type);
      switch (type) {
        case 'other_user':
          this.ORDER_REPAIR_EXAMINE_MESSAGE[type] = this.checkedAccountList.join(',');
          break;
        case 'first_examine':
        case 'second_examine':
          this.ORDER_REPAIR_EXAMINE_MESSAGE[type] = val;
          break;
      }
      this.ORDER_REPAIR_EXAMINE_MESSAGE["configType"] = "ORDER_REPAIR_EXAMINE_MESSAGE";
      configApi.saveConfig(this.ORDER_REPAIR_EXAMINE_MESSAGE).then(resp => {
          if (resp.success) {
              this.$message.success(resp.msg);
              this.checkRepairOtherUserDialog = false;
          }else {
              this.$message.error(resp.msg);
          }
      })
    },
    changeOrderQrcode: (val) => {
      configApi.saveConfig({"qrcode": val, "configType": "ORDER"});
    },
    setConsultationTime: () => {
      if (this.CONSULTATION_TIME || this.CONSULTATION_TIME.startTime == null || this.CONSULTATION_TIME.endTime == null) {
        this.$message.warning("请设置首咨领取时间")
      } else {
        configApi.saveConfig({
          "startTime": this.CONSULTATION_TIME.startTime,
          "endTime": this.CONSULTATION_TIME.endTime,
          "configType": "CONSULTATION_TIME"
        });
      }
    },
    changeCallPanshi: function (val) {
      configApi.saveConfig({"qrcode": val, "configType": "CALL_TYPE_PAN_SHI"});
    },
    changeRepairAddCode: function (val) {
      configApi.saveConfig({"qrcode": val, "configType": "REPAIR_ADD_WECHAT"});
    },
    changeOrderControl(val) {
      configApi.saveConfig({"switch": val, "configType": "ORDER_CONTROL"});
    },
    changeCallDouble(val) {
      configApi.saveConfig({"qrcode": val, "configType": "CALL_TYPE_DOUBLE"});
    },
    changeTelEncode(val) {
      configApi.saveConfig({"encode": val, "configType": "TELEPHONE_ENCODE"});
    },
    networkSuccess(res) {
      if (res.success) {
        if (res.success) {
          var params = {"configType": "BASE_INFO", 'logoPath': res.msg};
          configApi.saveConfig(params).then(res => {
            if (res.success) {
              this.msgSuccess("修改成功");
              this.findConfig();
            }
          });
        }
      }
    },
    smallLogoSuccess(res) {
      if (res.success) {
        if (res.success) {
          var params = {"configType": "BASE_INFO", 'smallLogoPath': res.msg};
          configApi.saveConfig(params).then(res => {
            if (res.success) {
              this.msgSuccess("修改成功");
              this.findConfig();
            }
          });
        }
      }
    },
    setName() {
      this.$prompt('请设置网校名称', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({value}) => {
        configApi.saveConfig({"netName": value, "configType": "BASE_INFO"}).then(res => {
          if (res.success) {
            this.msgSuccess("修改成功");
            this.findConfig();
          }
        });
      });
    },
    setAppletUrl() {
      this.$prompt('请设置小程序网址,小程序设置规则为:https://网址/applet', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({value}) => {
        configApi.saveConfig({"url": value, "configType": "APPLET_HTTPS_URL"}).then(res => {
          if (res.success) {
            this.msgSuccess("修改成功");
            this.findConfig();
          }
        });
      });
    },
    setTitle() {
      this.$prompt('请设置网校标语', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({value}) => {
        configApi.saveConfig({"title": value, "configType": "BASE_INFO"}).then(res => {
          if (res.success) {
            this.msgSuccess("修改成功");
            this.findConfig();
          }
        });
      });
    },
    setBaiduStatics() {
      this.$prompt('请设置百度统计参数', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({value}) => {
        configApi.saveConfig({"params": value, "configType": "BAIDU_STATISTICS"}).then(res => {
          if (res.success) {
            this.msgSuccess("修改成功");
            this.findConfig();
          }
        });
      });
    },
    handleClose(tag) {
      this.communicationProgressTags.splice(this.communicationProgressTags.indexOf(tag), 1);
    },
    handleCloseCustomer(tag, list) {
      // this.tabListObj[index].splice(this.tabListObj[index].indexOf(tag), 1);
      console.log(tag, list)
      list.splice(list.indexOf(tag), 1)
      this.$forceUpdate()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //将时间戳转为唯一字符串
    intToStr(n) {
      let eng_list = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      let str = "" + n
      let res = ''
      for (let s of str) {
        res += eng_list[s]
      }
      return res;
    },
    initSortable(activeName) {
      // 1. 获取拖拽元素的父元素
      // 因为使用的element的table 所以可直接获取tbody
      if (this.$refs[activeName]) {
        // console.log(this.$refs[activeName])

        let el = this.$refs[activeName].$el.querySelector(".el-table__body-wrapper tbody");
        let _this = this
        // 2. 创建并配置相关参数
        let sortable = new Sortable(el, {
          // 此处进行配置 及 回调函数的使用

          // 因为我的需求是只有按住手柄才能进行拖拽，故而设置此参数
          handle: '.handle_move', // css选择器的字符串 若设置该值，则表示只有按住拖动手柄才能使列表单元进行拖动
          animation: 2000,
          // 我的需求是行拖拽，所以该值设置为行class
          draggable: '.el-table__row', // 允许拖拽的项目类名


          // 因为拖拽过程中不尽兴位置调整，所以就要记录拖拽行初始位置以及目标位置
          // 拖拽中 回调函数
          onMove(customEvent) {
            // console.log('onMove', customEvent)
            // 禁止在拖拽过程中交换位置
            // 可将初始位置及目标位置进行记录
            _this.oldIndex = customEvent.dragged.rowIndex; //  初始位置
            _this.newIndex = customEvent.related.rowIndex; // 目标位置
            return false;  // 不进行交换
            // 返回值有三个
            // return false; — for cancel
            // return -1; — insert before target
            // return 1; — insert after target
          },

          // 拖拽结束，调整位置
          onEnd() {
            // 在拖拽结束后，获取初始及目标位置
            const {newIndex, oldIndex} = _this;
            const currRow = _this.tableData.splice(oldIndex, 1)[0];
            _this.tableData.splice(newIndex, 0, currRow);
            _this.newIndex = null;
            _this.oldIndex = null;
            for (let i in _this.tableData) {
              _this.tableData[i].sort = i
            }
          },
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 目标位置高亮
    // 给目标行 添加高亮样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === this.newIndex) {
        return "warning-row";
      }
      return "";
    },
    openSmsDialogVisible(){
        this.smsDialogVisible = true;
    },
    closeSmsDialogVisible(formName){
        this.smsForm = {}
        this.smsDialogVisible = false;
        this.$refs[formName].resetFields();
    },
    confirmSmsDialogVisible(){
        this.SMS_CONFIG_KEY_SECRET.configType = 'SMS_CONFIG_KEY_SECRET'
        configApi.saveConfig(this.SMS_CONFIG_KEY_SECRET).then(resp => {
          if (resp.success) {
              this.$message.success(resp.msg);
              this.smsDialogVisible = false;
          }else {
              this.$message.error(resp.msg);
          }
      })
      },
      saveEnableLoginVerificationCode() {
        this.ENABLE_LOGIN_VERIFICATION_CODE.configType = 'ENABLE_LOGIN_VERIFICATION_CODE';
        configApi.saveConfig(this.ENABLE_LOGIN_VERIFICATION_CODE).then(resp => {
            if (resp.success) {
                this.$message.success(resp.msg);
            }else {
                this.$message.error(resp.msg);
            }
        })
      }
    }
};
</script>

<style scoped>
#search-body {
  padding: 24px;
}

.mini-title {
  font-size: 12px;
  color: #909399;
}

.tab-title >>> .el-collapse-item__header {
  font-size: 20px;
}

.content {
  font-size: 16px;
}

#search-head {
  background-color: white;
  padding: 40px;
}

.disable {
  background-color: #d6d6d6;
  color: #909399;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.handle_move {
  cursor: pointer;
}

/deep/ .warning-row {
  background: oldlace;
}

/deep/ .el-form-item {
  /*margin-bottom: 12 px;*/
}

/deep/ .el-icon-s-fold, .el-icon-delete, .el-icon-edit {
  cursor: pointer;
}
</style>
