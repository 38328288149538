<template>
    <div>
        <el-tabs type="border-card">
            <el-tab-pane label="商品管理">
                <AppletsCourse></AppletsCourse>
            </el-tab-pane>
            <el-tab-pane label="教师管理">
                <AppletsTeacher></AppletsTeacher>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import AppletsCourse from "./appletsCourse";
    import AppletsTeacher from "./appletsTeacher";

    export default {
        name: "applets",
        components: {AppletsCourse, AppletsTeacher}
    }
</script>

<style scoped>

</style>