<template>
  <el-tabs type="border-card">
    <el-tab-pane label="海报素材">
      <template>
        <el-link :underline="false" @click="materialListAll"  >
          <span class="el-link-pad" :class="{'clicked-color':selectedIndex === -1 }">全部</span>
        </el-link>
        <el-link :underline="false" v-for="(singType,index) in tableData" :key="singType.typeId"
                 :value="singType.typeName">
          <span class="el-link-pad"  v-if="singType.enableStatus === 1" @click="materialList(singType.typeId,index)"
                :class="{ 'clicked-color': selectedIndex === index }">{{ singType.typeName }}</span>
        </el-link>
        <br><br>
      </template>

      <!--素材列表-->
      <template>
        <el-image
            style="width: 200px; height: 240px"
            :src="materialBackground"
            @click="dialogFormVisibleMaterial=true"
            class="el-image-pad"></el-image>

        <div style="display: inline-flex;flex-wrap: wrap;">
          <div v-for="singImg in materialAllList" :key="singImg.imgPath" style="display: flex;position: relative;">
            <el-popover
                placement="top-start"
                width="200"
                trigger="hover">
              <el-image
                  style="width: 190px; height: 240px"
                  :src="singImg.imgPath"
                  slot="reference"
                  class="el-image-pad">
              </el-image>

              <template>
                <div style="display: flex;flex-direction: row;justify-content: center">
                  <el-button type="primary" size="mini" @click="openUpdateInfoApi(singImg.materialId)">修改</el-button>
                  <el-button size="mini" type="danger" @click="deleteMaterial(singImg.materialId)">删除</el-button>
                </div>
              </template>

            </el-popover>

            <!--     修改素材       -->
            <el-dialog title="修改海报素材" :visible.sync="dialogFormVisibleMaterialUpdate">
              <el-form :model="material" :rules="materialRules" >
                <el-form-item label="海报名称" prop="materialName" :label-width="formLabelWidthMaterial">
                  <el-input v-model="material.materialName" autocomplete="off" style="width: 500px"></el-input>
                </el-form-item>
                <el-form-item label="海报类型" prop="posterTypeId" :label-width="formLabelWidthMaterial">
                  <el-select v-model="material.posterTypeId" clearable placeholder="请选择">
                    <el-option
                        v-for="item in tableData"
                        :key="item.typeId"
                        :label="item.typeName"
                        :value="item.typeId"
                        v-if="item.enableStatus===1">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="海报范围" prop="posterRange" :label-width="formLabelWidthMaterial">
                  <el-radio v-model="material.posterRange" :label="1">海报专用</el-radio>
                  <el-radio v-model="material.posterRange" :label="2">活码专用</el-radio>
                </el-form-item>
                <el-form-item label="二维码位置" prop="posterPosition" :label-width="formLabelWidthMaterial">
                  <el-radio v-model="material.posterPosition" :label="1">中间(580*580)</el-radio>
                  <el-radio v-model="material.posterPosition" :label="2">右下方(256*256)</el-radio>
                </el-form-item>
                <el-form-item label="封面图片" prop="posterImgPath" :label-width="formLabelWidthMaterial">
                  <el-upload
                      class="avatar-uploader"
                      action="/api/manage/file/uploadImg"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload">
                    <img v-if="material.imgPath" :src="material.imgPath" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"/>
                  </el-upload>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleMaterialUpdate = false">取 消</el-button>
                <el-button type="primary" @click="toUpdateApi">确 定</el-button>
              </div>
            </el-dialog>




<!--            <el-image-->
<!--                style="width: 190px; height: 240px"-->
<!--                :src="singImg.imgPath"-->
<!--                class="el-image-pad"-->
<!--                @mouseenter="showButtonsMe" @mouseleave="hideButtonsMe">-->
<!--            </el-image>-->
<!--            <div v-if="showButtons" class="button-container">-->
<!--              <button @click="deleteImage" class="delete-btn">删除</button>-->
<!--              <button @click="editImage" class="edit-btn">修改</button>-->
<!--            </div>-->
          </div>
        </div>


      </template>

      <!--新增素材-->
      <el-dialog title="新增海报素材" :visible.sync="dialogFormVisibleMaterial">
        <el-form :model="material" :rules="materialRules" >
          <el-form-item label="海报名称" prop="materialName" :label-width="formLabelWidthMaterial">
            <el-input v-model="material.materialName" autocomplete="off" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item label="海报类型" prop="posterTypeId" :label-width="formLabelWidthMaterial">
            <el-select v-model="material.posterTypeId" clearable placeholder="请选择">
              <el-option
                  v-for="item in tableData"
                  :key="item.typeId"
                  :label="item.typeName"
                  :value="item.typeId"
                  v-if="item.enableStatus===1">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="海报范围" prop="posterRange" :label-width="formLabelWidthMaterial">
            <el-radio v-model="material.posterRange" label="1">海报专用</el-radio>
            <el-radio v-model="material.posterRange" label="2">活码专用</el-radio>
          </el-form-item>
          <el-form-item label="二维码位置" prop="posterPosition" :label-width="formLabelWidthMaterial">
            <el-radio v-model="material.posterPosition" label="1">中间(580*580)</el-radio>
            <el-radio v-model="material.posterPosition" label="2">右下方(256*256)</el-radio>
          </el-form-item>
          <el-form-item label="封面图片" prop="posterImgPath" :label-width="formLabelWidthMaterial">
            <el-upload
                class="avatar-uploader"
                action="/api/manage/file/uploadImg"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="material.imgPath" :src="material.imgPath" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"/>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleMaterial = false">取 消</el-button>
          <el-button type="primary" @click="submitMaterialData">确 定</el-button>
        </div>
      </el-dialog>

    </el-tab-pane>
    <el-tab-pane label="海报类型">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="formInline.posterType" placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="posterTypeList">查询</el-button>
          <el-button type="primary" @click="resetInfo">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="dialogFormVisibleType=true">新增海报类型</el-button>
      <br><br><br>
      <el-dialog title="新增类型" :visible.sync="dialogFormVisibleType">
        <el-form :model="form" :rules="rules">
          <el-form-item label="类型名称：" prop="typeName" :label-width="formLabelWidth">
            <el-input v-model="form.typeName" autocomplete="off" placeholder="输入海报类型名称" maxlength="15" show-word-limit label="50px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleType = false">取 消</el-button>
          <el-button type="primary" @click="saveSubmit">确 定</el-button>
        </div>
      </el-dialog>
      <!--列表 -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="typeName" label="类型名称"></el-table-column>
        <el-table-column prop="founderName" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="enableStatus" label="启用状态">
          <template v-slot="scope">
            <el-switch
                v-model="scope.row.enableStatus"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="updateStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="operate" label="操作">
          <template v-slot="scope">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="typeUpdate(scope.row.typeId)">修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="typeDelete(scope.row.typeId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog title="修改" :visible.sync="dialogFormVisibleUpdate">
        <el-form :model="updatePosterMsg" :rules="rules">
          <el-form-item label="类型名称：" prop="typeName" :label-width="formLabelWidth">
            <el-input v-model="updatePosterMsg.typeName" autocomplete="off" placeholder="输入海报类型名称" label="50px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleUpdate = false">取 消</el-button>
          <el-button type="primary" @click="subUpdateType">确 定</el-button>
        </div>
      </el-dialog>

    </el-tab-pane>
  </el-tabs>
</template>

<script>

import * as posterOperate from "@/api/sphere/poster";
import materialBackground from "@/assets/images/hcf7b760bdfeddcaddacef.png"

export default {
  /*mounted() {
    this.posterTypeList();
    this.materialListAll();
  },*/
  // mounted() {
  //   async function executeMethods() {
  //     await this.posterTypeList();
  //     await this.materialListAll();
  //   }
  //   executeMethods();
  // },
  mounted() {
    this.posterTypeList(() => {
      this.materialListAll();
    });
  },
  name: "poster",
  data() {
    return {
      dialogFormVisibleMaterialUpdate:false,
      showButtons:false,
      materialBackground:materialBackground,
      materialAllList:[],
      material:{
        imgPath:""
      },
      updatePosterMsg:{},
      formInline: {
        id:"",
        posterType: ""
      },
      isClicked:false,
      selectedIndex: -1 ,// 初始化为 -1，表示没有选中
      tableData: [],
      dialogFormVisibleType: false,
      dialogFormVisibleMaterial: false,
      dialogFormVisibleUpdate:false,
      form: {
        typeName: '',
      },
      index:0,
      headers: {"version": localStorage.getItem("_version"),},
      formLabelWidth: '100px',
      formLabelWidthMaterial: '100px',
      rules: {
        typeName: [
          {required: true, message: '请输入类型名称', trigger: 'blur'}
        ],
      },
      materialRules:{
        materialName:[
          {required: true, message:'请填写海报名称',trigger:'blur'}
        ],
        posterTypeId:[
          { required: true, message: '请填写海报类型', trigger: 'change' }
        ],
        posterRange:[
          {required: true, message:'请选择海报范围',trigger:'change'}
        ],
        posterPosition:[
          {required: true, message:'请选择二维码位置',trigger:'change'}
        ],
        posterImgPath:[
          {required: true, message:'请上传图片',trigger:'blur'}
        ],
      }
    }
  },
  methods: {
    showButtonsMe() {
      this.showButtons = true;
    },
    hideButtonsMe() {
      this.showButtons = false;
    },
    deleteImage() {
      // 处理删除图片的逻辑
      console.log('删除图片');
    },
    editImage() {
      // 处理修改图片的逻辑
      console.log('修改图片');
    },
    //回显海报素材
    openUpdateInfoApi(id){
      this.dialogFormVisibleMaterialUpdate = true
      posterOperate.showMaterial(id).then(res =>{
        this.material = res.data
      })
    },
    //修改海报素材
    toUpdateApi(){
      posterOperate.updateMaterial(this.material).then(res =>{
          if (res.success){
            this.$message.success(res.msg)
            this.material = {}
            this.materialListAll()
            this.dialogFormVisibleMaterialUpdate = false
          }else{
            this.$message.error(res.msg)
          }
      })
    },
    //删除海报素材
    deleteMaterial(id){
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        posterOperate.deleteMaterial(id).then(res =>{
          if (res.success){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.materialListAll()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    updateStatus(params){
       posterOperate.updatePosterStatus(params).then(res =>{
         if (res.success){
           this.$message.success(res.msg)
           this.materialListAll();
         }else{
           this.$message.error(res.msg)
         }
       })
    },
    materialListAll(){
      this.selectedIndex = -1
      let ids = [];
      this.tableData.forEach(poster =>{
        if (poster.enableStatus === 1){
          ids.push(poster.typeId);
        }
      });
      posterOperate.queryMaterialAll(ids).then(res =>{
        this.materialAllList = res.data;
      })
    },
    materialList(id,index){
      this.selectedIndex = index;
      posterOperate.queryImgList(id).then(res =>{
        this.materialAllList = res.data;
      })
    },
    //获取列表
    posterTypeList(callback) {
      posterOperate.typeList(this.formInline).then(res => {
        this.tableData = res.data;
        callback()
      })
    },
    //重置
    resetInfo() {
      this.formInline = {}
    },
    //新增数据
    saveSubmit() {
      this.dialogFormVisibleType = false
      posterOperate.addPosterType(this.form).then(res => {
        this.$message.success(res.msg);
        this.posterTypeList();
        this.form = {}
      })

    },
    //修改前置
    typeUpdate(id) {
       this.dialogFormVisibleUpdate = true;
       posterOperate.queryPosterId(id).then(res =>{
          this.updatePosterMsg = res.data;
       })
    },
    //修改
    subUpdateType(){
      posterOperate.updatePosterType(this.updatePosterMsg).then(res =>{
          if (res.success){
            this.dialogFormVisibleUpdate = false
            this.$message.success(res.msg)
            this.posterTypeList();
          }else{
            this.$message.error(res.msg)
          }
      })
    },
    //删除
    typeDelete(id) {
      posterOperate.deletePosterType(id).then(res =>{
        if (res.success){
          this.$message.success(res.msg)
          this.posterTypeList();
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    //提交素材
    submitMaterialData(){
      posterOperate.addPosterMaterial(this.material).then(res =>{
         if (res.success){
           this.$message.success(res.msg)
           this.materialListAll()
           this.material = {}
           this.dialogFormVisibleMaterial = false
         }else{
           this.$message.error(res.msg)
         }
      })
    },
    //上传图片
    handleAvatarSuccess(res, file) {
      this.material.imgPath = res.msg;
      // this.$set(this.material.imgPath, 'cover', res.msg);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'|| file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    }
  }
}
</script>

<style scoped>
.el-link-pad {
  margin-left: 10px;
  margin-right: 10px;
}
.el-image-pad {
  margin: 20px;
}
.avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: black;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.clicked-color {
  color: red; /* 例如设置为红色，您可以根据需求修改颜色 */
}
img {
  position: relative;
}

.button-container {
  position: absolute;
  top: 0;
  left: 0;
}

.delete-btn,
.edit-btn {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.delete-btn {
  top: 10px;
  left: 10px;
}

.edit-btn {
  top: 10px;
  left: 50px;
}
</style>
