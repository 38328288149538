import request from '@/utils/request.js';

const url = "/manage/promote/data";

//添加列表数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: params
    })
}

//获取列表数据
export function list(params) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: params
    })
}

//删除当前列表
export function deleteCurrent(id) {
    return request.post(`${url}/remove/${id}`);
}

//更新数据
export function updateData(startTime, endTime) {
    return request.get(`${url}/updateData?startTime=${startTime}&endTime=${endTime}`,);
}

//转化数据
export function turnPromoteData(param) {
    return request({
        url: `${url}/turnPromoteData`,
        method: 'get',
        params: param
    })
}

//转化数据根据IDS
export function turnPromoteDataId(param) {
    return request({
        url: `${url}/turnPromoteDataId`,
        method: 'get',
        params: param
    })
}

//回退数据
export function turnBackData() {
    return request({
        url: `${url}/turnBackData`,
        method: 'get'
    })
}