import request from '@/utils/request.js';

const url = "/manage/system/downloadLog";

//获取列表数据
export function listDownloadLog(query) {
    return request({
        url: `${url}/getDownloadList`,
        method: 'get',
        params: query
    })
}