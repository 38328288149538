<template>
  <div>
    <el-row>
      <el-form v-model="condition" :inline="true" label-position="right" label-width="80px" @submit.prevent.native>
        <el-form-item label="配置名称" prop="configureName" size="mini">
          <el-input v-model="condition.name" class="condition-input" placeholder="配置名称"
                    @keyup.enter.native="getConfigure" @submit.prevent.native="false"></el-input>
        </el-form-item>

      </el-form>
    </el-row>
    <el-row>
      <el-button-group>
        <el-button type="primary" size="mini" @click="getConfigure">查询</el-button>
        <el-button type="primary" size="mini" @click="addConfigure">添加</el-button>
      </el-button-group>
    </el-row>
    <el-row>
      <el-table :data="configureTableData" max-height="800" style="width: 100%" stripe fit>
        <el-table-column align="center" min-width="120" prop="name" fixed="left" label="配置名称"/>
        <el-table-column align="center" min-width="100" prop="bringline" label="机会保护期"/>
        <el-table-column align="center" min-width="100" prop="opporMax" label="最大资源数"/>
        <el-table-column align="center" min-width="100" prop="drawDayMax" label="日领取上限"/>
        <el-table-column align="center" min-width="100" prop="firstAdvisoryDayMax" label="首咨日领取上限"/>
        <el-table-column align="center" min-width="100" prop="phoneCount" label="查看手机号"/>
        <el-table-column align="center" min-width="100" prop="wakeOpportunityDayMax" label="日领取唤醒数量"/>

        <el-table-column align="center" min-width="100" fixed="right" label="操作">
          <template slot-scope="slot">
            <el-button type="primary" size="mini" @click="setConfigure(slot.row)">编辑</el-button>
<!--            <el-button type="success" size="mini" @click="addSon(slot.row)">创建子配置</el-button>-->
            <el-button type="danger" size="mini" @click="doDel(slot.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </el-row>
    <ConfigureDetail v-if="configureObj.showObj.isShow"
                     :show-obj="configureObj.showObj"
                     :configure-id="configureObj.configure.id"
                     :type="configureObj.type"
                     @getConfigure="getConfigure">
    </ConfigureDetail>

  </div>
</template>

<script>
import * as api from "../../utils/api";
import ConfigureDetail from "./components/ConfigureDetail";
import * as receiveApi from '@/api/system/receive'
import * as deptApi from "@/api/system/dept";

export default {
    name: "Configuration",
    components: {ConfigureDetail},
    data() {
        return {
            receiveList: [],
            configureTableData: [],
            condition: {
                parentId: 0
            },
            page: {current: 1, size: 10, total: 0},
            loading: true,
            configureObj: {
                showObj: {
                    isShow: false
                },
                configure: {}
            },
            type: 1,
            deptList: [],
        }
    },
    methods: {
        /** 查询部门列表 */
        getDeptList() {
            deptApi.listDept().then(response => {
                this.deptList = response.data;
            });
        },
        findReceiveList() {
            receiveApi.list().then(res => {
                this.receiveList = res.data
            })
        },
        handleSizeChange(val) {
            this.page.size = val
            this.getConfigure()
        },
        handleCurrentChange(val) {
            this.page.current = val
            this.getConfigure()
        },
        getConfigure() {
            api.configureList(this.page.current, this.page.size, this.condition).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.page.current = resEntity.current
                    this.page.size = resEntity.size
                    this.page.total = resEntity.total
                    this.configureTableData = resEntity.records
                    this.loading = false
                }
            }).catch(() => {
                this.$message.error("获取数据列表失败")
            })
        },
        /**
         * 添加配置
         * @param row
         */
        addConfigure(row) {
            this.configureObj.showObj.isShow = true
            this.configureObj.type = 1
            this.configureObj.configure = {}
        },
        setConfigure(row) {
            this.configureObj.configure = row
            this.configureObj.type = 2
            this.configureObj.showObj.isShow = true
        },
        doDel(id) {
            api.deleteById(id).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.getConfigure()
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        // addSon(data) {
        //     console.log(data)
        //     this.$router.push({
        //         name: 'ConfigurationSon',
        //         params: data
        //     })
        // }
    },
    mounted() {
        this.getConfigure()
        this.findReceiveList()
        this.getDeptList();
    }
}
</script>

<style scoped>

</style>
