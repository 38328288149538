<template>
    <div>
        <el-row>
            <el-col :span="22">
                <div class="mini-title">
                    请勾选需要在学习顾问页面展示的操作内容，没勾选内容不会在学习顾问页面展现
                </div>
            </el-col>
            <el-col :span="2">
                <div style="text-align: end;">
                    <el-button type="success" icon="el-icon-check" size="mini"
                               @click="handleUpdate()">保存
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-checkbox-group v-model="content" class="content">
                <el-checkbox v-for="c in contents" :label="c.id">{{ c.label }}</el-checkbox>
            </el-checkbox-group>
        </el-row>
    </div>
</template>

<script>
import * as searchApi from "@/api/system/searchDeploy";

export default {
    name: "consultantOption",
    data() {
        return {
            content: [],
            contents: [
                {label: "领首咨", value: "0", status: true}
                , {label: "领库存", value: "1", status: true}
                , {label: "问题反馈", value: "2", status: true}
                , {label: "机会校验", value: "3", status: true}
                , {label: "批量回库", value: "4", status: true}
                , {label: "添加机会", value: "5", status: true}
            ],
        }
    },
    mounted() {
        this.findList()
    },
    methods: {
        findList() {
            searchApi.list({classify: "CONSULTANT_OPERATE"}).then(res => {
                this.contents = res.data;
                let cont = []
                for (let i in this.contents) {
                    if (this.contents[i].status) {
                        cont.push(this.contents[i].id)
                    }
                }
                this.content = cont
            });
        },
        handleUpdate() {
            const loading = this.$loading({
                lock: true,
                text: '保存中....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = []
            let contents = this.contents
            for (let i in contents) {
                if (this.content.includes(contents[i].id)) {
                    contents[i].status = true
                } else {
                    contents[i].status = false
                }
                data.push(contents[i])
            }
            searchApi.batchUpdate(data).then(res => {
                if (res.success) {
                    this.msgSuccess("修改成功");
                    this.list();
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close();
            }).catch(r => {
                loading.close();
            })

        },
    }
}
</script>

<style scoped>
.mini-title {
    font-size: 12px;
    color: #909399;
}

.content {
    font-size: 16px;
}
</style>