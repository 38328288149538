<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
            <el-form-item label="名称" prop="name">
                <el-input v-model="queryParams.name" placeholder="名称" clearable size="small"/>
            </el-form-item>
            <el-form-item label="账号" prop="configId">
                <el-select v-model="queryParams.configId" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="c in promoteConfigList" :value="c.id" :label="c.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="渠道" prop="mediatorId">
                <el-select v-model="queryParams.mediatorId" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="m in mediators" :value="m.id" :label="m.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标识" prop="possessId">
                <el-select v-model="queryParams.possessId" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option v-for="p in possessList" :value="p.id" :label="p.name"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-select v-model="queryParams.status" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option :value="1" label="正常"></el-option>
                    <el-option :value="0" label="停用"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="配置" prop="configStatus">
                <el-select v-model="queryParams.configStatus" filterable class="condition-select" size="small"
                           placeholder="请选择">
                    <el-option value="" label="全部"></el-option>
                    <el-option :value="1" label="已配置"></el-option>
                    <el-option :value="0" label="未配置"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="configList">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="configTable"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="name"
                    label="名称">
            </el-table-column>
          <el-table-column
              prop="aliName"
              label="推广页名称">
          </el-table-column>
            <el-table-column
                    prop="promoteId"
                    label="推广页ID">
            </el-table-column>
            <el-table-column
                    prop="configId"
                    label="账号">
                <template slot-scope="scope">
                    <span v-for="p in promoteConfigList" v-if="p.id == scope.row.configId" v-text="p.name"></span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="type"
                    label="类型">
            </el-table-column>
            <el-table-column
                    prop="mediatorId"
                    label="渠道">
                <template slot-scope="scope">
                    <span v-for="p in mediators" v-if="p.id == scope.row.mediatorId" v-text="p.name"></span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="possessId"
                    label="标识">
                <template slot-scope="scope">
                    <span v-for="p in possessList" v-if="p.id == scope.row.possessId" v-text="p.name"></span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.status"
                            @change="chanagePage(scope.row)"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                    prop="configStatus"
                    label="数据配置">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.configStatus"
                            :disabled="true"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                    prop="gmtCreate"
                    label="创建时间">
            </el-table-column>

            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="edit(scope.row,scope.row.id)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="deleteCurrent(scope.row.id)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30, 40]" :page.sync="queryParams.page"
                    @pagination="configList"
                    :limit.sync="queryParams.limit"/>

        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogFormVisible"
                width="600px"
                @close="resetFrom('roleForm')"
        >
            <el-form
                    :model="dialogForm"
                    ref="roleForm"
                    label-width="120px"
            >
                <el-form-item label="备注名称">
                    <el-input v-model="dialogForm.name"></el-input>
                </el-form-item>
                <el-form-item label="渠道">
                    <el-select v-model="dialogForm.mediatorId" filterable class="condition-select" size="small"
                               placeholder="请选择">
                        <el-option value="" label="未选择"></el-option>
                        <el-option v-for="m in mediators" :value="m.id" :label="m.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标识">
                    <el-select v-model="dialogForm.possessId" filterable class="condition-select" size="small"
                               placeholder="请选择">
                        <el-option value="" label="未选择"></el-option>
                        <el-option v-for="p in possessList" :value="p.id" :label="p.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-for="s of propertyList" :label="s.name">
                    <el-select v-model="s.value" filterable class="condition-select" size="small"
                               placeholder="请选择">
                        <el-option value="" label="未选择"></el-option>
                        <el-option v-for="p in opporAttrs" :value="p.value" :label="p.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="success" @click="save('roleForm')">保存</el-button>
                <el-button type="success" @click="updatePage">更新页面</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import * as api from "@/api/mini/promotePage";
    import * as apiConfig from "@/api/mini/promoteConfig";
    import * as apiUtils from "../../utils/api";

    export default {
        name: "promotePage",
        data() {
            return {
                // 总条数
                total: 0,
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10
                },
                configTable: [],
                dialogFormVisible: false,
                dialogTitle: "",
                dialogForm: {
                    name: "",
                    detail: ""
                },
                dialogForm_null: Object.assign({}, this.dialogForm),
                possessList: [],
                mediators: [],
                promoteConfigList: [],
                propertyList: [],
                opporAttrs:[
                    {
                        name:"手机号",value:"telephone"
                    },
                    {
                        name:"性别",value:"sex"
                    },
                    {
                        name:"邮箱",value:"email"
                    },
                    {
                        name:"姓名",value:"studentName"
                    },
                    {
                        name:"QQ",value:"qq"
                    },
                    {
                        name:"微信",value:"weiXin"
                    },
                    {
                        name:"详细地址",value:"address"
                    },
                    {
                        name:"学历",value:"verifyEducation"
                    },
                    {
                        name:"年龄",value:"verifyAge"
                    },
                    {
                        name:"项目",value:"subjectName"
                    }
                ]
            }
        },
        mounted() {
            this.configList();
            this.findPossessList();
            this.findAllMediators();
            this.findPromoteConfigList();
        },
        methods: {
            resetQuery() {
                this.resetForm("queryForm");
                this.queryParams = {};
                this.configList();
            },
            /*
            * 获取列表数据
            * */
            configList() {
                api.getList(this.queryParams).then(res => {
                    this.configTable = res.data
                    this.total = res.count
                });
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            edit(row, index) {
                this.dialogTitle = "修改配置";
                this.dialogForm = Object.assign({}, row);
                this.dialogFormVisible = true;
                let property = row.propertyList
                let pr = JSON.parse(property)
                this.propertyList = pr
            },

            /*
            * 新增/修改 保存列表数据
            * */
            save(roleForm) {
                this.$refs[roleForm].validate(valid => {
                    if (valid) {
                        let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                        this.dialogForm.propertyList = JSON.stringify(this.propertyList)
                        api.save(this.dialogForm).then(res => {
                            this.configList()
                            this.$message({
                                type: "success",
                                message: id ? "修改成功！" : "新增成功！"
                            });
                            this.dialogFormVisible = false;
                        });

                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                api.deleteCurrent(id).then(res => {
                    this.pagedomainList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            },
            findPossessList() {
                apiUtils.possessList().then(res => {
                    var t = res.data
                    this.possessList = []
                    for (let tmp of t) {
                        let v = {}
                        v['id'] = tmp.id
                        v['name'] = tmp.name
                        this.possessList.push(v)
                    }
                })
            },
            findAllMediators() {
                apiUtils.allMediators().then(res => {
                    var t = res.data
                    this.mediators = []
                    for (let tmp of t) {
                        let v = {}
                        v['id'] = tmp.id
                        v['name'] = tmp.mediatorName
                        this.mediators.push(v)
                    }
                })
            },
            findPromoteConfigList() {
                apiConfig.list().then(res => {
                    this.promoteConfigList = []
                    for (let tmp of res) {
                        let v = {}
                        v['id'] = tmp.id
                        v['name'] = tmp.name
                        this.promoteConfigList.push(v)
                    }
                })
            },
            chanageConfig(row) {
                let status = !row.status ? 0 : 1
                api.updateStatus(row.id, status).then(res => {
                    this.$message({
                        type: "success",
                        message: "修改状态成功!"
                    });
                })
            },
            updatePage(){
                api.updatePage().then(res => {
                    this.$message({
                        type: "success",
                        message: res.msg
                    });
                })
            },

        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
