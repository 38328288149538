<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="80px">
            <el-form-item label="机会ID" prop="opporId">
                <el-input
                        v-model="queryParams.opporId"
                        placeholder="请输入机会ID"
                        clearable
                        size="small"
                        @keyup.enter.native="findList"
                />
            </el-form-item>
            <el-form-item label="手机号" prop="telephone">
                <el-input
                        v-model="queryParams.telephone"
                        placeholder="请输入手机号"
                        clearable
                        size="small"
                        @keyup.enter.native="findList"
                />
            </el-form-item>

            <el-form-item label="咨询师" prop="accountId">
                <el-select v-model="queryParams.accountId" placeholder="请选择咨询师" size="small" clearable filterable>
                    <el-option
                            v-for="item in accountList"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="部门" prop="deptId">
                <el-select v-model="queryParams.deptId" placeholder="请选择部门" size="small" clearable filterable>
                    <el-option
                            v-for="item in deptList"
                            :key="item.id"
                            :label="item.deptName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="按钮" prop="btnId">
                <el-select v-model="queryParams.btnId" placeholder="请选择按钮" size="small"
                           clearable>
                    <el-option
                            v-for="item in btnList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="销售配置" prop="configureId">
                <el-select v-model="queryParams.configureId" placeholder="请选择销售配置" size="small"
                           clearable>
                    <el-option
                            v-for="item in configureList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="机会类别" prop="type">
                <el-select v-model="queryParams.type" placeholder="请选择机会类别" size="small" clearable>
                    <el-option label="全部" value=""/>
                    <el-option label="首咨" value="1"/>
                    <el-option label="非首咨" value="0"/>
                </el-select>
            </el-form-item>

            <el-form-item label="领取时间" prop="receiveDateArr">
                <el-date-picker
                        v-model="receiveDateArr"
                        type="daterange"
                        align="right"
                        unlink-panels
                        value-format="yyyy-MM-dd"
                        size="small"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="findList">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table
                :data="tableData"
                style="width: 100%"
                :row-class-name="tableRowClassName">
            <el-table-column prop="opporId" label="机会ID" align="center"/>
            <el-table-column prop="telephone" label="手机号" align="center"/>
            <el-table-column prop="nickname" label="领取人" align="center"/>
            <el-table-column prop="deptName" label="领取人部门" align="center"/>
            <el-table-column prop="btnName" label="领取按钮(领取时名称)" align="center"/>
            <el-table-column prop="btnId" label="领取按钮">
                <template v-slot="scope">
                    <el-tag effect="dark">{{ showBtnName(scope.row.btnId) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="机会类别">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.type === 1" type="success">首咨</el-tag>
                    <el-tag v-else>非首咨</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="configureId" label="销售配置">
                <template v-slot="scope">
                    <el-tag>{{ showConfigureName(scope.row.configureId) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="领取时间"/>
        </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="queryParams.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="queryParams.total">
        </el-pagination>
    </div>
</template>

<script>
import * as receiveApi from '@/api/system/receive'
import * as deptApi from "@/api/system/dept";
import * as accountApi from "@/api/system/account"
import * as api from "@/utils/api";

export default {
    name: "ReceiveButtonLog",
    data() {
        return {
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
                opporId: null,
                telephone: null,
                accountId: null,
                deptId: null,
                btnId: null,
                configureId: null,
                type: null,
                startDate: null,
                endDate: null,
            },
            accountList: [],
            deptList: [],
            btnList: [],
            receiveDateArr: [],
            configureList: [],
            tableData: [],
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0);
                        start.setMinutes(0);
                        start.setSeconds(0);
                        start.setMilliseconds(0);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        start.setHours(0);
                        start.setMinutes(0);
                        start.setSeconds(0);
                        start.setMilliseconds(0);
                        end.setTime(end.getTime() - 3600 * 1000 * 24);
                        end.setHours(23);
                        end.setMinutes(59);
                        end.setSeconds(59);
                        end.setMilliseconds(999);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    created() {
        this.getAccountList();
        this.getDeptList();
        this.getBtnList();
        this.getConfigureList();
    },
    mounted() {
        const btnId = this.$route.query.btnId
        if (btnId) {
            this.queryParams.btnId = Number(btnId)
        }
        this.findList();
    },
    methods: {
        showConfigureName(configId) {
            const config = this.configureList.find(item => item.id === configId)
            if (config) {
                return config.name
            } else {
                return '未找到相关配置'
            }
        },
        showBtnName(btnId) {
            const btn = this.btnList.find(item => item.id === btnId)
            if (btn) {
                return btn.name
            } else {
                return '未找到相关按钮'
            }
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val
            this.findList()
        },
        handleCurrentChange(val) {
            this.queryParams.pageNum = val
            this.findList()
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === 1) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return 'success-row';
            }
            return '';
        },
        findList() {
            const params = Object.assign({}, this.queryParams)
            if (this.receiveDateArr && this.receiveDateArr.length > 0) {
                params.startDate = this.receiveDateArr[0];
                params.endDate = this.receiveDateArr[1];
            }
            if (params.deptId) {
                params['deptList'] = params.deptId
            }
            receiveApi.queryPageLog(params).then(res => {
                if (res.success) {
                    this.tableData = res.data.data;
                    this.queryParams.total = res.data.total;
                }
            })
        },
        resetQuery(val) {
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = 10;
            this.$refs[val].resetFields();
            this.receiveDateArr = [];
            this.findList();
        },
        getAccountList() {
            accountApi.list().then(res => {
                this.accountList = res.data;
            })
        },
        getDeptList() {
            deptApi.list().then(res => {
                this.deptList = res.data;
            })
        },
        getBtnList() {
            receiveApi.list().then(res => {
                this.btnList = res.data;
            })
        },
        getConfigureList() {
            api.configureList(1, 99999, {parentId: -1}).then(res => {
                this.configureList = res.data.data;
            });
        },
    }
}
</script>

<style scoped>

</style>
