<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="queryParams.name" placeholder="商品名称" clearable size="small" @keyup.native.enter="handleQuery"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addCourse"
    >新增
    </el-button>

    <el-row>
      <el-col :span="5" v-for="(o, index) in tableData" :key="o.id" :offset="index % 4 == 0 ? 0:1">
        <el-card :body-style="{ padding: '0px'}" style="margin-top: 10px;">
          <img
              :src="o.background?o.background:'https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png'"
              class="image">
          <div style="padding: 14px;" class="card-style">
            <el-row>
              商品名:<span>{{ o.name }}</span>
            </el-row>
            <el-row>
              商品介绍:<span>{{ o.description }}</span>
            </el-row>
            <el-row>
              密码:<span>{{ o.password }}</span>
            </el-row>
            <el-row>
              时间:
              <time class="time">{{ o.createTime }}</time>
            </el-row>
            <el-row class="bottom bottom-right">
              <el-button size="small" @click="showDomain(o)">网址</el-button>
              <el-dropdown size="small" @command="handleClick" class="font-color" :data-id="o.id">
                <el-button size="small" type="primary">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="composeValue('catalogue',o)">商品目录</el-dropdown-item>
                  <el-dropdown-item :command="composeValue('modify',o)">修改</el-dropdown-item>
                  <el-dropdown-item command="delete">删除</el-dropdown-item>
                  <el-dropdown-item command="refresh">更新密码</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <pagination :total="total" :page-size="8" :page-sizes="[8,16,32]" :page.sync="queryParams.page"
                :limit.sync="queryParams.limit" @pagination="courseList"/>

    <el-dialog :title="course.name" :visible.sync="courseVisible" width="300px" style="text-align: center;">
      <div style="width: 200px;height: 200px;background-color: white;margin: 10px auto"
           id="domain-qrcode">二维码
      </div>
      <el-input :value="domainUrl"></el-input>
      <el-button @click="sy">生成二维码</el-button>
    </el-dialog>

    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogFormVisible"
        width="60%" top="4vh"
        @close="resetFrom('roleForm')"
    >
      <el-form
          :model="dialogForm"
          ref="roleForm"
          :rules="rules"
          label-width="120px"
      >
        <el-form-item label="商品名称" prop="name">
          <el-row>
            <el-col :span="12">
              <el-input v-model="dialogForm.name"></el-input>
            </el-col>
          </el-row>
        </el-form-item>

        <!--                <el-form-item label="封面">-->
        <!--                    <el-row>-->
        <!--                        <el-col :span="12">-->
        <!--                            <el-upload-->
        <!--                                    class="avatar-uploader"-->
        <!--                                    action="/api/manage/file/uploadImg"-->
<!--          :headers="headers"-->
        <!--                                    :show-file-list="false"-->
        <!--                                    :on-success="handlePosterSuccess"-->
        <!--                                    :before-upload="beforeAvatarUpload">-->
        <!--                                <img v-if="dialogForm.poster" :src="dialogForm.poster" class="avatar">-->
        <!--                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
        <!--                            </el-upload>-->
        <!--                        </el-col>-->
        <!--                    </el-row>-->
        <!--                </el-form-item>-->
        <el-form-item label="商品描述" prop="description">
          <el-row>
            <el-col :span="12">
              <el-input v-model="dialogForm.description"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="数据标识" prop="poss">
          <el-row>
            <el-col :span="24">
              <el-select v-model="dialogForm.poss">
                <el-option v-for="p in possList" :value="p.id" :label="p.name"></el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="是否需要密码" prop="needPassword">
          <el-row>
            <el-col :span="12">
              <el-radio v-model="dialogForm.needPassword" :label="1">需要密码</el-radio>
              <el-radio v-model="dialogForm.needPassword" :label="0">免密观看</el-radio>
              <el-radio v-model="dialogForm.needPassword" :label="2">短信验证码</el-radio>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item v-if="dialogForm.needPassword == 1" label="观看密码" prop="password">
          <el-row>
            <el-col :span="12">
              <el-input v-model="dialogForm.password"></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="商品描述" prop="content">
          <el-row>
            <el-col :span="12">
              <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="商品描述"
                  v-model="dialogForm.content">
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="背景图片" prop="background">
          <el-row>
            <el-col :span="12">
              <el-upload
                  class="avatar-uploader"
                  action="/api/manage/file/uploadImg"
                  :headers="headers"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="dialogForm.background" :src="dialogForm.background" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="success" @click="save('roleForm')">保存</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import * as api from "@/api/applets/appletsCourse";
import * as configApi from "@/api/system/config";
import * as possApi from "@/api/crm/possess";
import QRCode from 'qrcodejs2'

export default {
  name: "appletsCourse",
  data() {
    return {
        headers:{"version": localStorage.getItem("_version"),},
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 8
      },
      tableData: [],
      dialogFormVisible: false,
      dialogTitle: "设置商品信息",
      dialogForm: {
        name: "",
        detail: ""
      },
      dialogForm_null: Object.assign({}, this.dialogForm),
      domainUrl: "",
      course: {},
      courseVisible: false,
      appletUrl: "",
      possList: [],
      rules: {
        poss: [
          {required: true, message: '请选择一个标识', trigger: 'blur'}
        ],
        needPassword: [
          {required: true, message: '请选择一个类型', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入商品名称', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    this.courseList();
    this.findAppletUrl();
    this.findPossessList()
  },
  methods: {
    findPossessList() {
      possApi.list().then(res => {
        if (res.success) {
          this.possList = res.data
        }
      })
    },
    findAppletUrl() {
      configApi.findConfigByConfigType("APPLET_COURSE_HREF").then(res => {
        if (res) {
          this.appletUrl = res.url
        } else {
          this.appletUrl = "http://course.zhuoxueijiaoyu.com"
        }
      })
    },
    sy() {
      document.getElementById("domain-qrcode").innerHTML = "";
      let qrcode = new QRCode("domain-qrcode", {
        width: 200,
        height: 200,
        text: this.domainUrl,
        colorDark: "#333",
        colorLight: "#fff"
      });
    },
    showDomain(o) {
      this.courseVisible = true
      let url = `${this.appletUrl}?courseId=${o.id}`;
      this.domainUrl = url
    },
    handleClick(command) {
      if (command.name === 'catalogue') {
        let query = {id: command.row.id}
        this.$router.push({path: '/applets/catalogue', query: query})
      } else if (command.name === 'modify') {
        this.dialogForm.id = command.row.id
        this.edit(command.row)
      }
    },
    composeValue(item, row) {
      return {
        'name': item,
        'row': row
      }
    },
    handlePosterSuccess(res, file) {
      this.$set(this.dialogForm, 'poster', res.msg)
    },
    handleAvatarSuccess(res, file) {
      this.$set(this.dialogForm, 'background', res.msg)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传背景图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      this.$message({
        type: "success",
        message: "上传成功！"
      });
      return isJPG && isLt2M;
    },
    addCourse() {
      this.dialogFormVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.queryParams.limit = 8;
      this.courseList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams = {};
      this.handleQuery();
    },
    /*
    * 获取列表数据
    * */
    courseList() {
      api.getList(this.queryParams).then(res => {
        this.tableData = res.data.data
        this.total = res.data.count
      });
    },
    //重置表单规则
    resetFrom(roleForm) {
      this.$refs[roleForm].clearValidate();
    },
    handleButton(val) {
      //调用事件
      this[val.methods](val.row, val.index);
    },
    edit(row, index) {
      console.log(row);
      this.dialogTitle = "修改微页面";
      this.dialogForm = Object.assign({}, row);
      this.dialogFormVisible = true;
    },
    /*
    * 新增/修改 保存列表数据
    * */
    save(roleForm) {
      this.$refs[roleForm].validate(valid => {
        if (valid) {
          let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
          api.save(this.dialogForm).then(res => {
            this.courseList()
            this.$message({
              type: "success",
              message: id ? "修改成功！" : "新增成功！"
            });
            this.dialogFormVisible = false;
          });

        }
      });
    },
    /*
    * 删除
    * */
    deleteCurrent(id) {
      api.deleteCurrent(id).then(res => {
        console.log(res)
        this.pagedomainList()
        this.$message({
          type: "success",
          message: "删除成功"
        });
      })
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  /*width: 178px;*/
  height: 178px;
  display: block;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-bottom: 13px;
  margin-top: 5px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  /*width: 100 %;*/
  display: block;
  height: 180px;
  margin: auto;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.bottom-right {
  float: right;
}

.font-color {
  color: #2c91e7;
  cursor: pointer;
}

.card-style {
  font-size: 15px;
  color: #8c939d;
}

.card-style .el-row {
  display: flex;
  line-height: 22px;
}

.card-style .el-row .time {
  flex: 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-style span {
  font-size: 16px;
  color: #373a3e;
  flex: 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
