<template>
    <div>
        <el-form :model="queryParams" ref="form" :inline="true" class="demo-form-inline">
            <el-form-item v-permission="'crm:consultant:allData'" label="全部数据" prop="isAllData" size="mini">
                <el-switch v-model="queryParams.isAllData"
                           active-text="全部数据"
                           inactive-text=""
                           :active-value="true"
                           :inactive-value="false"
                />
            </el-form-item>
            <el-form-item label="手机号" prop="telephone" size="mini">
                <el-input v-model="queryParams.telephone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="机会ID" prop="id" size="mini">
                <el-input v-model="queryParams.id" placeholder="请输入机会ID"></el-input>
            </el-form-item>
            <el-form-item label="是否添加微信" prop="isAddWechat" size="mini">
                <el-select v-model="queryParams.isAddWechat" placeholder="请选择是否添加微信">
                    <el-option
                            v-for="item in isAddWechatOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="预计回库时间" prop="expectBackStoreTimeStrArr" size="mini">
                <el-date-picker
                        v-model="expectBackStoreTimeStrArr"
                        type="datetimerange"
                        align="right"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="回库时间" prop="backStoreTimeArr" size="mini">
                <el-date-picker
                        v-model="backStoreTimeArr"
                        type="datetimerange"
                        align="right"
                        :default-time="['00:00:00', '23:59:59']"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="doSearch">查询</el-button>
                <el-button type="info" @click="restart">重置</el-button>
            </el-form-item>
        </el-form>
        <el-button v-permission="'crm:consultant:update'" type="primary" @click="uploadExpectBackStoreTime">上传更新预计回库时间</el-button>
        <el-button v-permission="'crm:consultant:allExport'" type="primary" @click="exportMyOpportunity">导出</el-button>
        <el-table :data="tableData" max-height="506px"
                  :default-sort="sortRule" v-loading="loading" :row-class-name="handleRowClassName">
            <el-table-column align="center" prop="backStoreTime" label="回库时间" width="200"/>
            <el-table-column align="center" prop="expectBackStoreTime" width="200" label="预计回库时间"/>
            <el-table-column v-for="(col,index) in columnList"
                             :prop="col.name"
                             :sortable="col.name.indexOf('Time') > 0"
                             :fixed="col.name === 'telephone'"
                             :label="col.label"
                             width="150" align="center">
                <template slot-scope="scope">
                    <div v-if="col.name === 'telephone'" class="telephone">
                        <el-tooltip class="item" effect="dark" content="已拨打未接通" placement="bottom"
                                    v-if="scope.row.dialingStatus === 2">
                            <i class="el-icon-phone-outline red"
                               style="font-size: 19px;vertical-align:middle;margin-right: 4px"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="已拨打已沟通" placement="bottom"
                                    v-if="scope.row.dialingStatus === 1">
                            <img src="../../assets/icon/calling.png" alt=""
                                 style="vertical-align: middle;margin-top: -4px;margin-right: 4px">
                        </el-tooltip>
                        <span>{{ scope.row.hideTelephone }}</span>
                        <el-tooltip effect="dark" content="点击复制手机号" placement="bottom"
                                    v-if="scope.row.telephone">
                            <i class="el-icon-document-copy btn-click-open" @click="copyWechat(scope.row.id)"></i>
                        </el-tooltip>
                    </div>
                    <div v-else-if="col.name === 'type'">
                        <span v-if="scope.row.type === 1">首咨</span>
                        <span v-if="scope.row.type === 2">个人</span>
                        <span v-if="scope.row.type === 3">常规</span>
                        <span v-if="scope.row.type === 4">库存</span>
                        <span v-if="scope.row.type === 5">唤醒</span>
                    </div>
                    <div v-else-if="col.name === 'weiXin'">
                        <el-tooltip class="item" effect="dark" content="已添加微信" placement="bottom"
                                    v-if="scope.row.isAddWechat">
                            <i class="el-icon-check WeChatIcon"></i>
                        </el-tooltip>
                        <span>{{ scope.row.weiXin }}</span>
                        <el-tooltip class="item" effect="dark" content="点击复制微信号" placement="bottom"
                                    v-if="scope.row.weiXin">
                            <i class="el-icon-document-copy"
                               style="cursor: pointer;margin-left: 4px"
                               v-clipboard:copy="scope.row.weiXin"
                               v-clipboard:success="onCopy"
                               v-clipboard:error="onError"></i>
                        </el-tooltip>
                    </div>
                    <div v-else-if="col.name === 'subjectName'">
                        <template v-if="scope.row.subjectName" class="position">
                            <div>
                                {{ scope.row.subjectName }}
                            </div>
                        </template>
                        <span v-else>{{ scope.row.subjectName }}</span>
                    </div>
                    <div v-else-if="col.name === 'dialingStatus'">
                        <span v-if="scope.row.dialingStatus === 0">未拨打</span>
                        <span v-if="scope.row.dialingStatus === 1">已拨打已接通</span>
                        <span v-if="scope.row.dialingStatus === 2">已拨打未接通</span>
                    </div>
                    <div v-else-if="col.name === 'acquisitionMethod'">
                        <span v-if="scope.row.acquisitionMethod === 1">领取</span>
                        <span v-if="scope.row.acquisitionMethod === 2">分配</span>
                        <span v-if="scope.row.acquisitionMethod === 3">添加</span>
                    </div>
                    <div v-else-if="col.name === 'orderStatus'">
                        <span v-if="scope.row.orderStatus === 0">未成单</span>
                        <span v-if="scope.row.orderStatus === 1">已成单</span>
                        <span v-if="scope.row.orderStatus === 2">已下单</span>
                    </div>
                    <div v-else-if="col.name === 'isAppointment'">
                        <span v-if="scope.row.isAppointment === 0">无</span>
                        <span v-if="scope.row.isAppointment === 1">有</span>
                    </div>
                    <div v-else-if="col.name === 'remark'">
                        <el-tooltip class="item" effect="dark" :content="scope.row.remark" placement="top">
                            <span>{{ scope.row.remark | ellipsis }}</span>
                        </el-tooltip>
                    </div>
                    <div v-else-if="col.name === 'tags'">
                        <template v-for="item in splitTags(scope.row.tags)">
                            <el-tag v-for="(it,index) in tagsOptions" :type="typeOptions[index % 5]"
                                    v-if="it.id === item">
                                {{ it.name }}
                            </el-tag>
                        </template>
                    </div>
                    <div v-else-if="col.type === 'EXTRA'">
                        <span v-if="scope.row.tson">{{ scope.row.tson[col.name] }}</span>
                    </div>
                    <div v-else>
                        {{ showData(scope.row, col.name) }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column align="center" label="操作" fixed="right" min-width="300">
                <template slot-scope="scope">
                    <el-button type="info" size="mini"
                               @click="addWeChat(scope.row)">添加微信
                    </el-button>
                    <el-button type="primary" size="mini" :style="{backgroundColor:'#F19149',borderColor:'#F19149'}"
                               @click="openBackSync(scope.row.id)">回库
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.current"
                :page-sizes="[10, 20, 30, 200]"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total">
        </el-pagination>

        <AddWechat ref="addWeChatDialog" :data-message="addWeChatMessage"></AddWechat>

        <el-dialog title="机会回库" :visible.sync="backSync">
            <el-row v-for="item in backData">
                <el-col :span="12">机会ID:{{ item }}</el-col>
            </el-row>
            <el-row style="margin: 20px 0">
                <b style="color: #dd6161;font-size: 20px">请选择回库原因:</b>
            </el-row>
            <el-row>
                <el-radio-group v-model="backIntention">
                    <el-radio-button v-for="data in intentionDatas" :label="data.label" border></el-radio-button>
                </el-radio-group>
            </el-row>
            <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="backSync = false">关 闭</el-button>
        <el-button type="success" @click="batchGiveUp">确 定</el-button>
      </span>
        </el-dialog>

        <el-dialog :visible.sync="upload.open" :title="upload.title" width="400px" append-to-body>
            <el-upload
                    ref="upload"
                    :limit="1"
                    accept=".xlsx, .xls"
                    :headers="upload.headers"
                    :action="upload.url"
                    :disabled="upload.isUploading"
                    :on-progress="handleFileUploadProgress"
                    :on-success="handleFileSuccess"
                    :auto-upload="false"
                    drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>

                <div slot="tip" class="el-upload__tip" v-if="upload.isShowResult">一共识别上传数据[{{
                        upload.totalCount
                    }}]条
                </div>
                <div slot="tip" class="el-upload__tip" v-if="upload.isShowResult">处理成功[{{
                        upload.successCount
                    }}]条
                </div>
                <div slot="tip" class="el-upload__tip" v-if="upload.isShowResult && upload.errMsgList.length > 0">
                    以下是失败原因:
                </div>
                <div slot="tip" class="el-upload__tip" v-if="upload.isShowResult && upload.errMsgList.length > 0"
                     v-for="(resMsg,index) in upload.errMsgList"
                     :key="index">
                    {{ resMsg }}
                </div>

            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFileForm">确 定</el-button>
                <el-button @click="upload.open = false">取 消</el-button>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import * as api from "@/utils/api";
import {copy} from "@/utils/utils";
import * as tagsApi from "@/api/crm/tags";
import * as searchApi from "@/api/system/searchDeploy";
import AddWechat from "@/views/consultant/components/AddWechat.vue";
import * as receiveApi from '@/api/system/receive'
import axios from "axios";

export default {
    name: "ConsultantNoAddWx",
    components: {AddWechat},
    data() {
        return {
            tableData: [],
            sortRule: {prop: null, order: null},
            loading: false,
            subjectList: [],
            columnList: [],
            tagsOptions: [],
            typeOptions: ['', 'success', 'warning', 'danger', 'info'],
            page: {
                current: 1,
                size: 10,
                total: 0
            },
            backStoreTimeArr: [],
            expectBackStoreTimeStrArr: [],
            queryParams: {
                isAddWechat: '',
                backStoreTimeEnd: '',
                backStoreTimeStart: '',
                vallotTimeEnd: '',
                vallotTimeStart: '',
                haveTimeEnd: '',
                haveTimeStart: '',
                id: '',
                telephone: '',
                isAllData: false,
                queryType: 1
            },
            addWeChatMessage: {},
            backSync: false,
            backData: [],
            backIntention: "",
            intentionDatas: [],
            isAddWechatOptions: [
                {value: '', label: "全部"},
                {value: 1, label: "已添加"},
                {value: 0, label: "未添加"},
            ],
            upload: {
                open: false,
                title: "导入修改预计回库时间(注意:回库时间格式仅支持[2024-04-22 13:17:28], 唯一识别机会ID)",
                isUploading: false,
                url: `/api/manage/crm/counselor/uploadExpectBackStoreTime`,
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                totalCount: 0,
                successCount: 0,
                errMsgList: [],
                isShowResult: false,
            },
        }
    },
    methods: {
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            // this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            if (response.success) {
                this.upload.errMsgList = response.data.msgList
                this.upload.successCount = response.data.successCount
                this.upload.totalCount = response.data.totalCount
                this.upload.isShowResult = true
            } else {
                this.$message.warning(response.msg)
            }
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        },
        findIntention() {
            receiveApi.findOptionCode('intention').then(res => {
                this.intentionDatas = res.data
            })
        },
        handleRowClassName({row, index}) {
            if (row.orderStatus === 1) {
                return "order-success"
            }
            if (row.type === 5) {
                return "oppor-waken"
            }
        },
        copyWechat(id) {
            api.getTelephone(id).then(resp => {
                if (resp.success) {
                    let telephone = resp.msg
                    if (telephone !== null) {
                        copy(telephone);
                        this.$message.success("复制成功!")
                    }
                } else {
                    this.$message.error(resp.msg)
                }
            }).catch(e => {
                this.$message.error("复制失败!" + e)
            })
        },
        onCopy(e) {    // 复制成功
            this.$message({
                message: '复制成功！',
                type: 'success'
            })
        },
        onError(e) {   // 复制失败
            this.$message({
                message: '复制失败！',
                type: 'error'
            })
        },
        splitTags(tags) {
            return tags?.split(",").filter(e => e && e.trim()).map(Number) || []
        },
        findTagsOptions() {
            tagsApi.options().then(res => {
                this.tagsOptions = res.data
            })
        },
        openBackSync(id) {
            this.backData = [id]
            if (!this.backData) {
                this.$message.warning("请至少选择一行数据")
                return
            }
            this.backSync = true
        },
        addWeChat(row) {
            this.addWeChatMessage = Object.assign({}, row)
            this.$refs.addWeChatDialog.openDialog()
        },
        handleSizeChange(val) {
            this.page.size = val
            this.myOpportunity(this.queryParams)
        },
        handleCurrentChange(val) {
            this.page.current = val
            this.myOpportunity(this.queryParams)
        },
        showData(row, name) {
            return row[name];
        },
        getColumn: function () {
            searchApi.findByClassify("CONSULTANT_TABLES").then(res => {
                this.columnList = res.data;
            });
        },
        myOpportunity(params) {
            api.myOpportunity(this.page.current, this.page.size, params).then(res => {
                if (res.success) {
                    let resEntity = res.data
                    this.page.current = resEntity.current
                    this.page.size = resEntity.size
                    this.page.total = resEntity.total
                    this.tableData = resEntity.records
                    for (let i in this.tableData) {
                        if (this.tableData[i].extra) {
                            this.tableData[i].tson = JSON.parse(this.tableData[i].extra)
                        }
                    }
                    this.tabData = resEntity.records
                    if (this.sortRule.order !== null && this.sortRule.prop != null) {
                        this.handleSortChange(this.sortRule)
                    }
                    if (this.opporTypeRadios === '8') {
                        this.withinTwoDayBackNum = resEntity.total
                    } else if (this.opporTypeRadios === '9') {
                        this.withinTodayBackNum = resEntity.total
                    }
                    this.loading = false
                } else {
                    this.loading = false
                    this.$message.warning(res.msg);
                }
            }).catch(err => {
                this.loading = false
                this.$message.error("获取我的机会失败");
            })
        },
        /**
         * 搜索条件搜索
         */
        doSearch() {
            this.loading = true
            if (this.backStoreTimeArr && this.backStoreTimeArr.length > 0) {
                this.queryParams.backStoreTimeStart = this.backStoreTimeArr[0]
                this.queryParams.backStoreTimeEnd = this.backStoreTimeArr[1]
            } else {
                this.queryParams.backStoreTimeStart = ''
                this.queryParams.backStoreTimeEnd = ''
            }
            if (this.expectBackStoreTimeStrArr && this.expectBackStoreTimeStrArr.length > 0) {
                this.queryParams.expectBackStoreTimeStart = this.expectBackStoreTimeStrArr[0]
                this.queryParams.expectBackStoreTimeEnd = this.expectBackStoreTimeStrArr[1]
            } else {
                this.queryParams.expectBackStoreTimeStart = ''
                this.queryParams.expectBackStoreTimeEnd = ''
            }
            this.myOpportunity(this.queryParams)
        },
        restart() {
            this.page.current = 1;
            this.queryParams.id = '';
            this.backStoreTimeArr = [];
            this.expectBackStoreTimeStrArr = [];
            this.queryParams.telephone = '';
            this.queryParams.isAddWechat = '';
            this.doSearch();
        },
        uploadExpectBackStoreTime() {
            this.upload.open = true;
            this.upload.totalCount = 0;
            this.upload.successCount = 0;
            this.upload.errMsgList = [];
            this.upload.isShowResult = false;
        },
        exportMyOpportunity() {
            axios({
                method: "POST",
                url: `/api/manage/crm/counselor/exportMyOpportunity`,
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                data: this.queryParams,
                responseType: 'blob',
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '批量指派模板.xlsx';
                const eLink = document.createElement('a')
                eLink.download = fileName;
                eLink.style.display = 'none';
                eLink.href = URL.createObjectURL(blob);
                document.body.appendChild(eLink);
                eLink.click();
                URL.revokeObjectURL(eLink.href); // 释放URL 对象
                document.body.removeChild(eLink);
            }).catch(error => {
                this.$message.error("导出模板失败");
            })
        },
        /**
         * 批量回库
         */
        batchGiveUp() {
            if (!this.backIntention) {
                this.$message.error("请选择回库原因!");
                return
            }
            let params = {"intention": this.backIntention}
            api.giveMessageBack(this.backData, params).then(res => {
                if (res.success) {
                    this.$message.success("操作成功")
                    this.backSync = false
                    this.myOpportunity(this.queryParams)
                } else {
                    this.$message.warning(res.msg);
                }
            }).catch(error => {
                this.$message.error("批量回库失败");
            })
        },
    },
    filters: {
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 8) {
                return value.slice(0, 8) + '...'
            }
            return value
        }
    },
    created() {
        const now = new Date();
        this.queryParams.haveTimeStart = new Date(now.getTime() - (7 * 86400000)).format("yyyy-MM-dd HH:mm:ss")
        this.queryParams.haveTimeEnd = new Date(now.getTime() - (4 * 86400000)).format("yyyy-MM-dd HH:mm:ss")
        this.findIntention();
    },
    mounted() {
        this.getColumn()
        this.myOpportunity(this.queryParams);
    }
}
</script>

<style scoped>
/deep/ .telephone {
    cursor: pointer;
}
</style>
