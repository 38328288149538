import Cookies from 'js-cookie'

const TokenKey = 'sinKey'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    if (token) {
        let num = 8 //失效时间是几小时
        let time = new Date(new Date().getTime() + num * 60 * 60 * 1000);
        return Cookies.set('sinKey', token, {
            expires: num
        })
    }
}

// export function setToken(name,value,time){
//     var strsec = getsec(time);
//     var exp = new Date();
//     exp.setTime(exp.getTime() + strsec*1);
//     document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
// }
// function getsec(str){
//     alert(str);
//     var str1=str.substring(1,str.length)*1;
//     var str2=str.substring(0,1);
//     if (str2=="s"){
//         return str1*1000;
//     }else if (str2=="h"){
//         return str1*60*60*1000;
//     }else if (str2=="d"){
//         return str1*24*60*60*1000;
//     }
// }

export function removeToken() {
    return Cookies.remove(TokenKey)
}

//个人信息
export function getInformation() {
    let information = Cookies.get('information')
    if (information) {
        let userObj = JSON.parse(information)
        return userObj
    }
}

export function setInformation(information) {
    information['permissions'] = null
    let num = 8 //失效时间是几小时
    let time = new Date(new Date().getTime() + num * 60 * 60 * 1000);
    return Cookies.set('information', information, {
        expires: time
    })
}

export function removeInformation() {
    return Cookies.remove('information')
}

export function getUserId() {
    return getInformation().id
}

export function getPersonalPhone() {
    return getInformation().personalPhone
}

export function getUserName() {
    return getInformation().nickName
}

export function getAccountName() {
    return getInformation().userName
}

export function getDataKey() {
    return Cookies.get('dataKey')
}

/**
 * 获取个人信息字段
 * {"id":1,"token":"MMcCylrY12JHN525uUTBxJ97603C2E762877YD7zhzo9e3v32F5e53iR1991d9ot","userName":"admin","nickName":"管理员","loginTime":1713231348627,"expireTime":null,"ipaddr":"192.168.110.1","permissions":["*"],"roles":null,"roleStr":",6,","account":null,"menus":null,"deptId":1,"deptName":null,"trafficId":null,"configureId":52050710,"configMarketId":null,"telephone":"15300162250","isAdmin":1,"dataScope":1,"encode":false,"hideFirst":false,"doubleName":"KEY20240322102007","personalPhone":"18986657978"}
 * @param field 具体字段
 * @returns {*|null}
 */
export function getInformationField(field) {
    try {
        const informationStr = Cookies.get('information');
        const informationJson = JSON.parse(informationStr);
        return informationJson[field] || null;
    } catch (e) {
        return null;
    }
}

/*获取Time的cookie*/
export function setDateTime(dateTime) {
    let num = 24 //失效时间是几天
    let time = new Date(new Date().getTime() + num * 24 * 60 * 60 * 1000);
    return Cookies.set('dateTime', dateTime, {
        expires: time
    })
}

export function getDateTime() {
    return Cookies.get('dateTime')
}

export function removeDateTime() {
    return Cookies.remove('dateTime')
}