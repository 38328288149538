import request from '@/utils/request.js';

//获取活码专用列表
export function getLiveCodeMaterialList() {
    return request.get(`${process.env.VUE_APP_BASEURL_API2}/sphere/material/conditionList`);
}

export function getDetailList(data,query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/we/work/privateDomainDetailPage`,
        method: 'post',
        params: query,
        data: data
    })
}

export function  downloadUrlFile(url){

    // sec-ch-ua:
    // "Not)A;Brand";v="99", "Microsoft Edge";v="127", "Chromium";v="127"
    // sec-ch-ua-mobile:
    //     ?0
    //     sec-ch-ua-platform:
    // "Windows"
    // user-agent:
    // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36 Edg/127.0.0.0
    return request({
        url:  url,
        method: 'get',
        headers :{
            'sec-ch-ua':  'Not)A;Brand";v="99", "Microsoft Edge";v="127", "Chromium";v="127',
            'responseType': 'blob',
            'sec-ch-ua-mobile':'?0',
            'sec-ch-ua-platform':'"Windows"',
            'user-agent':'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36 Edg/127.0.0.0',
            'Access-Control-Allow-Origin' :'*'
        }
    })
}

export function addLiveCodePoster(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/liveCode/generateLink`,JSON.stringify(params));
}

export function downLoadPhoto(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/liveCode/downLoadPoster?posterLink=${params}`,);
}

// export function queryLinkList(params,query) {
//     return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/liveCode/linkList`,JSON.stringify(params),JSON.stringify(query));
// }

export function queryLinkList(params,query) {
    return request({
        url: `${process.env.VUE_APP_BASEURL_API2}/sphere/liveCode/linkList`,
        method: 'post',
        params: query,
        data: params
    })
}

export function showImg(params) {
    return request.post(`${process.env.VUE_APP_BASEURL_API2}/sphere/liveCode/linkList`,JSON.stringify(params));
}