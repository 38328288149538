import { render, staticRenderFns } from "./poster.vue?vue&type=template&id=0c902ab0&scoped=true&"
import script from "./poster.vue?vue&type=script&lang=js&"
export * from "./poster.vue?vue&type=script&lang=js&"
import style0 from "./poster.vue?vue&type=style&index=0&id=0c902ab0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c902ab0",
  null
  
)

export default component.exports