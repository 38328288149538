<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" @submit.prevent.native>
      <el-form-item label="名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
            @submit.native.prevent="false"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            v-permission="'crm:possess:add'"
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            v-permission="'crm:possess:update'"
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
        >修改
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="possessList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="主键" align="center" prop="id"/>
      <el-table-column label="名称" align="center" prop="name"/>
      <el-table-column label="标识部门" align="center" prop="deptIds"/>
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.status"
              :active-value="0"
              :inactive-value="1"
              @change="handleCanAdd(scope.row.id,scope.row.status,scope.row.canAdd)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否可添加" align="center" prop="canAdd">
        <template slot-scope="scope">
            <el-switch
                    v-model="scope.row.canAdd"
                    :active-value="1"
                    :inactive-value="0"
                    @change="handleCanAdd(scope.row.id,scope.row.status,scope.row.canAdd)"
            ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.limit"
        @pagination="getList"
    />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="180px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"/>
        </el-form-item>
        <el-form-item label="每日首咨领取数量" prop="firstNumber">
          <el-input v-model="form.firstNumber"/>
        </el-form-item>
        <el-form-item label="首咨推迟领取(小时)" prop="firstTime">
          <el-input v-model="form.firstTime" type="number"/>
        </el-form-item>
        <el-form-item label="每日库存领取数量" prop="opporNumber">
          <el-input v-model="form.opporNumber"/>
        </el-form-item>
        <el-form-item label="部门" prop="deptIds">
          <el-tree
              ref="deptForm"
              :data="deptTree"
              show-checkbox
              node-key="id"
              :default-checked-keys="checkedDept"
              :default-expand-all="expandall"
              :props="defaultProps">
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as possApi from "@/api/crm/possess";

export default {
  name: "Possess",
  components: {},
  data() {
    var validateNumber = (rule, value, callback) => {
      if (value == null || value.length > 4) {
        callback(new Error('最大允许输入9999'))
      }
      if (!(/^(\d+)$/).test(value)) {
        callback(new Error('请输入数字值'))
      } else {
        callback()
      }
    };
    return {
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 参数配置表格数据
      possessList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        name: null
      },
      // 表单参数
      form: {},
      // 表单校验
      checkAll: false,
      isIndeterminate: true,
      depts: [],
      deptId: 0,
      deptTree: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      deptIds: null,
      checkedDept: [],
      expandall: true,
      rules: {
        firstNumber: [{validator: validateNumber, trigger: 'blur'}],
        firstTime: [{validator: validateNumber, trigger: 'blur'}],
        opporNumber: [{validator: validateNumber, trigger: 'blur'}],
      }
    };
  },
  created() {
    this.getList();
    this.getDeptTree();
  },
  methods: {
    getDeptTree(id) {
      possApi.findDeptTreeByPoss(id).then(res => {
        this.deptTree = res.data;
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true;
      possApi.listPossess(this.queryParams).then(response => {
        this.possessList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });

    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.receiveSync = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.receiveForm = {}
      this.form = {};
      this.checkedDept = []
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加配置";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      possApi.getPossess(id).then(response => {
        this.form = response.data;
        this.checkedDept = this.form.deptIds;
        this.open = true;
        this.title = "修改配置";
        this.$nextTick(function () {
          this.$refs.deptForm.setCheckedKeys(this.checkedDept);
        })
      });
    },
    /** 提交按钮 */
    submitForm() {
      console.log()
      this.$refs["form"].validate(valid => {
        if (valid) {
          const formDept = this.$refs.deptForm.getCheckedKeys(true);
          this.form.deptIds = formDept;
          if (this.form.id != null) {
            possApi.updatePossess(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            possApi.addPossess(this.form).then(response => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    handlecheckedDeptsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.depts.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.depts.length;
      },
    handleSwitch(id, status) {
      possApi.changeStatus(id, status).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
      })
      // value === 1 关闭
      // value === 0 启用
    },
    handleCanAdd(id,status,canAdd) {
        possApi.changeCanAdd(id, status, canAdd).then(res => {
            if (res.success) {
                this.$message.success(res.msg)
            } else {
                this.$message.warning(res.msg)
            }
        })
        // value === 1 关闭
        // value === 0 启用
    }
  }
};
</script>
