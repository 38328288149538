<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="queryParams.name" placeholder="名称" clearable size="small"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryParams.status" filterable class="condition-select" size="small"
                   placeholder="请选择">
          <el-option value="" label="全部"></el-option>
          <el-option :value="1" label="正常"></el-option>
          <el-option :value="0" label="停用"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="activatedList">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button type="success" icon="el-icon-circle-plus-outline" size="mini"
                   @click="dialogFormVisible = true">新建券码
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="activatedTable"
        stripe
        style="width: 100%">
      <el-table-column
          align="center"
          prop="name"
          label="名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="courseName"
          label="对应商品">
      </el-table-column>
      <el-table-column
          align="center"
          prop="sumNumber"
          label="券码总量">
      </el-table-column>
      <el-table-column
          align="center"
          prop="lastNumber"
          label="剩余数量">
      </el-table-column>
      <el-table-column
          align="center"
          prop="status"
          label="状态">
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.status"
              @change="chanageStatus(scope.row)"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="createTime"
          label="创建时间">
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="codeShow(scope.row.id)"
          >查看兑换码
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="generateShow(scope.row.id)"
          >生成兑换码
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="edit(scope.row,scope.row.id)"
          >修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30, 40]" :page.sync="queryParams.page"
                @pagination="activatedList"
                :limit.sync="queryParams.limit"/>
    <el-dialog
        title="查看兑换码"
        :visible.sync="codeVisible"
        width="600px"
    >
      <el-row align="center">
        <span>剩余券码数量:{{codeList.length}}</span>
      </el-row>
      <el-table
          :data="codeList"
          stripe>
      <el-table-column
          align="center"
          prop="code"
          label="券码">
      </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="codeVisible = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="生成兑换码"
        :visible.sync="generateVisible"
        width="600px"
        @close="resetFrom('roleForm')"
    >
      <el-form
          :model="generateForm"
          ref="generateForm"
          label-width="120px"
      >
        <el-form-item label="生成数量">
          <el-input v-model="generateForm.size"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="generateVisible = false">取消</el-button>
        <el-button type="success" @click="generate">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogFormVisible"
        width="600px"
        @close="resetFrom('roleForm')"
    >
      <el-form
          :model="dialogForm"
          ref="roleForm"
          label-width="120px"
      >
        <el-form-item label="名称">
          <el-input v-model="dialogForm.name"></el-input>
        </el-form-item>
        <el-form-item label="选择商品">
          <el-select v-model="dialogForm.course">
            <el-option :value="td" :label="td.courseName" v-for="td of tableData" :key="td.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="success" @click="save('roleForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import * as api from "@/api/mini/activated";
import * as apiUtils from "../../utils/api";

export default {
  name: "activated",
  components: {},
  data() {
    return {
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10
      },
      activatedTable: [],
      dialogFormVisible: false,
      dialogTitle: "",
      dialogForm: {
        name: ""
      },
      dialogForm_null: Object.assign({}, this.dialogForm),
      pages: {
        current: 1,
        size: 1000
      },
      formData: {
        subjectIds: "",
        courseName: ""
      },
      tableData: [],
      generateVisible: false,
      generateForm: {},
      codeList: [],
      codeVisible: false
    }
  },
  mounted() {
    this.activatedList();
    this.findGoods();
  },
  methods: {
    codeShow(id) {
      api.codeList({"activatedId": id}).then(res => {
        this.codeList = res.data
        this.codeVisible = true
      })
    },
    generateShow(id) {
      this.generateForm.id = id
      this.generateVisible = true
    },
    //生成兑换码
    generate() {
      api.genCode(this.generateForm.id, this.generateForm.size).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.generateVisible = false
      })
    },
    findGoods() {
      apiUtils.goodList(this.pages.current, this.pages.size, this.formData).then(res => {
        this.tableData = res.data.data;
        this.pages.current = res.data.pageNum;
        this.pages.size = res.data.pageSize;
        this.pages.total = res.data.total;
      })
    },
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams = {};
      this.activatedList();
    },
    /*
    * 获取列表数据
    * */
    activatedList() {
      api.getList(this.queryParams).then(res => {
        this.activatedTable = res.data.data
        this.total = res.data.count
      });
    },
    //重置表单规则
    resetFrom(roleForm) {
      this.$refs[roleForm].clearValidate();
    },
    handleButton(val) {
      //调用事件
      this[val.methods](val.row, val.index);
    },
    edit(row, index) {
      this.dialogTitle = "修改配置";
      this.dialogForm = Object.assign({}, row);
      this.dialogFormVisible = true;
    },
    /*
    * 新增/修改 保存列表数据
    * */
    save(roleForm) {
      this.$refs[roleForm].validate(valid => {
        if (valid) {
          this.dialogForm.courseName = this.dialogForm.course.courseName
          this.dialogForm.courseId = this.dialogForm.course.id
          let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
          api.save(this.dialogForm).then(res => {
            console.log(res, 'ressss')
            this.activatedList()
            this.$message({
              type: "success",
              message: id ? "修改成功！" : "新增成功！"
            });
            this.dialogFormVisible = false;
          });

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /*
    * 删除
    * */
    deleteCurrent(id) {
      api.deleteCurrent(id).then(res => {
        this.pagedomainList()
        this.$message({
          type: "success",
          message: "删除成功"
        });
      })
    },
    chanageStatus(row) {
      let status = !row.status ? 0 : 1
      api.updateStatus(row.id, status).then(res => {
        this.$message({
          type: "success",
          message: "修改状态成功!"
        });
      })
    },

  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
