import request from '@/utils/request.js';

const url = "/manage/system/dictionary";

//获取列表数据
export function listDictionary(query) {
    return request({
        url: `${url}/listPage`,
        method: 'get',
        params: query
    })
}

export function list(param) {
    return request({
        url: `${url}/list`,
        method: 'get',
        params: param
    });
}

//查询父级下数据
export function findParent(id) {
    return request.get(`${url}/findParent/${id}`);
}

//通过ID获取
export function get(id) {
    return request.get(`${url}/get/${id}`);
}

//删除
export function deleteDictionary(id) {
    return request.get(`${url}/delete/${id}`);
}

//保存
export function saveDictionary(param) {
    return request.post(`${url}/save`, param);
}

/**
 * 通过CODE查找所有子集
 */
export function findChildrenByCode(code) {
    return request.get(`${url}/findChildrenByCode/${code}`);
}

/**
 * 获取所有字典集合
 */
export function findExtraDictMap() {
    return request.get(`${url}/find-extra-dict-map`);
}