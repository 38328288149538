import request from '@/utils/request.js';

const url = "/manage/subject";

//获取列表数据
export function getList(params) {
    return request({
        url: `${url}/listTree`,
        method: 'get',
        params: params
    })
}

//获取父级列表
export function subjectList(params) {
    return request({
        url: `${url}/subjectList`,
        method: 'get',
        params: params
    })
}

//保存数据
export function save(params) {
    return request.post(`${url}/save`, JSON.stringify(params));
}

//删除
export function remove(id) {
    return request({
        url: `${url}/remove/${id}`,
        method: 'get'
    })
}

//禁用
export function forbids(id) {
    return request({
        url: `${url}/forbids/${id}`,
        method: 'get'
    })
}
