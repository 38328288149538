<template>
    <div>
        <el-dialog
                :title="wechatDialogVisible.addTitle"
                :visible.sync="wechatDialogVisible.addWechat"
                width="30%"
                append-to-body
                @close="wechatDialogVisible.copyWechat=true">

            <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
                <div>
                    <span
                            v-if="wechatDialogVisible.copyWechat && this.dataMessage.weiXin !== null">
                        微信号 : * * * * * * <i class="el-icon-view"></i>
                    </span>

                    <span
                            v-if="!wechatDialogVisible.copyWechat && dataMessage.weiXin !== null">
                        微信号:{{ dataMessage.weiXin }}
                    </span>

                    <span
                            v-if="this.dataMessage.weiXin === null">
                        尚 未 添 加
                    </span>
                </div>
                <el-button type="text" @click="copyWechat" v-if="this.dataMessage.weiXin !== null">复制微信号
                </el-button>
            </div>


            <span slot="footer" class="dialog-footer">
    <el-button @click="wechatDialogVisible.addWechat = false">取 消</el-button>
    <el-button type="warning" @click="updateWechat">修改微信</el-button>
    <el-button type="primary" @click="wechatDialogVisible.addWechat = false">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog
                :title="wechatDialogVisible.updateTitle"
                :visible.sync="wechatDialogVisible.updateWechat"
                width="25%"
                append-to-body>

            <el-col>
                <el-radio v-model="wechatDialogVisible.updateRadio" :label="1">微信号</el-radio>
                <el-radio v-model="wechatDialogVisible.updateRadio" :label="2">同手机号</el-radio>
            </el-col>

            <br>
            <br>

            <el-col>
                <span v-if="wechatDialogVisible.updateRadio === 2">
                    微信号:  {{ wechatDialogVisible.realTelephone }}
                </span>

                <el-input
                        v-else
                        v-model="wechatDialogVisible.newTelephone"
                        placeholder="请输入用户新的手机号"
                        size="mini">
                </el-input>
            </el-col>

            <span slot="footer" class="dialog-footer">
    <el-button @click="wechatDialogVisible.updateWechat = false">取 消</el-button>
    <el-button type="primary" @click="submitUpdateWechat">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import * as api from "@/utils/api";
import {copy} from "@/utils/utils";

export default {
    name: "AddWechat",
    props: {
        id: Number,
        dataMessage: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            wechatDialogVisible: {
                addTitle: '添加微信',
                updateTitle: '修改微信',
                addWechat: false,
                updateWechat: false,
                copyWechat: true,
                updateRadio: 1,
                newTelephone: '',
                realTelephone: ''
            },
        }
    },
    methods: {
        openDialog() {
            this.wechatDialogVisible.addWechat = true
        },
        closeDialog() {
            this.wechatDialogVisible.addWechat = false
        },
        updateWechat() {
            api.getTelephone(this.dataMessage.id).then(resp => {
                if (resp.success) {
                  this.wechatDialogVisible.realTelephone = resp.msg
                }else{
                  this.$message.error(resp.msg)
                }
            })
            this.wechatDialogVisible.updateWechat = true
        },
        submitUpdateWechat() {
            let param = {"isAddWechat": 1}
            if (this.wechatDialogVisible.updateRadio === 1) {
                this.$set(param, "weiXin", this.wechatDialogVisible.newTelephone)
            } else if (this.wechatDialogVisible.updateRadio === 2) {
                this.$set(param, "weiXin", this.wechatDialogVisible.realTelephone)
            }
            api.saveMessage(this.dataMessage.id, param).then(resp => {
                if (resp.success) {
                    this.wechatDialogVisible.updateWechat = false
                    this.dataMessage.weiXin = param.weiXin
                    this.wechatDialogVisible.newTelephone = ''
                    this.dataMessage.isAddWechat = 1
                    this.$message.success("修改成功")
                } else {
                    this.$message.error("修改失败")
                }
            })
        },
        copyWechat() {
            api.getTelephone(this.dataMessage.id).then(resp => {
                if (resp.success) {
                    this.wechatDialogVisible.realTelephone = resp.msg
                    this.wechatDialogVisible.copyWechat = false;
                }else{
                  this.$message.error(resp.msg)
                }
            })
            const text = this.dataMessage.weiXin;
            if (text === null || text === '') {
                this.$message.error('未添加微信复制失败');
                return
            }

            api.saveMessage(this.dataMessage.id, {"isAddWechat": 1}).then(resp => {
                if (resp.success) {
                    copy(text)
                    this.$message.success('已成功复制到剪贴板并且添加微信');
                } else {
                    this.$message.error("修改失败")
                }
            })
        },
    }
}
</script>

<style scoped>
/deep/ .el-dialog {
    border-radius: 25px;

}


</style>