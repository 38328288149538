<template>
    <div>
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
            <el-form-item label="名称" prop="name">
                <el-input v-model="queryParams.name" placeholder="备注名" clearable size="small"/>
            </el-form-item>
            <el-form-item label="应用ID" prop="appId">
                <el-input v-model="queryParams.appId" placeholder="应用ID" clearable size="small"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="configList">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                <el-button type="success" icon="el-icon-plus" size="mini" @click="addConfig">新增</el-button>
            </el-form-item>
        </el-form>

        <el-table
                :data="configTable"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="name"
                    label="备注名">
            </el-table-column>
            <el-table-column
                    prop="appId"
                    label="应用ID">
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.status"
                            @change="chanageConfig(scope.row)"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="edit(scope.row,scope.row.id)"
                    >修改
                    </el-button>
                    <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            @click="deleteCurrent(scope.row.id)"
                    >删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="10" :page-sizes="[10, 20, 30, 40]" :page.sync="queryParams.page"
                    @pagination="configList"
                    :limit.sync="queryParams.limit"/>

        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogFormVisible"
                width="600px"
                @close="resetFrom('roleForm')"
        >
            <el-form
                    :model="dialogForm"
                    ref="roleForm"
                    label-width="120px"
            >
                <el-form-item label="备注名称">
                    <el-input v-model="dialogForm.name"></el-input>
                </el-form-item>
                <el-form-item label="应用ID">
                    <el-input v-model="dialogForm.appId"></el-input>
                </el-form-item>
                <el-form-item label="应用私钥">
                    <el-input v-model="dialogForm.privateKey"></el-input>
                </el-form-item>
                <el-form-item label="支付宝公钥">
                    <el-input v-model="dialogForm.publicKey"></el-input>
                </el-form-item>
                <el-form-item label="灯火平台Token">
                    <el-input v-model="dialogForm.bizToken"></el-input>
                </el-form-item>
                <el-form-item label="商户标识">
                    <el-input v-model="dialogForm.principalTag"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="success" @click="save('roleForm')">保存</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import * as api from "@/api/mini/promoteConfig";

    export default {
        name: "promoteConfig",
        data() {
            return {
                // 总条数
                total: 0,
                // 查询参数
                queryParams: {
                    page: 1,
                    limit: 10
                },
                configTable: [],
                dialogFormVisible: false,
                dialogTitle: "",
                dialogForm: {
                    name: "",
                    detail: ""
                },
                dialogForm_null: Object.assign({}, this.dialogForm),
            }
        },
        mounted() {
            this.configList();
        },
        methods: {
            resetQuery() {
                this.resetForm("queryForm");
                this.queryParams = {};
                this.configList();
            },
            /*
            * 获取列表数据
            * */
            configList() {
                api.getList(this.queryParams).then(res => {
                    this.configTable = res.data
                    this.total = res.count
                });
            },
            //新增
            addConfig() {
                this.dialogTitle = "添加配置";
                this.dialogForm = Object.assign({}, this.dialogForm_null);
                this.dialogFormVisible = true;
            },
            //重置表单规则
            resetFrom(roleForm) {
                this.$refs[roleForm].clearValidate();
            },
            handleButton(val) {
                //调用事件
                this[val.methods](val.row, val.index);
            },
            edit(row, index) {
                this.dialogTitle = "修改配置";
                this.dialogForm = Object.assign({}, row);
                this.dialogFormVisible = true;
            },

            /*
            * 新增/修改 保存列表数据
            * */
            save(roleForm) {
                this.$refs[roleForm].validate(valid => {
                    if (valid) {
                        let id = this.dialogForm.id;  //获取弹窗表单里是否有id，有id为编辑，没有的话为新增，点击编辑时已经从表格中获取到该行的值了
                        api.save(this.dialogForm).then(res => {
                            console.log(res, 'ressss')
                            this.configList()
                            this.$message({
                                type: "success",
                                message: id ? "修改成功！" : "新增成功！"
                            });
                            this.dialogFormVisible = false;
                        });

                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /*
            * 删除
            * */
            deleteCurrent(id) {
                api.deleteCurrent(id).then(res => {
                    this.pagedomainList()
                    this.$message({
                        type: "success",
                        message: "删除成功"
                    });
                })
            },
            chanageConfig(row) {
                let status = !row.status ? 0 : 1
                api.updateStatus(row.id, status).then(res => {
                    this.$message({
                        type: "success",
                        message: "修改状态成功!"
                    });
                })
            }
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>