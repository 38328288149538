import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./router/permission"
import './plugin'
import VueClipboard from 'vue-clipboard2'
import "normalize.css/normalize.css"; // 这个是初始化css
import '@/assets/css/common.css'
import "../src/utils/mixin-btn-listener"

import "./utils/custom-prototype"
import {
    addDateRange,
    handleTree,
    parseTime,
    resetForm,
    selectDictLabel,
    selectDictLabels,
    formatSeconds
} from "@/utils/utils";
import {isPermission} from "./utils/api";

import Pagination from "@/components/Pagination";
import {removeInformation, removeToken} from "@/utils/auth";

const AV = require('leancloud-storage');
AV.init({
    appId: "mHdTP7Kas6PxkWQ97WKebrcc-gzGzoHsz",
    appKey: "hpaIM1g5UbnbuP0qcgwcdMxu",
    serverURL: "https://mhdtp7ka.lc-cn-n1-shared.com"
});

// 定义事件总线
let bus = new Vue()
Vue.prototype.bus = bus

Vue.config.productionTip = false
Vue.use(VueClipboard)
//分页组件
Vue.component('Pagination', Pagination)

//消息弹窗
Vue.prototype.msgSuccess = function (msg) {
    this.$message({showClose: true, message: msg, type: "success"});
}

// 全局方法挂载
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.formatSeconds = formatSeconds
/**
 * 自定义指令，按钮是否有权限
 */
Vue.directive("permission", {
    inserted(el, binding) {
        el.style.display = "none";
        let permission = binding.value
        let permissionStr = localStorage.getItem("permission");
        if ((!permissionStr)) {
            removeToken()
            removeInformation()
            router.push("/login");
            return;
        }
        let hasPermission = permissionStr.indexOf(permission) !== -1;
        if (permissionStr === '*' || hasPermission) {
            el.style.display = "inline-block";
        } else isPermission(permission).then(hasPermission => {
            if (hasPermission) {
                el.style.display = "inline-block";
            }
        })
    }
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')






